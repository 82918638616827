// eslint-disable-next-line no-useless-concat
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import * as React from "react";
import { useDispatch } from "react-redux";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { DialogComponent } from "../../components/dialog";
import { CellRender, RowRender } from "../../components/renderers";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential } from "../../lib/cookies";
import { removeMessage } from "../../redux/actions/setting-actions";
import MessageSchema from "../../utils/messageSchema";
import {
  getfilterDataString,
  changeCancelBtnState,
  changeUpdateBtnState,
  // isSameRow,
  debounceForFunc,
  executeGetColumnOrderInfo,
} from "../../utils/utils";
import useApi from "./service";
import { LoadingPanel } from "../../components/loadingPanel";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { useHistory } from "react-router";
import useColumnReorder from "src/custom-hooks/useColumnReorder";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "comp_plan_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

export const PlanData = (restProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cookiesData = getUserCredential();
  const [list, Action] = useApi();
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const {
    data,
    columns,
    dataCount,
    setData,
    originalData,
    isAPICalling,
    setConfirmationPopup,
    confirmationPopup,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
    setLoadingStateSearch,
    loading,
    setColumns,
  } = list;

  const { columnReorderData } = useColumnReorder(
    cookiesData,
    setColumns,
    "",
    "procare_system",
    setLoadingStateSearch,
);
  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [exportData, setExportData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [errorObj, setErrorObj] = React.useState({});
  const [gridColumns, setGridColumns] = React.useState([]);
  const [resetSort, setResetSort] = React.useState(false);
  const [filterField, setFilterField] = React.useState({
    logic: "and",
    filters: [],
  });
  const [sortField, setSortField] = React.useState([]);
  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_user_role_filter: "> 0",
    in_load_type: "data-reload",
  };

  if (document.getElementById("grid-incell-cancel")) {
    document.getElementById("grid-incell-cancel").onclick = function (e) {
      if (e.isTrusted) {
        // TODO : UNSAVED CHANGES
        // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));

        changeCancelBtnState();
        changeUpdateBtnState();
        restProps.setisCancelClick(true);
        restProps.isPlanEdit === true && cancelPlanGridChanges();
        restProps.setCancelKpiAwaitStatus(false);
      } else {
        const inputCell = document.activeElement.closest("td");
        if (!inputCell) {
          return false;
        }
        const cellClass = inputCell.classList[0];

        switch (cellClass) {
          case "plan-field":
            cancelPlanGridChanges();
            break;
          case "kpi-field":
            restProps.setCancelKpiAwaitStatus(false);
            break;
          default:
            return false;
        }
      }
    };
  }

  if (document.getElementById("grid-incell-update")) {
    document.getElementById("grid-incell-update").onclick = function (e) {
      restProps.isPlanEdit === true && updatePlanGridChanges();
      restProps.isKpiEdit === true && updateKpiGridChanges();
      restProps.isProviderEdit === true && updateproviderGridChanges();
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
    };
  }

  React.useEffect(() => {
    if (restProps.isRefreshBtnClick) {
      restProps.setRefreshBtnClick(false);
      changeCancelBtnState();
      changeUpdateBtnState();
      restProps.setisCancelClick(true);
      restProps.isPlanEdit === true && cancelPlanGridChanges();
      restProps.setCancelKpiAwaitStatus(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isRefreshBtnClick]);

  React.useEffect(() => {
    if (restProps.isEffectiveVersionDelete) {
      restProps.setSelectedRowId("");
      setSelectedState({});
      restProps.setIsEffectiveVersionDelete(false);
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isEffectiveVersionDelete]);

  React.useEffect(() => {
    if (restProps.isAddNewConfig) {
      restProps.setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAddNewConfig]);

  const handleDeleteData = async () => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
      };
      let res = await Action.getListDataLengthAsync(dataVal);

      if (res.length === 0) {
        setResetSort(true);

        // Set filter to false
        setFilterData({
          ...filterData,
          data: "",
          isFilter: false,
        });
        onDeleteRefreshClick();
      } else {
        onRefreshClick();
      }
    }
  };

  const handleAddData = async () => {
    if (restProps.isAPICall) {
      restProps.setSelectedRowId("");
      setSelectedState({});
      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
      };
      let res = await Action.getListDataLengthAsync(dataVal);

      if (res.length === 0) {
        setResetSort(true);

        // Set filter to false
        setFilterData({
          ...filterData,
          data: "",
          isFilter: false,
        });
        onDeleteRefreshClick();
      } else {
        onAddRefreshClick();
      }
    }
  };
  const onAddRefreshClick = async () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    getGridData(dataVal);
  };
  const onDeleteRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: DEFAULT_SORT.number,
      sort_order: DEFAULT_SORT.order,
    };
    setResetSort(false);
    getGridData(dataVal);
  };

  React.useEffect(() => {
    handleDeleteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    handleAddData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (restProps.isShow.deleteRows) {
      remove();
      restProps.setShow({ ...restProps.isShow, deleteRows: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isShow.deleteRows]);

  React.useEffect(() => {
    restProps.setDataList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setDataList(data);
      restProps.setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    if (originalData && originalData.length > 0) {
      let prevSelected = originalData.find(
        (obj) => obj.comp_plan_mstr_key == restProps.selectedRowId
      );

      setNewSelectedRow(
        prevSelected !== undefined
          ? { [prevSelected.comp_plan_mstr_key]: true }
          : { [originalData[0].comp_plan_mstr_key]: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalData]);

  React.useEffect(() => {
    const dataaVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
    };
    getGridData(dataaVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (restProps.isCheckColumn) {
      setGridColumns(restProps.columnsData);
      restProps.setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isCheckColumn]);

  const getGridData = async (inParams, isFilter, isSort) => {
    setErrorObj({});
    restProps.setisPlanEdit(false);
    let res = await Action.getListDataAsync(inParams);
    if (res.length > 0 && (isFilter || isSort)) {
      restProps.setPlanGridRefresh(true);
    }
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const cancelPlanGridChanges = () => {
    dispatch(
      removeMessage({
        message: "",
        type: "Error",
        show: false,
      })
    );
    originalData.map((item) => {
      item.inEdit = false;
      item.selected = false;
      return 0;
    });
    restProps.setisPlanEdit(false);
    setData(originalData);
    setErrorObj({});
    restProps.setDataList(originalData);
  };

  const updateKpiGridChanges = () => {
    restProps.setisKpiUpdateClick(true);
  };

  const updateproviderGridChanges = () => {
    restProps.setisProviderUpdateClick(true);
  };

  const handleValidation = () => {
    let isValid = true;
    data
      .filter((x) => x.inEdit !== undefined)
      .map((item) => {
        const { comp_plan_name, rowId } = item;
        let errorObj = {
          comp_plan_name: "",
        };

        if (comp_plan_name.trim() === "") {
          errorObj.comp_plan_name = MessageSchema.planTableRequired;
        } else {
          errorObj.comp_plan_name = "";
        }

        let obj = {};

        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);

        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }

        return 0;
      });

    return isValid;
  };

  const updatePlanGridChanges = () => {
    let valid = handleValidation();

    if (valid) {
      updatePlanData();
    } else {
      const elements = document.getElementsByClassName("k-textbox");
      elements[0].focus();

      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
    }
  };

  const updatePlanData = async () => {
    let valid = handleValidation();
    if (valid) {
      let updatedPlanDataList = [];

      //update plan data
      data
        .filter((x) => x.inIteamEdit === true)
        .map((item) => {
          let obj = {};

          obj.in_user_id = cookiesData.out_user_id.toString();
          obj.in_tenant_id = cookiesData.out_tenant_id;
          obj.in_comp_plan_mstr_key = item.comp_plan_mstr_key;
          obj.in_comp_plan_name = item.comp_plan_name;
          obj.in_comp_plan_desc = item.comp_plan_desc;
          obj.in_period_mstr_key = item.period_mstr_key;

          updatedPlanDataList.push(obj);

          return 0;
        });

      const updatePlanBodyData = {
        updateEvent: [...updatedPlanDataList],
      };
      if (updatedPlanDataList.length > 0) {
        let response = await Action.updateDataAsync(updatePlanBodyData);

        if (response) {
          data.map((item) => {
            item.inEdit = false;
            return 0;
          });

          restProps.setisPlanEdit(false);
          onRefreshAllData();
        }
      }
    } else {
      cancelPlanGridChanges();
    }
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  const onDeleteConfirm = (event) => {
    const deleteArray = deleteSelectedIds.map((obj2) => {
      const obj1 = data.find(
        (elem) => elem.comp_plan_mstr_key === obj2.mstr_key
      );
      return {
        mstr_key: obj2.mstr_key,
        compPlanName: obj1.comp_plan_name,
        //date_version: obj1.date_version,
      };
    });
    let deleteData = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_version_data: deleteArray,
    };
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    Action.deleteDataAsync(deleteData).then(function (result) {
      if (result === "success") {
        setDeleteSelectedIds([]);
      }
    });

    setErrorObj({});
    restProps.setisPlanEdit(false);
  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setData([...data]);
  };

  const onRowClick = (dataItem) => {
    if (restProps.isAPICall === true) {
      restProps.setAPICall(false);
      //return false;
    }
    if (originalData.length <= 0 || data.length <= 0) {
      restProps.setSelectedRowId("");
      return false;
    }
    //default select clicked row
    restProps.setSelectedRowId(dataItem.comp_plan_mstr_key);
  };

  const setNewSelectedRow = (newState, isReset) => {
    const keys = Object.keys(newState).filter((key) => newState[key] === true);
    setSelectedState(newState);
    let tempArray = [];
    keys.map((item) => {
      if (newState[item] === false) {
        return 0;
      }
      let obj = {};
      obj.mstr_key = Number(item);
      tempArray.push(obj);
      return 0;
    });
    setDeleteSelectedIds(tempArray);
    const selectedKeyArr = keys.filter(function (x) {
      return newState[x] !== false;
    });
    if (isReset) {
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = selectedKeyArr.includes(
          item.comp_plan_mstr_key.toString()
        );
        return 0;
      });
      const item = originalData.find((it) => it.selected === true);
      setData(originalData);
      restProps.setDataList(originalData);
      debounceForFunc(() => onRowClick(item), 100);
    } else {
      let newData = data.map((item) => ({
        ...item,
        selected: selectedKeyArr.includes(item.comp_plan_mstr_key.toString()),
      }));
      const item = newData.find((it) => it.selected === true);
      setData(newData);
      restProps.setDataList(newData);
      debounceForFunc(() => onRowClick(item), 100);
    }
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    // let newKeys = Object.keys(newSelectedState);
    // let oldKeys = Object.keys(selectedState);
    // const isSame = isSameRow(oldKeys, newKeys);

    setNewSelectedRow(newSelectedState, false);
  };

  const enterEdit = (dataItem, field) => {
    if (restProps.pageProps.writeAccess === 0) {
      return false;
    }

    let newData = data.map((item) => ({
      ...item,
      inEdit:
        item.comp_plan_mstr_key === dataItem.comp_plan_mstr_key
          ? field
          : undefined,
    }));

    setData(newData);
    restProps.setisPlanEdit(true);
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    if (dataItem.comp_plan_name === "") {
      handleValidation();
    }
  };

  const itemChange = React.useCallback(
    (event) => {
      const field = event.field || "";
      const newData = data.map((item) =>
        item.comp_plan_mstr_key === event.dataItem.comp_plan_mstr_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );
      let editItem = event.dataItem;
      editItem[field] = event.value;
      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {
    if (isSort) {
      setSortField(columnMenuData);
    } else {
      setFilterField(filterObj);
    }
    let index = 1;

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }
    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);
    getGridData(dataVal, isFilter, isSort);
  };

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          className="plan-field"
        >
          {isInEdit ? (
            <Input
              // required
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={props.dataItem[props.field || ""]}>
              {props.dataItem[props.field || ""]}
            </span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={NameCell}
          sortable={true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let GroupColumn = useCol(gridColumns);

  const onClearFilter = () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal, true);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    getGridData(dataVal);
  };

  const onRefreshAllData = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    getGridData(dataVal, true);
  };

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      exitEdit={exitEdit}
    />
  );

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);

    if (res) {
      setExportData(res);
    }
  };

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };
  return (
    <>
      {isRefreshLoading && <LoadingPanel />}
      {columns.length > 0 ? (
        <>
          <DataTable
            isAPICalling={isAPICalling}
            resetSort={resetSort}
            initialLoading={initialLoading}
            data={data.map((item) => ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)],
            }))}
            id="plan-grid"
            editField={editField}
            dataCount={dataCount}
            customColumn={GroupColumn}
            columns={gridColumns}
            dataItemKey={DATA_ITEM_KEY}
            width={"auto"}
            module={"comp_plan"}
            getExportData={getExportData}
            setExportData={restProps.setExportDataClick}
            isExportDataClick={restProps.exportDataClick}
            fileName={"Export_comp_plan.xlsx"}
            exportData={exportData}
            rowRender={customRowRender}
            cellRender={customCellRender}
            itemChange={itemChange}
            isPagination={false}
            handleColumnMenu={handleColumnMenu}
            onClearFilter={onClearFilter}
            onSelectionChange={onSelectionChange}
            reorderable={restProps.pageProps.writeAccess === 0 ? false : true}
            columnReorderData={columnReorderData}
            initialFilter={filterField}
            initialSort={sortField}
          />
        </>
      ) : (
        false
      )}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        className={"Dialog-Delete"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={
          confirmationPopup.action === "Delete"
            ? "delete-confirm"
            : "unSave-confirm"
        }
      />
    </>
  );
};

export const GridButton = (restProps) => {
  return (
    <>
      <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
        Cancel
      </Button>
      <Button type="submit" className="primarybtn" id="grid-incell-update">
        Save
      </Button>
    </>
  );
};
