export const DEFAULT_SORT = { number: 1, order: "DESC" };

//set grid height at initial bases. This should be overwrite during data load into grid
export const INIT_GRID_HEIGHT = "100px";

// remove after code restructure
export const USER_GRID_PAGE_SIZES = [
    { recordTake: 100, id: 100 },
    { recordTake: 200, id: 200 },
    { recordTake: 500, id: 500 },
    { recordTake: 1000, id: 1000 },
    { recordTake: 2000, id: 2000 },
    { recordTake: 3000, id: 3000 },
];

// remove after code restructure
export const USER_GRID_DEFAULT_PAGE_SIZE = 0;

export const VIRTUAL_PAGE_SIZE = 30;
