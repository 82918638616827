import React, { useCallback, useMemo, useRef, useState } from "react";
import { Pager } from "@progress/kendo-react-data-tools";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { getTenantCookieData } from "../lib/cookies";

const GridPager = React.memo((props) => {
  const {
    defaultItem,
    take,
    total,
    onPageChange,
    data,
    refreshClick,
    module,
    group,
    isFilterData,
    classes = "",
    dataCount,
  } = props;

  const skip = ["event", "audit-log-grid", "custom-table-data-grid", "provider-position"].includes(module) 
  ? props.skipvs 
  : props.skip;
  const tooltip = useRef(null);
  const [pageSize, setPageSize] = useState(defaultItem);
  // const [pageSizevs, setPageSizevs] = useState(defaultItem);

  const currentPage = useMemo(() => Math.floor(skip / take) + 1, [skip, take]);

  const totalPages = useMemo(
    () => Math.ceil((total || 0) / take),
    [total, take]
  );

  let currentPageMessage = useMemo(() => skip + 1, [skip]);
  let dataLoadCount = skip + data.length;

  if (module === "job-postion-grid" && isFilterData) {
    dataLoadCount = dataCount;
    currentPageMessage = 1;
  }

  // Default assignment
  if (module === "result") {
    const isEndOfData = total < currentPage * take;
    const isPageValid = total > currentPage * take;

    if (group.length > 0 && isFilterData) {
      dataLoadCount = isEndOfData
        ? total
        : isPageValid
        ? take
        : skip + data.length;
    } else if (group.length > 0 && isEndOfData) {
      dataLoadCount = total;
    } else if (isPageValid) {
      dataLoadCount = currentPage * take;
    }
  }

  if (module === "reports") {
    dataLoadCount = total < skip + take ? total : skip + take;
  }

  const defaultPageList = useMemo(
    () => getTenantCookieData("record_per_page_list"),
    []
  );

  const state = useMemo(
    () => ({
      skip: 0,
      take: 3,
      buttonCount: 3,
      type: "numeric",
      info: false,
      pageSizes: true,
      previousNext: true,
      responsive: false,
    }),
    []
  );
  const handlePagerChange = useCallback(
    (e) => {
      if (onPageChange) {
        onPageChange({
          target: e.target,
          skip: e.skip,
          take: take,
          syntheticEvent: e,
          nativeEvent: e.nativeEvent,
        });
      }
    },
    [onPageChange, take]
  );

  const handleDropDownChange = useCallback(
    (e) => {
      if (onPageChange) {
        onPageChange({
          target: e.target,
          skip: 0,
          take: e.target.value.recordTake,
          syntheticEvent: e,
          nativeEvent: e.nativeEvent,
          rows: e.target.value.recordTake,
        });
      }
      // if (module === "event") {
      //   setPageSizevs(e.target.value);
      // }
      setPageSize(e.target.value);
    },
    [onPageChange]
  );

  const handleChange = useCallback(
    (e) => {
      if (!e.value) e.value = 1;

      if (onPageChange) {
        onPageChange({
          target: e.target,
          skip: (e.value - 1) * take,
          take: take,
          syntheticEvent: e,
          nativeEvent: e.nativeEvent,
        });
      }
    },
    [onPageChange, take]
  );
  const onRefreshClick = useCallback(() => {
    refreshClick();
  }, [refreshClick]);

  return (
    <div className={`${"gridFooter"} ${classes}`}>
      <div className="gridControls">
        <div className="Results">
          {`${currentPageMessage} - ${dataLoadCount} of ${total}`}
          <span>{total === 1 ? "Result" : "Results"}</span>
        </div>
        {pageSize && (
          <>
            <div className="ResultsDropdown">
              <label>Show:</label>
              <DropDownList
                data={defaultPageList}
                textField="recordTake"
                dataItemKey="id"
                value={pageSize}
                onChange={handleDropDownChange}
                style={{ width: "auto" }}
              />
            </div>
            {pageSize.id < total && (
              <>
                <Pager
                  skip={skip}
                  take={take}
                  total={total}
                  buttonCount={state.buttonCount}
                  info={state.info}
                  type={state.type}
                  previousNext={state.previousNext}
                  responsive={state.responsive}
                  onPageChange={handlePagerChange}
                  className="gridPager"
                />
                <div className="jumpTo">
                  <label>Jump to:&nbsp;</label>
                  <NumericTextBox
                    value={currentPage}
                    onChange={handleChange}
                    min={1}
                    max={totalPages}
                    width={"70px"}
                  />
                </div>
              </>
            )}
          </>
        )}
        <div
          onMouseOver={(event) =>
            tooltip.current && tooltip.current.handleMouseOver(event)
          }
          onMouseOut={(event) =>
            tooltip.current && tooltip.current.handleMouseOut(event)
          }
        >
          {/* <span
            title="Refresh"
            style={{ cursor: "pointer" }}
            className="k-icon k-i-reload-sm"
            onClick={onRefreshClick}
          ></span> */}
          {module !== "job-postion-grid" ? (
            <span
              title="Refresh"
              style={{ cursor: "pointer" }}
              className="k-icon k-i-reload-sm"
              onClick={onRefreshClick}
            ></span>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
});

export default GridPager;
