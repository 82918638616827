import React from "react";
import { getUserCredential } from "../../lib/cookies";
import { errStatusHandler, POSTAPI } from "../../api-setup/api-helper";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";
import {
    SELECT_KPI_TYPE,
    DELETE_KPI_TYPE,
    UPDATE_KPI_TYPE,
    INSERT_KPI_TYPE,
} from "../../api-setup/api-endpoints";
import { useHistory } from "react-router-dom";
import { GridColumnDataMapper } from "../../utils/utils";

export default function Events() {
    const history = useHistory();
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [initialLoading, setLoadingState] = React.useState(false);
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
        isDeleteData: undefined,
    });
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [originalData, setOriginalData] = React.useState([]);
    const [visible, setVisible] = React.useState(false);
    const [noDataFound, setNoDataFound] = React.useState(false);
    const [dataState, setDataState] = React.useState();
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });

    const getKpiTypeDataList = (listData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                SELECT_KPI_TYPE,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        // setNoDataFound(true);
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                        // resolve([]);

                    }

                    setAPICallingState(false);
                    setLoadingState(true);
                    res.data.row_result.map((item, idx) => {
                        item.rowId = idx;
                        return 0;
                    });

                    setVisible(true);
                    const columData = GridColumnDataMapper(
                        res.data.out_parameters.out_columns_xml
                    );
                    if (columData.length > 0) {
                        setData(res.data.row_result);
                        setColumns(columData);
                        setDataCount(res.data.out_parameters.out_total_rows);
                        setOriginalData(res.data.row_result);
                    }
                    setNoDataFound(false);
                    setRefreshLoading(false);
                    resolve(res.data.row_result);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateKPIDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_KPI_TYPE,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (res && res.data.out_parameters.out_ret_code === 0) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };
    const addKPITypeDataAsync = (bodyData, data) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });
        POSTAPI(false, INSERT_KPI_TYPE, null, header, bodyData, dispatch).then(
            (res) => {
                setRefreshLoading(false);
                if (res.status) {
                    if (res.data.out_parameters.out_ret_code > 0) {
                        dispatch(
                            setNewMessage({
                                message: res.data.out_parameters.out_error_msg,
                                type: "Error",
                                show: true,
                            })
                        );
                        errStatusHandler(res, history, dispatch);
                    } else {
                        setLoadState({ ...loadingState, isSaveData: true });
                    }
                }
            }
        );
    };

    const deleteKPITypeDataAsync = async (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_KPI_TYPE,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                const totalKpiTypes = bodyData.in_kpi_type_mstr_key.length;
                const unSucessFullCount = res.data.successCount;
                setRefreshLoading(false);
                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;

                    

                    if (totalKpiTypes !== unSucessFullCount) {
                        msg =
                            unSucessFullCount +
                            " KPI Type (" +
                            res.data.successName +
                            ") out of " +
                            totalKpiTypes + ' '+
                            "selected linked to active KPI - cannot delete.";


                        setLoadState({ ...loadingState, isDeleteData: true });
                        setTimeout(function () {
                            resolve("success");
                        });
                    } else {
                        setLoadState({ ...loadingState, isDeleteData: false });
                        setTimeout(function () {
                            resolve("error");
                        });
                    }
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isAPICalling,
            initialLoading,
            setAPICallingState,
            originalData,
            visible,
            setVisible,
            setNoDataFound,
            noDataFound,
            dataState,
            loadingState,
            setLoadState,
            setRefreshLoading,
            isRefreshLoading,
            setDataState
        },
        {
            getKpiTypeDataList,
            updateKPIDataAsync,
            addKPITypeDataAsync,
            deleteKPITypeDataAsync,
        },
    ];
}
