// eslint-disable-next-line no-useless-concat
import React from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { useHistory } from "react-router";
import {
  GetPeriodsDataList,
  GetPeriodTypeAPI,
  GetCompPlanDataApi,
  GetResultName,
  updateColumnReorderAsync,
} from "../../lib/common-service";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import userResultsApi from "./service";
import { errStatusHandler, POSTAPI } from "../../api-setup/api-helper";
import { DEFAULT_SORT } from "../../constants/grid";
import {
  applyFormat,
  executeGetColumnOrderInfo,
  formatDateOnly,
  getResultFilterDataString,
  replaceEncKey,
} from "../../utils/utils";
import { HeaderPage } from "../base/headerPage";
import { Label } from "@progress/kendo-react-labels";
import CustomDropdown from "../../components/customDropdown";
import MultiSelectDropdown from "../../components/multiSelect";
import { Button } from "@progress/kendo-react-buttons";
import { Switch } from "@progress/kendo-react-inputs";
import { MULTI_SELECT_ALL_TEXT } from "src/constants/constants";

import {
  GET_RESULT_CREDITS,
  GET_RESULT_INCENTIVES,
  GET_RESULT_MEASUREMENTS,
  GET_RESULT_PAYMENTS,
  GET_SELECT_GRID_COLUMN_XML,
} from "../../api-setup/api-endpoints";
import _ from "lodash";
import DataTable from "../../components/dataTable";
import { GridColumnDataMapper } from "../../utils/utils";

import { columnMenuProps } from "../../components/customColumnMenu";
import {
  EXPORT_RESULT_MODULE,
  RESULT_SCREEN_STRING,
  RESULT_TYPES,
  RESULT_TYPES_MODULE,
  RESULT_TYPES_STRING,
  RESULT_TYPES_STRING_UPPERCASE,
} from "../../constants/constants";
import { LoadingPanel } from "../../components/loadingPanel";
import { setExpandedState } from "@progress/kendo-react-data-tools";
import { setNewMessage } from "../../redux/actions/setting-actions";
import { usePrevious } from "../../lib/hooks/custom-common-hooks";
import { transformDateTime } from "src/lib/date-time.util";

import { linkedRulesSelector } from "src/redux/selectors/custom-selector";

const initialSort = [
  {
    field: "a$position_name",
    dir: "asc",
  },
];

const MULTI_SELECT_ALL_ID = "0";

const SELECT_ALL = [
  {
    text: MULTI_SELECT_ALL_TEXT,
    id: MULTI_SELECT_ALL_ID,
  },
];

const Results = (pageProps) => {
  const getDataToLocalStorage = () => {
    let value = localStorage.getItem("resultData");
    return JSON.parse(value);
  };
  const localStorageData = getDataToLocalStorage();
  const divRef = React.createRef();
  const [apiCallData, setAPICallData] = React.useState({});
  const [list, Action] = userResultsApi();
  const history = useHistory();
  const cookiesData = getUserCredential();
  const dispatch = useDispatch();
  const defaultPageSize = getTenantCookieData("record_per_page");

  const { linkedRules } = useSelector(linkedRulesSelector, shallowEqual);

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [sort, setSort] = React.useState(initialSort);
  const [groupData, setGroupData] = React.useState([]);
  const [filterField, setFilterField] = React.useState({
    logic: "and",
    filters: [],
  });
  const [sortField, setSortField] = React.useState([]);
  const {
    data,
    columns,
    dataCount,
    setColumns,
    isColumnMenuSearch,
    isAPICalling,
    setAPICallingState,
    collapsedState,
    initialLoading,
    setCollapsedState,
    group,
    setGroup,
    setData,
    originalData,
    setLoadingStateSearch,
    loading,
    setLoadingState,
    noDataFound,
    aliasData,
    setDataCount,
  } = list;

  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  const header = {
    "x-access-token": cookiesData.secretToken,
  };
  const setDataToLocalStorage = (obj) => {
    localStorage.setItem("resultData", JSON.stringify(obj));
  };

  const [periodsTypeFields, setPeriodTypeFields] = React.useState({
    periodTypeValue: {
      in_period_mstr_key: "",
      in_period_start_date: "",
      in_period_end_date: "",
    },
    periodTypeData: [],
    isPeriodTypeDataLoading: true,
  });
  const [periodsFields, setPeriodFields] = React.useState({
    periodsValue: {
      id: "",
      text: "",
    },
    periodsData: [],
    isPeriodDataLoading: true,
  });

  const [compPlanFields, setcompPlanFields] = React.useState({
    resultCompPlanValue: [],
    resultcompPlanData: [],
    isResultcompPlanDataLoading: true,
  });
  const [resultNameFields, setResultNameFields] = React.useState({
    resultNameValue: [],
    resultNameData: [],
    isResultNameDataLoading: true,
  });
  const [periodTypeList, setPeriodTypeList] = React.useState([]);
  const [periodsList, setPeriodsList] = React.useState([]);
  const [resultCompPlanist, setResultCompPlanist] = React.useState([]);

  const [resultNameList, setResultNameList] = React.useState([]);
  const [errorObj, setErrorObj] = React.useState({});
  const [checked, setChecked] = React.useState(
    cookiesData?.out_db_role_type_abbr !== "adm1"
      ? false
      : localStorageData?.checked !== undefined
      ? localStorageData?.checked
      : true
  );

  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [isSearchClick, setIsSearchClick] = React.useState(false);

  const [searchPeriodMstrKey, setSearchPeriodMstrKey] = React.useState({});

  const perviousUrl = usePrevious(history.location.pathname);

  React.useEffect(() => {
    if (isCheckColumn) {
      updateColumDataAsync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckColumn]);

  React.useEffect(() => {
    GetCompPlanDataApiData();
  }, []);

  React.useEffect(() => {
    getResultNameDataList();
  }, []);

  React.useEffect(() => {
    if (
      linkedRules.dashboard &&
      !resultNameFields.isResultNameDataLoading &&
      !compPlanFields.isResultcompPlanDataLoading &&
      !periodsFields.isPeriodDataLoading &&
      !periodsTypeFields.isPeriodTypeDataLoading &&
      !isSearchClick
    ) {
      onSearchClick(history.location.pathname, true);
    }
  }, [
    linkedRules,
    resultNameFields,
    compPlanFields,
    periodsFields,
    periodsTypeFields,
  ]);

  const prepareColumnString = (colData) => {
    return executeGetColumnOrderInfo(colData);
  };

  const updateColumDataAsync = async () => {
    setLoadingStateSearch({ ...loading, updateColumn: true });
    let columnString = prepareColumnString(columnsData);
    let reqData = {
      in_db_name: "procare_system",
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "N",
      in_filter_label_cond: "N",
      in_column_info: columnString,
    };
    let res = await updateColumnReorderAsync(reqData, dispatch, history);
    if (res) {
      setLoadingStateSearch({ ...loading, updateColumn: false });
      setColumns(columnsData);
      setColumnCheck(false);
    } else {
      setLoadingStateSearch({ ...loading, updateColumn: false });
    }
  };

  const getInFilter = () => {
    return filterData.isFilter
      ? filterData.data
      : history.location.pathname === RESULT_TYPES.CREDITS
      ? null
      : history.location.pathname === RESULT_TYPES.MEASUREMENTS
      ? ` AND a.period_type_mstr_key = ${periodsTypeFields.periodTypeValue.id} `
      : ` AND l.period_type_mstr_key = ${periodsTypeFields.periodTypeValue.id} `;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const handlePageChange = (event) => {
    setAPICallingState(true);
    const pageData = event.page;
    setPage({ skip: pageData.skip, take: pageData.take });
    apiCallData.in_filter = getInFilter();
    apiCallData.in_sort_number = getInSortNumber();
    apiCallData.in_sort_order = getInSortOrder();
    apiCallData.in_page_rows = pageData.take;
    apiCallData.in_page_row_offset = pageData.skip;
    let groupValue = createGroupArray(group);
    const matchedKey = Object.keys(aliasData).find(
      (key) => aliasData[key] === groupValue.in_groupby
    );
    apiCallData.in_groupby = matchedKey;
    apiCallData.in_groupby_sort_order = groupValue.in_groupby_sort_order;
    GetListAsync(history.location.pathname, apiCallData, group);
    setGroupData(group);
  };

  const showBreadCrumsResultsText = () => {
    let htmlContent = "";
    switch (history.location.pathname) {
      case RESULT_TYPES.CREDITS:
        htmlContent = RESULT_TYPES_STRING_UPPERCASE.CREDITS;
        break;
      case RESULT_TYPES.MEASUREMENTS:
        htmlContent = RESULT_TYPES_STRING_UPPERCASE.MEASUREMENTS;
        break;
      case RESULT_TYPES.INCENTIVES:
        htmlContent = RESULT_TYPES_STRING_UPPERCASE.INCENTIVES;
        break;
      case RESULT_TYPES.PAYMENTS:
        htmlContent = RESULT_TYPES_STRING_UPPERCASE.PAYMENTS;
        break;
      default:
        break;
    }
    return htmlContent;
  };

  const fetchRuleResultValue = () => {
    let ruleResultValue = "";
    switch (history.location.pathname) {
      case RESULT_TYPES.CREDITS:
        ruleResultValue = RESULT_TYPES_STRING.CREDITS;
        break;
      case RESULT_TYPES.MEASUREMENTS:
        ruleResultValue = RESULT_TYPES_STRING.MEASUREMENTS;
        break;
      case RESULT_TYPES.INCENTIVES:
        ruleResultValue = RESULT_TYPES_STRING.INCENTIVES;
        break;
      case RESULT_TYPES.PAYMENTS:
        ruleResultValue = RESULT_TYPES_STRING.PAYMENTS;
        break;
      default:
        break;
    }
    return ruleResultValue;
  };

  const onResetState = () => {
    setData([]);
    setCollapsedState([]);
    setAPICallData({});
    setColumns([]);
    setColumns([]);
    setColumnsData([]);
    setChecked(
      localStorageData?.checked !== undefined ? localStorageData?.checked : true
    );
    setLoadingStateSearch({ ...loading, isSearchLoader: false });
  };

  React.useEffect(() => {
    if (perviousUrl !== history.location.pathname) {
      handleOpenDropdown("periodType");
      onResetState();
    } else {
      handleOpenDropdown("periodType");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  const getResultNameDataList = async (req) => {
    setResultNameFields({
      ...resultNameFields,
      isResultNameDataLoading: true,
    });
    let resultNameData = {
      in_filter: null,
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_user_key: cookiesData.out_user_id,
      in_rule_filter: null,
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_rule_result_table: fetchRuleResultValue(),
      in_dash_tab_mstr_key: null,
    };
    let resName = await GetResultName(resultNameData, dispatch, history);
    if (resName.length > 0) {
      let tempArray = [];
      resName.map((item) => {
        let obj = {};
        obj.text = item.result_name;
        obj.id = item.result_name_mstr_key;
        tempArray.push(obj);
        return 0;
      });
      setResultNameList(tempArray);
      setResultNameFields({
        ...resultNameFields,
        resultNameData: tempArray,
        isResultNameDataLoading: false,
        resultNameValue: getResultData(tempArray),
      });
    } else {
      setResultNameFields({
        ...resultNameFields,
        isResultNameDataLoading: false,
        resultNameValue: getResultData([]),
      });
    }
  };

  const getPeriodsDataList = async (id, resultDataValue, method) => {
    setPeriodFields({
      ...periodsFields,
      isPeriodDataLoading: true,
      isResultcompPlanDataLoading: true,
      isResultNameDataLoading: true,
    });
    let periodsDataReq = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: `AND a.period_type_mstr_key = ${id}`,
      in_sort_number: null,
      in_sort_order: null,
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
      in_sort_number_concat:
        "a.period_end_date DESC, a.period_level_order DESC",
    };
    let resPeriods = await GetPeriodsDataList(
      periodsDataReq,
      dispatch,
      history
    );
    if (resPeriods.length > 0) {
      let tempArray = [];
      resPeriods.map((item) => {
        let obj = {};
        obj.text = item.period_name;
        obj.id = item.period_mstr_key;
        obj.in_period_start_date = item.period_start_date;
        obj.in_period_end_date = item.period_end_date;
        tempArray.push(obj);
        return 0;
      });

      if (!isSearchClick) {
        setPeriodsList(tempArray);
        setPeriodFields({
          ...periodsFields,
          periodsData: tempArray,
          periodsValue:
            method === "change" ? tempArray[0] : getPeriods(tempArray),
          isPeriodDataLoading: false,
          isResultcompPlanDataLoading: false,
          isResultNameDataLoading: false,
        });
      } else {
        if (method === "change") {
          setPeriodsList(tempArray);
        }
        setPeriodFields({
          ...periodsFields,
          periodsData: tempArray,
          periodsValue:
            method === "change" ? tempArray[0] : getPeriods(tempArray),
          isPeriodDataLoading: false,
          isResultcompPlanDataLoading: false,
          isResultNameDataLoading: false,
        });
      }

      let dataObj = {
        in_period_mstr_key: tempArray[0].id,
        in_period_start_date: formatDateOnly(tempArray[0].in_period_start_date),
        in_period_end_date: formatDateOnly(tempArray[0].in_period_end_date),
      };
    } else {
      setPeriodsList([]);

      setPeriodFields({
        periodsValue: {
          id: "",
          text: "",
        },
        periodsData: [],
        isPeriodDataLoading: false,
        isResultcompPlanDataLoading: false,
        isResultNameDataLoading: false,
      });
    }
  };

  const getPeriodTypeData = (dataSet) => {
    if (!dataSet[0]) {
      return { text: "", id: "" };
    }
    const dataVal = localStorageData?.periodTypeValue || 0;
    let id = dataVal?.id ? dataVal?.id : dataVal;
    const returnObj = dataSet.find((o) => o?.id === id);

    if (!returnObj?.id) {
      return dataSet[0];
    }

    return returnObj;
  };

  const getPeriods = (dataSet) => {
    if (!dataSet[0]) {
      return { text: "", id: "" };
    }
    const dataVal = localStorageData?.periodsValue || 0;

    let id = dataVal?.id ? dataVal?.id : dataVal;
    const returnObj = dataSet.find((o) => o?.id === id);

    if (!returnObj?.id) {
      return dataSet[0];
    }

    return returnObj;
  };

  const getCommonObjects = (arr1, arr2) => {
    if (arr2) {
      return arr1.filter((obj1) =>
        arr2.some((obj2) => obj1?.id === obj2?.id && obj1?.name === obj2?.name)
      );
    }
    return [];
  };

  const getCompPlanData = (dataSet) => {
    if (!dataSet[0]) {
      return [];
    }
    const commonObjects = getCommonObjects(
      dataSet,
      localStorageData?.resultCompPlanValue
    );
    const dataVal = localStorageData?.resultCompPlanValue || [];
    if (!dataVal.length) {
      return dataSet.length > 0 ? SELECT_ALL : [dataSet[0]];
    } else {
      return commonObjects.length === 0
        ? dataSet.length > 0
          ? SELECT_ALL
          : [dataSet[0]]
        : commonObjects;
    }
  };

  const getResultData = (dataSet) => {
    if (!dataSet[0]) {
      return [];
    }
    const commonObjects = getCommonObjects(
      dataSet,
      localStorageData?.resultNameValue
    );
    const dataVal = localStorageData?.resultNameValue || [];
    if (!dataVal.length) {
      return dataSet.length > 0 ? SELECT_ALL : [dataSet[0]];
    } else {
      return commonObjects.length === 0
        ? dataSet.length > 0
          ? SELECT_ALL
          : [dataSet[0]]
        : commonObjects;
    }
  };

  const handleOpenDropdown = async (module) => {
    switch (module) {
      case "periodType":
        let periodTypeData = {
          in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
          in_sort_number: null,
          in_sort_order: null,
        };
        let res = await GetPeriodTypeAPI(periodTypeData, dispatch, history);
        if (res.length > 0) {
          let tempArray = [];
          res.map((item) => {
            let obj = {};
            obj.text = item.period_type_name;
            obj.id = item.period_type_mstr_key;
            tempArray.push(obj);
            return 0;
          });
          if (isSearchClick) {
            getPeriodsDataList(getPeriodTypeData(tempArray).id);
          } else {
            getPeriodsDataList(getPeriodTypeData(tempArray).id);
            setPeriodTypeList(tempArray);
            setPeriodTypeFields({
              ...periodsTypeFields,
              periodTypeData: tempArray,
              periodTypeValue: getPeriodTypeData(tempArray),
              isPeriodTypeDataLoading: false,
            });
          }
        } else {
          setPeriodTypeFields({
            ...periodsTypeFields,
            isPeriodTypeDataLoading: false,
          });
        }
        await GetCompPlanDataApiData();
        await getResultNameDataList();
        break;
      default:
        break;
    }
  };

  const GetCompPlanDataApiData = async () => {
    let obj = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_sort_number: 6,
      in_sort_order: "ASC",
      in_user_key: cookiesData.out_user_id,
      in_multi_sort_order: null,
      in_rule_result_table: getModule(history.location.pathname),
    };
    let resDataObj = await GetCompPlanDataApi(obj, history, dispatch);

    if (resDataObj.length > 0) {
      let tempArray = [];
      resDataObj.map((item) => {
        let obj = {};
        obj.text = item.comp_plan_name;
        obj.id = item.comp_plan_mstr_key;
        tempArray.push(obj);
        return 0;
      });
      setResultCompPlanist(tempArray);
      setcompPlanFields({
        ...compPlanFields,
        resultcompPlanData: tempArray,
        isResultcompPlanDataLoading: false,
        resultCompPlanValue: getCompPlanData(tempArray),
      });
    } else {
      setcompPlanFields({
        ...compPlanFields,
        resultcompPlanData: [],
        isResultcompPlanDataLoading: false,
        resultCompPlanValue: getCompPlanData([]),
      });
    }
  };
  const getModule = (module) => {
    let string = "";
    switch (module) {
      case RESULT_TYPES.CREDITS:
        string = RESULT_TYPES_MODULE.CREDITS;
        break;
      case RESULT_TYPES.MEASUREMENTS:
        string = RESULT_TYPES_MODULE.MEASUREMENTS;
        break;
      case RESULT_TYPES.INCENTIVES:
        string = RESULT_TYPES_MODULE.INCENTIVES;
        break;
      case RESULT_TYPES.PAYMENTS:
        string = RESULT_TYPES_MODULE.PAYMENTS;
        break;
      default:
        break;
    }
    return string;
  };

  const GetListAsync = (module, prepareRequestData, groupData) => {
    let apiName = "";
    switch (module) {
      case RESULT_TYPES.CREDITS:
        apiName = GET_RESULT_CREDITS;
        Action.getListDataAsync(
          prepareRequestData,
          apiName,
          groupData,
          checked
        );
        break;
      case RESULT_TYPES.MEASUREMENTS:
        apiName = GET_RESULT_MEASUREMENTS;
        Action.getListDataAsync(
          prepareRequestData,
          apiName,
          groupData,
          checked
        );
        break;
      case RESULT_TYPES.INCENTIVES:
        apiName = GET_RESULT_INCENTIVES;
        Action.getListDataAsync(
          prepareRequestData,
          apiName,
          groupData,
          checked
        );
        break;
      case RESULT_TYPES.PAYMENTS:
        apiName = GET_RESULT_PAYMENTS;
        Action.getListDataAsync(
          prepareRequestData,
          apiName,
          groupData,
          checked
        );
        break;
      default:
        break;
    }
  };

  const onSearchClick = async (module, isListAPICall) => {
    setIsSearchClick(true);
    setGroup([]);
    setGroupData([]);
    setLoadingStateSearch({ ...loading, isSearchLoader: true });
    setCollapsedState([]);
    setPageSize(defaultPageSize);
    setDataCount(0);
    setPage({
      skip: 0,
      take: defaultPageSize.recordTake,
    });
    setData([]);
    setColumns([]);
    setFilterData({
      isSort: false,
      isFilter: false,
      data: "",
      in_sort_number: DEFAULT_SORT.number,
      sort_order: DEFAULT_SORT.order,
    });
    setFilterField({ logic: "and", filters: [] });
    setSortField([]);
    setSearchPeriodMstrKey(periodsTypeFields);
    getColumnAPIAsyc(module, isListAPICall);
  };

  const getColumnAPIAsyc = (module, isListAPICall) => {
    const reqData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: " AND ( (a.result_name_column = 0) )",
      in_filter_db_name: "procare_system",
      in_filter_table_name: "vw_" + getModule(history.location.pathname),
      in_result_name_mstr_key: 0,
      in_event_type_mstr_key: "0",
      in_hide_column: 0,
    };
    POSTAPI(
      false,
      GET_SELECT_GRID_COLUMN_XML,
      null,
      header,
      reqData,
      dispatch
    ).then((res) => {
      if (res.data.out_parameters.out_ret_code > 0) {
        dispatch(
          setNewMessage({
            message: res.data.out_parameters.out_error_msg,
            type: "Error",
            show: true,
          })
        );
        setLoadingState(true);
        setAPICallingState(true);
        errStatusHandler(res, history, dispatch);
      } else {
        const columData = GridColumnDataMapper(
          res.data.out_parameters.out_columns_xml,
          "field_proceed",
          true
        );
        let columnFilterData = [...columData];
        if (checked) {
          columnFilterData.map((cItem) => {
            if (cItem.field === "is_active") {
              cItem.show = false;
            }
            return 0;
          });
        } else {
          columnFilterData.map((cItem) => {
            if (cItem.field === "is_published") {
              cItem.show = false;
            }
            return 0;
          });
        }
        let manageColumns = checked
          ? columData.filter((x) => x.field !== "is_active")
          : columnFilterData.filter((x) => x.field !== "is_published");
        setColumnsData(manageColumns);
        setColumns([]);
        setColumns(JSON.parse(JSON.stringify(columnFilterData)));
        if (isListAPICall) {
          getGridData(module);
        }
      }
    });
  };

  const getGridData = (module) => {
    let obj = {
      periodTypeValue: periodsTypeFields.periodTypeValue,
      periodsValue: periodsFields.periodsValue,
      resultCompPlanValue: compPlanFields.resultCompPlanValue,
      resultNameValue: resultNameFields.resultNameValue,
    };

    if (cookiesData?.out_db_role_type_abbr === "adm1") {
      obj.checked = checked;
    }

    setDataToLocalStorage(obj);
    const ids = [];
    for (const item of compPlanFields.resultCompPlanValue) {
      if (item.id !== MULTI_SELECT_ALL_ID) {
        ids.push(item.id.toString());
      }
    }
    const resultids = [];
    for (const item of resultNameFields.resultNameValue) {
      if (item.id !== MULTI_SELECT_ALL_ID) {
        resultids.push(item.id.toString());
      }
    }

    const compPlanIds = ids.join(",");
    const resultsDataIds = resultids.join(",");
    let prepareRequestData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter:
        history.location.pathname === RESULT_TYPES.CREDITS
          ? null
          : history.location.pathname === RESULT_TYPES.MEASUREMENTS
          ? ` AND a.period_type_mstr_key = ${periodsTypeFields.periodTypeValue.id} `
          : ` AND l.period_type_mstr_key = ${periodsTypeFields.periodTypeValue.id} `,
      in_filter_linked_results: null,
      in_user_key: cookiesData.out_user_id,
      in_period_mstr_key: periodsFields.periodsValue.id,
      in_result_name_all_flag: resultsDataIds.length > 0 ? 0 : 1,
      in_result_name_mstr_key: resultsDataIds,
      in_comp_plan_mstr_key: compPlanIds,
      in_aggregate_flag: 1,
      in_groupby: null,
      in_groupby_sort_order: null,
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: DEFAULT_SORT.order,
      in_page_row_offset: 0,
      in_page_rows: defaultPageSize.recordTake,
      in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
      in_show_current_or_published: checked
        ? " AND a.is_active = 1 "
        : " AND a.is_published = 1 ",
      in_user_id: cookiesData.out_user_id,
      in_user_role_filter: "> 0",
    };
    setAPICallData(prepareRequestData);
    GetListAsync(module, prepareRequestData);
  };

  const handleChange = (e) => {
    setChecked(e.value);
  };

  const handleColumnMenu = async (
    columnMenuData,
    isFilter,
    isSort,
    filterObj
  ) => {
    if (isSort) {
      setSortField(columnMenuData);
    } else {
      setFilterField(filterObj);
    }
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();
    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj =
        groupData.length > 0
          ? groupData.length === 1
            ? Object.keys(data[0].items[0])
            : groupData.length === 2
            ? Object.keys(data[0].items[0].items[0])
            : groupData.length === 3
            ? Object.keys(data[0].items[0].items[0].items[0])
            : groupData.length === 4
            ? Object.keys(data[0].items[0].items[0].items[0].items[0])
            : Object.keys(data[0].items[0].items[0].items[0].items[0])
          : Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    let periodTypeKey = isSearchClick
      ? searchPeriodMstrKey.periodTypeValue.id
      : periodsTypeFields.periodTypeValue.id;
    let appendPeriodKey = `AND l.period_type_mstr_key = ${periodTypeKey}`;
    let appendPeriodMstrKey = `AND a.period_type_mstr_key = ${periodTypeKey}`;

    let filterString = isFilter
      ? getResultFilterDataString(
          columnMenuData,
          columns,
          cookiesData.out_acct_encrypt_key
        )
      : filterData.data;

    if (isFilter) {
      if (history.location.pathname === RESULT_TYPES.MEASUREMENTS) {
        filterString += `${appendPeriodMstrKey}`;
      } else if (
        history.location.pathname === RESULT_TYPES.INCENTIVES ||
        history.location.pathname === RESULT_TYPES.PAYMENTS
      ) {
        filterString += `${appendPeriodKey}`;
      }
    }

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;
    let groupValue = createGroupArray(group);
    const matchedKey = Object.keys(aliasData).find(
      (key) => aliasData[key] === groupValue.in_groupby
    );

    isSort = !columnMenuData.length ? false : filterData.isSort;
    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    apiCallData.in_filter = isFilter
      ? JSON.parse(JSON.stringify(filterString))
      : history.location.pathname === RESULT_TYPES.CREDITS
      ? null
      : history.location.pathname === RESULT_TYPES.MEASUREMENTS
      ? ` AND a.period_type_mstr_key = ${periodsTypeFields.periodTypeValue.id} `
      : ` AND l.period_type_mstr_key = ${periodsTypeFields.periodTypeValue.id} `;
    apiCallData.in_groupby = matchedKey;
    apiCallData.in_groupby_sort_order = groupValue.in_groupby_sort_order;
    apiCallData.in_sort_number = sortNumber;
    apiCallData.in_sort_order = sortDir;
    apiCallData.in_page_row_offset = filterSkip;
    apiCallData.in_page_rows = filterTake;
    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });
    setAPICallingState(true);
    setGroupData(group);
    GetListAsync(history.location.pathname, { ...apiCallData }, group);
  };

  const StringReadAndOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return defaultRendering;
  }, []);

  const DynamicNumberCell = React.memo((props) => {
    const { ariaColumnIndex, columnIndex } = props;
    const value = props.dataItem[props.field];
    const format = props.format;
    const formattedValue = !isNaN(value) ? applyFormat(value, format) : value;

    return (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={formattedValue}>{formattedValue}</span>
      </td>
    );
  });

  const CustomFooterCell = (props) => {
    if (props.field === "result_name") {
      return (
        <td
          className="result-group-footer"
          style={{
            textAlign: "center",
          }}
        >{`Total Count: ${dataCount}`}</td>
      );
    }
    return <td className="result-group-footer"></td>;
  };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          sortable={true}
          width={column.min_width}
          format={column.format}
          cell={
            column.type === "date"
              ? ""
              : column.type === "number"
              ? DynamicNumberCell
              : StringReadAndOnlyCell
          }
          footerCell={CustomFooterCell}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };
  let CustomColumn = useCol(columns);

  const createGroupArray = (groupData) => {
    let val = null;
    let sortValue = null;
    let filedData = [];
    let sortOrder = [];
    if (groupData && groupData.length > 1) {
      groupData.map((item) => {
        let fieldName = item.field.replace("$", ".");
        sortOrder.push("asc");
        filedData.push(fieldName);
        return 0;
      });
    } else if (groupData && groupData.length === 1) {
      let fieldName = groupData[0].field.replace("$", ".");
      val = fieldName;
      sortValue = "asc";
    }
    if (filedData.length > 1) {
      let filedNameData = filedData.toString();
      val = filedNameData.replace(",", "|");
    }
    if (sortOrder.length > 1) {
      let sortOrderData = sortOrder.toString();
      sortValue = sortOrderData.replace(",", "|");
    }

    let obj = {
      in_groupby: val,
      in_groupby_sort_order: sortValue,
    };
    return obj;
  };

  function removeDuplicateObjectFromArray(array, key) {
    let res = true;
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < i; j++) {
        if (array[i].field === array[j].field) {
          res = false;
        }
      }
    }
    return res;
  }

  const onGroupChange = (event) => {
    if (removeDuplicateObjectFromArray(event.group, "field")) {
      if (event.group.length < 5) {
        setGroup(event.group);
        setAPICallingState(true);
        let groupValue = createGroupArray(event.group);
        const matchedKey = Object.keys(aliasData).find(
          (key) => aliasData[key] === groupValue.in_groupby
        );

        apiCallData.in_groupby = matchedKey;
        apiCallData.in_groupby_sort_order = groupValue.in_groupby_sort_order;
        GetListAsync(history.location.pathname, apiCallData, event.group);
        setGroupData(event.group);
      } else {
        dispatch(
          setNewMessage({
            message: "Max limit of 4 groups exceeded",
            type: "Error",
            show: true,
          })
        );
      }
    }
  };

  const onExpandChange = (event) => {
    const item = event.dataItem;
    if (item.groupId) {
      const newCollapsedIds = !event.value
        ? [...collapsedState, item.groupId]
        : collapsedState.filter((groupId) => groupId !== item.groupId);

      setCollapsedState(newCollapsedIds);
      let processedGroupDataVal = setExpandedState({
        data: event.target.props.data,
        collapsedIds: newCollapsedIds,
      });
      setData(processedGroupDataVal);
    }
  };

  const onRefreshClick = () => {
    setAPICallingState(true);
    GetListAsync(history.location.pathname, apiCallData, groupData);
  };

  const onClearFilter = async () => {
    setAPICallingState(true);
    setFilterField({ logic: "and", filters: [] });
    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter:
        history.location.pathname === RESULT_TYPES.CREDITS
          ? null
          : history.location.pathname === RESULT_TYPES.MEASUREMENTS
          ? ` AND a.period_type_mstr_key = ${periodsTypeFields.periodTypeValue.id} `
          : ` AND l.period_type_mstr_key = ${periodsTypeFields.periodTypeValue.id} `,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });
    setAPICallData(dataVal);

    GetListAsync(history.location.pathname, dataVal, groupData);
  };

  const fetchExportProcName = () => {
    let string = "";
    switch (history.location.pathname) {
      case RESULT_TYPES.CREDITS:
        string = EXPORT_RESULT_MODULE.CREDITS;
        break;
      case RESULT_TYPES.MEASUREMENTS:
        string = EXPORT_RESULT_MODULE.MEASUREMENTS;
        break;
      case RESULT_TYPES.INCENTIVES:
        string = EXPORT_RESULT_MODULE.INCENTIVES;
        break;
      case RESULT_TYPES.PAYMENTS:
        string = EXPORT_RESULT_MODULE.PAYMENTS;
        break;
      default:
        break;
    }
    return string;
  };

  const fetchScreenName = () => {
    let ruleResultValue = "";
    switch (history.location.pathname) {
      case RESULT_TYPES.CREDITS:
        ruleResultValue = RESULT_SCREEN_STRING.CREDITS;
        break;
      case RESULT_TYPES.MEASUREMENTS:
        ruleResultValue = RESULT_SCREEN_STRING.MEASUREMENTS;
        break;
      case RESULT_TYPES.INCENTIVES:
        ruleResultValue = RESULT_SCREEN_STRING.INCENTIVES;
        break;
      case RESULT_TYPES.PAYMENTS:
        ruleResultValue = RESULT_SCREEN_STRING.PAYMENTS;
        break;
      default:
        break;
    }
    return ruleResultValue;
  };

  const handleExportToExcel = () => {
    setLoadingStateSearch({ ...loading, updateColumn: true });
    let index = 1;
    if (originalData.length > 0) {
      let obj = Object.keys(originalData[0]);
      index = obj.findIndex((x) => x === sort[0].field) + 1;
    }
    let urlIndex = window.location.href.indexOf(`/${fetchRuleResultValue()}`);
    let currentURL =
      urlIndex !== -1 ? window.location.href.substring(0, urlIndex) : null;

    let reqData = {
      screenName: fetchScreenName(),
      loggedInUserRole: cookiesData.out_db_role_type_abbr,
      currentUrl: currentURL,
      paramArr: [
        {
          " -t ": cookiesData.out_tenant_id,
          " -u ": cookiesData.out_user_id,
          " -f ": "",
          " -c ": fetchExportProcName(),
          " -e ": parseInt(periodsTypeFields.periodTypeValue.id),
          " -n ": index === 0 ? 1 : index,
          " -o ": sort ? sort[0].dir.toUpperCase() : "DESC",
          " -a ": cookiesData.out_acct_encrypt_key,
          " -s ":
            history.location.pathname === RESULT_TYPES.CREDITS
              ? getInFilter() === null
                ? "NULL"
                : replaceEncKey(getInFilter(), cookiesData.out_acct_encrypt_key)
              : replaceEncKey(getInFilter(), cookiesData.out_acct_encrypt_key),
          " -b ": periodsFields.periodsValue.id,
          " -d ":
            resultNameFields.resultNameValue.filter(
              (x) => x.id !== MULTI_SELECT_ALL_ID
            ).length === 0
              ? resultNameFields.resultNameData
                  .filter((x) => x.id !== MULTI_SELECT_ALL_ID)
                  .map((item) => {
                    return item.id;
                  })
                  .toString()
              : resultNameFields.resultNameValue
                  .filter((x) => x.id !== MULTI_SELECT_ALL_ID)
                  .map((item) => {
                    return item.id;
                  })
                  .toString(),
          " -h ":
            compPlanFields.resultCompPlanValue.filter(
              (x) => x.id !== MULTI_SELECT_ALL_ID
            ).length === 0
              ? compPlanFields.resultcompPlanData
                  .filter((x) => x.id !== MULTI_SELECT_ALL_ID)
                  .map((item) => {
                    return item.id;
                  })
                  .toString()
              : compPlanFields.resultCompPlanValue
                  .filter((x) => x.id !== MULTI_SELECT_ALL_ID)
                  .map((item) => {
                    return item.id;
                  })
                  .toString(),
          " -p ": checked ? "C" : "P",
          " -q ": "null",
          " -z ": checked ? "a.is_active" : "a.is_published",
          " -m ": "NULL",
          " -w ": "NULL",
        },
      ],
    };

    Action.exportResultData(reqData);
  };

  const headerConfig = {
    title: showBreadCrumsResultsText(),
    id: "results",
    action_menu: {
      export: { fx: handleExportToExcel, caption: "Export to CSV" },
    },
    ...(pageProps.writeAccess === 1 && {
      manage_col: {
        columnsData: columnsData,
        setColumnsData: setColumnsData,
        setColumnCheck: setColumnCheck,
      },
    }),
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    exportToExcel && exportToExcel.disableKendoMenu();
    data.length && exportToExcel && exportToExcel.enableKendoMenu();
  }

  const columnReorderData = async (columnData) => {
    const str = prepareColumnString(columnData);
    setLoadingStateSearch({ ...loading, updateColumn: true });
    let reqData = {
      in_db_name: "procare_system",
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "N",
      in_filter_label_cond: "N",
      in_column_info: str,
    };
    let res = await updateColumnReorderAsync(reqData, dispatch, history);
    if (res) {
      getColumnAPIAsyc(history.location.pathname, false);
      setLoadingStateSearch({ ...loading, updateColumn: false });
    } else {
      setLoadingStateSearch({ ...loading, updateColumn: false });
    }
  };
  const rowRender = (td, props) => {
    if (td && td.props.children && props.rowType === "groupFooter") {
      let newChildren = React.Children.map(td.props.children, (item, i) => {
        if (item.props && item.props.field === "result_name") {
          let additionalContent = (
            <span className="result-group-footer">
              Result Count: {props.dataItem.items.length}
            </span>
          );
          return (
            <React.Fragment key={i}>
              {item}
              {additionalContent}
            </React.Fragment>
          );
        }
        return item;
      });
      return React.cloneElement(
        td,
        {
          ...td.props,
          className: `${td.props.className || ""} result-group-footer`,
          style: {
            ...td.props.style, // Retain existing styles
            height: "30px", // Set a height for proper display
            lineHeight: "30px", // Center the text vertically
          },
        },
        newChildren
      );
    }
    return td;
  };

  const cellRender = (td, props) => {
    if (td && td.props.children && props.rowType === "groupHeader") {
      let groupColumnName = columns.find(
        (x) => x.field === props.dataItem.field
      );

      let element = td.props.children.props.children;
      let newElement = [...element];
      let collapseIcon = newElement[0];
      newElement.shift();

      if (groupColumnName?.type === "date") {
        let dateFormat = groupColumnName.format
          .replace("{0:", "")
          .replace("}", "");
        let [datePart, timePart] = dateFormat.split(" ");
        let capitalizedResult = datePart
          .split("/") // Split by slashes
          .map((word) => word.toUpperCase()) // Capitalize each part
          .join("/");
        if (newElement[1]) {
          let formattedDate = transformDateTime({
            date: new Date(newElement[1]),
            format: `${capitalizedResult} ${timePart}`,
          });
          newElement[1] = formattedDate;
        }
      } else if (groupColumnName?.type === "number") {
        if (!isNaN(newElement[1])) {
          let formattedNumber = applyFormat(
            newElement[1],
            groupColumnName.format
          );
          newElement[1] = formattedNumber;
        }
      }

      let children = (
        <span>
          {collapseIcon} {groupColumnName?.title} : {newElement}
        </span>
      );
      return React.cloneElement(td, td.props, children);
    }
    return td;
  };
  return (
    <>
      <div className="Main eventspage">
        {loading.isAPICalling ? <LoadingPanel /> : false}
        <div className="content">
          <div className="content-head">
            <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
          </div>
          <div className="content-body">
            {" "}
            <ul className="Filter-links creditfilters">
              <div className={"k-form-field-wrap-popup width20em"} ref={divRef}>
                <Label>{"Period Type:"}</Label>
                <CustomDropdown
                  name={"periodTypeValue"}
                  data={periodsTypeFields.periodTypeData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: dataValue,
                    });
                  }}
                  dataItemKey="id"
                  valueData={periodTypeList.find(
                    (c) => c.text === periodsTypeFields.periodTypeValue.text
                  )}
                  onChange={(e, name) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      [name]: e,
                    });
                    let resultDataValue = getDataToLocalStorage();
                    getPeriodsDataList(e.id, resultDataValue, "change");
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setPeriodTypeFields({
                      ...periodsTypeFields,
                      periodTypeData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={periodTypeList}
                  loading={periodsTypeFields.isPeriodTypeDataLoading}
                  disabled={periodsTypeFields.isPeriodTypeDataLoading}
                />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown width20em"}
                ref={divRef}
              >
                <Label>{"Periods:"}</Label>

                <CustomDropdown
                  name={"periodsValue"}
                  data={periodsFields.periodsData}
                  textField="text"
                  onOpen={(dataValue) => {
                    setPeriodFields({
                      ...periodsFields,
                      periodsData: dataValue,
                    });
                  }}
                  dataItemKey="id"
                  valueData={periodsList.find(
                    (c) => c.text === periodsFields.periodsValue.text
                  )}
                  onChange={(e, name) => {
                    setPeriodFields({
                      ...periodsFields,
                      [name]: e,
                    });
                  }}
                  filterable={true}
                  onFilterChange={(dataValue) => {
                    setPeriodFields({
                      ...periodsFields,
                      periodsData: dataValue,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  dataList={periodsList}
                  loading={periodsFields.isPeriodDataLoading}
                  disabled={periodsFields.isPeriodDataLoading}
                />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={divRef}
              >
                <Label>{"Comp Plan:"}</Label>
                <MultiSelectDropdown
                  name={"resultCompPlanValue"}
                  module="compPlanName"
                  data={compPlanFields.resultcompPlanData}
                  textField="text"
                  onOpen={(dataV) => {
                    setcompPlanFields({
                      ...compPlanFields,
                      resultcompPlanData: dataV,
                    });
                  }}
                  dataItemKey="id"
                  valueData={compPlanFields.resultCompPlanValue}
                  onChange={(data, name) => {
                    setcompPlanFields({
                      ...compPlanFields,
                      [name]: data,
                      //  resultcompPlanData:
                      //   resultCompPlanist,
                    });
                    setErrorObj({
                      ...errorObj,
                      [name]: "",
                    });
                    // let resultObj = {};
                    // if (
                    //     data[0].id === MULTI_SELECT_ALL_ID
                    // ) {
                    //     resultObj = {
                    //         in_comp_plan_mstr_key: null,
                    //         in_period_start_date: null,
                    //         in_period_end_date: null,
                    //     };
                    // } else {
                    //     resultObj = {
                    //         in_comp_plan_mstr_key: _.get(
                    //             data[0],
                    //             "id",
                    //             "0"
                    //         ),
                    //         in_period_start_date: _.get(
                    //             data[0],
                    //             "period_start_date",
                    //             formatDateOnly(
                    //                 periodsFields
                    //                     .periodsValue
                    //                     .in_period_start_date
                    //             )
                    //         ),
                    //         in_period_end_date: _.get(
                    //             data[0],
                    //             "period_end_date",
                    //             formatDateOnly(
                    //                 periodsFields
                    //                     .periodsValue
                    //                     .in_period_end_date
                    //             )
                    //         ),
                    //     };
                    // }
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setcompPlanFields({
                      ...compPlanFields,
                      resultcompPlanData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  selectAllId={"0"}
                  dataList={resultCompPlanist}
                  loading={compPlanFields.isResultcompPlanDataLoading}
                  disabled={compPlanFields.isResultcompPlanDataLoading}
                />
              </div>
              <div
                className={"k-form-field-wrap-popup valueDropdown"}
                ref={divRef}
              >
                <Label>{"Result Name:"}</Label>
                <MultiSelectDropdown
                  name={"resultNameValue"}
                  module="resultName"
                  data={resultNameFields.resultNameData}
                  textField="text"
                  onOpen={(data) => {
                    setResultNameFields({
                      ...resultNameFields,
                      resultNameData: data,
                    });
                  }}
                  dataItemKey="id"
                  valueData={resultNameFields.resultNameValue}
                  onChange={(data, name) => {
                    setResultNameFields({
                      ...resultNameFields,
                      [name]: data,
                      // resultNameData: resultNameList,
                    });
                    setErrorObj({
                      ...errorObj,
                      [name]: "",
                    });
                  }}
                  filterable={true}
                  onFilterChange={(data) => {
                    setResultNameFields({
                      ...resultNameFields,
                      resultNameData: data,
                    });
                  }}
                  autoClose={false}
                  autoBind={true}
                  isSelectALl={true}
                  selectAllId={"0"}
                  dataList={resultNameList}
                  loading={resultNameFields.isResultNameDataLoading}
                  disabled={resultNameFields.isResultNameDataLoading}
                />
              </div>
              {cookiesData?.out_db_role_type_abbr === "adm1" && (
                <div className="k-form-field-wrap-popup currentpublished">
                  <Label>{"Current/Published:"}</Label>
                  <Switch
                    className={"result-switch"}
                    thumbRounded={"medium"}
                    trackRounded={"medium"}
                    size={"medium"}
                    onChange={handleChange}
                    checked={checked}
                    onLabel={"CURRENT"}
                    offLabel={"PUBLISHED"}
                  />
                </div>
              )}
              <div className="k-form-buttons search-btn">
                <Button
                  primary={true}
                  disabled={
                    periodsTypeFields.isPeriodTypeDataLoading ||
                    periodsFields.isPeriodDataLoading ||
                    resultCompPlanist.isResultcompPlanDataLoading ||
                    resultNameFields.isResultNameDataLoading ||
                    periodTypeList.length === 0 ||
                    resultNameList.length === 0 ||
                    resultCompPlanist.length === 0
                  }
                  type={"submit"}
                  onClick={(e) => {
                    onSearchClick(history.location.pathname, true);
                  }}
                  className="primarybtn"
                >
                  <i className="fas fa-search"></i>
                </Button>
              </div>
            </ul>
            <div className="dtu-file-container padtop15">
              {loading.updateColumn ? <LoadingPanel /> : false}
              {loading.isSearchLoader ? <LoadingPanel /> : false}
              {!loading.isSearchLoader && columns.length > 0 ? (
                <DataTable
                  isAPICalling={isAPICalling}
                  initialLoading={initialLoading}
                  data={data}
                  handleColumnMenu={handleColumnMenu}
                  pageData={pageData}
                  dataCount={dataCount}
                  customColumn={CustomColumn}
                  isColumnMenuSearch={isColumnMenuSearch}
                  columns={columns}
                  dataItemKey={"user_key"}
                  width={"auto"}
                  setSort={setSort}
                  defaultPageSize={defaultPageSize}
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                  handlePageChange={handlePageChange}
                  refreshClick={onRefreshClick}
                  onClearFilter={onClearFilter}
                  onGroupChange={onGroupChange}
                  expandChange={onExpandChange}
                  group={group}
                  groupable={
                    pageProps.writeAccess === 0
                      ? false
                      : {
                          footer: "visible",
                        }
                  }
                  module={"result"}
                  noDataFound={noDataFound}
                  reorderable={pageProps.writeAccess === 0 ? false : true}
                  columnReorderData={columnReorderData}
                  isFilterData={filterData.isFilter}
                  initialFilter={filterField}
                  initialSort={sortField}
                  cellRender={cellRender}
                  rowRender={rowRender}
                  isDragMode={false}
                />
              ) : (
                false
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Results;
