/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DataTable from "../../components/dataTable";
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { DialogComponent } from "../../components/dialog";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import { DEFAULT_SORT } from "../../constants/grid";
import { columnMenuProps } from "../../components/customColumnMenu";
import { LoadingPanel } from "../../components/loadingPanel";
import { useDispatch } from "react-redux";
import { getter } from "@progress/kendo-react-common";
import Popup from "../../components/customPopup";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { CellRender, RowRender } from "../../components/renderers";
import serviceData from "./kpiTypeService";
import {
  getfilterDataString,
  changeCancelBtnState,
  changeUpdateBtnState,
} from "../../utils/utils";
import { removeMessage } from "../../redux/actions/setting-actions";
import { HeaderPage } from "../base/headerPage";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { GridButtonKpiType } from "../kpi/kpiGrid";
import { Button } from "@progress/kendo-react-buttons";
import { Window } from "@progress/kendo-react-dialogs";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "kpi_type_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const initialFormFields = {
  kpi_type_desc: "",
  kpi_type_name: "",
};
const cookiesData = getUserCredential();

export const ManageKpiType = (restProps) => {
  let kpiTypeAccess = {
    ...restProps.pageProps,
    writeAccess: restProps.pageProps.kpiTypeWriteAccess,
    readAccess: restProps.pageProps.kpiTypeReadAccess,
  };
  const [list, Action] = serviceData();

  const dispatch = useDispatch();
  // const { unsavedPopup } = useSelector(
  //   unSaveChangesPopupSelector,
  //   shallowEqual
  // );
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [exportData, setExportData] = React.useState([]);

  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const [errorObj, setErrorObj] = React.useState({});
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this record?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [selectedState, setSelectedState] = React.useState({});
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    deleteSelectedRow: false,
    sendEmails: false,
    isResult: false,
  });
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  //state to set and get limit, offset
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [addNew, setAddNew] = React.useState(false);
  const [kpiTypeAddUpdate, setkpiTypeAddUpdate] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const addRef = React.useRef(null);
  let popupRef = React.useRef();
  const {
    data,
    columns,
    dataCount,
    setData,
    originalData,
    isAPICalling,
    setAPICallingState,
    initialLoading,
    visible,
    setVisible,
    noDataFound,
    dataState,
    isRefreshLoading,
  } = list;

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
  };

  React.useEffect(() => {
    if (kpiTypeAddUpdate === false) {
      changeCancelBtnState("disable", "grid-kpi-cancel");
      changeUpdateBtnState("disable", "grid-kpi-update");
    }
    // if (kpiTypeAddUpdate === true) {
    //   changeCancelBtnState("enable", "grid-kpi-cancel");
    //   changeUpdateBtnState("enable", "grid-kpi-update");
    // }
  }, [kpiTypeAddUpdate, columns]);

  React.useEffect(() => {
    if (isShow.deleteRows) {
      remove();
      setShow({ ...restProps.isShow, deleteRows: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow.deleteRows]);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setAPICall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  if (document.getElementById("grid-kpi-cancel")) {
    document.getElementById("grid-kpi-cancel").onclick = function (e) {
      dispatch(
        removeMessage({
          message: "",
          type: "Error",
          show: false,
        })
      );
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = false;
        return 0;
      });
      setData(originalData);
      setErrorObj({});
      setDataList(originalData);

      //disable in-cell update and cancel button
      e.target.disableKendoBtn();
      changeUpdateBtnState("disable", "grid-kpi-update");

      // changeUpdateBtnState();
    };
  }

  React.useEffect(() => {
    setDataList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (columns.length > 0) {
      setDataList(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  if (document.getElementById("grid-kpi-update")) {
    document.getElementById("grid-kpi-update").onclick = function (e) {
      handleUpdate();
    };
  }

  React.useEffect(() => {
    Action.getKpiTypeDataList(apiCallData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (isAPICall) {
      setAPICall(false);
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAPICall]);

  const onRowClick = (dataItem) => {
    let newData = data.map((item) => ({
      ...item,
      selected:
        item.kpi_type_mstr_key === dataItem.kpi_type_mstr_key ? true : false,
    }));
    setSelectedRowId(dataItem.kpi_type_mstr_key);
    setData(newData);
    setDataList(newData);
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    if (!addNew) {
      if (dataItem.kpi_type_name === "") {
        handleUpdateValidation();
      }
    }
    let newData = data.map((item) => ({
      ...item,
      inEdit: undefined,
    }));
    setData(newData);
  };
  const addNewData = async (e) => {
    // TODO : UNSAVED CHANGES

    // if (unsavedPopup.isUnSavedChange) {
    //   dispatch(
    //     setUnSavedChangesPopup({
    //       ...unsavedPopup,
    //       isVisible: true,
    //       callbackFn: () => {
    //         document.getElementById("grid-kpi-cancel")?.click();
    //         setAddNew(true);
    //       },
    //     })
    //   );
    // } else {
    setAddNew(true);
    //}
    e.preventDefault();
  };

  const enterEdit = (dataItem, field) => {
    if (restProps.pageProps.kpiTypeWriteAccess === 0) {
      return false;
    }
    const newData = data.map((item) => ({
      ...item,
      [editField]:
        item.kpi_type_mstr_key === dataItem.kpi_type_mstr_key
          ? field
          : undefined,
    }));

    setData(newData);
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  const onDeleteConfirm = () => {
    const deleteArray = deleteSelectedIds.map((obj2) => {
      const obj1 = data.find((elem) => elem.kpi_type_mstr_key === obj2.id);
      return {
        id: obj2.id,
        kpiTypeName: obj1.kpi_type_name,
      };
    });
    let deleteData = {
      in_user_id: cookiesData.out_user_id.toString(),
      in_kpi_type_mstr_key: deleteArray,
      in_tenant_id: cookiesData.out_tenant_id,
    };
    Action.deleteKPITypeDataAsync(deleteData);
    setSelectedRowId("");
    setSelectedState({});

    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setData([...data]);
  };
  const handleUpdateValidation = () => {
    let isValid = true;
    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        const { kpi_type_name, kpi_type_desc, rowId } = item;
        let errorObj = {
          kpi_type_name: "",
        };
        if (kpi_type_name.trim() === "") {
          errorObj.kpi_type_name = "Please enter KPI Name";
        } else {
          errorObj.kpi_type_name = "";
        }
        if (kpi_type_desc.trim() === "") {
          errorObj.kpi_type_desc = "Please enter KPI Description";
        } else {
          errorObj.kpi_type_desc = "";
        }

        let obj = {};
        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);
        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }
        return 0;
      });
    return isValid;
  };

  const handleValidation = (dataItem = null) => {
    let isValid = true;

    if (formFields.kpi_type_name.trim() === "") {
      errorObj.kpi_type_name = "Please enter KPI Name";
      isValid = false;
    } else {
      errorObj.kpi_type_name = "";
    }

    if (formFields.kpi_type_desc.trim() === "") {
      errorObj.kpi_type_desc = "Please enter KPI Description";
      isValid = false;
    } else {
      errorObj.kpi_type_desc = "";
    }

    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };

  const handleUpdate = async () => {
    let valid = handleUpdateValidation();
    if (valid) {
      let updatedDataList = [];
      data
        .filter((x) => x.inIteamEdit === true && x.inEdit !== true)
        .map((item) => {
          let obj = {};
          obj.in_user_id = cookiesData.out_user_id.toString();
          obj.in_tenant_id = cookiesData.out_tenant_id;
          obj.in_kpi_type_name = item.kpi_type_name;
          obj.in_kpi_type_desc = item.kpi_type_desc;
          obj.in_kpi_type_mstr_key = item.kpi_type_mstr_key;
          updatedDataList.push(obj);
          return 0;
        });
      const bodyData = {
        updateKpi: [...updatedDataList],
      };
      if (updatedDataList.length > 0) {
        let response = await Action.updateKPIDataAsync(bodyData);

        if (response) {
          onRefreshClick();
          setkpiTypeAddUpdate(false);
        }
      }
    } else {
      // const elements = document.getElementsByClassName("k-textbox");
      // elements[0].focus();

      changeCancelBtnState("enable", "grid-kpi-cancel");
      changeUpdateBtnState("enable", "grid-kpi-update");
    }
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      setErrorObj("");
      const bodyData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_kpi_type_name: formFields.kpi_type_name,
        in_kpi_type_desc: formFields.kpi_type_desc,
      };
      Action.addKPITypeDataAsync(bodyData);
      setkpiTypeAddUpdate(false);
      setSelectedRowId("");
      onRefreshClick();
      setAddNew(false);
      setErrorObj({});
      setFormFields(initialFormFields);
    }
  };

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      let value = props.field + dataItem.rowId;

      const handleChange = (e, props, value) => {
        if (props.onChange) {
          setkpiTypeAddUpdate(true);
          // TODO : UNSAVED CHANGES
          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
          // );
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };
      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          title={dataItem[props.field]}
        >
          {isInEdit ? (
            <Input
              // required
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
            />
          ) : (
            <span title={props.dataItem[props.field || ""]}>
              {props.dataItem[props.field || ""]}
            </span>
          )}
          {/* {!minMaxValidation(props.dataItem[props.field])&& <div className="manage-event"><Error>This Field Is required</Error></div>} */}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.kpi_type_mstr_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys && selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }

    setSelectedRowId(strSelectedKeys);
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, columns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };

  const itemChange = React.useCallback(
    (event) => {
      setkpiTypeAddUpdate(true);
      // handleValidation();
      const field = event.field || "";
      let newData = "";

      newData = data.map((item) =>
        item.kpi_type_mstr_key === event.dataItem.kpi_type_mstr_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );

      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    let response = await Action.getKpiTypeDataList(dataVal);

    setDataList(response);
    setPageMenuState(response);
  };

  const getGridData = async (dataVal) => {
    let response = await Action.getKpiTypeDataList(dataVal);
    setDataList(response);
    setPageMenuState(response);
    changeCancelBtnState("disable", "grid-kpi-cancel");
    changeUpdateBtnState("disable", "grid-kpi-update");
    setErrorObj({});
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    getGridData(dataVal);
  };

  const handleMenuItemClick = (module) => {
    switch (module) {
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const customColumn = columns.map((column, idx) => {
    return column.show ? (
      <Column
        {...columnMenuProps(column)}
        key={idx}
        field={column.field}
        cell={NameCell}
        sortable={true}
      />
    ) : (
      false
    );
  });

  const onClosePopup = () => {
    setVisible(!visible);
    restProps.setShow({ ...restProps.visible, dtuLogs: false });
  };
  const ModalTitle = () => {
    return <span>KPI Type</span>;
  };
  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-kpi-update"
        cancelActBtn="grid-kpi-cancel"
      />
    );
  };
  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      errorObj={errorObj}
      exitEdit={exitEdit}
    />
  );

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    setSelectedRowId(selectedState);
    setSelectedState(newSelectedState);
    let tempArray = [];

    Object.keys(newSelectedState).map((item) => {
      if (newSelectedState[item] === false) {
        return 0;
      }
      let obj = {};
      obj.id = Number(item);

      // obj.text = item.event_group_name;
      tempArray.push(obj);
      return 0;
    });
    setDeleteSelectedIds(tempArray);
    setDataList(data);
  };

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_page_row_offset: 0,
      in_page_rows: dataCount,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);

    if (res) {
      setExportData(res);
    }
  };

  const onClearFilter = async () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    let response = await Action.getKpiTypeDataList(dataVal);
    setDataList(response);
    setPageMenuState(response);
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          // href="/#"
          className="cta-small"
          onClick={(e) => {
            addNewData(e);
            e.preventDefault();
          }}
        >
          Add New KPI Type
        </a>
        {AddNewPopup()}
      </div>
    );
  };
  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    setFormFields({ ...formFields, [name]: e.value });
    setErrorObj({ ...errorObj, [name]: "" });
  };
  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );
  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setAddNew(false);
  };
  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="kpitypeform" horizontal={true}>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup m-right">
                <Label>KPI Type Name*: </Label>
                <Input
                  type="text"
                  name="kpi_type_name"
                  value={formFields.kpi_type_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                />
                <ShowError name="kpi_type_name" />
              </div>
              <div className="k-form-field-wrap-popup">
                <Label>KPI Type Description*: </Label>
                <Input
                  type="text"
                  name="kpi_type_desc"
                  value={formFields.kpi_type_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                />
                <ShowError name="kpi_type_desc" />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };
  const AddNewPopup = () => {
    return (
      <Popup anchor={addRef.current} show={addNew} setShow={setAddNew}>
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New KPI Type</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };

  const headerConfig = {
    id: "Kpitype",
    buttons: { template_fx: GridButtonKpiType },
    add_new_fx: AddNewLink,
    action_menu: {
      delete: { fx: handleMenuItemClick },
    },
  };
  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const deleteRows = parent.querySelector(".delete-rows");
    const auditLog = parent.querySelector(".audit-log");

    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  return (
    <>
      <>
        <Window
          title={<ModalTitle />}
          onClose={onClosePopup}
          width={900}
          height={600}
          top={40}
          resizable={false}
        >
          <div className="dtu-file-container-logs manageeventtype-dialogue">
            <div className="content">
              <div className="Quick-links eventtypequicklinks">
                <HeaderPage
                  pageProps={kpiTypeAccess}
                  headerConfig={headerConfig}
                />
              </div>
            </div>
            {isRefreshLoading && loadingPanel}
            {columns.length > 0 ? (
              <DataTable
                isAPICalling={isAPICalling}
                initialLoading={initialLoading}
                data={data.map((item) => ({
                  ...item,
                  [SELECTED_FIELD]: selectedState[idGetter(item)],
                }))}
                id="kpi-type-grid"
                itemChange={itemChange}
                pageData={pageData}
                dataCount={dataCount}
                customColumn={customColumn}
                editField={editField}
                columns={columns}
                className={"DataTable-dtuLog"}
                defaultPageSize={defaultPageSize}
                pageSize={pageSize}
                setPageSize={setPageSize}
                setPage={setPage}
                handlePageChange={handlePageChange}
                handleColumnMenu={handleColumnMenu}
                refreshClick={onRefreshClick}
                dataItemKey={DATA_ITEM_KEY}
                setSelectedRowId={setSelectedRowId}
                setExportDataClick={setExportDataClick}
                isExportDataClick={exportDataClick}
                setExportData={setExportDataClick}
                fileName={"Export_resultName.xlsx"}
                cellRender={customCellRender}
                rowRender={customRowRender}
                onSelectionChange={onSelectionChange}
                getExportData={getExportData}
                exportData={exportData}
                dataState={dataState}
                onClearFilter={onClearFilter}
                noDataFound={noDataFound}
                reorderable={false}
              />
            ) : initialLoading && data.length === 0 ? (
              false
            ) : (
              <LoadingPanel />
            )}
            <DialogComponent
              title={confirmationPopup.title}
              onCancelConfirm={onCancelConfirm}
              width={"450"}
              height={"auto"}
              className={"Dialog-Delete"}
              desc={confirmationPopup.desc}
              visible={confirmationPopup.isVisible}
              actionBtn={confirmationPopup.actionBtn}
              titleClassName={
                confirmationPopup.action === "Delete"
                  ? "delete-confirm"
                  : "unSave-confirm"
              }
            />
          </div>
        </Window>
      </>
    </>
  );
};
