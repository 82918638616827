// eslint-disable-next-line no-useless-concat
import * as React from "react";

import { Input } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { Button } from "@progress/kendo-react-buttons";
import {
    GridColumn as Column,
    getSelectedState,
} from "@progress/kendo-react-grid";
import {  useDispatch } from "react-redux";
import { columnMenuProps } from "../../../components/customColumnMenu";
import { DialogComponent } from "../../../components/dialog";
import DataTable from "../../../components/dataTable";
import { CellRender, RowRender } from "../../../components/renderers";
import { DELETE_BTN_ACTIONS } from "../../../constants/constants";
import { DEFAULT_SORT } from "../../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../../lib/cookies";
import {
    removeMessage,
    setUnSavedChangesPopup,
} from "../../../redux/actions/setting-actions";
import {
    getfilterDataString,
    changeCancelBtnState,
    changeUpdateBtnState,
    executeGetColumnOrderInfo,
    // isSameRow,
} from "../../../utils/utils";
import useApi from "./service";
import { FormulaMaster } from "src/pages/formula-master/formula-master";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { useHistory } from "react-router";
import useColumnReorder from "src/custom-hooks/useColumnReorder";

const editFieldTier = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "rate_table_tier_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);
export const TableDataRows = (restProps) => {
    const effVersionDetails = restProps.versionData.effVersionDetails;
    const tierData = effVersionDetails.tierData;

    const selectedVersion = effVersionDetails.data.filter(
        (x) => x.isSelected === 1
    )[0];

    const dispatch = useDispatch();
    const history = useHistory();
    const cookiesData = getUserCredential();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [list, Action] = useApi();
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const formulaRef = React.useRef();
    const {
        data,
        columns,
        dataCount,
        setData,
        originalData,
        isAPICalling,
        setConfirmationPopup,
        confirmationPopup,
        setAPICallingState,
        initialLoading,
        isRefreshLoading,
        isSaveLoading,
        setColumns,
    } = list;
    
    const { columnReorderData } = useColumnReorder(
        cookiesData,
        restProps.setColumnsData,
        "rule",
        "procare_system",
    );
    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });

    let pageSize = defaultPageSize

    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });
    const [openPopup, setOpenPopup] = React.useState({
        visible: false,
        data: null,
    });
    const [exportData, setExportData] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState({});
    const [errorObj, setErrorObj] = React.useState({});
    const [gridColumns, setGridColumns] = React.useState([]);

    const apiCallData = {
        ...restProps.tierApiCallData,
        in_rate_table_mstr_key: restProps.versionData.rate_table_mstr_key,
        in_date_version: selectedVersion.dateVersion,
        in_load_type: "data-reload",
        in_init: 1,
    };

    if (
        document.getElementById(
            "grid-incell-cancel-" + restProps.versionData.rate_table_mstr_key
        )
    ) {
        document.getElementById(
            "grid-incell-cancel-" + restProps.versionData.rate_table_mstr_key
        ).onclick = function (e) {
            dispatch(
                removeMessage({
                    message: "",
                    type: "Error",
                    show: false,
                })
            );

            originalData.map((item) => {
                item.inEdit = false;
                item.selected = false;
                return 0;
            });

            const freshOriginData = originalData.filter(
                (x) => x.rate_table_tier_key === 0
            );
            const freshData = data.filter((x) => x.rate_table_tier_key === 0);

            if (freshData.length > 0) {
                const selectedIds = deleteSelectedIds;
                const findIndex = selectedIds.findIndex((a) => a.id === 0);

                findIndex !== -1 && selectedIds.splice(findIndex, 1);

                if (selectedIds.length === 0) {
                    restProps.setSelectedRowId("");
                } else {
                    restProps.setSelectedRowId(selectedIds[0].id);
                }
                setDeleteSelectedIds(selectedIds);
            }
            if (freshOriginData.length > 0) {
                originalData.shift();
            }

            setData(originalData);
            setErrorObj({});
            restProps.setDataList(originalData);

            //disable in-cell update and cancel button
            e.target.disableKendoBtn();
            setTimeout(function () {
                changeCancelBtnState(
                    "disable",
                    "grid-incell-cancel-" +
                        restProps.versionData.rate_table_mstr_key
                );
                changeUpdateBtnState(
                    "disable",
                    "grid-incell-update-" +
                        restProps.versionData.rate_table_mstr_key
                );
            }, 1000);
            restProps.setSelectedRowId("");
            setSelectedState({})
            setDeleteSelectedIds([])
        };
    }

    if (
        document.getElementById(
            "grid-incell-update-" + restProps.versionData.rate_table_mstr_key
        )
    ) {
        document.getElementById(
            "grid-incell-update-" + restProps.versionData.rate_table_mstr_key
        ).onclick = function (e) {
            handleUpdate();
        };
    }

    React.useEffect(() => {
        tierData.rateTierData &&
            Action.getTierPromiseData(
                tierData.rateTierData,
                tierData.rateTierColumn
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedVersion.dateVersion]);

    React.useEffect(() => {
        if (tierData.rateTierColumn) {
            restProps.setDataList(data);
            setGridColumns(tierData.rateTierColumn);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tierData.rateTierColumn]);

    React.useEffect(() => {
        restProps.setDataList(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (restProps.isAddNewConfig) {
            let promise = new Promise(function (resolve, reject) {
                addRow();
                restProps.setNewConfig(false);
      // TODO : UNSAVED CHANGES
                // dispatch(setUnSavedChangesPopup({...unsavedPopup, isUnSavedChange: true}));
                let tempArray = deleteSelectedIds;
                tempArray.push({ id: 0 });
                setDeleteSelectedIds(tempArray);
                resolve("done");
            });

            promise.then((result) => {
                const elements = document.getElementsByClassName("table-input");
                elements[0].focus();
                changeUpdateBtnState(
                    "enable",
                    "grid-incell-update-" +
                        restProps.versionData.rate_table_mstr_key
                );
            }, null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAddNewConfig]);// unsavedPopup]);

    React.useEffect(() => {
        if (list.loadingState.isDeleteData) {
            list.setLoadState({ ...list.loadingState, isDeleteData: false });
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isDeleteData]);

    React.useEffect(() => {
        if (restProps.isShow.deleteRows) {
            remove();
            restProps.setShow({ ...restProps.isShow, deleteRows: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isShow.deleteRows]);

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };

    const addRow = () => {
        const freshKeyLen = data.filter(
            (x) => x.rate_table_tier_key === 0
        ).length;

        const initialFormFields = {
            rate_table_mstr_key: restProps.versionData.rate_table_mstr_key,
            rate_table_tier_key: 0,
            version: 1,
            date_version: selectedVersion.dateVersion,
            rate_table_tier_name: "Tier " + (dataCount + 1),
            tier_low_value: 0,
            tier_high_value: 0,
            rate_value: 0,
            tier_low_formula_mstr_key: 0,
            tier_high_formula_mstr_key: 0,
            rate_formula_mstr_key: 0,
            low_formula_name: null,
            high_formula_name: null,
            rate_formula_name: null,
            inIteamEdit: true,
        };
        if (freshKeyLen === 0) {
            let initialData = JSON.parse(JSON.stringify(initialFormFields));
            data.unshift(initialData);
            setData([...data]);
            restProps.setDataList(data);
        }
        enterEdit(initialFormFields, "rate_table_tier_name");
        restProps.setSelectedRowId(0);
        setDeleteSelectedIds([])
    };

    const handleValidation = () => {
        let errorArr = {};
        const reqCol = gridColumns.filter(
            (c) => c.isRequired === true && c.title !== ""
        );
        const editRow = data.find((x) => x.inIteamEdit === true);

        if (editRow === undefined) {
            return true;
        }

        reqCol.map((col) => {
            let fieldVal = editRow[col.field];
            if (col.type === "string") {
                fieldVal = fieldVal.trim();
            }

            if (fieldVal === "") {
                errorArr[col.field + editRow.rowId] = col.required_msg;
            }
            return 0;
        });

        if (Object.keys(errorArr).length) {
            setErrorObj(errorArr);
            return false;
        }
        return true;
    };

    const handleUpdate = async () => {
        let valid = handleValidation();
        if (valid) {
            let updatedDataList = [];
            data.filter((x) => x.inIteamEdit === true).map((item) => {
                let obj = {};
                obj.in_user_id = cookiesData.out_user_id.toString();
                obj.in_tenant_id = cookiesData.out_tenant_id;
                obj.in_rate_table_mstr_key =
                    restProps.versionData.rate_table_mstr_key;
                obj.in_rate_table_tier_key = item.rate_table_tier_key;
                obj.in_date_version = item.date_version;
                obj.in_tier_name = item.rate_table_tier_name;
                obj.in_tier_low_value = item.tier_low_value;
                obj.in_tier_high_value = item.tier_high_value;
                obj.in_rate_value = item.rate_value;
                obj.in_tier_low_formula_mstr_key =
                    item.tier_low_formula_mstr_key;
                obj.in_tier_high_formula_mstr_key =
                    item.tier_high_formula_mstr_key;
                obj.in_rate_formula_mstr_key = item.rate_formula_mstr_key;

                updatedDataList.push(obj);
                return 0;
            });

            const bodyData = {
                insertUpdateArray: [...updatedDataList],
            };
            if (updatedDataList.length > 0) {
    
                let response = await Action.updateDataAsync(bodyData);

                if (response) {
                    dispatch(
                        setUnSavedChangesPopup({ isUnSavedChange: false })
                    );
                    data.map((item) => {
                        item.inEdit = false;
                        return 0;
                    });
                    setData([...data])
                    setAPICallingState(true);

                    const dataVal = {
                        ...apiCallData,
                        in_filter: getInFilter(),
                        in_sort_number: getInSortNumber(),
                        in_sort_order: getInSortOrder(),
                        in_page_row_offset: getPageSkip(),
                        in_page_rows: getPageTake(),
                    };
            
                    let response = await Action.getListDataAsync(dataVal);
                    if (response.rateTierData) {
                        response = response.rateTierData;
                    } else {
                        return false;
                    }
        
                    changeCancelBtnState(
                        "disable",
                        "grid-incell-cancel-" +
                            restProps.versionData.rate_table_mstr_key
                    );
                    changeUpdateBtnState(
                        "disable",
                        "grid-incell-update-" +
                            restProps.versionData.rate_table_mstr_key
                    );
                    restProps.setDataList(response);
                    restProps.setSelectedRowId("");
                    setSelectedState({})
                    setDeleteSelectedIds([])
                    setPageMenuState(response);
                }
            }
        } else {
            const elements = document.getElementsByClassName("table-input");
            elements[0] && elements[0].focus();

            changeCancelBtnState(
                "enable",
                "grid-incell-cancel-" +
                    restProps.versionData.rate_table_mstr_key
            );
            changeUpdateBtnState(
                "enable",
                "grid-incell-update-" +
                    restProps.versionData.rate_table_mstr_key
            );
        }
    };

    const remove = (ids) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
            data: [],
            action: "Delete",
        });
    };

    const onDeleteConfirm = (event) => {
        let clientDelete = false;
        const deleteArray = deleteSelectedIds.map((obj2) => {
            if (obj2.id > 0) {
                const idx = data.findIndex(
                    (elem) => elem.rate_table_tier_key === obj2.id
                );

                let previousTierId = 0;
                if (idx > 0) {
                    previousTierId = data[idx - 1].rate_table_tier_key;
                }

                const obj1 = data[idx];

                return {
                    id: obj2.id,
                    in_pre_id: previousTierId,
                    rateTableName: obj1.rate_table_tier_name,
                    date_version: obj1.date_version,
                };
            } else {
                clientDelete = true;
                return {};
            }
        });

        var serverDeleteArray = deleteArray.filter(
            (value) => Object.keys(value).length !== 0
        );

        if (clientDelete === true) {
            originalData.shift();
            setData(originalData);
            setErrorObj({});
            restProps.setDataList(originalData);
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: false,
            });
            restProps.setSelectedRowId("");
            setDeleteSelectedIds([]);
            changeCancelBtnState(
                "disable",
                "grid-incell-cancel-" +
                    restProps.versionData.rate_table_mstr_key
            );
            changeUpdateBtnState(
                "disable",
                "grid-incell-update-" +
                    restProps.versionData.rate_table_mstr_key
            );
        }

        if (serverDeleteArray.length > 0) {
            let deleteData = {
                in_user_id: cookiesData.out_user_id,
                in_tenant_id: cookiesData.out_tenant_id,
                in_rate_table_tier_key: serverDeleteArray,
            };

            Action.deleteDataAsync(deleteData).then(function (result) {
                setErrorObj({});
                restProps.setRateTableEdit(false);

                if (result === "success") {
                    const dataVal = {
                        ...apiCallData,
                        in_filter: getInFilter(),
                        in_sort_number: getInSortNumber(),
                        in_sort_order: getInSortOrder(),
                        in_page_row_offset: getPageSkip(),
                        in_page_rows: getPageTake(),
                    };
                    restProps.setTierApiCallDataState(dataVal);
                }
            });
        }
    };

    const getSelectedFormula = () => {
        if (openPopup.data) {
            const { item, field } = openPopup.data;
            if (field === "tier_low_value") {
                return item.tier_low_formula_mstr_key || 0;
            } else if (field === "tier_high_value") {
                return item.tier_high_formula_mstr_key || 0;
            } else if (field === "rate_value") {
                return item.rate_formula_mstr_key || 0;
            }
        }
    };

    const setNewSelectedRow = (newState, isReset) => {
        setSelectedState(newState);
        let tempArray = [];
        const keys = Object.keys(newState).filter(key => newState[key] === true);

        keys.map((item) => {
            let obj = {};
            obj.id = Number(item);

            tempArray.push(obj);
            return 0;
        });

        if (tempArray.length) {
            restProps.setSelectedRowId(tempArray[0].id);
        } else {
            restProps.setSelectedRowId("");
        }
        setDeleteSelectedIds(tempArray);

        if (isReset) {
            const freshOriginData = originalData.filter(
                (x) => x.rate_table_tier_key !== 0
            );
            freshOriginData.map((item) => {
                item.inEdit = false;
                item.selected =
                    Number(item.rate_table_tier_key) === Number(keys[0]);
                return 0;
            });
            setData(freshOriginData);
            restProps.setDataList(freshOriginData);
        } else {
            const freshData = data.find((x) => x.rate_table_tier_key === 0);
            if (freshData) {
                newState[0] = true;
            }
            const selectedKeyArr = keys.filter(function (x) {
                return keys[x] !== false;
            });
            let newData = [...data];
            newData = data.map((item) => ({
                ...item,
                selected: selectedKeyArr.includes(
                    item.rate_table_tier_key.toString()
                ),
            }));
            setData(newData);
            restProps.setDataList(data);
        }
    };

    const onSelectionChange = (event) => {

        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        // let newKeys = Object.keys(newSelectedState);
        // let oldKeys = Object.keys(selectedState);
        // const isSame = isSameRow(oldKeys, newKeys);
        // if (unsavedPopup.isUnSavedChange && !isSame) {
        //     dispatch(setUnSavedChangesPopup({...unsavedPopup, isVisible: true, callbackFn: () => {
        //         setNewSelectedRow(newSelectedState, true);
        //         setErrorObj({});
        //         changeUpdateBtnState("disable",  "grid-incell-update-" + restProps.versionData.rate_table_mstr_key);
        //         changeCancelBtnState("disable", "grid-incell-cancel-" + restProps.versionData.rate_table_mstr_key);
        //       }}))
              
        // } else {
        //     setNewSelectedRow(newSelectedState, false);
        // }
        setNewSelectedRow(newSelectedState, false);
    };

    const onCancelConfirm = (action) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
        data.map((item) => {
            item.selected = false;
            return 0;
        });
        setData([...data]);
      // TODO : UNSAVED CHANGES
    //   dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
    };

    const enterEdit = (dataItem, field) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false;
        }

        let newData = data.map((item) => ({
            ...item,
            inEdit:
                item.rate_table_tier_key === dataItem.rate_table_tier_key
                    ? field
                    : undefined,
        }));

        setData(newData);
    };

    const itemChange = React.useCallback(
        (event) => {
            restProps.setRateTableEdit(true);

            const field = event.field || "";
            let obj = {};
            if (field === "tier_low_value") {
                obj.low_formula_name = "";
                obj.tier_low_formula_mstr_key = 0;
                obj.tier_low_value = isNaN(event.value)
                    ? event.dataItem.tier_low_value
                    : event.value;
            } else if (field === "tier_high_value") {
                obj.high_formula_name = "";
                obj.tier_high_formula_mstr_key = 0;
                obj.tier_high_value = isNaN(event.value)
                    ? event.dataItem.tier_high_value
                    : event.value;
            } else if (field === "rate_value") {
                obj.rate_formula_name = "";
                obj.rate_formula_mstr_key = 0;
                obj.rate_value = isNaN(event.value)
                    ? event.dataItem.rate_value
                    : event.value;
            }

            const newData = data.map((item) =>
                item.rate_table_tier_key === event.dataItem.rate_table_tier_key
                    ? {
                          ...item,
                          [field]: event.value,
                          ...obj,
                          inIteamEdit: true,
                      }
                    : item
            );
            let editItem = event.dataItem;
            delete editItem.inEdit;
            editItem[field] = event.value;
            setData(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    const clearField = (props) => {
        setTimeout(function () {
            changeCancelBtnState(
                "enable",
                "grid-incell-cancel-" +
                    restProps.versionData.rate_table_mstr_key
            );
            changeUpdateBtnState(
                "enable",
                "grid-incell-update-" +
                    restProps.versionData.rate_table_mstr_key
            );
        }, 1000);
        if (props.onChange) {
            enterEdit(props.dataItem, props.field);
            setTimeout(() => {
                props.onChange({
                    dataItem: props.dataItem,
                    field: props.field,
                    value: props.dataItem[props.field],
                });
            });
        }
    };

    const setPageMenuState = (response) => {
        if (!deleteSelectedIds.length) {
            return false;
        }

        let ids = response.map((el) => el.rate_table_tier_key);

        let selectedKeys = [];
        let tempArray = [];
        let tempArrayData = [];

        Object.keys(selectedState).map((item) => {
            let obj = {};

            obj.id = Number(item);
            selectedKeys.push(item);
            tempArray.push(obj);

            return 0;
        });

        ids.map((item) => {
            if (selectedKeys.includes(item.toString())) {
                tempArrayData.push(item);
            }
            return 0;
        });

        let strSelectedKeys = "";

        if (tempArrayData.length > 0) {
            strSelectedKeys = parseInt(selectedKeys[0]);
        }

        restProps.setSelectedRowId(strSelectedKeys);
    };

    const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        let sortOriginField = "";

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            let sortNameField = "";

            //replace mstr key with name field only when sorting is applied
            if (
                columnMenuData[0].field &&
                columnMenuData[0].field.indexOf("_mstr_key")
            ) {
                let sortKeyField = columnMenuData[0].field;
                sortNameField = sortKeyField.replace("_mstr_key", "_name");
            }

            sortOriginField =
                sortNameField !== "" ? sortNameField : columnMenuData[0].field;

            index = obj.findIndex((x) => x === sortOriginField) + 1;
        }

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        const dataVal = {
            ...apiCallData,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
            in_sort_field: sortOriginField,
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);

        getGridData(dataVal);
    };

    const NameCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let fldRow = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    setTimeout(() => {
                        dispatch(
                            setUnSavedChangesPopup({ isUnSavedChange: true })
                        );
                    }, 0);

                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <Input
                            value={dataItem[field]}
                            onChange={(e) => {
                                handleChange(e, props, fldRow);
                            }}
                            className="table-input"
                            name={`${field}`}
                        />
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[fldRow]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const fetchFormulaText = (field, dataItem) => {
        if (field === "tier_low_value") {
            return dataItem.low_formula_name || dataItem.tier_low_value;
        } else if (field === "tier_high_value") {
            return dataItem.high_formula_name || dataItem.tier_high_value;
        } else if (field === "rate_value") {
            return dataItem.rate_formula_name || dataItem.rate_value;
        }
        return dataItem[field];
    };

    const fetchInputType = (field, dataItem) => {
        if (field === "tier_low_value") {
            return dataItem.tier_low_formula_mstr_key > 0 ? "text" : "number";
        } else if (field === "tier_high_value") {
            return dataItem.tier_high_formula_mstr_key > 0 ? "text" : "number";
        } else if (field === "rate_value") {
            return dataItem.rate_formula_mstr_key > 0 ? "text" : "number";
        }
    };

    const NumberCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            let isInEdit = field === dataItem.inEdit;

            let value = props.field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
      // TODO : UNSAVED CHANGES
    //   dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));

                    props.onChange({
                        // dataIndex: 0,
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };

            const inputType = fetchInputType(props.field, props.dataItem);
            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <Input
                            type={inputType}
                            value={fetchFormulaText(
                                props.field,
                                props.dataItem
                            )}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            style={{
                                width: inputType === "text" ? "83%" : "90%",
                            }}
                            className="table-input"
                            name={`${field}`}
                        />
                    ) : (
                        <span>
                            {fetchFormulaText(props.field, props.dataItem)}
                        </span>
                    )}
                    <button
                        style={{ marginTop: isInEdit ? "3px" : "0" }}
                        className="dataTableRt"
                        onClick={(e) => {
                            if (inputType === "text") {
                                clearField(props);
                            } else {
                                goToFx(dataItem, field);
                            }
                        }}
                    >
                        {" "}
                        {inputType === "text" ? "Clear" : "Fx"}{" "}
                    </button>

                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const goToFx = (dataItem, fieldName) => {
        setOpenPopup({
            visible: true,
            data: { item: dataItem, field: fieldName },
        });
    };

    const onClosePopup = () => {
        setOpenPopup({ ...openPopup, visible: false });
    };

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={column.type === "number" ? NumberCell : NameCell}
                    sortable={false}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    let GroupColumn = useCol(gridColumns);

    const onClearFilter = async () => {
        setAPICallingState(true);

        //set in_params
        const dataVal = {
            ...apiCallData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getGridData(dataVal);
    };

    const getGridData = async (dataVal) => {
        if (restProps.rateTableEdit === true) {
            restProps.setTierApiCallDataState(dataVal);
        } else {
            let response = await Action.getListDataAsync(dataVal);
            if (response.rateTierData) {
                response = response.rateTierData;
            } else {
                return false;
            }

            changeCancelBtnState(
                "disable",
                "grid-incell-cancel-" +
                    restProps.versionData.rate_table_mstr_key
            );
            changeUpdateBtnState(
                "disable",
                "grid-incell-update-" +
                    restProps.versionData.rate_table_mstr_key
            );
            restProps.setDataList(response);
            setPageMenuState(response);
        }
    };

    const onRefreshClick = () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        getGridData(dataVal);
    };

    const exitEdit = (dataItem, index, dataIndex, e) => {
        // let newData = data.map((item) => ({
        //     ...item,
        //     inEdit: undefined
        //   }));
        //   setData(newData);
        return handleValidation();
    };

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            className={"thisisdada"}
            editField={editFieldTier}
            exitEdit={exitEdit}
        />
    );

    const getExportData = async () => {
        const dataVal = {
            ...apiCallData,
            in_page_row_offset: 0,
            in_page_rows: dataCount,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };

        let res = await Action.exportDataAsync(dataVal);
        if (res) {
            const finalRes = res.map((it) => ({
                ...it,
                tier_low_value: it.low_formula_name || it.tier_low_value,
                tier_high_value: it.high_formula_name || it.tier_high_value,
                rate_value: it.rate_formula_name || it.rate_value,
            }));
            setExportData(finalRes);
        }
    };

    const onFormulaSelected = (formula) => {
        if(!formula) return ;
      // TODO : UNSAVED CHANGES
    //   dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));

        const { item, field } = openPopup.data;
        const newData = [...data];
        const idx = newData.findIndex(
            (it) => it.rate_table_tier_key === item.rate_table_tier_key
        );
        setTimeout(function () {
            changeCancelBtnState(
                "enable",
                "grid-incell-cancel-" +
                    restProps.versionData.rate_table_mstr_key
            );
            changeUpdateBtnState(
                "enable",
                "grid-incell-update-" +
                    restProps.versionData.rate_table_mstr_key
            );
        }, 1000);
        if (idx >= 0) {
            if (field === "tier_low_value") {
                newData[idx] = {
                    ...newData[idx],
                    tier_low_formula_mstr_key: formula.formula_mstr_key,
                    low_formula_name: formula.formula_name,
                    tier_low_value: 0,
                };
            } else if (field === "tier_high_value") {
                newData[idx] = {
                    ...newData[idx],
                    tier_high_formula_mstr_key: formula.formula_mstr_key,
                    high_formula_name: formula.formula_name,
                    tier_high_value: 0,
                };
            } else if (field === "rate_value") {
                newData[idx] = {
                    ...newData[idx],
                    rate_formula_mstr_key: formula.formula_mstr_key,
                    rate_formula_name: formula.formula_name,
                    rate_value: 0,
                };
            }
            newData[idx].inIteamEdit = true;
            delete newData[idx].inEdit;
            setData(newData);

            const errorItems = { ...errorObj };
            delete errorItems[field + item.rowId];
            setErrorObj(errorItems);
        }
    };

    const customCellRender = (td, props) => {
        return (
            <CellRender
                navigatable={true}
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={editFieldTier}
                updateActBtn={
                    "grid-incell-update-" +
                    restProps.versionData.rate_table_mstr_key
                }
                cancelActBtn={
                    "grid-incell-cancel-" +
                    restProps.versionData.rate_table_mstr_key
                }
            />
        );
    };

    
    const FORMULA_MASTER_ACTIONS = [
        {
            name: "Cancel",
            onClick: onClosePopup,
        },
        {
            name: "Ok",
            onClick: () => {
                const selectedFormula = formulaRef.current?.chooseFormula();
                if(!selectedFormula){
                    alert("Please select filter row")
                    return
                }
                onFormulaSelected(selectedFormula);
                onClosePopup();
            },
        },
    ];

    return (
        <>
             {(isRefreshLoading ) && <>{loadingPanel}</>}

            {columns && columns.length > 0 ? (
                <DataTable
                    isAPICalling={isAPICalling}
                    initialLoading={initialLoading}
                    data={data.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    id="rate-tier-table-grid"
                    module={"rate-tier-table-grid"}
                    editField={editFieldTier}
                    //pageData={pageData}
                    //dataCount={dataCount}
                    customColumn={GroupColumn}
                    columns={gridColumns}
                    dataItemKey={DATA_ITEM_KEY}
                    width={"auto"}
                    gridHeight={"100%"}
                    className="rateinnertablesorting"
                    getExportData={getExportData}
                    setExportData={restProps.setExportDataClick}
                    isExportDataClick={restProps.exportDataClick}
                    fileName={
                        "Export_rate_tier_" +
                        restProps.versionData.rate_table_name +
                        ".xlsx"
                    }
                    exportData={exportData}
                    rowRender={customRowRender}
                    cellRender={customCellRender}
                    itemChange={itemChange}
                    //defaultPageSize={defaultPageSize}
                    //pageSize={pageSize}
                    //setPageSize={setPageSize}
                    handleColumnMenu={handleColumnMenu}
                    //handlePageChange={handlePageChange}
                    refreshClick={onRefreshClick}
                    onClearFilter={onClearFilter}
                    onSelectionChange={onSelectionChange}
                    reorderable={false}
                    columnReorderData={columnReorderData}
                />
            ) : (
                false
            )}
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />

            <DialogComponent
                desc={
                    <FormulaMaster
                        defaultKey={getSelectedFormula()}
                        filterType="RateTierFx"
                        ref={formulaRef}
                        closePopup={onClosePopup}
                        pageProps={restProps.pageProps}
                    />
                }
                title={"Select a Formula"}
                visible={openPopup.visible}
                primBtnText={"Cancel"}
                width={"95%"}
                height={"95%"}
                actionBtn={FORMULA_MASTER_ACTIONS}
                onPrimButton={onClosePopup}
                onCancelConfirm={onClosePopup}
            />
        </>
    );
};

export const GridButton = (restProps) => {
    const args = restProps.args;

    return (
        <>
            <Button
                type="submit"
                className="k-state-disabled cancelbtn"
                id={"grid-incell-cancel-" + args.rate_table_mstr_key}
            >
                Cancel
            </Button>
            <Button
                type="submit"
                className="k-state-disabled primarybtn"
                id={"grid-incell-update-" + args.rate_table_mstr_key}
            >
                Save
            </Button>
        </>
    );
};
