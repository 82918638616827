/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  changeCancelBtnState,
  changeUpdateBtnState,
} from "../../../utils/utils";
import React from "react";
import { HeaderPage } from "../../base/headerPage";
import { GridButton, TableDataRows } from "./dataRowsGrid";
// import {  useSelector } from "react-redux";
// import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";

const TableData = (dataRowProps) => {
  const addRef = React.useRef(null);
  const [isAddNewConfig, setNewConfig] = React.useState(false);
  const [isShow, setShow] = React.useState({
    export: false,
    deleteRows: false,
  });
  // const { unsavedPopup } = useSelector(unSaveChangesPopupSelector);
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [dataList, setDataList] = React.useState([]);
  const [rateTableEdit, setRateTableEdit] = React.useState(false);
  const [columnsData, setColumnsData] = React.useState([]);

  React.useEffect(() => {
    if (rateTableEdit === false) {
      changeCancelBtnState(
        "disable",
        "grid-incell-cancel-" + dataRowProps.versionData.rate_table_mstr_key
      );
      changeUpdateBtnState(
        "disable",
        "grid-incell-update-" + dataRowProps.versionData.rate_table_mstr_key
      );
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rateTableEdit]);

  const addNewConfig = (e) => {
    setNewConfig(!isAddNewConfig);
    e.preventDefault();
  };

  const AddNewLink = () => {
    return (
      <>
        <a
          className="cta-small"
          ref={addRef}
          onClick={(e) => {
            addNewConfig(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Tier
        </a>
      </>
    );
  };

  const handleMenuItemClick = (action) => {
    switch (action) {
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };

  const headerConfig = {
    title: dataRowProps.versionData.rate_table_name
      ? "Rate Table Tiers | " + dataRowProps.versionData.rate_table_name
      : "Rate Table Tiers",
    id: "rate-tier-tables-" + dataRowProps.versionData.rate_table_mstr_key,
    add_new_fx: AddNewLink,
    buttons: {
      template_fx: GridButton,
      args: {
        rate_table_mstr_key: dataRowProps.versionData.rate_table_mstr_key,
      },
    },
    action_menu: {
      export: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");
    const auditLog = parent.querySelector(".audit-log");
    const deleteRows = parent.querySelector(".delete-rows");

    exportToExcel && exportToExcel.disableKendoMenu();
    auditLog && auditLog.disableKendoMenu();
    deleteRows && deleteRows.disableKendoMenu();

    dataList &&
      dataList.length &&
      exportToExcel &&
      exportToExcel.enableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }

  return (
    <>
      <>
        <div className="content-head">
          <HeaderPage
            pageProps={dataRowProps.pageProps}
            headerConfig={headerConfig}
          />
        </div>

        <div className="   ">
          <div className="dtu-file-container hoverenabled disablesortingcursor">
            <TableDataRows
              tierApiCallData={dataRowProps.tierApiCallData}
              pageProps={dataRowProps.pageProps}
              versionData={dataRowProps.versionData}
              setRefreshTableKey={dataRowProps.setRefreshTableKey}
              tierApiCallDataState={dataRowProps.tierApiCallDataState}
              setTierApiCallDataState={dataRowProps.setTierApiCallDataState}
              isAPICall={isAPICall}
              setAPICall={setAPICall}
              setExportDataClick={setExportDataClick}
              exportDataClick={exportDataClick}
              selectedRowId={selectedRowId}
              setSelectedRowId={setSelectedRowId}
              setShow={setShow}
              isShow={isShow}
              setDataList={setDataList}
              rateTableEdit={rateTableEdit}
              setRateTableEdit={setRateTableEdit}
              setNewConfig={setNewConfig}
              isAddNewConfig={isAddNewConfig}
              setColumnsData={setColumnsData}
              columnsData={columnsData}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default React.memo(TableData);
