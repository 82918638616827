import React, { useEffect, useRef, useState } from "react";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import Filters from "./filters";
import SectionOne from "./sectionOne";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { reportsSelector } from "../../redux/selectors/custom-selector";
import SectionTwo from "./sectionTwo";
import "./reports.scss";
import useReportsService from "./service";
import { LoadingPanel } from "../../components/loadingPanel";
import { HeaderPage } from "../base/headerPage";
import { getTenantCookieData } from "../../lib/cookies";
import {
  clearReportsState,
  setReportsCompDetailSection2DataColumns,
  setReportsCompDetailSection2DataLoading,
  setReportsCompDetailSection2PageSize,
  setShowReportsCompDetailSection,
} from "../../redux/actions/reports-actions";
import PDFCompDetailSec2Template from "./pdfCompDetailSec2Template";
import PDFPageTemplate from "./pdfPageTemplate";
import { renderToString } from "react-dom/server";
import {
  executeGetColumnOrderInfo,
  getReportScreenHeight,
} from "src/utils/utils";

const Reports = (pageProps) => {
  const exportSec1 = React.useRef(null);
  const exportSec2 = React.useRef(null);
  const {
    reportsCompDetailSection2DataColumns,
    reportsCompDetailSection2Data,
    showReportsCompDetailSection1,
    showReportsCompDetailSection2,
    isReportsCompDetailSection1DataLoading,
    isReportsCompDetailSection2DataLoading,
    showReportsResults,
    compPlanFilterValue,
    providerFilterValue,
    eventTypeFilterValue,
    periodFilterValue,
    reportsCompDetailSection2FilterString,
    reportsCompDetailSection2SortNumber,
    compPlanPeriodFilterValue,
    isFromProvider,
  } = useSelector(reportsSelector, shallowEqual);
  const reportsServiceActions = useReportsService();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const dispatch = useDispatch();
  const [renderExportSec2, setRenderExportSec2] = useState(false);

  const [exportData, setExportData] = useState();
  const [reportHeight, setReportHeight] = useState(0);

  const section2Ref = useRef(null);

  useEffect(() => {
    if (showReportsResults) {
      setReportHeight(getReportScreenHeight() - 20);
    } else {
      setReportHeight(0);
    }
  }, [showReportsResults]);

  React.useEffect(() => {
    dispatch(setReportsCompDetailSection2PageSize(defaultPageSize));

    return () => {
      if (isFromProvider) {
        clearReportsState();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchExportData = async () => {
    const res = await reportsServiceActions.exportToPdf(
      providerFilterValue["provider_key"],
      eventTypeFilterValue["event_type_mstr_key"],
      compPlanFilterValue["comp_plan_key"],
      periodFilterValue["period_key"],
      periodFilterValue["period_start_date"],
      periodFilterValue["period_end_date"],
      0,
      10000000000000,
      reportsCompDetailSection2SortNumber,
      reportsCompDetailSection2FilterString
    );
    setExportData(res);
    return res;
  };

  const handleExport = async () => {
    // firstly we will get the data for export for whole page.
    let paperSize = "A4";
    if (showReportsCompDetailSection2) {
      const res = await fetchExportData();
      const colsCount = res.columns
        .filter((it) => it.title !== "")
        .filter((it) => it.show).length;
      if (colsCount > 14) {
        paperSize = "A2";
      } else if (colsCount > 9) {
        paperSize = "A3";
      }
    }

    if (showReportsCompDetailSection1) {
      if (exportSec1.current) {
        const group1 = await drawDOM(exportSec1.current, {
          paperSize: "A4",
          landscape: true,
          margin: {
            top: "2cm",
            left: "0.5cm",
            right: "0.5cm",
            bottom: "1.5cm",
          },
          scale: 0.4,
          template: (props) => {
            let newProps = { ...props };
            newProps.compPlan = compPlanFilterValue["comp_plan_name"];
            newProps.period = compPlanPeriodFilterValue["period_name"];
            let z = document.createElement("div");
            z.innerHTML = renderToString(PDFPageTemplate(newProps));
            return z;
          },
        });
        if (showReportsCompDetailSection2) {
          setRenderExportSec2(true);
          await new Promise((r) => setTimeout(r, 500));
          if (exportSec2.current) {
            let group2 = await drawDOM(exportSec2.current, {
              allPages: true,
              paperSize: paperSize,
              landscape: true,
              margin: {
                top: "2cm",
                left: "0.5cm",
                right: "0.5cm",
                bottom: "1.5cm",
              },
              repeatHeaders: true,
              scale: 0.5,
              template: (props) => {
                let newProps = { ...props };
                newProps.compPlan = compPlanFilterValue["comp_plan_name"];
                newProps.period = compPlanPeriodFilterValue["period_name"];
                let z = document.createElement("div");
                z.innerHTML = renderToString(PDFPageTemplate(newProps));
                return z;
              },
            });
            group2.children.unshift(group1);
            exportPDF(group2, { multiPage: true }).then((uri) => {
              saveAs(uri, "compensation_detail.pdf");
              setExportData(undefined);
            });

            setRenderExportSec2(false);
            return;
          }
        }
        exportPDF(group1, { multiPage: true }).then((uri) => {
          saveAs(uri, "compensation_detail.pdf");
          setExportData(undefined);
        });
        return;
      }
    }

    if (!showReportsCompDetailSection1) {
      if (showReportsCompDetailSection2) {
        setRenderExportSec2(true);
        await new Promise((r) => setTimeout(r, 500));
        if (exportSec2.current) {
          let group1 = await drawDOM(exportSec2.current, {
            allPages: true,
            landscape: true,
            paperSize: paperSize,
            margin: {
              top: "2cm",
              left: "0.5cm",
              right: "0.5cm",
              bottom: "1.5cm",
            },
            repeatHeaders: true,
            scale: 0.5,
            template: (props) => {
              let newProps = { ...props };
              newProps.compPlan = compPlanFilterValue["comp_plan_name"];
              newProps.period = compPlanPeriodFilterValue["period_name"];
              let z = document.createElement("div");
              z.innerHTML = renderToString(PDFPageTemplate(newProps));
              return z;
            },
          });
          exportPDF(group1, { multiPage: true }).then((uri) => {
            saveAs(uri, "compensation_detail.pdf");
            setExportData(undefined);
          });
          setRenderExportSec2(false);
          return;
        }
      }
    }
  };

  const onColumnReorder = (columnsData) => {
    const columnDatas = columnsData.map((item) => ({
      ...item,
      alias: item.alias,
      field: item.field.includes("$") ? item.field.substring(2) : item.field,
    }));
    const str = executeGetColumnOrderInfo(columnDatas, "compensation-detail");

    reportsServiceActions.compDetailUpdateColumnOrder(str);
  };

  const updateColumnStringData = (data) => {
    let columnString = "";
    data.forEach((element, index) => {
      let field = element.field.replace("egt_", "");
      let currentString = `"${element.gname}","comp_report_grid","${
        element.alias ? element.alias + "." : ""
      }${field}",${index},${element.show ? 1 : 0}`;

      if (index === 0) {
        columnString = currentString;
      } else {
        columnString = `${columnString}|${currentString}`;
      }
    });

    reportsServiceActions
      .compDetailUpdateColumnOrder(columnString)
      .then(() => {
        dispatch(setReportsCompDetailSection2DataColumns(data));
      })
      .finally(() => {
        dispatch(setReportsCompDetailSection2DataLoading(false));
      });
  };

  const actionMenu = {
    reportSection1: {
      checked: showReportsCompDetailSection1,
      label: "Section 1",
      onChange: (checked) => {
        dispatch(setShowReportsCompDetailSection(checked, 1));
      },
    },
    reportSection2: {
      checked: showReportsCompDetailSection2,
      label: "Section 2",
      onChange: (checked) => {
        dispatch(setShowReportsCompDetailSection(checked, 2));
      },
    },
    exportToPDF: {
      caption: "Export to PDF",
      disabled: !showReportsResults,
      fx: handleExport,
    },
  };
  const headerConfig = {
    title: "Compensation Detail",
    id: "compensation_detail",
    action_menu: showReportsResults
      ? {
          ...actionMenu,
          export:
            pageProps.exportControlAccess === 1
              ? {
                  caption: "Export to CSV",
                  disabled:
                    !showReportsCompDetailSection2 ||
                    reportsCompDetailSection2Data?.length === 0,
                  fx: () => section2Ref.current.excelExport(),
                }
              : undefined,
        }
      : undefined,
    manage_col: {
      columnsData: [...reportsCompDetailSection2DataColumns],
      setColumnsData: (data) => {
        const newItems = reportsCompDetailSection2DataColumns.map((it) => {
          const dataITem = data.find((item) => item.field === it.field);
          const newItem = { ...it };
          if (dataITem) {
            newItem.show = dataITem.show;
          }
          return newItem;
        });

        updateColumnStringData(newItems);
      },
      setColumnCheck: () => null,
    },
  };

  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const exportToExcel = parent.querySelector(".export-to-excel");

    exportToExcel && exportToExcel.disableKendoMenu();

    reportsCompDetailSection2Data.length &&
      exportToExcel &&
      exportToExcel.enableKendoMenu();
  }

  return (
    <div className="Main">
      <div className="content">
        <div className="content-head">
          <HeaderPage pageProps={pageProps} headerConfig={headerConfig} />
        </div>
        <div className="content-body">
          <div id="topSection">
            <Filters />
          </div>

          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              maxHeight: reportHeight,
              marginTop: 20,
            }}
          >
            <div ref={exportSec1}>
              {showReportsResults && showReportsCompDetailSection1 ? (
                <SectionOne pageProps={pageProps} />
              ) : null}
            </div>
            {showReportsResults && showReportsCompDetailSection2 ? (
              <SectionTwo
                ref={section2Ref}
                onColumnReorder={onColumnReorder}
                reportHeight={reportHeight}
                pageProps={pageProps}
              />
            ) : null}
          </div>
          {showReportsResults &&
          showReportsCompDetailSection2 &&
          exportData &&
          renderExportSec2 ? (
            <div
              style={{
                position: "absolute",
                top: "0px",
                right: "calc(100vw + 100px)",
              }}
            >
              <div className="export-sec2-container" ref={exportSec2}>
                <PDFCompDetailSec2Template
                  reportsCompDetailSection2DataColumns={exportData.columns}
                  reportsCompDetailSection2Data={exportData.data}
                  reportsCompDetailSection2DataTotalRows={exportData.totalRows}
                  reportsCompDetailSection2PageSize={exportData.totalRows}
                />
              </div>
            </div>
          ) : null}

          {isReportsCompDetailSection1DataLoading &&
          isReportsCompDetailSection2DataLoading ? (
            <LoadingPanel />
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Reports);
