/* eslint-disable jsx-a11y/anchor-is-valid */
import { Checkbox } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./rule-filters.scss";
import { Label } from "@progress/kendo-react-labels";
import { GetSelectOperators, GetUDFDataObj } from "src/lib/common-service";
import RuleFilterItem from "./ruleFilterItem";
import unionBy from "lodash/unionBy";
import { v4 as uuidv4 } from "uuid";
import { CLOSE_PAREN_DATA, OPEN_PAREN_DATA } from "src/constants/constants";
import { changeCancelBtnState, changeUpdateBtnState } from "src/utils/utils";
import { setRuleFilterItemDataAction } from "src/redux/actions/rule-library-actions";
import { prorateDataSelector } from "src/redux/selectors/custom-selector";
import { Button } from "@progress/kendo-react-buttons";
import {
  getAttributeFromObject,
  getAttributeOperatorAndKeyFromAttribute,
} from "./ruleFilterHelper";
import moment from "moment";
import { Loader } from "@progress/kendo-react-indicators";
// import { setUnSavedChangesPopup } from "src/redux/actions/setting-actions";

const RuleFilterConditions = (restProps) => {
  const [enableFilterConditions, setEnableFilterConditions] = useState(false);
  const prorateReduxData = useSelector(prorateDataSelector);
  
  const dispatch = useDispatch();
  const {
    ruleFilterItems,
    rule_filter_text: ruleFilterText,
    formula_filter_text: formulaFilterText,
  } = prorateReduxData.prorateData;
  const [objectDataList, setObjectDataList] = useState([]);
  const [attributeDataList, setAttributeDataList] = useState([]);
  const [logicalOperators, setLogicalOperators] = useState([]);
  const [compareOperators, setCompareOperators] = useState([]);
  const [isLoading, setIsLoading] = useState(true)
  //const [valueOptions, setValueOptions] = useState([]);

  const fetchObjectListData = async () => {
    const response = await GetUDFDataObj(
      {
        in_filter: ` AND ( u.input_control IS NULL OR u.input_control REGEXP '[{(.*)?"(.[^",]*)?rule_filter":"1"]' ) AND u.rfct_field_name <> 'custom_string_value' `,
        in_sort_number: 2,
        in_sort_order: "ASC",
        in_is_group: 1,
      },
      dispatch
    );
    const result = response.map((item) => ({
      ...item,
      text: item.udf_data_obj_label,
      value: item.udf_data_obj_label,
      input_control:
        typeof item.input_control === "string"
          ? JSON.parse(item.input_control)
          : item.input_control,
    }));
    setObjectDataList(result);
  };

  const fetchObjectAttributes = async () => {
    const response = await GetUDFDataObj(
      {
        in_filter: ` AND (u.input_control IS NULL OR u.input_control REGEXP '[{(.*)?"(.[^",]*)?rule_filter":"1"]' ) AND u.rfct_field_name <> '0' `,
        in_sort_number: 3,
        in_sort_order: "ASC",
        in_is_group: 0,
      },
      dispatch
    );
    const result = response.map((item) => ({
      ...item,
      text: item.udf_data_obj_field_label,
      value: item.udf_data_obj_field_name,
      input_control:
        typeof item.input_control === "string"
          ? JSON.parse(item.input_control)
          : item.input_control,
    }));
    setAttributeDataList(result);
  };

  const fetchOperatorFilterList = async () => {
    const response = await GetSelectOperators(null, dispatch);
    if (response) {
      if (response.logicalOperator) {
        const operatorsList = response.logicalOperator;
        operatorsList.unshift({ text: "", value: "" });
        setLogicalOperators(operatorsList);
      }

      if (response.numericOperator && response.stringOperator) {
        const res = unionBy(
          response.numericOperator,
          response.stringOperator,
          "operator_value"
        );
        setCompareOperators(res);
      }
    }
  };

  const init = async () => {
    await fetchObjectListData();
    await fetchObjectAttributes();
    await fetchOperatorFilterList();
    setIsLoading(false)
  };

  useEffect(() => {
    if(restProps.effVersionState.insertVersion.rowId >= 0 && (ruleFilterText || formulaFilterText)) {
      setEnableFilterConditions(true);
    }
  }, [restProps.effVersionState, ruleFilterText, formulaFilterText])

  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      objectDataList.length &&
      compareOperators.length &&
      attributeDataList.length &&
      logicalOperators.length &&
      !ruleFilterItems.length
    ) {
      setupTheExistingRules();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ruleFilterText,
    formulaFilterText,
    objectDataList.length,
    compareOperators.length,
    attributeDataList.length,
    logicalOperators.length,
    ruleFilterItems.length,
  ]);

  const setupTheExistingRules = () => {
    const ruleFilters = [];
    let ruleFilter = ruleFilterText || "";

    let ruleText = ruleFilter
      .replace("<condition>", "")
      .replace("</condition>", "");

    if (ruleText.length > 0) {
      // first we have to split string by and/or.
      const nullIfRegex = /(?:NULLIF\()([^)]+)(?:, '')/;
      const filterItems = ruleText.split(/(OR|AND)/);
      filterItems.forEach((item, index) => {
        if (item !== "AND" && item !== "OR") {
          let ruleFilter = {
            uId: uuidv4(),
            openParen: OPEN_PAREN_DATA[0],
            closeParen: CLOSE_PAREN_DATA[0],
          };
          const parts = item.split(
            /(=|!=|>=|<=|<|>|IS NULL|IS NOT NULL|NOT IN|IN|NOT LIKE|LIKE)/
          );
          if (parts.length > 0) {
            let firstPart = parts[0];
            const operator = parts[1] || null;
            let value = parts[2] ? parts[2].trim() || "" : "";
            const match = firstPart.includes("NULLIF")
              ? firstPart.trim().match(nullIfRegex)
              : false;
            const attributeFieldName = match
              ? match[1]
              : firstPart.replace("(", "").trim();

            if (firstPart.trim().startsWith("(")) {
              ruleFilter.openParen = OPEN_PAREN_DATA[1];
            }
            if (
              (operator === "IN" || operator === "NOT IN") &&
              value.replaceAll(/\s/g, "").endsWith("))")
            ) {
              ruleFilter.closeParen = CLOSE_PAREN_DATA[1];
              value = value.substring(0, value.length - 1);
            } else if (
              (operator === "IN" || operator === "NOT IN") &&
              value.replaceAll(/\s/g, "").endsWith(")")
            ) {
              ruleFilter.closeParen = CLOSE_PAREN_DATA[0];
            } else if (value.trim().endsWith(")")) {
              ruleFilter.closeParen = CLOSE_PAREN_DATA[1];
              value = value.replace(")", "").trim();
            }

            if(value.startsWith("\"") && value.endsWith("\"")) {
              value = value.replaceAll("\"", "");
            }

            const attrib = attributeDataList.find(
              (it) => it.value === attributeFieldName
            );
            if (attrib) {
              ruleFilter.attributeItem = attrib;
              ruleFilter.objectItem = objectDataList.find(
                (it) => it.udf_data_obj_label.toLowerCase() === attrib.udf_data_obj_label.toLowerCase()
              );
              const { key } = getAttributeOperatorAndKeyFromAttribute(
                attrib,
                compareOperators
              );
              ruleFilter.valueItem = {
                error: false,
                errorMsg: "",
                value:
                  key === "date" &&
                  ["=", "!=", ">", "<", ">=", "<="].includes(operator)
                    ? moment(value.trim(), "YYYY-MM-DD").toDate()
                    : value.trim(),
                key: key,
              };
            }
            ruleFilter.operatorItem = compareOperators.find(
              (it) => it.value === operator
            );
            ruleFilter.andOr =
              filterItems[index + 1] === "AND"
                ? logicalOperators.find((it) => it.value === "AND")
                : filterItems[index + 1] === "OR"
                ? logicalOperators.find((it) => it.value === "OR")
                : logicalOperators[0];

            ruleFilters.push(ruleFilter);
          }
        }
      });
    }
    if (formulaFilterText && formulaFilterText.length > 0) {
      //if we have already other rule filters then we have to add the AND condition on last row.
      if (ruleFilters.length > 0) {
        ruleFilters[ruleFilters.length - 1] = {
          ...ruleFilters[ruleFilters.length - 1],
          andOr: logicalOperators.find((it) => it.value === "AND"),
        };
      }

      const formulaIds = formulaFilterText.split(/(OR|AND)/);
      formulaIds.forEach((it, index) => {
        if (it !== "AND" && it !== "OR") {
          let ruleFilter = {
            uId: uuidv4(),
            openParen: OPEN_PAREN_DATA[0],
            closeParen: CLOSE_PAREN_DATA[0],
          };
          if (it.trim().startsWith("(")) {
            ruleFilter.openParen = OPEN_PAREN_DATA[1];
          }
          if (it.trim().endsWith(")")) {
            ruleFilter.closeParen = CLOSE_PAREN_DATA[1];
          }

          ruleFilter.attributeItem = null;
          ruleFilter.objectItem = objectDataList.find(
            (item) => item.value === "System Fx"
          );
          ruleFilter.operatorItem = compareOperators.find(
            (item) => item.value === "="
          );
          ruleFilter.valueItem = {
            error: false,
            errorMsg: "",
            value: {
              text: "",
              value: it.replace(")", "").replace("(", "").trim(),
            },
            key: "system_fx",
          };

          ruleFilter.andOr =
            formulaIds[index + 1] === "AND"
              ? logicalOperators.find((it) => it.value === "AND")
              : formulaIds[index + 1] === "OR"
              ? logicalOperators.find((it) => it.value === "OR")
              : logicalOperators[0];
          ruleFilters.push(ruleFilter);
        }
      });
    }
    // if ruleFilterText is null or no items and formula also
    if (ruleFilters.length === 0) {
      ruleFilters.push({
        ...getNewObj(),
        andOr: logicalOperators.length
          ? logicalOperators.find((it) => it.value === "AND")
          : null,
      });
      ruleFilters.push(getNewObj());
      setEnableFilterConditions(false);
      restProps.setRuleFilterEnabled(false);
    } else {
      setEnableFilterConditions(true);
      restProps.setRuleFilterEnabled(true);
    }
    dispatch(setRuleFilterItemDataAction(ruleFilters));
  };

  const getNewObj = () => {
    let newObj = {
      uId: uuidv4(),
      openParen: OPEN_PAREN_DATA[0],
      closeParen: CLOSE_PAREN_DATA[0],
      andOr: logicalOperators[0],
    };
    const objectItem = objectDataList.length ? objectDataList[0] : null;
    if (objectItem) {
      const attributeList = getAttributeFromObject(
        attributeDataList,
        objectDataList[0]
      );
      if (attributeList.length) {
        const { operators, key } = getAttributeOperatorAndKeyFromAttribute(
          attributeList[0],
          compareOperators
        );
        newObj.objectItem = objectItem;
        newObj.attributeItem = attributeList[0];
        newObj.operatorItem = operators[0];

        newObj.valueItem = {
          error: false,
          errorMsg: "",
          key: key,
          value:
            newObj.attributeItem?.input_control?.type === "select"
              ? { text: "", value: "" }
              : "",
        };
      }
    }
    return newObj;
  };

  const addNewRuleFilterItem = () => {
    const ruleFilters = [...ruleFilterItems];
    if (ruleFilters[ruleFilters.length - 1]?.andOr?.value === "") {
      ruleFilters[ruleFilters.length - 1] = {
        ...ruleFilters[ruleFilters.length - 1],
        //uId: uuidv4(),
        andOr: logicalOperators.find((it) => it.value === "AND"),
      };
    }
    const newObj = getNewObj();

    const finalArr = [...ruleFilters, newObj];

    dispatch(setRuleFilterItemDataAction(finalArr));

    setTimeout(() => {
      const scrollableDiv = document.getElementById("ruleFilterItemsList");
      if(scrollableDiv) {
        scrollableDiv.scrollTop = scrollableDiv.scrollHeight;
      }
    }, 0);

    changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
    changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
  };

  return (

    <div className="inner-title-cont flex-dir-column">
        {isLoading ?
            <Loader size="medium" className="k-loading-procare" />
        :
        <>
      <div className="rule-filter-top-section">
        <div className="set-filter-checkbox">
          <Checkbox
            checked={enableFilterConditions}
            value={enableFilterConditions}
            label={"Set Filter Conditions"}
            className={"k-checkbox-label innerlabletext"}
            disabled={restProps.pageProps.writeAccess === 0 ? true : false}
            onChange={(e) => {
              changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
              changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
              setEnableFilterConditions(e.value);
              restProps.setRuleFilterEnabled(e.value);
              // TODO: UNSAVED CHANGES
              // dispatch(setUnSavedChangesPopup({isUnSavedChange: true}));
            }}
            />
        </div>
        {restProps.pageProps.writeAccess === 1 &&
        <Button
        className="cta-add-new primarybtn"
        disabled={!enableFilterConditions}
        onClick={addNewRuleFilterItem}
        
        >
          <i className="fas fa-plus"></i> Insert Condition
        </Button>}
      </div>

      <div style={{ position: "relative" }}>
        <div id="ruleFilterContainer">
          <div>
            <div style={{ display: "flex" }} className="rulesfilterconditiontitle">
              {ruleFilterItems?.length > 1 ? (
                <span className="k-icon removeIcon"></span>
                ) : null}
              <div className="parenItem">
                <Label className="innerlabletext">{"Open Paren"}</Label>
              </div>
              <div className="objectItem">
                <Label className="innerlabletext">{"Object"}</Label>
              </div>
              <div className="objectItem">
                <Label className="innerlabletext">{"Object Attribute"}</Label>
              </div>
              <div className="objectItem">
                <Label className="innerlabletext">{"Filter"}</Label>
              </div>
              <div className="otherItem">
                <Label className="innerlabletext">{"Value"}</Label>
              </div>
              <div className="parenItem">
                <Label className="innerlabletext">{"Close Paren"}</Label>
              </div>
              <div className="parenItem">
                <Label className="innerlabletext">{"And/Or"}</Label>
              </div>
            </div>
            <div
              id="ruleFilterItemsList"
              style={{
                maxHeight: "20vh",
                overflowX: "hidden",
                overflowY: enableFilterConditions ? "scroll" : "visible",
              }}
              >
              {ruleFilterItems?.map((item) => (
                <RuleFilterItem
                {...restProps}
                key={item.uId}
                objectDataList={objectDataList}
                attributesDataList={attributeDataList}
                logicalOperators={logicalOperators}
                compareOperators={compareOperators}
                item={item}
                andOr={item.andOr}
                enableFilterConditions={enableFilterConditions}
                visibleCancelIcon={ruleFilterItems.length > 1}

                // valueOptions={valueOptions} 
                // setValueOptions={setValueOptions}
                removeRuleFilterItem={() => {
                  const newItems = ruleFilterItems.filter(
                    (it) => it.uId !== item.uId
                    );
                    
                    newItems[newItems.length - 1] = {
                      ...newItems[newItems.length - 1],
                      andOr: logicalOperators.find((it) => it.value === ""),
                      //uId: uuidv4(),
                    };
                    
                    dispatch(setRuleFilterItemDataAction(newItems));
                    changeCancelBtnState(
                      "enable",
                      "grid-incell-cancel-comp-rule"
                      );
                      changeUpdateBtnState(
                        "enable",
                        "grid-incell-update-comp-rule"
                        );
                        // TODO: UNSAVED CHANGES
                        // dispatch(setUnSavedChangesPopup({isUnSavedChange: true}));
                      }}
                      onValueUpdate={(value) => {
                        const newItems = [...ruleFilterItems];
                        const itemIdx = newItems.findIndex(
                          (it) => it.uId === item.uId
                          );
                          if (itemIdx >= 0) {
                            newItems[itemIdx] = { ...value };
                          }
                          if (
                            newItems?.filter(
                              (it) => it?.objectItem?.value === "System Fx"
                        ).length === 10
                        ) {
                          setObjectDataList((prev) =>
                          prev.map((it) => ({
                            ...it,
                            disabled: it?.value === "System Fx",
                          }))
                          );
                        } else {
                          setObjectDataList((prev) =>
                          prev.map((it) => ({ ...it, disabled: false }))
                          );
                    }
                    dispatch(setRuleFilterItemDataAction(newItems));
                  }}
                />
              ))}
            </div>
          </div>
          </div>
          {!enableFilterConditions ? (
            <div
            style={{
            height:
            (document?.getElementById("ruleFilterContainer")
            ?.offsetHeight || 0) + 10,
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10000,
            maxHeight: "20vh",
            width: "100%",
            backgroundColor: "rgba(0,0,0,0.3)",
          }}
          />
          ) : null}
      </div>
    </>
    }
      </div>
  );
};

export default React.memo(RuleFilterConditions);
