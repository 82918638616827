// eslint-disable-next-line no-useless-concat
import { GridColumn as Column } from "@progress/kendo-react-grid";
import * as React from "react";
import { useDispatch } from "react-redux";
import { columnMenuProps } from "../../../components/customColumnMenu";
import DataTable from "../../../components/dataTable";
import { CellRender, RowRender } from "../../../components/renderers";
import { removeMessage } from "../../../redux/actions/setting-actions";
import {
  changeCancelBtnState,
  changeUpdateBtnState,
  getfilterDataString,
} from "../../../utils/utils";
import useApi from "./service";

import { DEFAULT_SORT } from "../../../constants/grid";
import { getTenantCookieData, getUserCredential } from "../../../lib/cookies";

const editField = "inEdit";
const DATA_ITEM_KEY = "org_position_key";

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const ProviderTableData = (restProps) => {
  const dispatch = useDispatch();
  const [list, Action] = useApi();
  const defaultPageSize = getTenantCookieData("record_per_page");

  const pageId = "plan_provider_assign";

  const {
    data,
    setData,
    columns,
    dataCount,
    isAPICalling,
    setAPICallingState,
    isRefreshLoading,
  } = list;

  const [isHeaderChecked, setIsHeaderChecked] = React.useState(false);
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [uniqueueKey, setUniqueueKey] = React.useState("0_0");
  const [exportData, setExportData] = React.useState([]);
  let selectedProviderRowId = "";

  const cookiesData = getUserCredential();

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  // const [errorObj, setErrorObj] = React.useState({});
  const [gridColumns, setGridColumns] = React.useState([]);
  const [resetSort, setResetSort] = React.useState(true);
  const [checkboxFlag, setCheckboxFlag] = React.useState(false);

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_user_key: cookiesData.out_user_id,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: pageSize.recordTake,
    in_comp_plan_mstr_key: restProps.compTableMasterKey.toString(),
    in_date_version: restProps.effVersionDataItem.dateVersion,
    in_load_type: "data-reload",
  };

  React.useEffect(() => {
    if (restProps.isReload.providerReload) {
      restProps.setReload({
        ...restProps.isReload,
        providerReload: false,
      });
      const dataaVal = {
        ...apiCallData,
        in_load_type: "grid-reload",
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
      };

      setAPICallingState(true);
      getGridData(dataaVal);
      setResetSort(true);
      setFilterData({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isReload]);

  React.useEffect(() => {
    setkHeaderCheckbox();
    const assign = document.querySelector(
      "#content-" + restProps.headerAssignmentConfigId
    );

    if (assign) {
      const exportToExcel = assign.querySelector(".export-to-excel");
      if (exportToExcel) {
        exportToExcel.disableKendoMenu();
        data.length && exportToExcel.enableKendoMenu();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (restProps.selectedRowId !== selectedProviderRowId) {
      setUniqueueKey("0_0");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.selectedRowId]);

  React.useEffect(() => {
    if (restProps.selectedTab == 1) {
      setUniqueueKey(
        restProps.compTableMasterKey +
          "_" +
          restProps.effVersionDataItem.periodMstrKey
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    restProps.effVersionDataItem.uniqueKey,
    restProps.selectedTab,
    restProps.dateVersion,
    restProps.selectedRowId,
  ]);

  React.useEffect(() => {
    if (uniqueueKey !== "0_0") {
      setPageSize(defaultPageSize);
      setPage({
        skip: 0,
        take: defaultPageSize.recordTake,
      });

      setResetSort(true);
      setFilterData({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
      });

      const dataaVal = {
        ...apiCallData,
        in_load_type: "grid-reload",
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
      };

      setAPICallingState(true);
      getGridData(dataaVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueueKey, restProps.dateVersion]);

  React.useEffect(() => {
    if (restProps.cancelProviderAwaitStatus === false) {
      restProps.setCancelProviderAwaitStatus(undefined);
      restProps.isProviderEdit && cancelAssignment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.cancelProviderAwaitStatus]);

  React.useEffect(() => {
    if (restProps.isProviderUpdateClick === true) {
      updateAssignment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isProviderUpdateClick]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setproviderColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  const getGridData = async (inParams) => {
    if (restProps.screenLock !== true) {
      restProps.setScreenLock(true);
    }
    inParams.in_date_version = restProps.effVersionDataItem.dateVersion;
    await Action.getListDataAsync(inParams);

    restProps.setisProviderEdit(false);
    setResetSort(false);
    restProps.setScreenLock(false);
  };

  React.useEffect(() => {
    if (restProps.isproviderCheckColumn) {
      setGridColumns(restProps.providerColumnsData);
      restProps.setproviderColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isproviderCheckColumn]);

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  //hook use to check / uncheck header checkbox
  const setkHeaderCheckbox = () => {
    setIsHeaderChecked(
      data.length ? !data.filter((x) => x.is_position_assign === 0).length : 0
    );
  };

  const onRowClick = (dataItem) => {
    let newData = data.map((item) => ({
      ...item,
      selected:
        item.org_position_key === dataItem.org_position_key ? true : false,
    }));
    setData(newData);
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: undefined
    // }));
    // setData(newData);
  };

  const itemChange = React.useCallback(
    (event) => {
      const field = event.field || "";
      const newData = data.map((item) =>
        item.org_position_key === event.dataItem.org_position_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );
      let editItem = event.dataItem;
      editItem[field] = event.value;
      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handlePageChange = (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridData(dataVal);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      let sortNameField = "";

      //replace mstr key with name field only when sorting is applied
      if (
        columnMenuData[0].field &&
        columnMenuData[0].field.indexOf("_mstr_key")
      ) {
        let sortKeyField = columnMenuData[0].field;
        sortNameField = sortKeyField.replace("_mstr_key", "_name");
      }

      const sortOriginField =
        sortNameField !== "" ? sortNameField : columnMenuData[0].field;

      index = obj.findIndex((x) => x === sortOriginField) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };

  const headerCheckboxOnChange = (e) => {
    restProps.setisProviderEdit(true);

    setIsHeaderChecked(!isHeaderChecked);

    data
      .filter((x) => x.is_position_assign === (isHeaderChecked ? 1 : 0))
      .map((item) => {
        item.is_position_assign = !isHeaderChecked ? 1 : 0;
        item.inIteamEdit = true;

        if (isHeaderChecked) {
          item.inEdit = false;
        }
        return 0;
      });
    // TODO : UNSAVED CHANGES
    // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));

    changeCancelBtnState("enable");
    changeUpdateBtnState("enable");
    setData(data);
  };

  const cellCheckboxOnChange = (e, props) => {
    restProps.setisProviderEdit(true);

    setCheckboxFlag(true);
    props.dataItem.is_position_assign = e.target.checked ? 1 : 0;
    props.dataItem.inEdit = false;
    props.dataItem.inIteamEdit = true;

    setTimeout(function () {
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));

      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
      setCheckboxFlag(false);
      setkHeaderCheckbox();
    });
  };

  const CheckboxCell = React.memo((props) => {
    let opts = {};

    if (restProps.pageProps.writeAccess === 0) {
      opts["disabled"] = "disabled";
    }

    return (
      <td className="k-command-cell delRecord">
        <input
          className="k-checkbox"
          type="checkbox"
          value={props.dataItem.is_position_assign}
          checked={props.dataItem.is_position_assign}
          onChange={(e) => cellCheckboxOnChange(e, props)}
          {...opts}
        />
      </td>
    );
  });

  const BooleanReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

    let val =
      dataItem[field] !== "" ? (dataItem[field] === 1 ? "True" : "False") : "";

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return render?.call(undefined, defaultRendering, props);
  }, []);

  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return render?.call(undefined, defaultRendering, props);
  }, []);

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      if (column.field === "is_position_assign") {
        column["aditionalParam"] = {
          assignCheckboxProps: {
            checked: isHeaderChecked,
            onChange: headerCheckboxOnChange,
            disabled: !data.length || restProps.pageProps.writeAccess === 0,
          },
          dataTableProps: {
            id: "plan_provider_assign",
          },
        };
      }

      return column.show ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          width={column.field === "is_position_assign" ? "130px" : "150px"}
          cell={
            column.type === "boolean"
              ? column.field === "is_position_assign"
                ? CheckboxCell
                : BooleanReandOnlyCell
              : StringReandOnlyCell
          }
          resizable={column.field === "is_assign" ? false : true}
        />
      ) : (
        false
      );
    });
  };

  let providerColumn = useCol(gridColumns);

  const onClearFilter = () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);
    restProps.setRefreshBtnClick(true);
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    getGridData(dataVal);
  };

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        onSelectionChange={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      exitEdit={exitEdit}
    />
  );

  const updateAssignment = async () => {
    const bodydata = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_comp_plan_mstr_key: apiCallData.in_comp_plan_mstr_key,
      in_date_version: apiCallData.in_date_version,
    };

    let updatedproviderAssignDataList = [];
    let updatedproviderAssignIDDataList = [];

    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        let obj = {
          id: item.org_position_key,
        };
        let assignObj = {
          [item.org_position_key]: item.is_position_assign,
        };

        updatedproviderAssignDataList.push(obj);
        updatedproviderAssignIDDataList.push(assignObj);

        return 0;
      });

    bodydata["in_org_position_key_array"] = updatedproviderAssignDataList;
    bodydata["in_is_assign_array"] = updatedproviderAssignIDDataList;
    if (bodydata.in_org_position_key_array.length > 0) {
      let response = await Action.addUpdateDataAsync(bodydata);

      if (response) {
        data.map((item) => {
          item.inEdit = false;
          return 0;
        });

        //setData(data);
        restProps.setisProviderEdit(false);
        onRefreshClick();
      }
    }
  };
  const enterEdit = () => {
    return false;
  };
  const cancelAssignment = () => {
    dispatch(
      removeMessage({
        message: "",
        type: "Error",
        show: false,
      })
    );
    // setErrorObj({});
    onRefreshClick();
  };
  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_page_row_offset: 0,
      in_page_rows: dataCount,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);

    if (res) {
      const exportRes = res.map((item) => ({
        ...item,
        is_position_assign: item.is_position_assign === 1,
      }));
      setExportData(exportRes);
    }
  };
  return (
    <>
      {isRefreshLoading && loadingPanel}
      {columns.length > 0 ? (
        <>
          <DataTable
            isAPICalling={isAPICalling}
            initialLoading={true}
            resetSort={resetSort}
            data={data}
            id={pageId}
            module={pageId}
            dataItemKey={DATA_ITEM_KEY}
            width={"auto"}
            editField={editField}
            dataCount={dataCount}
            customColumn={providerColumn}
            columns={gridColumns}
            rowRender={customRowRender}
            cellRender={customCellRender}
            itemChange={itemChange}
            pageData={pageData}
            defaultPageSize={defaultPageSize}
            pageSize={pageSize}
            setPageSize={setPageSize}
            handleColumnMenu={handleColumnMenu}
            handlePageChange={handlePageChange}
            refreshClick={onRefreshClick}
            onClearFilter={onClearFilter}
            getExportData={getExportData}
            setExportData={restProps.setVersionExportDataClick}
            isExportDataClick={restProps.versionExportDataClick}
            fileName={"Export_plan_provider.xlsx"}
            exportData={exportData}
            reorderable={false}
            isDragMode={false}
          />
        </>
      ) : (
        <>{loadingPanel}</>
      )}
    </>
  );
};
