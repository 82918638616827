import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { executeGetColumnOrderInfo } from "src/utils/utils";
import { updateColumnReorderAsync } from "src/lib/common-service";

const useColumnReorder = (cookiesData, setColumnsData, module="", role="", setLoadingState = ()=>{}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const prepareColumnString = (colData) => {
    return executeGetColumnOrderInfo(colData, module);
  };

  const columnReorderData = async (columnData) => {
    const str = prepareColumnString(
      columnData.filter((x) => x.field !== "action")
    );

    setLoadingState((prevState) => ({
      ...prevState,
      updateColumn: true,
    }));

    const reqData = {
      in_db_name: role,
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter_logical_cond: "N",
      in_filter_label_cond: "N",
      in_column_info: str,
    };

    try {
      const res = await updateColumnReorderAsync(reqData, dispatch, history);

      if (res) {
        setColumnsData([]);
        const colDataList = JSON.parse(JSON.stringify(columnData));
        colDataList.forEach((item) => {
          item.hidden = false;
        });

        const sortedColData = colDataList.sort(
          (a, b) => a.ariaColumnIndex - b.ariaColumnIndex
        );

        setColumnsData(sortedColData);
      }
    } catch (error) {
      console.error("Column reordering failed:", error);
    } finally {
      setLoadingState((prevState) => ({
        ...prevState,
        updateColumn: false,
      }));
    }
  };

  return {
    columnReorderData,
  };
};

export default useColumnReorder;
