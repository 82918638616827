import {
    changeCancelBtnState,
    changeUpdateBtnState,
} from "../../../utils/utils";
import React from "react";
import { HeaderPage } from "../../base/headerPage";
import { GridButton, TableDataRows } from "./dataRowsGrid";
import UploadDtu from "src/pages/user/uploadDtu";
import DtuBatchLogs from "src/pages/common/dtuBatchLogs";



const TableData = (dataRowProps) => {
    const addRef = React.useRef(null);
    const [isAddNewConfig, setNewConfig] = React.useState(false);
    const [isShow, setShow] = React.useState({
        export: false,
        deleteRows: false,
    });
    const [exportDataClick, setExportDataClick] = React.useState(false);
    const [isAPICall, setAPICall] = React.useState(false);
    const [selectedRowId, setSelectedRowId] = React.useState("");
    const [dataList, setDataList] = React.useState([]);
    const [rateTableEdit, setRateTableEdit] = React.useState(false);

    const [columnsData, setColumnsData] = React.useState([]);
    const [isCheckColumn, setColumnCheck] = React.useState(false);
    const [isReloadData ,setReloadData] = React.useState(false)


    React.useEffect(() => {
        if (rateTableEdit === false) {
            changeCancelBtnState(
                "disable",
                "grid-incell-cancel-" +
                dataRowProps.versionData.custom_table_mstr_key
            );
            changeUpdateBtnState(
                "disable",
                "grid-incell-update-" +
                dataRowProps.versionData.custom_table_mstr_key
            );
        }
            // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rateTableEdit]);



    const addNewConfig = (e) => {
        setNewConfig(!isAddNewConfig);
        e.preventDefault();
    };

    const AddNewLink = () => {
        return (
            <>
                <a
                    href="/#"
                    className="cta-small"
                    ref={addRef}
                    onClick={(e) => {
                        addNewConfig(e);
                    }}
                >
                    <i className="fas fa-plus"></i> Add New Row
                </a>
            </>
        );
    };

    const handleMenuItemClick = (action) => {
        switch (action) {
            case "export":
                setExportDataClick(true);
                break;
            case "delete":
                setShow({
                    ...isShow,
                    deleteRows: !isShow.deleteRows,
                });
                break;
            case "dtuLogs":
                setShow({
                    ...isShow,
                    dtuLogs: !isShow.dtuLogs,
                });
                break;
            default:
                break;
        }
    };


    const handleUpload = (e) => {
        setShow({
            ...isShow,
            dtuUpload: !isShow.dtuUpload,
        });
        e.preventDefault()
    };

    const headerConfig = {
        // title: dataRowProps.versionData.custom_table_name
        //     ? "Custom Table Data | " + dataRowProps.versionData.custom_table_name
        //     : "Custom Table Data",
        id: "rate-tier-tables-" + dataRowProps.versionData.custom_table_mstr_key,
        add_new_fx: AddNewLink,
        upload_fx: handleUpload,
        upload_title :"Custom Table Data",
        buttons: {
            template_fx: GridButton,
            args: {
                custom_table_mstr_key:
                    dataRowProps.versionData.custom_table_mstr_key,
            },
        },
        action_menu: {
            dtuLog: { fx: handleMenuItemClick },
            export: { fx: handleMenuItemClick },
            delete: { fx: handleMenuItemClick },
        },
        manage_col: {
            columnsData: columnsData,
            setColumnsData: setColumnsData,
            setColumnCheck: setColumnCheck,
        },
    };

    const parent = document.querySelector("#content-" + headerConfig.id);

    if (parent) {

        const exportToExcel = parent.querySelector(".export-to-excel");
        const auditLog = parent.querySelector(".audit-log");
        const deleteRows = parent.querySelector(".delete-rows");

        exportToExcel && exportToExcel.disableKendoMenu();
        auditLog && auditLog.disableKendoMenu();
        deleteRows && deleteRows.disableKendoMenu();

		if (dataRowProps.dataRowsCount > 0) {
			exportToExcel && exportToExcel.enableKendoMenu();
		}

        if (selectedRowId !== "") {
            auditLog && auditLog.enableKendoMenu();
            deleteRows && deleteRows.enableKendoMenu();
        }
    }

    const onReloadData =()=>{
        setReloadData(true)
    }

    return (
        <>
            <>
                <div className="content-head customtabledatapopup">
                    <HeaderPage
                        pageProps={dataRowProps.pageProps}
                        headerConfig={headerConfig}
                        versionData={dataRowProps.versionData}
                    />
                </div>

                <div className="content-body">
                {/* <div className="customtablemessage">columns with "*" represented with a message. message goes here</div> */}
                    <div className="dtu-file-container customtablegridcont">
                        <TableDataRows
                            tierApiCallData={dataRowProps.tierApiCallData}
                            pageProps={dataRowProps.pageProps}
                            versionData={dataRowProps.versionData}
                            tierApiCallDataState={
                                dataRowProps.tierApiCallDataState
                            }
                            setTierApiCallDataState={
                                dataRowProps.setTierApiCallDataState
                            }
                            isAPICall={isAPICall}
                            setAPICall={setAPICall}
                            setExportDataClick={setExportDataClick}
                            exportDataClick={exportDataClick}
                            selectedRowId={selectedRowId}
                            setSelectedRowId={setSelectedRowId}
                            setShow={setShow}
                            isShow={isShow}
                            setDataList={setDataList}
                            rateTableEdit={rateTableEdit}
                            setRateTableEdit={setRateTableEdit}
                            columnsData={columnsData}
                            setColumnsData={setColumnsData}
                            setColumnCheck={setColumnCheck}
                            isCheckColumn={isCheckColumn}
                            setNewConfig={setNewConfig}
                            isAddNewConfig={isAddNewConfig}
                            effVersionLoadingState={dataRowProps.effVersionLoadingState}
                            setUpdateCustomTableData={dataRowProps.setUpdateCustomTableData}
                            setReloadData={setReloadData}
                            isReloadData={isReloadData}
                            changeVersion={dataRowProps.changeVersion}
                            setChangeVersion={dataRowProps.setChangeVersion}
                            dataRowsCount={dataRowProps.dataRowsCount}
                            setDataRowsCount={dataRowProps.setDataRowsCount}
                        />
                    </div>
                </div>
            </>
            {isShow.dtuLogs && (
                <DtuBatchLogs
                    visible={isShow}
                    setShow={setShow}
                    module={"custom_table_data"}
                    pageProps={dataRowProps.pageProps}
                />
            )}
            {isShow.dtuUpload && <UploadDtu visible={isShow} setShow={setShow} title={'Upload Custom table Data Rows'} pageId={"custom_table_data"}
                versionData={dataRowProps.versionData} setAPICall={onReloadData}

            />}
        </>
    );
};

export default React.memo(TableData);
