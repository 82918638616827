import React, {
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Label } from "@progress/kendo-react-labels";
import { Input } from "@progress/kendo-react-inputs";
import ResultName from "../../resultType/index";
import "./rule-filters.scss";
import { FormulaMaster } from "src/pages/formula-master/formula-master";
import RuleFilterItemValue from "./ruleFilterItemValue";
import useApi from "../compensation-rules/service";
import { CLOSE_PAREN_DATA, OPEN_PAREN_DATA } from "src/constants/constants";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DialogComponent } from "src/components/dialog";
import { changeCancelBtnState, changeUpdateBtnState } from "src/utils/utils";
import {
  fetchDefaultValueBasedOnOperator,
  fetchExistingValues,
  getAttributeOperatorAndKeyFromAttribute,
  tinyIntValues,
} from "./ruleFilterHelper";
// import { useDispatch } from "react-redux";

const RuleFilterItem = (restProps) => {
  const {
    objectDataList,
    attributesDataList,
    logicalOperators,
    compareOperators,
    item,
    andOr,
    removeRuleFilterItem,
    onValueUpdate,
    visibleCancelIcon,
    enableFilterConditions,
  } = restProps;
  const [_, Action] = useApi();
  // const dispatch = useDispatch();
  const formulaRef = useRef();
  const resultRef = useRef();

  const [loading, setLoading] = useState(false);
  const [objectAttributes, setObjectAttributes] = useState([]);
  const [operators, setOperators] = useState([]);
  const [valueOptions, setValueOptions] = useState([]);

  const [openPopup, setOpenPopup] = useState({ isVisible: false });

  const [ruleFilterItem, setRuleFilterItem] = useState();

  useEffect(() => {
    setOperators(compareOperators);
  }, [compareOperators]);

  useEffect(() => {
    if (item && !ruleFilterItem) {
      setRuleFilterItem(item);
      fetchObjectAttributes(item, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item, ruleFilterItem, attributesDataList]);

  useEffect(() => {
    if (ruleFilterItem) {
      onValueUpdate?.(ruleFilterItem);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ruleFilterItem]);

  useEffect(() => {
    if (andOr && ruleFilterItem) {
      setRuleFilterItem({ ...ruleFilterItem, andOr: andOr });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [andOr]);

  const fetchObjectAttributes = async (ruleItem, initState) => {
    const objectItem = ruleItem.objectItem;

    let items = [];
    if (objectItem?.input_control) {
      items = await fetchDropdownValues(objectItem.input_control?.object);
    }

    if (objectItem?.input_control?.object === "system_fx") {
      setObjectAttributes([]);
      setRuleFilterItem({
        ...ruleItem,
        attributeItem: null,
        objectItem: objectItem,
        operatorItem: compareOperators.find((it) => it.value === "="),
        valueItem: {
          ...ruleItem.valueItem,
          key: "system_fx",
          value: items.find(
            (it) => it.value == ruleItem.valueItem.value.value
          ) ?? {
            text: "",
            value: "",
          },
        },
      });
      return;
    }

    const result =
      attributesDataList &&
      attributesDataList?.filter(
        (it) =>
          it.udf_data_obj_label.toLowerCase() ===
          objectItem?.value.toLowerCase()
      )
      ?.map((it) => {
        if(it?.input_control && 
          it?.input_control?.rule_filter === '0') {
            return null;
          }
          return it;
      }).filter(Boolean);
      ;
    setObjectAttributes(result);

    const attribute =
      result.length > 0
        ? !initState
          ? result[0]
          : result.find((it) => it.value === ruleItem.attributeItem.value)
        : null;
    if (attribute) {
      const { operators, key } = getAttributeOperatorAndKeyFromAttribute(
        attribute,
        compareOperators
      );

      if (key === "boolean") {
        items = tinyIntValues;
        setValueOptions(items);
      }

      let operatorItem = ruleItem.operatorItem;
      const currentOperator = operators.find(
        (it) => it.value === ruleItem.operatorItem.value
      )?.disabled;
      if (currentOperator) {
        operatorItem = operators[0];
      }

      if (attribute.input_control && attribute.input_control.object) {
        items = await fetchDropdownValues(attribute.input_control.object);
      }

      const newVal = !initState
        ? fetchDefaultValueBasedOnOperator(
            items,
            { ...ruleItem.valueItem, key: key },
            operatorItem
          )
        : fetchExistingValues(
            items,
            { ...ruleItem.valueItem, key: key },
            operatorItem
          );

      const newRuleItem = {
        ...ruleItem,
        objectItem: objectItem,
        attributeItem: attribute,
        operatorItem: operatorItem,
        valueItem: { ...ruleItem.valueItem, key: key, value: newVal },
      };
      setRuleFilterItem(newRuleItem);
      setOperators(operators);
    }
  };

  const fetchDropdownValues = async (key) => {
    // if (key == "") {
    //   return valueOptions;
    // }
    let res = [];
    setLoading(true);
    if (key === "event_type") {
      res = await Action.getEventTypesListAsync();
    } else if (key === "kpi") {
      res = await Action.getKPIsListAsync();
    } else if (key === "period_name") {
      res = await Action.getPeriodNamesListAsync();
    } else if (key === "group") {
      res = await Action.getGroupNameListAsync();
    } else if (key === "period_type") {
      res = await Action.getPeriodTypesListAsync();
    } else if (key === "result_name") {
      res = await Action.getResultTypeListAsync();
    } else if (key === "system_fx") {
      res = await Action.getFormulaListAsync();
    } else if (key === "boolean") {
      res = tinyIntValues;
    } else {
      res = [];
    }
    setValueOptions(res);
    setLoading(false);
    return res;
  };

  const onClosePopup = () => {
    setOpenPopup({
      ...openPopup,
      isShowFormula: false,
      isShowResult: false,
    });
  };

  const RESULT_NAME_ACTIONS = [
    {
      name: "Cancel",
      onClick: onClosePopup,
    },
    {
      name: "Ok",
      onClick: () => {
        // get selected data here...
        const result = resultRef.current?.chooseResult();
        const val = valueOptions.find((it) => Number(it.value) === result);
        if (val) {
          setRuleFilterItem({
            ...ruleFilterItem,
            valueItem: {
              ...ruleFilterItem.valueItem,
              value: { text: val.text, value: val.value },
              error: false,
              errorMsg: "",
            },
          });
        }

        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
        // TODO: UNSAVED CHANGES
        // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
        onClosePopup();
      },
    },
  ];

  const FORMULA_MASTER_ACTIONS = [
    {
      name: "Cancel",
      onClick: onClosePopup,
    },
    {
      name: "Ok",
      onClick: async () => {
        const formula = formulaRef.current?.chooseFormula();
        if (!formula) {
          alert("Please select filter row");
          return;
        }
        const val = valueOptions.find(
          (it) => Number(it.value) === formula.formula_mstr_key
        );
        if (val) {
          setRuleFilterItem({
            ...ruleFilterItem,
            valueItem: {
              ...ruleFilterItem.valueItem,
              value: { text: val.text, value: val.value },
              error: false,
              errorMsg: "",
            },
          });
        } else {
          setValueOptions([...valueOptions, {...formula, text: formula.formula_name, value: formula.formula_mstr_key}]);
          setRuleFilterItem({
            ...ruleFilterItem,
            valueItem: {
              ...ruleFilterItem.valueItem,
              value: { text: formula.formula_name, value: formula.formula_mstr_key },
              error: false,
              errorMsg: "",
            },
          });
        }

        changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
        changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
        // TODO: UNSAVED CHANGES
        // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
        onClosePopup();
      },
    },
  ];

  const itemRender = (li, itemProps) => {
    const { dataItem } = itemProps;
    const itemChildren = <span>{li.props.children}</span>;
    return React.cloneElement(
      li,
      {
        ...li.props,
        className: `k-item ${
          dataItem.disabled ? "k-disabled" : li.props.className
        }`,
      },
      itemChildren
    );
  };

  if (!ruleFilterItem) return <></>;

  return (
    <div id={ruleFilterItem.uId} style={{ display: "flex", marginTop: 5 }}>
      {visibleCancelIcon && restProps.pageProps.writeAccess === 1 ? (
        <span
          className="k-icon k-font-icon k-i-minus-circle removeIcon"
          onClick={removeRuleFilterItem}
        ></span>
      ) : null}

      {/* <div className="parenItem"> */}
      <DropDownList
        className="parenItem"
        name={`openParenDropdown_${ruleFilterItem.uId}`}
        data={OPEN_PAREN_DATA}
        textField="text"
        dataItemKey="value"
        onChange={(e, name) => {
          setRuleFilterItem({ ...ruleFilterItem, openParen: e.target.value });
          changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
          // TODO: UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
          changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
        }}
        value={ruleFilterItem.openParen}
        filterable={false}
        disabled={restProps.pageProps.writeAccess === 0 ? true : false}
      />
      {/* </div> */}
      {/* <div className="objectItem"> */}

      <DropDownList
        className="objectItem"
        name={`objectItem_${ruleFilterItem.uId}`}
        data={objectDataList}
        itemRender={itemRender}
        textField="text"
        dataItemKey="value"
        onChange={(e, name) => {
          fetchObjectAttributes(
            { ...ruleFilterItem, objectItem: e.target.value },
            false
          );
          changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
          changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
          // TODO: UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
        }}
        value={ruleFilterItem.objectItem}
        filterable={false}
        disabled={
          restProps.pageProps.writeAccess === 0
            ? true
            : !ruleFilterItem.objectItem
        }
      />
      {/* </div> */}

      {/* <div className="objectItem"> */}
      {/* <Label className="innerlabletext">{"Object Attributes"}</Label> */}
      <DropDownList
        className="objectItem"
        name={`objectAttribute_${ruleFilterItem.uId}`}
        data={objectAttributes}
        itemRender={itemRender}
        textField="text"
        dataItemKey="value"
        onChange={async (e, name) => {
          changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
          changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
          // TODO: UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
          const attrib = e.target.value;
          const { operators, key } = getAttributeOperatorAndKeyFromAttribute(
            attrib,
            compareOperators
          );
          setOperators(operators);
          let operatorItem = ruleFilterItem.operatorItem;
          const currentOperator = operators.find(
            (it) => it.value === ruleFilterItem.operatorItem.value
          )?.disabled;
          if (currentOperator) {
            operatorItem = operators[0];
          }

          if (key === "boolean") {
            setValueOptions(tinyIntValues);
          }

          let items = valueOptions;
          if (attrib.input_control) {
            items = await fetchDropdownValues(attrib.input_control?.object);
          }

          const newVal = fetchDefaultValueBasedOnOperator(
            key === "boolean" ? tinyIntValues : items,
            { ...ruleFilterItem.valueItem, key: key },
            operatorItem
          );
          setRuleFilterItem({
            ...ruleFilterItem,
            attributeItem: attrib,
            operatorItem: operatorItem,
            valueItem: {
              ...ruleFilterItem.valueItem,
              key: key,
              value: newVal,
              error: false,
              errorMsg: "",
            },
          });
        }}
        value={ruleFilterItem.attributeItem}
        filterable={false}
        disabled={
          restProps.pageProps.writeAccess === 0
            ? true
            : !ruleFilterItem.attributeItem
        }
      />
      {/* </div> */}

      {/* <div className="objectItem"> */}

      <DropDownList
        className="objectItem"
        name={`filters_${ruleFilterItem.uId}`}
        data={operators}
        itemRender={itemRender}
        textField="text"
        dataItemKey="value"
        onChange={(e, name) => {
          changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
          changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
          // TODO: UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
          const val = fetchDefaultValueBasedOnOperator(
            valueOptions,
            ruleFilterItem.valueItem,
            e.target.value
          );
          setRuleFilterItem({
            ...ruleFilterItem,
            operatorItem: e.target.value,
            valueItem: {
              ...ruleFilterItem.valueItem,
              value: val,
              error: false,
              errorMsg: "",
            },
          });
        }}
        value={ruleFilterItem.operatorItem}
        filterable={false}
        disabled={
          restProps?.pageProps?.writeAccess === 0
            ? true
            : !ruleFilterItem?.operatorItem ||
              ruleFilterItem?.objectItem?.value === "System Fx"
        }
      />
      {/* </div> */}

      <div className="otherItem">
        {ruleFilterItem.valueItem.key === "system_fx" ||
        ruleFilterItem.valueItem.key === "result_name" ? (
          <div>
            <Input
              type="text"
              name={`value_${ruleFilterItem.uId}`}
              placeholder={loading ? "Loading..." : ""}
              className={
                restProps.pageProps.writeAccess === 0
                  ? "customDisabled"
                  : ruleFilterItem.operatorItem?.value === "IS NULL" ||
                    ruleFilterItem.operatorItem?.value === "IS NOT NULL"
                  ? "customDisabled"
                  : ""
              }
              value={
                ruleFilterItem.valueItem
                  ? ruleFilterItem.valueItem.value.text
                  : ""
              }
              style={{ width: "100%" }}
              disabled={
                restProps.pageProps.writeAccess === 0
                  ? true
                  : ruleFilterItem.operatorItem?.value === "IS NULL" ||
                    ruleFilterItem.operatorItem?.value === "IS NOT NULL"
              }
              onClick={(e) => {
                setOpenPopup({
                  ...openPopup,
                  isShowFormula: ruleFilterItem.valueItem.key === "system_fx",
                  isShowResult: ruleFilterItem.valueItem.key === "result_name",
                });
              }}
            />
            {ruleFilterItem.valueItem.errorMsg &&
            ruleFilterItem.valueItem.error ? (
              <Label style={{ fontSize: 11, color: "red" }}>
                {ruleFilterItem.valueItem.errorMsg}
              </Label>
            ) : (
              <Label />
            )}
          </div>
        ) : (
          <RuleFilterItemValue
            enableFilterConditions={enableFilterConditions}
            ruleItem={ruleFilterItem}
            dropdownItems={valueOptions}
            loading={loading}
            onObjectChange={(res) => {
              setRuleFilterItem({ ...ruleFilterItem, valueItem: res });
              changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
              changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
              // TODO: UNSAVED CHANGES
              // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
            }}
          />
        )}
      </div>

      {/* <div className="parenItem"> */}

      <DropDownList
        className="parenItem"
        name={`closeParenDropdown_${ruleFilterItem.uId}`}
        data={CLOSE_PAREN_DATA}
        textField="text"
        dataItemKey="value"
        onChange={(e, name) => {
          setRuleFilterItem({ ...ruleFilterItem, closeParen: e.target.value });
          changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
          changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
          // TODO: UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
        }}
        value={ruleFilterItem.closeParen}
        filterable={false}
        disabled={restProps.pageProps.writeAccess === 0 ? true : false}
      />
      {/* </div> */}

      {/* <div className="parenItem"> */}

      <DropDownList
        className="parenItem"
        name={`andOrDropdown_${ruleFilterItem.uId}`}
        data={logicalOperators}
        textField="text"
        dataItemKey="value"
        onChange={(e) => {
          setRuleFilterItem({ ...ruleFilterItem, andOr: e.target.value });
          changeCancelBtnState("enable", "grid-incell-cancel-comp-rule");
          changeUpdateBtnState("enable", "grid-incell-update-comp-rule");
          // TODO: UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
        }}
        dataList={logicalOperators}
        value={ruleFilterItem.andOr}
        filterable={false}
        disabled={restProps.pageProps.writeAccess === 0 ? true : false}
      />
      {/* </div> */}
      
      <DialogComponent
        desc={
          <FormulaMaster
            defaultKey={ruleFilterItem?.valueItem?.value?.value || null}
            filterType="SystemFx"
            ref={formulaRef}
            closePopup={onClosePopup}
            pageProps={restProps.pageProps}
          />
        }
        title={"Select a Formula"}
        visible={openPopup.isShowFormula}
        primBtnText={"Cancel"}
        width={"95%"}
        height={"95%"}
        actionBtn={FORMULA_MASTER_ACTIONS}
        onPrimButton={onClosePopup}
        onCancelConfirm={onClosePopup}
      />

      <DialogComponent
        title={"Select a Result Name"}
        desc={
          <ResultName
            ref={resultRef}
            defaultResultNameKey={
              ruleFilterItem?.valueItem?.value?.value || null
            }
            showNameResultPopup={false}
            showRuleResultPopup={true}
          />
        }
        className={"result-name-window"}
        visible={openPopup.isShowResult}
        primBtnText={"Cancel"}
        width={"95%"}
        height={"95%"}
        actionBtn={RESULT_NAME_ACTIONS}
        onPrimButton={onClosePopup}
        onCancelConfirm={onClosePopup}
      />
    </div>
  );
};

export default React.memo(RuleFilterItem);
