import React from "react";
import {
    GET_SPECIALITY_DATA,
    ADD_SPECIALITY_DATA,
    DELETE_SPECIALITY_DATA,
    UPDATE_SPECIALITY_DATA,
} from "../../api-setup/api-endpoints";
import { getUserCredential } from "../../lib/cookies";
import {
    POSTAPI,
    errStatusHandler,
    generateHeader,
} from "../../api-setup/api-helper";
import { GridColumnDataMapper } from "../../utils/utils";
import { useHistory } from "react-router";
import { USER_GRID_DEFAULT_PAGE_SIZE } from "../../constants/grid";
import { useDispatch } from "react-redux";
import { setNewMessage } from "../../redux/actions/setting-actions";

const initialDataState = {
    skip: 0,
    take: USER_GRID_DEFAULT_PAGE_SIZE,
};
let defaultItem = {
    text: USER_GRID_DEFAULT_PAGE_SIZE,
    id: 2,
};

export default function Specialty() {
    const cookiesData = getUserCredential();
    const header = {
        "x-access-token": cookiesData.secretToken,
    };
    const history = useHistory();
    const dispatch = useDispatch();
    const [data, setData] = React.useState([]);
    const [columns, setColumns] = React.useState([]);
    const [dataCount, setDataCount] = React.useState(0);
    const [isColumnMenuSearch, setIsColumnMenuSearch] = React.useState(false);
    const [paginationData, setPaginationData] = React.useState({
        pageData: 0,
        rows: USER_GRID_DEFAULT_PAGE_SIZE,
    });
    const [loadingState, setLoadState] = React.useState({
        isSaveData: undefined,
    });
    const [initialLoading, setLoadingState] = React.useState(false);
    const [pageData, setPage] = React.useState(initialDataState);
    const [page, setPageData] = React.useState(0);
    const [editData, setEditData] = React.useState("");
    const [pageSize, setPageSize] = React.useState(defaultItem);
    const [confirmationPopup, setConfirmationPopup] = React.useState({
        title: "Delete",
        desc: "Are you sure you want to delete this record?",
        isVisible: false,
        type: "",
        actionBtn: [],
        data: {},
        action: "Delete",
    });
    const [originalData, setOriginalData] = React.useState([]);
    const [isAPICalling, setAPICallingState] = React.useState(false);
    const [dataState, setDataState] = React.useState();
    const [isRefreshLoading, setRefreshLoading] = React.useState(false);

    const getListDataAsync = (listData, extraColumn) => {
        return new Promise(function (resolve, reject) {
            setIsColumnMenuSearch(true);
            if (extraColumn && (extraColumn.isFilter || extraColumn.isSort)) {
                setIsColumnMenuSearch(true);
            } else {
                setIsColumnMenuSearch(false);
            }
            POSTAPI(
                false,
                GET_SPECIALITY_DATA,
                null,
                header,
                listData,
                dispatch
            ).then((res) => {
                if (res) {
                    let dataEvent = data.eventDataState;
                    setDataState(dataEvent);
                    if (res.data.out_parameters.out_ret_code > 0) {
                        setLoadingState(true);
                        errStatusHandler(res, history, dispatch);
                        setIsColumnMenuSearch(false);
                        
                    }
                    if (extraColumn && extraColumn.event) {
                        setPageData(extraColumn.pageData);
                        setPage(extraColumn.event);
                        setPaginationData({
                            ...paginationData,
                            pageData: extraColumn.pageValue * extraColumn.rows,
                            rows: extraColumn.rows,
                        });
                        if (extraColumn.pageSizeValue) {
                            setPageSize(extraColumn.pageSizeValue);
                        }
                    }
                    setAPICallingState(false);
                    setLoadingState(true);
                    setEditData("");
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    let userData = JSON.parse(
                        JSON.stringify(res.data.row_result)
                    );
                    if (listData.in_load_type === "grid-reload") {
                        const columData = GridColumnDataMapper(
                            res.data.out_parameters.out_column_xml
                        );
                        columData.map((cItem) => {
                            cItem.minWidth = 210;
                            cItem.show =
                                cItem.field === "specialty_mstr_key" ||
                                cItem.field === "version"
                                    ? false
                                    : true;
                            return 0;
                        });
                        setColumns(columData);
                    }
                    userData.map((item, idx) => {
                        item.rowId = idx;
                        item.selected = false;
                        return 0;
                    });

                    setData(userData);
                    setOriginalData(userData);

                    setDataCount(res.data.out_parameters.out_total_rows);
                    setIsColumnMenuSearch(false);
                    setRefreshLoading(false);
                    resolve(userData);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const addDataAsync = (bodyData) => {
        setRefreshLoading(true);
        setLoadState({ ...loadingState, isSaveData: false });
        POSTAPI(
            false,
            ADD_SPECIALITY_DATA,
            null,
            header,
            bodyData,
            dispatch
        ).then((res) => {
            setRefreshLoading(false);
            if (res.status) {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                } else {
                    setLoadState({ ...loadingState, isSaveData: true });
                }
            }
        });
    };

    const deleteDataAsync = async (bodyData) => {
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                DELETE_SPECIALITY_DATA,
                null,
                header,
                bodyData,
                dispatch
            ).then((res) => {
                const totalSpecialities = bodyData.in_specialty_mstr_key.length;
                const unSucessFullCount = res.data.successCount;

                if (res.data.out_parameters.out_ret_code > 0) {
                    let msg = res.data.out_parameters.out_error_msg;
                    if (totalSpecialities !== unSucessFullCount) {
                        msg =
                            unSucessFullCount +
                            " Specialities (" +
                            res.data.successName +
                            ") out of " +
                            totalSpecialities +
                            " as " +
                            msg;

                        setLoadState({ ...loadingState, isDeleteData: true });
                        setTimeout(function () {
                            resolve("success");
                        });
                    } else {
                        setLoadState({ ...loadingState, isDeleteData: false });
                        setTimeout(function () {
                            resolve("error");
                        });
                    }

                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    dispatch(
                        setNewMessage({
                            message: msg,
                            type: "Error",
                            show: true,
                        })
                    );
                } else {
                    setConfirmationPopup({
                        ...confirmationPopup,
                        isVisible: false,
                        type: "",
                        actionBtn: [],
                        data: {},
                        action: "",
                    });
                    setLoadState({ ...loadingState, isDeleteData: true });
                    setTimeout(function () {
                        resolve("success");
                    });
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const updateSpecialityDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                UPDATE_SPECIALITY_DATA,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(true);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    const exportDataAsync = (bodyData) => {
        setRefreshLoading(true);
        return new Promise(function (resolve, reject) {
            POSTAPI(
                false,
                GET_SPECIALITY_DATA,
                null,
                generateHeader(),
                bodyData,
                dispatch
            ).then((res) => {
                setRefreshLoading(false);
                if (
                    res &&
                    (res.data.out_parameters.out_ret_code === 0 ||
                        res.data.out_parameters.out_ret_code === 2)
                ) {
                    resolve(res.data.row_result);
                } else {
                    dispatch(
                        setNewMessage({
                            message: res?.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    resolve([]);
                    errStatusHandler(res, history, dispatch);
                }
            });
        })
            .then((res) => {
                return res;
            })
            .catch((res) => {
                if (res.data.out_parameters.out_ret_code > 0) {
                    dispatch(
                        setNewMessage({
                            message: res.data.out_parameters.out_error_msg,
                            type: "Error",
                            show: true,
                        })
                    );
                    errStatusHandler(res, history, dispatch);
                }
            });
    };

    return [
        {
            data,
            columns,
            dataCount,
            setData,
            setColumns,
            isColumnMenuSearch,
            pageData,
            editData,
            page,
            originalData,
            pageSize,
            isAPICalling,
            setEditData,
            confirmationPopup,
            setConfirmationPopup,
            initialLoading,
            setPage,
            setPageData,
            setPageSize,
            setPaginationData,
            setAPICallingState,
            paginationData,
            loadingState,
            setOriginalData,
            dataState,
            setLoadState,
            isRefreshLoading,
        },
        {
            getListDataAsync,
            addDataAsync,
            deleteDataAsync,
            updateSpecialityDataAsync,
            exportDataAsync,
        },
    ];
}
