/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import DataTable from "../../components/dataTable";
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { DialogComponent } from "../../components/dialog";
import { getTenantCookieData, getUserCredential } from "../../lib/cookies";
import { DEFAULT_SORT } from "../../constants/grid";
import { columnMenuProps } from "../../components/customColumnMenu";
import { LoadingPanel } from "../../components/loadingPanel";
import Popup from "../../components/customPopup";
import { FieldWrapper, Form, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { Error, Label } from "@progress/kendo-react-labels";
import { useDispatch } from "react-redux";
import { getter } from "@progress/kendo-react-common";
import DtuBatchLogs from "../common/dtuBatchLogs";
import { CellRender, RowRender } from "../../components/renderers";
import serviceData from "./resultTypeService";
import {
  getfilterDataString,
  changeCancelBtnState,
  changeUpdateBtnState,
} from "../../utils/utils";
import { removeMessage } from "../../redux/actions/setting-actions";
import { HeaderPage } from "../base/headerPage";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { GridButtonResultType } from "./manage-results";
import { Button } from "@progress/kendo-react-buttons";
import UploadDtu from "../user/uploadDtu";
// import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
import { Window } from "@progress/kendo-react-dialogs";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "result_type_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const initialSort = [
  {
    field: "user_first_name",
    dir: "asc",
  },
];

const initialFormFields = {
  result_type_name: "",
  result_type_desc: "",
};
const cookiesData = getUserCredential();

export const ManageResultType = (restProps) => {
  let resultTypeAccess = {
    ...restProps.pageProps,
    writeAccess: restProps.pageProps.resultTypeWriteAccess,
    readAccess: restProps.pageProps.resultTypeReadAccess
  }
  const [list, Action] = serviceData();

  const dispatch = useDispatch();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [exportData, setExportData] = React.useState([]);

  // const { unsavedPopup } = useSelector(unSaveChangesPopupSelector, shallowEqual);

  const [sort, setSort] = React.useState(initialSort);
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const [errorObj, setErrorObj] = React.useState({});
  const [confirmationPopup, setConfirmationPopup] = React.useState({
    title: "Delete",
    desc: "Are you sure you want to delete this record?",
    isVisible: false,
    type: "",
    actionBtn: [],
    data: {},
    action: "Delete",
  });
  const [selectedState, setSelectedState] = React.useState({});
  const [exportDataClick, setExportDataClick] = React.useState(false);
  const [selectedRowId, setSelectedRowId] = React.useState("");
  const [isShow, setShow] = React.useState({
    dtuLogs: false,
    deleteSelectedRow: false,
    sendEmails: false,
    isResult: false,
  });
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  //state to set and get limit, offset
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [addNew, setAddNew] = React.useState(false);
  const [resultTypeAddUpdate, setresultTypeAddUpdate] = React.useState(false);
  const [isAPICall, setAPICall] = React.useState(false);
  const [dataList, setDataList] = React.useState([]);
  const [columnsData, setColumnsData] = React.useState([]);
  const [isCheckColumn, setColumnCheck] = React.useState(false);
  const [gridColumns, setGridColumns] = React.useState([]);
  const [formFields, setFormFields] = React.useState({
    ...initialFormFields,
  });
  const [modal, setModal] = React.useState(true)
  const [stageChange, setStateChange] = React.useState('DEFAULT')

  const addRef = React.useRef(null);
  let popupRef = React.useRef();
  const {
    data,
    columns,
    dataCount,
    setData,
    originalData,
    isAPICalling,
    setAPICallingState,
    initialLoading,
    visible,
    setVisible,
    noDataFound,
    dataState,
    isRefreshLoading,
  } = list;

  const apiCallData = {
    in_filter: null,
    in_sort_number: 1,
    in_sort_order: "DESC",
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
  };

  if (document.getElementById("grid-result-cancel")) {
    document.getElementById("grid-result-cancel").onclick = function (e) {
      dispatch(
        removeMessage({
          message: "",
          type: "Error",
          show: false,
        })
      );
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = false;
        return 0;
      });
      setData(originalData);
      setErrorObj({});
      setDataList(originalData);
      // setSelectedRowId("");
      // setSelectedState({});

      //disable in-cell update and cancel button
      e.target.disableKendoBtn();
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
      changeUpdateBtnState("disable", "grid-result-update");
    };
  }

  React.useEffect(() => {
    if (resultTypeAddUpdate === false) {
      changeCancelBtnState("disable", "grid-result-cancel");
      changeUpdateBtnState("disable", "grid-result-update");
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultTypeAddUpdate, columns]);

  React.useEffect(() => {
    if (isShow.deleteRows) {
      remove();
      setShow({ ...restProps.isShow, deleteRows: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow.deleteRows]);

  React.useEffect(() => {
    if (list.loadingState.isSaveData) {
      setAPICall(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isSaveData]);

  React.useEffect(() => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    setDataList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (columns.length > 0) {
      setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  if (document.getElementById("grid-result-update")) {
    document.getElementById("grid-result-update").onclick = function (e) {
      handleUpdate();
    };
  }

  React.useEffect(() => {
    const dataVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
    };
    Action.getResultDataList(dataVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (isCheckColumn) {
      setGridColumns(columnsData);
      setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheckColumn]);

  React.useEffect(() => {
    if (isAPICall) {
      setAPICall(false);
      onRefreshClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAPICall]);

  const exitEdit = (dataItem, index, dataIndex, e) => {
    if (!addNew) {
      if (dataItem.result_type_name === "") {
        handleUpdateValidation();
      }
    }
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: undefined
    // }));
    // setData(newData);
  };

  const enterEdit = (dataItem, field) => {

    if (restProps.pageProps.resultTypeWriteAccess === 0) {
      return false
    }
    setresultTypeAddUpdate(true);

    const newData = data.map((item) => ({
      ...item,
      [editField]:
        item.result_type_mstr_key === dataItem.result_type_mstr_key
          ? field
          : undefined,
    }));

    setData(newData);
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  const onDeleteConfirm = () => {
    const deleteArray = deleteSelectedIds.map((obj2) => {
      const obj1 = data.find((elem) => elem.result_type_mstr_key === obj2.id);
      return {
        id: obj2.id,
        resultTypeName: obj1.result_type_name,
      };
    });

    let deleteData = {
      in_user_id: cookiesData.out_user_id.toString(),
      in_result_type_mstr_key: deleteArray,
      in_tenant_id: cookiesData.out_tenant_id,
    };
    Action.deleteResultTypeDataAsync(deleteData, apiCallData);

    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    setSelectedRowId("");
    setSelectedState({});
  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setData([...data]);
  };

  const handleValidation = () => {
    let isValid = true;

    if (formFields.result_type_name.trim() === "") {
      errorObj.result_type_name = "Please enter result type name";
      isValid = false;
    } else {
      errorObj.result_type_name = "";
    }
    setErrorObj((preVState) => ({ ...preVState, errorObj }));
    return isValid;
  };
  const handleUpdateValidation = () => {
    let isValid = true;
    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        const { result_type_name, rowId } = item;
        let errorObj = {
          result_type_name: "",
        };
        if (result_type_name.trim() === "") {
          errorObj.result_type_name = "Please enter result type name";
        } else {
          errorObj.result_type_name = "";
        }

        let obj = {};
        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);
        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }
        return 0;
      });
    return isValid;
  };

  const handleUpdate = async () => {
    let valid = handleUpdateValidation();
    if (valid) {
      let updatedDataList = [];
      data
        .filter((x) => x.inIteamEdit === true && x.inEdit !== true)
        .map((item) => {
          let obj = {};
          obj.in_user_id = cookiesData.out_user_id.toString();
          obj.in_tenant_id = cookiesData.out_tenant_id;
          obj.in_result_type_mstr_key = item.result_type_mstr_key;
          obj.in_result_type_name = item.result_type_name;
          obj.in_result_type_desc = item.result_type_desc;
          updatedDataList.push(obj);
          return 0;
        });

      const bodyData = {
        updateResult: [...updatedDataList],
      };
      if (updatedDataList.length > 0) {
        let response = await Action.updateResultDataAsync(bodyData);

        if (response) {
          onRefreshClick();
          setresultTypeAddUpdate(false);
        }
      }
    } else {
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));

      changeCancelBtnState("enable", "grid-result-cancel");
      changeUpdateBtnState("enable", "grid-result-update");
    }
  };

  const handleSubmit = () => {
    let valid = handleValidation();
    if (valid) {
      const bodyData = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_tenant_id: cookiesData.out_tenant_id,
        in_result_type_name: formFields.result_type_name,
        in_result_type_desc: formFields.result_type_desc,
      };
      Action.addResultTypeDataAsync(bodyData);
      onRefreshClick();
      setresultTypeAddUpdate(false);
      setSelectedRowId("");
      setAddNew(false);
      setErrorObj({});
      setFormFields(initialFormFields);
    }
  };

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      let value = props.field + dataItem.rowId;

      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO : UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));

          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };
      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          title={dataItem[props.field]}
        >
          {isInEdit ? (
            <Input
              // required
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
            />
          ) : (
            <span>{props.dataItem[props.field || ""]}</span>
          )}
          {/* {!minMaxValidation(props.dataItem[props.field])&& <div className="manage-event"><Error>This Field Is required</Error></div>} */}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.result_type_mstr_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys && selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }

    setSelectedRowId(strSelectedKeys);
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);

    getGridData(dataVal);
  };

  const itemChange = React.useCallback(
    (event) => {
      // handleValidation();
      const field = event.field || "";
      let newData = "";

      newData = data.map((item) =>
        item.result_type_mstr_key === event.dataItem.result_type_mstr_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );

      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handlePageChange = async (event) => {
    const pageData = event.page;

    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    let response = await Action.getResultDataList(dataVal);

    setDataList(response);
    setPageMenuState(response);
  };

  const getGridData = async (dataVal) => {
    let response = await Action.getResultDataList(dataVal);
    setDataList(response);
    setPageMenuState(response);
    setresultTypeAddUpdate(false);
    setErrorObj({});
    // TODO : UNSAVED CHANGES
    // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));

    changeCancelBtnState("disable", "grid-result-cancel");
    changeUpdateBtnState("disable", "grid-result-update");
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    getGridData(dataVal);
  };

  const handleMenuItemClick = (module) => {
    switch (module) {
      case "dtuLogs":
        setShow({
          ...isShow,
          dtuLogs: !isShow.dtuLogs,
        });
        break;
      case "export":
        setExportDataClick(true);
        break;
      case "delete":
        setShow({
          ...isShow,
          deleteRows: !isShow.deleteRows,
        });
        break;
      default:
        break;
    }
  };
  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          field={column.field}
          key={idx}
          cell={NameCell}
          sortable={true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let ResultTypeColumn = useCol(gridColumns);

  const onClosePopup = () => {
    setVisible(!visible);
    restProps.setShow({ ...restProps.visible });
  };
  const ModalTitle = () => {
    return (
      <span>
        Result Type
      </span>
    );
  };
  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        updateActBtn="grid-result-update"
        cancelActBtn="grid-result-cancel"
      />
    );
  };
  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      errorObj={errorObj}
      exitEdit={exitEdit}
    />
  );

  const setNewSelectedRow = (newState, isReset) => {
    const keys = Object.keys(newState).filter(key => newState[key] === true);
    setSelectedState(newState);
    let tempArray = [];
    setSelectedRowId(selectedState);
    setSelectedState(newState);

    keys.map((item) => {
      let obj = {};
      obj.id = Number(item);
      // obj.text = item.event_group_name;
      tempArray.push(obj);
      return 0;
    });
    setDeleteSelectedIds(tempArray);
    if (isReset) {
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = Number(item.result_type_mstr_key) === Number(keys[0]);
        return 0;
      });
      const item = originalData.find((it) => it.selected === true);
      if (item) {
        setSelectedRowId(item.result_type_mstr_key);
      }

      setData(originalData);
      setDataList(originalData);
    } else {
      const item = data.find((it) => it.selected === true);
      if (item) {
        setSelectedRowId(item.result_type_mstr_key);
      }
      setData(data);
      setDataList(data);
    }
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    //  let newKeys = Object.keys(newSelectedState);
    // let oldKeys = Object.keys(selectedState);
    // const isSame = isSameRow(oldKeys, newKeys);
    // TODO : UNSAVED CHANGES
    // if (unsavedPopup.isUnSavedChange && !isSame) {
    //   dispatch(setUnSavedChangesPopup({
    //     ...unsavedPopup, isVisible: true, callbackFn: () => {
    //       setNewSelectedRow(newSelectedState, true);
    //       setErrorObj({});
    //       changeCancelBtnState("disable", "grid-result-cancel");
    //       changeUpdateBtnState("disable", "grid-result-update");
    //     }
    //   }));
    //   return;
    // }
    setNewSelectedRow(newSelectedState, false);
  };

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_page_row_offset: 0,
      in_page_rows: dataCount,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);

    if (res) {
      setExportData(res);
    }
  };

  const onClearFilter = async () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    let response = await Action.getResultDataList(dataVal);
    setDataList(response);
    setPageMenuState(response);
  };
  const handleFieldChange = (e) => {
    const name = e.name ? e.name : e.target.name;
    setFormFields({ ...formFields, [name]: e.value });
    setErrorObj({ ...errorObj, [name]: "" });
  };
  const ShowError = React.useCallback(
    (props) => {
      return <Error>{errorObj[props.name]}</Error>;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );
  const AddNewForm = () => {
    return (
      <Form
        onSubmit={handleSubmit}
        render={() => (
          <FormElement className="emailForm" horizontal={true}>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup">
                <Label>Result Type Name *: </Label>
                <Input
                  type="text"
                  name="result_type_name"
                  value={formFields.result_type_name}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  autoFocus={true}
                  placeholder="Result Type Name"
                />
                <ShowError name="result_type_name" />
              </div>
            </FieldWrapper>
            <FieldWrapper>
              <div className="k-form-field-wrap-popup width170">
                <Label>Result Type Description : </Label>
                <Input
                  type="text"
                  name="result_type_desc"
                  value={formFields.result_type_desc}
                  onChange={(e) => {
                    handleFieldChange(e);
                  }}
                  placeholder="Result Type Description"
                />
              </div>
            </FieldWrapper>
          </FormElement>
        )}
      />
    );
  };
  const AddNewPopup = () => {
    return (
      <Popup
        anchor={addRef.current}
        show={addNew}
        setShow={setAddNew}
        className="right0"
      >
        <div className="dot-dropdown-open dot-dropdown-open-Form">
          <div className="caption">
            <span>Add New Result Type</span>
          </div>

          {AddNewForm()}

          <div className="dropdownFooter">
            <div className="k-form-buttons">
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  onCancelClick();
                }}
              >
                Cancel
              </Button>
              <Button
                primary={true}
                type="submit"
                onClick={() => {
                  handleSubmit(formFields, errorObj);
                }}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      </Popup>
    );
  };
  const onCancelClick = () => {
    setFormFields(initialFormFields);
    setErrorObj({});
    setAddNew(false);
  };
  const addNewData = async (e) => {
    setAddNew(!addNew);
    e.preventDefault();
  };

  const AddNewLink = () => {
    return (
      <div ref={popupRef}>
        <a
          // href="/#"
          className="cta-small"
          onClick={(e) => {
            // TODO : UNSAVED CHANGES
            // if (unsavedPopup.isUnSavedChange) {
            //   dispatch(setUnSavedChangesPopup({
            //     ...unsavedPopup, isVisible: true, callbackFn: () => {
            //       addNewData(e);
            //       document.getElementById("grid-result-cancel")?.click();
            //     }
            //   }))
            // } else {
            //   addNewData(e);
            // }
            addNewData(e);
          }}
        >
          <i className="fas fa-plus"></i> Add New Result Type
        </a>
        {AddNewPopup()}
      </div>
    );
  };

  const handleUpload = (e) => {
    setShow({
      ...isShow,
      dtuUpload: !isShow.dtuUpload,
    });
    e.preventDefault();
  };

  const headerConfig = {
    id: "resulttype",
    buttons: { template_fx: GridButtonResultType },
    add_new_fx: AddNewLink,
    upload_fx: handleUpload,
    upload_title: "Result Type",
    action_menu: {
      dtuLog: { fx: handleMenuItemClick },
      delete: { fx: handleMenuItemClick },
    },
    manage_col: {
      columnsData: columnsData,
      setColumnsData: setColumnsData,
      setColumnCheck: setColumnCheck,
    },
  };
  const parent = document.querySelector("#content-" + headerConfig.id);

  if (parent) {
    const deleteRows = parent.querySelector(".delete-rows");
    const auditLog = parent.querySelector(".audit-log");

    deleteRows && deleteRows.disableKendoMenu();
    auditLog && auditLog.disableKendoMenu();

    if (selectedRowId !== "") {
      auditLog && auditLog.enableKendoMenu();
      deleteRows && deleteRows.enableKendoMenu();
    }
  }
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );

  const handleStageChange = (e) => {
    setStateChange(e.state)
    if (e.state === 'MINIMIZED') {
      setModal(false)
    } else if (e.state === 'DEFAULT') {
      setModal(true)
    }
  };

  return (
    <>
      <Window
        title={<ModalTitle />}
        visible={visible}
        width={1200}
        height={600}
        onClose={onClosePopup}
        stage={stageChange}
        onStageChange={handleStageChange}
        modal={modal}
      >
        <div className="dtu-file-container-logs Manageresulttype-dialogue">
          <div className="content">
            <div className="Quick-links eventtypequicklinks resultstylepopup">
              <HeaderPage
                pageProps={resultTypeAccess}
                headerConfig={headerConfig}
              />
            </div>
          </div>
          {isRefreshLoading && loadingPanel}
          {columns.length > 0 ? (
            <DataTable
              isAPICalling={isAPICalling}
              initialLoading={initialLoading}
              data={data.map((item) => ({
                ...item,
                [SELECTED_FIELD]: selectedState[idGetter(item)],
              }))}
              id="result-type-grid"
              itemChange={itemChange}
              pageData={pageData}
              dataCount={dataCount}
              sort={sort}
              customColumn={ResultTypeColumn}
              editField={editField}
              columns={gridColumns}
              setSort={setSort}
              defaultPageSize={defaultPageSize}
              pageSize={pageSize}
              setPageSize={setPageSize}
              setPage={setPage}
              handlePageChange={handlePageChange}
              handleColumnMenu={handleColumnMenu}
              refreshClick={onRefreshClick}
              dataItemKey={DATA_ITEM_KEY}
              setSelectedRowId={setSelectedRowId}
              setExportDataClick={setExportDataClick}
              isExportDataClick={exportDataClick}
              setExportData={setExportDataClick}
              fileName={"Export_resultName.xlsx"}
              cellRender={customCellRender}
              rowRender={customRowRender}
              onSelectionChange={onSelectionChange}
              getExportData={getExportData}
              exportData={exportData}
              dataState={dataState}
              onClearFilter={onClearFilter}
              noDataFound={noDataFound}
              gridHeight={'450px'}
              reorderable={false}
            />
          ) : initialLoading && data.length === 0 ? (
            false
          ) : (
            <LoadingPanel />
          )}
          <DialogComponent
            title={confirmationPopup.title}
            onCancelConfirm={onCancelConfirm}
            width={"450"}
            height={"auto"}
            className={"Dialog-Delete"}
            desc={confirmationPopup.desc}
            visible={confirmationPopup.isVisible}
            actionBtn={confirmationPopup.actionBtn}
            titleClassName={
              confirmationPopup.action === "Delete"
                ? "delete-confirm"
                : "unSave-confirm"
            }
          />

        </div>

      </Window>

      {isShow.dtuLogs && (
        <DtuBatchLogs
          visible={isShow}
          setShow={setShow}
          module={"result_type_master"}
          pageProps={restProps.pageProps}
        />
      )}

      {isShow.dtuUpload && (
        <UploadDtu
          visible={isShow.dtuUpload}
          setShow={setShow}
          title={"Upload Result Type"}
          pageId={"result_type"}
          setAPICall={setAPICall}
        />
      )}
    </>
  );
};
