import moment from "moment";

export const transformDateTime = ({
    date,
    format = "YYYY-MM-DD",
    includeTime = false,
    defaultTime = false,
    returnDate = false,
}) => {
    if (!date) {
        return "";
    }

    const isDateObj = date.getMonth;

    if (isDateObj) {
        date = new Date(date);
    }

    const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const locale = "en-US";
    const options = {
        timeZone: clientTimezone,
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    };

    const dateToLocal = date.toLocaleString(locale, options);

    let formattedDate;

    if (!format) {
        format = "YYYY-MM-DD";
    }

    if (returnDate) {
        includeTime = true;
    }

    if (!isDateObj) {
        if (includeTime) {
            format += " HH:MM:SS";
        }

        formattedDate = moment.utc(dateToLocal).format(format);
    } else {
        //parse locale string to [MM/DD/YYYY, HH:MM:SS]
        const timezoneDate = dateToLocal.split(" ");

        //access monthdayyear @ date[0] & time @ date[1]
        let mdy = timezoneDate[0];
        let time = timezoneDate[1];

        //parse the mdy into parts
        mdy = mdy.split("/");

        const splitDate = {};

        splitDate.month = mdy[0];
        splitDate.day = mdy[1];
        splitDate.year = parseInt(mdy[2]);

        //parse date into formatted date
        formattedDate = format.toLowerCase();

        formattedDate = formattedDate.replace("mm", splitDate.month);
        formattedDate = formattedDate.replace("dd", splitDate.day);
        formattedDate = formattedDate.replace("yyyy", splitDate.year);
        formattedDate = formattedDate.replace("hh:mm:ss", "");

        if (includeTime) {
            formattedDate += " " + time;
        }
    }

    if (returnDate) {
        formattedDate = new Date(formattedDate.trim());
    }

    return formattedDate;
};
