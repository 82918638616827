import * as React from "react";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import { Form, FormElement, FieldWrapper } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { columnMenuProps } from "../../components/customColumnMenu";
import { Input, Switch } from "@progress/kendo-react-inputs";
import { DialogComponent } from "../../components/dialog";
import { DEFAULT_SORT } from "../../constants/grid";
import DataTable from "../../components/dataTable";
import DtuData from "./service";
import { Label, Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { TextArea } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";
import { getSelectedState } from "@progress/kendo-react-grid";

import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import MessageSchema from "../../utils/messageSchema";
import Popup from "../../components/customPopup";
import { getfilterDataString } from "../../utils/utils";
import { RowRender } from "src/components/renderers";
import useColumnReorder from "src/custom-hooks/useColumnReorder";

const DATA_ITEM_KEY = "etl_file_mstr_key";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);
const defaultPageSize = getTenantCookieData("record_per_page");

const anchorAlign = {
    horizontal: "right",
    vertical: "top",
};
const popupAlign = {
    horizontal: "left",
    vertical: "top",
    className: "testssss",
};

const initialFormFields = {
    etl_file_name: "",
    etl_file_desc: "",
    etl_table_label_name: "",
    in_event_type_mstr_label: "",
    etl_pdi_label: "",
    in_etl_file_mstr_key: "",
    etl_table_mstr_key_data: "",
    in_event_type_mstr_key_data: "",
};
const attrInitialFormFields = {
    action_on_existing: "",
    data_load_option: "",
    max_error_count: "",
    is_delta_file: "",
    is_auto_dtu: "",
    is_file_encrypt: "",
    is_file_compress: "",
    is_file_checksum: "",
    auto_run_time: "",
    auto_run_window_in_hrs: "",
};

export const DtuDataListGroup = (restProps) => {
    const anchor = React.useRef(null);
    const cookiesData = getUserCredential();
    const [list, Action] = DtuData();
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState({});
    const [formFields, setFormFields] = React.useState({
        ...initialFormFields,
    });
    const [eventLoading, setEventLoading] = React.useState(false);
    const [actionExistingData, setactionExistingData] = React.useState([]);
    const [actionDataLoad, setactionDataLoad] = React.useState([]);
    const [maxErrorCount, setmaxErrorCount] = React.useState([]);
    const [deltaFile, setdeltaFile] = React.useState([]);
    const [etlService, setetlService] = React.useState([]);
    const [fileEnc, setfileEnc] = React.useState([]);
    const [autoRunData, setautoRunData] = React.useState([]);
    const [attrFormFields, setAttrFormFields] = React.useState({
        ...attrInitialFormFields,
    });
    const [checksumDisabled, setchecksumDisabled] = React.useState(true);
    const [serviceDisabled, setserviceDisabled] = React.useState(true);
    const [compressionDisabled, setcompressionDisabled] = React.useState(true);
    const [autoruntimeDisabled, setautoruntimeDisabled] = React.useState(true);
    const [autoruntimeoutDisabled, setautoruntimeoutDisabled] =
        React.useState(true);
    // const [isDisable, setIsDisable] = React.useState(true);
    // const [fileKey, setfileKey] = React.useState(null);
    const [errorObj, setErrorObj] = React.useState({});
    const [popupAppendTo, setPopupAppendTo] = React.useState(null);
    const [customeventLoading, setcustomEventLoading] = React.useState(false);
    const [gridColumns, setGridColumns] = React.useState([]);

    const {
        data,
        columns,
        dataCount,
        setData,
        // setColumns,
        isColumnMenuSearch,
        editData,
        // originalData,
        isAPICalling,
        setConfirmationPopup,
        confirmationPopup,
        setEditData,
        setAPICallingState,
        initialLoading,
        isRefreshLoading,
        dtuTableData,
        eventTypeData,
        seteventTypeData,
        updatEtlAttrData,
    } = list;
    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });
    const [pageSize, setPageSize] = React.useState(defaultPageSize);
    const [exportData, setExportData] = React.useState([]);
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });

    const [isSaveClick, setSaveClick] = React.useState(false);

    const apiCallData = {
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
        in_load_type: "data-reload",
    };

    React.useEffect(() => {
        setPopupAppendTo(restProps.setdivRef.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        restProps.setDataList(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (restProps.isAPICall) {
            restProps.setAPICall(false);
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAPICall]);

    React.useEffect(() => {
        if (restProps.isAddNewConfig) {
            restProps.setNewConfig(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAddNewConfig]);

    React.useEffect(() => {
        if (restProps.isShow.deleteRows) {
            remove();
            restProps.setShow({ ...restProps.isShow, deleteRows: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isShow.deleteRows]);

    React.useEffect(() => {
        if (list.loadingState.isDeleteData) {
            list.setLoadState({ ...list.loadingState, isDeleteData: false });
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isDeleteData]);

    React.useEffect(() => {
        if (list.loadingState.isupdateData) {
            list.setLoadState({ ...list.loadingState, isupdateData: false });
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isupdateData]);

    React.useEffect(() => {
        if (columns.length > 0) {
            restProps.setDataList(data);
            restProps.setColumnsData(columns);
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        const dataVal = {
            ...apiCallData,
            in_load_type: "grid-reload",
        };
        Action.getListDataAsync(dataVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (restProps.isCheckColumn) {
            setGridColumns(restProps.columnsData);
            restProps.setColumnCheck(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isCheckColumn]);

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };

    React.useEffect(() => {
        if (restProps.isDeleteSelectedRow) {
            restProps.setSelectedRowId("");
            setConfirmationPopup({
                ...confirmationPopup,
                isVisible: true,
                type: "DELETE_CONFIRM",
                actionBtn: [
                    {
                        name: "Yes",
                        onClick: () => {
                            onDeleteConfirm();
                        },
                    },
                    {
                        name: "Cancel",
                        onClick: onCancelConfirm,
                    },
                ],
                data: restProps.selectedRowId,
                action: "Delete",
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isDeleteSelectedRow]);

    React.useEffect(() => {
        if (columns.length > 0 && restProps.columnsData.length === 0) {
            restProps.setColumnsData(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    const remove = (ids) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            type: "DELETE_CONFIRM",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
            data: [],
            action: "Delete",
        });
    };

    const onDeleteConfirm = () => {
        var deleteIdArray = [];
        var etlObjArray = [];
        deleteSelectedIds.map((item) => {
            let obj = {};
            obj.in_etl_file_mstr_key = item.id;
            obj.in_etl_attrib_file_config_key = null;
            deleteIdArray.push(obj);
            etlObjArray.push(obj);
            return 0;
        });

        let data = {
            in_user_id: cookiesData.out_user_id.toString(),
            deleteETLFileArray: deleteIdArray,
            in_tenant_id: cookiesData.out_tenant_id,
        };
        Action.deleteDataAsync(data, etlObjArray);
        setDeleteSelectedIds([]);
        setErrorObj({});
        restProps.setSelectedRowId("");
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
    };

    const onCancelConfirm = () => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });
        restProps.setShow({ ...restProps.visible, deleteSelectedRow: false });
        data.map((item) => {
            item.selected = false;
            return 0;
        });
        setEditData(editData);
        setData([...data]);
        restProps.setSelectedRowId("");
        setSelectedState({});
    };

    // const discard = () => {
    //   const newData = JSON.parse(JSON.stringify(data));
    //   newData.splice(0, 1);
    //   setData(newData);
    //   setEditData("");
    // };

    const enterEdit = async (dataItem) => {
        // close add popup while edit
        restProps.setNewConfig(false);
        let editDataArray = [];
        let attrEditDataArray = [];

        let response = await Action.GetEventConfigAPI(
            dataItem.etl_file_mstr_key
        );
        if (response) {
            let attrResponse = await Action.GetEventAttributeAPI(response);
            if (attrResponse) {
                attrResponse.map((item) => {
                    let dataObjAction = {};
                    dataObjAction.value = item.value;
                    dataObjAction.attributeCode = item.attribute_code;
                    dataObjAction.dataSource = item.data_source;

                    editDataArray.push(dataObjAction);
                    return 0;
                });
            }

            editDataArray.map((item, index) => {
                let attrdataObjAction = {};
                attrdataObjAction.value = item.value;
                attrdataObjAction.attributeCode = item.attributeCode;
                attrdataObjAction.dataSource = JSON.parse(item.dataSource);
                attrEditDataArray.push(attrdataObjAction);
                return 0;
            });

            let updatedAttrFormFields = { ...attrFormFields };
            attrEditDataArray.forEach((attrData) => {
                const { attributeCode, dataSource, value } = attrData;
                if (attributeCode !== "auto_run_time") {
                    if (dataSource && Array.isArray(dataSource)) {
                        updatedAttrFormFields[attributeCode] = dataSource.find(
                            (c) => c.key === value
                        );
                    }
                    if (attrData.attributeCode === "action_on_existing") {
                        setactionExistingData(attrData.dataSource);
                    }
                    if (attrData.attributeCode === "data_load_option") {
                        setactionDataLoad(attrData.dataSource);
                    }
                    if (attrData.attributeCode === "max_error_count") {
                        setmaxErrorCount(attrData.dataSource);
                    }
                    if (attrData.attributeCode === "is_auto_dtu") {
                        setetlService(attrData.dataSource);
                        if (value === "1" || value === "2") {
                            setchecksumDisabled(false);
                            setserviceDisabled(false);
                            setcompressionDisabled(false);
                            setautoruntimeDisabled(false);
                            setautoruntimeoutDisabled(false);
                        }
                        if (value === "0") {
                            setchecksumDisabled(true);
                            setserviceDisabled(true);
                            setcompressionDisabled(true);
                            setautoruntimeDisabled(true);
                            setautoruntimeoutDisabled(true);
                        }
                    }

                    if (attrData.attributeCode === "is_delta_file") {
                        updatedAttrFormFields.is_delta_file = dataSource.find(
                            (c) => c.key === parseInt(value)
                        );

                        setdeltaFile(attrData.dataSource);
                    }
                    if (attrData.attributeCode === "is_file_checksum") {
                        updatedAttrFormFields.is_file_checksum =
                            value === "0" ? false : true;
                    }
                    if (attrData.attributeCode === "is_file_compress") {
                        updatedAttrFormFields.is_file_compress =
                            value === "0" ? false : true;
                    }
                    if (attrData.attributeCode === "is_file_encrypt") {
                        setfileEnc(attrData.dataSource);
                    }
                    if (attrData.attributeCode === "auto_run_window_in_hrs") {
                        setautoRunData(attrData.dataSource);
                    }
                } else {
                    updatedAttrFormFields.auto_run_time = value;
                }
            });

            setAttrFormFields(updatedAttrFormFields);
            // setfileKey(dataItem.etl_file_mstr_key);
            Action.getDtuLogs();
            restProps.setUpdatenewConfig(!restProps.isUpdatenewConfig);
            setAPICallingState(false);
            if (
                dataItem.etl_table_label === "Event Patient Sat" ||
                dataItem.etl_table_label === "Event Quality" ||
                dataItem.etl_table_label === "Event Encounter" ||
                dataItem.etl_table_label === "Event"
            ) {
                let eventresponseData = await Action.GetEventTypeAPI(
                    dataItem.etl_table_mstr_key
                );
                setFormFields({
                    ...formFields,
                    etl_file_name: dataItem.etl_file_name,
                    etl_file_desc: dataItem.etl_file_desc,
                    etl_pdi_label: dataItem.etl_pdi_label,
                    in_etl_file_mstr_key: dataItem.etl_file_mstr_key,
                    etl_table_mstr_key_data: dataItem.etl_table_mstr_key,
                    in_event_type_mstr_key_data:
                        dataItem.etl_event_type_mstr_key,
                    etl_table_label_name: {
                        etl_table_label_name: dataItem.etl_table_label,
                        etl_table_mstr_key: dataItem.etl_table_mstr_key,
                    },
                    in_event_type_mstr_label: {
                        in_event_type_mstr_label: dataItem.etl_event_type_name,
                        in_event_type_mstr_key:
                            dataItem.etl_event_type_mstr_key,
                    },
                });
                setEventLoading(true);
                setcustomEventLoading(false);
            } else if (
                dataItem.etl_table_label === "Custom" ||
                dataItem.etl_table_label === "Custom Table"
            ) {
                setcustomEventLoading(true);
                setEventLoading(false);
                setFormFields({
                    ...formFields,
                    etl_file_name: dataItem.etl_file_name,
                    etl_file_desc: dataItem.etl_file_desc,
                    etl_pdi_label: dataItem.etl_pdi_label,
                    in_etl_file_mstr_key: dataItem.etl_file_mstr_key,
                    etl_table_mstr_key_data: dataItem.etl_table_mstr_key,
                    in_event_type_mstr_key_data: "",
                    etl_table_label_name: {
                        etl_table_label_name: dataItem.etl_table_label,
                        etl_table_mstr_key: dataItem.etl_table_mstr_key,
                    },
                    in_event_type_mstr_label: {
                        in_event_type_mstr_label: "",
                        in_event_type_mstr_key: "",
                    },
                });
            } else {
                setEventLoading(false);
                setcustomEventLoading(false);
                setFormFields({
                    ...formFields,
                    etl_file_name: dataItem.etl_file_name,
                    etl_file_desc: dataItem.etl_file_desc,
                    etl_pdi_label: dataItem.etl_pdi_label,
                    in_etl_file_mstr_key: dataItem.etl_file_mstr_key,
                    etl_table_mstr_key_data: dataItem.etl_table_mstr_key,
                    in_event_type_mstr_key_data: "",
                    etl_table_label_name: {
                        etl_table_label_name: dataItem.etl_table_label,
                        etl_table_mstr_key: dataItem.etl_table_mstr_key,
                    },
                    in_event_type_mstr_label: {
                        in_event_type_mstr_label: "",
                        in_event_type_mstr_key: "",
                    },
                });
            }
        }
    };
    const itemChange = React.useCallback(
        (event) => {
            const field = event.field || "";
            const newData = data.map((item) =>
                item.etl_file_mstr_key === event.dataItem.etl_file_mstr_key
                    ? { ...item, [field]: event.value }
                    : item
            );
            let editItem = event.dataItem;
            editItem[field] = event.value;
            delete editItem.inEdit;
            setEditData(editItem);
            setData(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    // const onColumnsSubmit = (columnsState) => {
    //   setColumns(columnsState);
    // };

    const setPageMenuState = (response) => {
        if (!deleteSelectedIds.length) {
            return false;
        }

        let ids = response.map((el) => el.etl_file_mstr_key);

        let selectedKeys = [];
        let tempArray = [];
        let tempArrayData = [];

        Object.keys(selectedState).map((item) => {
            let obj = {};

            obj.id = Number(item);
            selectedKeys.push(item);
            tempArray.push(obj);

            return 0;
        });

        ids.map((item) => {
            if (selectedKeys.includes(item.toString())) {
                tempArrayData.push(item);
            }
            return 0;
        });

        let strSelectedKeys = "";

        if (tempArrayData.length > 0) {
            strSelectedKeys = parseInt(selectedKeys[0]);
        }

        restProps.setSelectedRowId(strSelectedKeys);
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;

        setAPICallingState(true);

        setPage({ skip: pageData.skip, take: pageData.take });

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        };

        getGridData(dataVal);
    };

    const handleColumnMenu = async (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            let sortNameField = "";
            //replace mstr key with name field only when sorting is applied
            if (
                columnMenuData[0].field &&
                columnMenuData[0].field.indexOf("_mstr_key")
            ) {
                let sortKeyField = columnMenuData[0].field;
                if (sortKeyField === "etl_table_mstr_key") {
                    sortNameField = sortKeyField.replace("_mstr_key", "_label");
                } else {
                    sortNameField = sortKeyField.replace("_mstr_key", "_name");
                }
            }
            const sortOriginField =
                sortNameField !== "" ? sortNameField : columnMenuData[0].field;

            index = obj.findIndex((x) => x === sortOriginField) + 1;
        }

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        const dataVal = {
            ...apiCallData,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);

        getGridData(dataVal);
    };

    // const onUnsavedChanges = () => {
    //   if (data.filter((x) => x.etl_file_mstr_key === undefined).length > 0) {
    //     discard();
    //   } else {
    //     let newData = originalData.map((item) =>
    //       item.etl_file_mstr_key === editData.etl_file_mstr_key
    //         ? { ...item, inEdit: false }
    //         : { ...item, inEdit: false }
    //     );
    //     if (editData.etl_file_mstr_key === undefined) {
    //       discard();
    //     } else {
    //       setData(newData);
    //     }
    //   }
    //   setEditData("");
    //   setConfirmationPopup({
    //     ...confirmationPopup,
    //     isVisible: false,
    //     type: "",
    //     actionBtn: [],
    //     data: {},
    //     action: "",
    //   });
    // };

    // const columnProps = (field) => {
    //   let val = {};
    //   if (field !== "Delete") {
    //     val = {
    //       field: field,
    //       columnMenu: false,
    //       onColumnsSubmit: onColumnsSubmit,
    //       columns: columns,
    //       // isShowFilter: false,
    //     };
    //   }
    //   return val;
    // };

    const StringReadAndOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;

        let val = dataItem[field || ""];

        const defaultRendering = (
            <td
                aria-colindex={ariaColumnIndex}
                data-grid-col-index={columnIndex}
            >
                <span title={val}>{val}</span>
            </td>
        );

        return defaultRendering;
    }, []);

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={
                        column.field === "etl_event_type_mstr_key" ||
                        column.field === "etl_table_mstr_key"
                            ? StringReandOnlyCell
                            : StringReadAndOnlyCell
                    }
                    sortable={true}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    const StringReandOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field } = props;

        let val = dataItem[field || ""];

        if (field.indexOf("_mstr_key") !== -1) {
            val =
                field === "etl_table_mstr_key"
                    ? dataItem[field.replace("_mstr_key", "_label") || ""]
                    : dataItem[field.replace("_mstr_key", "_name") || ""];
        }
        const defaultRendering = (
            <td
                aria-colindex={ariaColumnIndex}
                data-grid-col-index={columnIndex}
            >
                <span title={val}>{val}</span>
            </td>
        );

        return defaultRendering;
    }, []);

    let customColumn = useCol(gridColumns);

    const getExportData = async () => {
        const dataVal = {
            ...apiCallData,
            in_page_row_offset: 0,
            in_page_rows: dataCount,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
        };

        let res = await Action.exportDataAsync(dataVal);

        if (res) {
            setExportData(res);
        }
    };

    const onClearFilter = async () => {
        setAPICallingState(true);

        //set in_params
        const dataVal = {
            ...apiCallData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getGridData(dataVal);
    };

    const handleFieldChange = (e) => {
        const name = e.name ? e.name : e.target.name;

        // let dataIndex = data.findIndex((x) => x.etl_file_mstr_key === fileKey);

        // if (data[dataIndex][name] == e.value) {
        //   setIsDisable(true);
        // } else {
        //   setIsDisable(false);
        // }

        if (name === "etl_table_label_name") {
            setFormFields({
                ...formFields,
                etl_table_label_name: {
                    etl_table_label_name: e.value.etl_table_label_name,
                    etl_table_mstr_key: e.value.etl_table_mstr_key,
                },
                etl_table_mstr_key_data: e.value.etl_table_mstr_key,
            });
            handleOpenDropdown(
                e.value.etl_table_label_name,
                e.value.etl_table_mstr_key
            );
        } else if (name === "in_event_type_mstr_label") {
            setFormFields({
                ...formFields,
                in_event_type_mstr_label: {
                    in_event_type_mstr_label: e.value.in_event_type_mstr_label,
                    in_event_type_mstr_key: e.value.in_event_type_mstr_key,
                },
                in_event_type_mstr_key_data: e.value.in_event_type_mstr_key,
            });
        } else {
            setFormFields({ ...formFields, [name]: e.value });
        }
        setErrorObj({ ...errorObj, [name]: "" });
    };

    const handleFieldChangeAction = (e, attributeCode) => {
        let updatedAttrFormFields = { ...attrFormFields };

        if (attributeCode === "is_auto_dtu") {
            if (
                e.value.value === "Auto Inbound" ||
                e.value.value === "Auto Outbound"
            ) {
                setchecksumDisabled(false);
                setserviceDisabled(false);
                setcompressionDisabled(false);
                setautoruntimeDisabled(false);
                setautoruntimeoutDisabled(false);
            } else {
                setchecksumDisabled(true);
                setserviceDisabled(true);
                setcompressionDisabled(true);
                setautoruntimeDisabled(true);
                setautoruntimeoutDisabled(true);
                updatedAttrFormFields.is_file_checksum = false;
                updatedAttrFormFields.is_file_compress = false;
                if (e.value.value === "Manual Inbound") {
                    updatedAttrFormFields.auto_run_time = "00:00";
                    updatedAttrFormFields.auto_run_window_in_hrs =
                        autoRunData[autoRunData.length - 1];
                    updatedAttrFormFields.is_file_encrypt = fileEnc[0];
                }
            }
        }
        updatedAttrFormFields[attributeCode] = e.value;
        setAttrFormFields(updatedAttrFormFields);
    };

    const handleOpenDropdown = async (module, etl_key) => {
        if (
            module === "Event Patient Sat" ||
            module === "Event Quality" ||
            module === "Event Encounter" ||
            module === "Event"
        ) {
            seteventTypeData([]);
            setEventLoading(true);
            setcustomEventLoading(false);

            let eventresponseData = await Action.GetEventTypeAPI(etl_key);
            if (eventresponseData.length > 0) {
                setFormFields({
                    ...formFields,
                    etl_pdi_label: "",
                    etl_table_mstr_key_data: etl_key,
                    in_event_type_mstr_key_data:
                        eventresponseData[0].event_type_mstr_key,
                    in_event_type_mstr_label: {
                        in_event_type_mstr_label:
                            eventresponseData[0].event_type_name,
                        in_event_type_mstr_key:
                            eventresponseData[0].event_type_mstr_key,
                    },
                    etl_table_label_name: {
                        etl_table_label_name: module,
                        etl_table_mstr_key: etl_key,
                    },
                });
            } else {
                setFormFields({
                    ...formFields,
                    etl_pdi_label: "",
                    etl_table_mstr_key_data: etl_key,
                    in_event_type_mstr_key_data: "",
                    in_event_type_mstr_label: {
                        in_event_type_mstr_label: "",
                        in_event_type_mstr_key: "",
                    },
                    etl_event_type_mstr_key: "",
                    etl_table_label_name: {
                        etl_table_label_name: module,
                        etl_table_mstr_key: etl_key,
                    },
                });
            }
        } else if (module === "Custom" || module === "Custom Table") {
            setcustomEventLoading(true);
            setEventLoading(false);
            setFormFields({
                ...formFields,
                etl_pdi_label: "",
                etl_table_mstr_key_data: etl_key,
                in_event_type_mstr_key_data: "",
                in_event_type_mstr_label: {
                    in_event_type_mstr_label: "",
                    in_event_type_mstr_key: "",
                },
                etl_event_type_mstr_key: "",
                etl_table_label_name: {
                    etl_table_label_name: module,
                    etl_table_mstr_key: etl_key,
                },
            });
        } else {
            setFormFields({
                ...formFields,
                etl_pdi_label: "",
                etl_table_mstr_key_data: etl_key,
                in_event_type_mstr_key_data: "",
                in_event_type_mstr_label: {
                    in_event_type_mstr_label: "",
                    in_event_type_mstr_key: "",
                },
                etl_event_type_mstr_key: "",
                etl_table_label_name: {
                    etl_table_label_name: module,
                    etl_table_mstr_key: etl_key,
                },
            });
            setEventLoading(false);
            setcustomEventLoading(false);
        }
    };
    const handleValidation = () => {
        let isValid = true;
        const pattern = /^[^. ]+(\.(txt|csv))?$/;
        const etlpattern = /^[a-zA-Z0-9_]+$/;
        const updatedErrorObj = { ...errorObj }; // Create a copy of the errorObj

        if (formFields.etl_file_name.trim() === "") {
            isValid = false;
            updatedErrorObj.etl_file_name = MessageSchema.etlFileNameRequired;
        }
        if (
            !pattern.test(formFields.etl_file_name) &&
            formFields.etl_file_name.trim() !== ""
        ) {
            isValid = false;
            updatedErrorObj.etl_file_name = MessageSchema.etlFileNameValidation;
        }
        if (
            eventLoading &&
            formFields.in_event_type_mstr_label.in_event_type_mstr_key === ""
        ) {
            isValid = false;
            updatedErrorObj.etl_event_type_mstr_key =
                MessageSchema.etlEventTypeNameRequired;
        }

        if (
            (eventLoading || customeventLoading) &&
            (formFields.etl_pdi_label === null ||
                formFields.etl_pdi_label.trim() === "")
        ) {
            isValid = false;
            updatedErrorObj.etl_pdi_label =
                MessageSchema.etlFilPdiLableRequired;
        }
        if (
            (eventLoading || customeventLoading) &&
            !etlpattern.test(formFields.etl_pdi_label) &&
            formFields.etl_pdi_label.trim() !== ""
        ) {
            isValid = false;
            updatedErrorObj.etl_pdi_label =
                MessageSchema.etlFilPdiLableRequiredValid;
        }

        setErrorObj(updatedErrorObj); // Update the state with the new error object
        return isValid;
    };

    const ShowError = React.useCallback(
        (props) => {
            return <Error>{errorObj[props.name]}</Error>;
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj, isSaveClick]
    );

    const onRowDoubleClick = (props) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false;
        }
        enterEdit(props.originalProps.dataItem);
        setAPICallingState(true);
    };

    const getGridData = async (dataVal) => {
        let response = await Action.getListDataAsync(dataVal);

        restProps.setDataList(response);
        setPageMenuState(response);
    };
    const onRefreshClick = () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        getGridData(dataVal);
    };

    const handleSubmit = () => {
        let valid = handleValidation();
        if (valid) {
            setErrorObj("");
            let finalArray = [];
            updatEtlAttrData.map((item) => {
                let obj = {};
                obj.in_etl_file_mstr_key = item.etl_file_mstr_key;
                obj.in_etl_attrib_file_config_key =
                    item.etl_attrib_file_config_key;
                obj.in_etl_attrib_mstr_key = item.etl_attrib_mstr_key;
                obj.in_data_type_mstr_key = item.data_type_mstr_key;

                obj.in_value =
                    item.attribute_code === "is_file_compress" ||
                    item.attribute_code === "is_file_checksum" ||
                    item.attribute_code === "auto_run_time"
                        ? attrFormFields[item.attribute_code]
                        : attrFormFields[item.attribute_code].key;
                finalArray.push(obj);
                return 0;
            });
            const attrData = {
                in_user_id: cookiesData.out_user_id.toString(),
                in_tenant_id: cookiesData.out_tenant_id,
                ETLFileArray: finalArray,
            };
            const bodyData = {
                in_user_id: cookiesData.out_user_id.toString(),
                in_tenant_id: cookiesData.out_tenant_id,
                in_etl_file_name: formFields.etl_file_name,
                in_etl_file_desc: formFields.etl_file_desc,
                in_etl_table_mstr_key: formFields.etl_table_mstr_key_data,
                in_event_type_mstr_key: formFields.in_event_type_mstr_key_data,
                in_etl_pdi_label:
                    formFields.etl_pdi_label === ""
                        ? null
                        : formFields.etl_pdi_label,
                in_etl_file_mstr_key: formFields.in_etl_file_mstr_key,
            };
            Action.UpdateDataAsync(bodyData, attrData);
            setEventLoading(false);
            setcustomEventLoading(false);
            setAPICallingState(true);
            restProps.setUpdatenewConfig(false);
            restProps.setSelectedRowId("");
        }
    };

    const onCancelClick = () => {
        setFormFields(initialFormFields);
        setErrorObj({});
        restProps.setUpdatenewConfig(false);
        setSaveClick(false);
    };

    const onRowClick = (dataItem) => {
        let newData = data.map((item) => ({
            ...item,
            selected:
                item.etl_file_mstr_key === dataItem.etl_file_mstr_key
                    ? true
                    : false,
        }));
        setData(newData);
        restProps.setDataList(newData);
    };

    const loadingPanel = (
        <div className="k-loading-mask">
            <span className="k-loading-text">Loading</span>
            <div className="k-loading-image"></div>
            <div className="k-loading-color"></div>
        </div>
    );

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);
        let tempArray = [];

        Object.keys(newSelectedState).map((item) => {
            if (newSelectedState[item] === false) {
                return 0;
            }
            let obj = {};
            obj.id = Number(item);
            // obj.text = item.event_group_name;
            tempArray.push(obj);
            return 0;
        });
        restProps.setSelectedRowId(tempArray[0].id);

        setDeleteSelectedIds(tempArray);
        restProps.setDataList(data);
    };

    // const updatePopup = () => {
    //   return (
    //     <Popup
    //       anchor={anchor.current}
    //       show={restProps.isUpdatenewConfig}
    //       anchorAlign={anchorAlign}
    //       className={"edit-custom-popup"}
    //       popupAlign={popupAlign}
    //       appendTo={popupAppendTo}
    //     >
    //       <div className="dot-dropdown-open dot-dropdown-open-Form updatedtusettings">
    //         <div className="caption">
    //           <span>Update DTU Data File Settings</span>
    //         </div>

    //         <Form
    //           onSubmit={handleSubmit}
    //           render={() => (
    //             <FormElement
    //               className="emailForm textboxeswrapper"
    //               horizontal={true}
    //             >
    //               <FieldWrapper className="full-width">
    //                 <div className={"k-form-field-wrap-popup"}>
    //                   <Label>{"ETL File Name*:"}</Label>
    //                   <Input
    //                     type={"text"}
    //                     name="etl_file_name"
    //                     placeholder="ETL File Name"
    //                     value={formFields.etl_file_name}
    //                     onChange={(e) => {
    //                       handleFieldChange(e);
    //                     }}
    //                   />
    //                   <ShowError name={"etl_file_name"} />
    //                 </div>
    //                 <div className={"k-form-field-wrap-popup valueDropdown"}>
    //                   <Label>{"ETL Table Name*:"}</Label>
    //                   <DropDownList
    //                     onChange={(e) => {
    //                       handleFieldChange(e);
    //                     }}
    //                     value={formFields.etl_table_label_name}
    //                     data={dtuTableData}
    //                     textField="etl_table_label_name"
    //                     dataItemKey="etl_table_mstr_key"
    //                     name="etl_table_label_name"
    //                   />
    //                 </div>
    //               </FieldWrapper>
    //               <FieldWrapper className="full-width">
    //                 <div className={"k-form-field-wrap-popup"}>
    //                   <Label>{"ETL File Description:"}</Label>
    //                   <TextArea
    //                     rows={4}
    //                     value={formFields.etl_file_desc}
    //                     onChange={(e) => {
    //                       handleFieldChange(e);
    //                     }}
    //                     name="etl_file_desc"
    //                     placeholder="ETL File Description"
    //                   />
    //                 </div>
    //               </FieldWrapper>
    //               <FieldWrapper className="full-width">
    //                 {eventLoading ? (
    //                   <>
    //                     <div
    //                       className={"k-form-field-wrap-popup valueDropdown hide"}
    //                     >
    //                       <Label>{"Event Type*:"}</Label>

    //                       <DropDownList
    //                         onChange={(e) => {
    //                           console.log("onChange event", e); // Log the event
    //                           handleFieldChange(e); // Call the handler
    //                         }}
    //                         value={formFields.in_event_type_mstr_label}
    //                         data={eventTypeData}
    //                         textField="in_event_type_mstr_label"
    //                         dataItemKey="in_event_type_mstr_key"
    //                         name="in_event_type_mstr_label"
    //                       />

    //                       <ShowError name={"etl_event_type_mstr_key"} />
    //                     </div>
    //                   </>
    //                 ) : (
    //                   ""
    //                 )}
    //                 {eventLoading || customeventLoading ? (
    //                   <>
    //                     <div className={"k-form-field-wrap-popup"}>
    //                       <Label>{"ETL Pdi Label*:"}</Label>
    //                       <Input
    //                         value={formFields.etl_pdi_label}
    //                         onChange={(e) => {
    //                           handleFieldChange(e);
    //                         }}
    //                         name="etl_pdi_label"
    //                         placeholder="ETL Pdi Label"
    //                       />
    //                       <ShowError name={"etl_pdi_label"} />
    //                     </div>
    //                   </>
    //                 ) : (
    //                   ""
    //                 )}
    //               </FieldWrapper>
    //               <FieldWrapper className="full-width">
    //                 <div className={"k-form-field-wrap-popup valueDropdown hide"}>
    //                   <Label>{"Action On Existing"}</Label>
    //                   <DropDownList
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "action_on_existing");
    //                     }}
    //                     value={attrFormFields.action_on_existing}
    //                     data={actionExistingData}
    //                     textField="value"
    //                     dataItemKey="key"
    //                   />
    //                 </div>

    //                 <div className={"k-form-field-wrap-popup valueDropdown hide"}>
    //                   <Label>{"Data Load On Options"}</Label>

    //                   <DropDownList
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "data_load_option");
    //                     }}
    //                     value={attrFormFields.data_load_option}
    //                     data={actionDataLoad}
    //                     textField="value"
    //                     dataItemKey="key"
    //                   />
    //                 </div>
    //               </FieldWrapper>
    //               <FieldWrapper className="full-width">
    //                 <div className={"k-form-field-wrap-popup valueDropdown hide"}>
    //                   <Label>{"Max Error Count"}</Label>
    //                   <DropDownList
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "max_error_count");
    //                     }}
    //                     value={attrFormFields.max_error_count}
    //                     data={maxErrorCount}
    //                     textField="value"
    //                     dataItemKey="key"
    //                   />
    //                 </div>

    //                 <div className={"k-form-field-wrap-popup valueDropdown hide"}>
    //                   <Label>{"Data File"}</Label>
    //                   <DropDownList
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "is_delta_file");
    //                     }}
    //                     value={attrFormFields.is_delta_file}
    //                     data={deltaFile}
    //                     textField="value"
    //                     dataItemKey="key"
    //                   />
    //                 </div>
    //               </FieldWrapper>
    //               <FieldWrapper className="full-width">
    //                 <div className={"k-form-field-wrap-popup valueDropdown hide"}>
    //                   <Label>{"ETL Service Type:"}</Label>

    //                   <DropDownList
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "is_auto_dtu");
    //                     }}
    //                     value={attrFormFields.is_auto_dtu}
    //                     data={etlService}
    //                     textField="value"
    //                     dataItemKey="key"
    //                     // name="in_event_type_mstr_label"
    //                   />
    //                 </div>

    //                 <div className={"k-form-field-wrap-popup valueDropdown hide"}>
    //                   <Label>{"File Encryption:"}</Label>

    //                   <DropDownList
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "is_file_encrypt");
    //                     }}
    //                     value={attrFormFields.is_file_encrypt}
    //                     //   value={eventTypeData.find(
    //                     //     (c) => c.event_type_name === formFields.in_event_type_mstr_key
    //                     // )}
    //                     data={fileEnc}
    //                     textField="value"
    //                     dataItemKey="key"
    //                     disabled={serviceDisabled}
    //                   />
    //                 </div>
    //               </FieldWrapper>
    //               <FieldWrapper className="full-width">
    //                 <div className={"k-form-field-wrap-popup"}>
    //                   <Label>{"Compression"}</Label>
    //                   <Switch
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "is_file_compress");
    //                     }}
    //                     checked={attrFormFields.is_file_compress}
    //                     disabled={compressionDisabled}
    //                   />
    //                 </div>
    //                 <div className={"k-form-field-wrap-popup"}>
    //                   <Label>{"Checksum"}</Label>
    //                   <Switch
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "is_file_checksum");
    //                     }}
    //                     checked={attrFormFields.is_file_checksum}
    //                     disabled={checksumDisabled}
    //                   />
    //                 </div>

    //                 <div className={"k-form-field-wrap-popup"}>
    //                   <Label>{"Auto Run Time:"}</Label>
    //                   <Input
    //                     type={"text"}
    //                     placeholder="Auto Run Time"
    //                     value={attrFormFields.auto_run_time}
    //                     onChange={(e) => {
    //                       handleFieldChangeAction(e, "auto_run_time");
    //                     }}
    //                     disabled={autoruntimeDisabled}
    //                   />
    //                 </div>
    //               </FieldWrapper>
    //               <FieldWrapper>
    //                 <div className={"k-form-field-wrap-popup"}>
    //                   <div className={"k-form-field-wrap-popup valueDropdown"}>
    //                     <Label>{"Auto Run Time Window (in hours):"}</Label>

    //                     <DropDownList
    //                       onChange={(e) => {
    //                         handleFieldChangeAction(e, "auto_run_window_in_hrs");
    //                       }}
    //                       value={attrFormFields.auto_run_window_in_hrs}
    //                       data={autoRunData}
    //                       textField="value"
    //                       dataItemKey="key"
    //                       disabled={autoruntimeoutDisabled}
    //                     />
    //                   </div>
    //                 </div>
    //               </FieldWrapper>
    //             </FormElement>
    //           )}
    //         />

    //         <div className="dropdownFooter">
    //           <div className="k-form-buttons">
    //             <Button
    //               primary={true}
    //               type={"submit"}
    //               onClick={() => {
    //                 onCancelClick();
    //               }}
    //             >
    //               Cancel
    //             </Button>
    //             <Button
    //               primary={true}
    //               type={"submit"}
    //               // disabled={isDisable}
    //               onClick={() => {
    //                 handleSubmit(formFields);
    //               }}
    //             >
    //               Update
    //             </Button>
    //           </div>
    //         </div>
    //       </div>
    //     </Popup>
    //   );
    // };

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            onRowDoubleClick={onRowDoubleClick}
            title={
                restProps.pageProps.writeAccess
                    ? "Double click to edit record"
                    : ""
            }
        />
    );

    const { columnReorderData } = useColumnReorder(
        cookiesData,
        restProps.setColumnsData,
        "",
        "procare_system"
    );

    return (
        <>
            {isRefreshLoading && loadingPanel}
            {columns.length > 0 ? (
                <DataTable
                    isAPICalling={isAPICalling}
                    initialLoading={initialLoading}
                    // data={data}
                    data={data.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    id="dtu-grid"
                    handleColumnMenu={handleColumnMenu}
                    itemChange={itemChange}
                    pageData={pageData}
                    dataCount={dataCount}
                    customColumn={customColumn}
                    isColumnMenuSearch={isColumnMenuSearch}
                    onRowClick={onRowClick}
                    columns={gridColumns}
                    dataItemKey={DATA_ITEM_KEY}
                    width={"auto"}
                    module={"DTUDATA"}
                    defaultPageSize={defaultPageSize}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    handlePageChange={handlePageChange}
                    onClearFilter={onClearFilter}
                    refreshClick={onRefreshClick}
                    getExportData={getExportData}
                    setExportData={restProps.setExportDataClick}
                    isExportDataClick={restProps.exportDataClick}
                    fileName={"Export_dtuData.xlsx"}
                    exportData={exportData}
                    onSelectionChange={onSelectionChange}
                    rowRender={customRowRender}
                    reorderable={true}
                    columnReorderData={columnReorderData}
                />
            ) : (
                <>{loadingPanel}</>
            )}

            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={"450"}
                height={"auto"}
                className={"Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
            <Popup
                anchor={anchor.current}
                show={restProps.isUpdatenewConfig}
                anchorAlign={anchorAlign}
                className={"edit-custom-popup"}
                popupAlign={popupAlign}
                appendTo={popupAppendTo}
            >
                <div className="dot-dropdown-open dot-dropdown-open-Form updatedtusettings">
                    <div className="caption">
                        <span>Update DTU Data File Settings</span>
                    </div>

                    <Form
                        onSubmit={handleSubmit}
                        render={() => (
                            <FormElement
                                className="emailForm textboxeswrapper"
                                horizontal={true}
                            >
                                <FieldWrapper className="full-width">
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"ETL File Name*:"}</Label>
                                        <Input
                                            type={"text"}
                                            name="etl_file_name"
                                            placeholder="ETL File Name"
                                            value={formFields.etl_file_name}
                                            onChange={(e) => {
                                                handleFieldChange(e);
                                            }}
                                        />
                                        <ShowError name={"etl_file_name"} />
                                    </div>
                                    <div
                                        className={
                                            "k-form-field-wrap-popup valueDropdown"
                                        }
                                    >
                                        <Label>{"ETL Table Name*:"}</Label>
                                        <DropDownList
                                            onChange={(e) => {
                                                handleFieldChange(e);
                                            }}
                                            value={
                                                formFields.etl_table_label_name
                                            }
                                            data={dtuTableData}
                                            textField="etl_table_label_name"
                                            dataItemKey="etl_table_mstr_key"
                                            name="etl_table_label_name"
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper className="full-width">
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"ETL File Description:"}</Label>
                                        <TextArea
                                            rows={4}
                                            value={formFields.etl_file_desc}
                                            onChange={(e) => {
                                                handleFieldChange(e);
                                            }}
                                            name="etl_file_desc"
                                            placeholder="ETL File Description"
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper className="full-width">
                                    {eventLoading ? (
                                        <>
                                            <div
                                                className={
                                                    "k-form-field-wrap-popup valueDropdown hide"
                                                }
                                            >
                                                <Label>{"Event Type*:"}</Label>

                                                <DropDownList
                                                    onChange={(e) => {
                                                        handleFieldChange(e); // Call the handler
                                                    }}
                                                    value={
                                                        formFields.in_event_type_mstr_label
                                                    }
                                                    data={eventTypeData}
                                                    textField="in_event_type_mstr_label"
                                                    dataItemKey="in_event_type_mstr_key"
                                                    name="in_event_type_mstr_label"
                                                    // onOpen={(e) => {
                                                    //   handleOpenDropdown(
                                                    //     formFields.etl_table_label_name
                                                    //       .etl_table_label_name,
                                                    //     formFields.etl_table_label_name.etl_table_mstr_key
                                                    //   );
                                                    // }}
                                                />

                                                <ShowError
                                                    name={
                                                        "etl_event_type_mstr_key"
                                                    }
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {eventLoading || customeventLoading ? (
                                        <>
                                            <div
                                                className={
                                                    "k-form-field-wrap-popup"
                                                }
                                            >
                                                <Label>
                                                    {"ETL Pdi Label*:"}
                                                </Label>
                                                <Input
                                                    value={
                                                        formFields.etl_pdi_label
                                                    }
                                                    onChange={(e) => {
                                                        handleFieldChange(e);
                                                    }}
                                                    name="etl_pdi_label"
                                                    placeholder="ETL Pdi Label"
                                                />
                                                <ShowError
                                                    name={"etl_pdi_label"}
                                                />
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </FieldWrapper>
                                <FieldWrapper className="full-width">
                                    <div
                                        className={
                                            "k-form-field-wrap-popup valueDropdown hide"
                                        }
                                    >
                                        <Label>{"Action On Existing"}</Label>
                                        <DropDownList
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "action_on_existing"
                                                );
                                            }}
                                            value={
                                                attrFormFields.action_on_existing
                                            }
                                            data={actionExistingData}
                                            textField="value"
                                            dataItemKey="key"
                                        />
                                    </div>

                                    <div
                                        className={
                                            "k-form-field-wrap-popup valueDropdown hide"
                                        }
                                    >
                                        <Label>{"Data Load On Options"}</Label>

                                        <DropDownList
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "data_load_option"
                                                );
                                            }}
                                            value={
                                                attrFormFields.data_load_option
                                            }
                                            data={actionDataLoad}
                                            textField="value"
                                            dataItemKey="key"
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper className="full-width">
                                    <div
                                        className={
                                            "k-form-field-wrap-popup valueDropdown hide"
                                        }
                                    >
                                        <Label>{"Max Error Count"}</Label>
                                        <DropDownList
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "max_error_count"
                                                );
                                            }}
                                            value={
                                                attrFormFields.max_error_count
                                            }
                                            data={maxErrorCount}
                                            textField="value"
                                            dataItemKey="key"
                                        />
                                    </div>

                                    <div
                                        className={
                                            "k-form-field-wrap-popup valueDropdown hide"
                                        }
                                    >
                                        <Label>{"Delta File"}</Label>
                                        <DropDownList
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "is_delta_file"
                                                );
                                            }}
                                            value={attrFormFields.is_delta_file}
                                            data={deltaFile}
                                            textField="value"
                                            dataItemKey="key"
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper className="full-width">
                                    <div
                                        className={
                                            "k-form-field-wrap-popup valueDropdown hide"
                                        }
                                    >
                                        <Label>{"ETL Service Type:"}</Label>

                                        <DropDownList
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "is_auto_dtu"
                                                );
                                            }}
                                            value={attrFormFields.is_auto_dtu}
                                            data={etlService}
                                            textField="value"
                                            dataItemKey="key"
                                            // name="in_event_type_mstr_label"
                                        />
                                    </div>

                                    <div
                                        className={
                                            "k-form-field-wrap-popup valueDropdown hide"
                                        }
                                    >
                                        <Label>{"File Encryption:"}</Label>

                                        <DropDownList
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "is_file_encrypt"
                                                );
                                            }}
                                            value={
                                                attrFormFields.is_file_encrypt
                                            }
                                            //   value={eventTypeData.find(
                                            //     (c) => c.event_type_name === formFields.in_event_type_mstr_key
                                            // )}
                                            data={fileEnc}
                                            textField="value"
                                            dataItemKey="key"
                                            disabled={serviceDisabled}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper className="full-width">
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Compression"}</Label>
                                        <Switch
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "is_file_compress"
                                                );
                                            }}
                                            checked={
                                                attrFormFields.is_file_compress
                                            }
                                            disabled={compressionDisabled}
                                        />
                                    </div>
                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Checksum"}</Label>
                                        <Switch
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "is_file_checksum"
                                                );
                                            }}
                                            checked={
                                                attrFormFields.is_file_checksum
                                            }
                                            disabled={checksumDisabled}
                                        />
                                    </div>

                                    <div className={"k-form-field-wrap-popup"}>
                                        <Label>{"Auto Run Time:"}</Label>
                                        <Input
                                            type={"text"}
                                            placeholder="Auto Run Time"
                                            value={attrFormFields.auto_run_time}
                                            onChange={(e) => {
                                                handleFieldChangeAction(
                                                    e,
                                                    "auto_run_time"
                                                );
                                            }}
                                            disabled={autoruntimeDisabled}
                                        />
                                    </div>
                                </FieldWrapper>
                                <FieldWrapper>
                                    <div className={"k-form-field-wrap-popup"}>
                                        <div
                                            className={
                                                "k-form-field-wrap-popup valueDropdown"
                                            }
                                        >
                                            <Label>
                                                {
                                                    "Auto Run Time Window (in hours):"
                                                }
                                            </Label>

                                            <DropDownList
                                                onChange={(e) => {
                                                    handleFieldChangeAction(
                                                        e,
                                                        "auto_run_window_in_hrs"
                                                    );
                                                }}
                                                value={
                                                    attrFormFields.auto_run_window_in_hrs
                                                }
                                                data={autoRunData}
                                                textField="value"
                                                dataItemKey="key"
                                                disabled={
                                                    autoruntimeoutDisabled
                                                }
                                            />
                                        </div>
                                    </div>
                                </FieldWrapper>
                            </FormElement>
                        )}
                    />

                    <div className="dropdownFooter">
                        <div className="k-form-buttons">
                            <Button
                                primary={true}
                                type={"submit"}
                                onClick={() => {
                                    onCancelClick();
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                primary={true}
                                type={"submit"}
                                // disabled={isDisable}
                                onClick={() => {
                                    handleSubmit(formFields);
                                }}
                            >
                                Update
                            </Button>
                        </div>
                    </div>
                </div>
            </Popup>
        </>
    );
};
