import React, { useCallback } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { HeaderPage } from "../base/headerPage";
import userPositionApi from "./service";
import { getUserCredential } from "src/lib/cookies";
import {
    GetPositionType,
    GetSPECIALTYAPI,
    GetUnitType,
} from "src/lib/common-service";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { LoadingPanel } from "src/components/loadingPanel";
import {
    changeCancelBtnState,
    changeUpdateBtnState,
    formatDateOnlyMoment,
    // formatDateOnly,
    toCamelCase,
} from "src/utils/utils";
import { ListBox } from "@progress/kendo-react-listbox";
import ProviderFields from "./providerFields";
import moment from "moment";

const SELECTED_FIELD = "selected";

export const FormFieldsContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const ErrorContext = React.createContext([
    {},
    () => {
        /* noop */
    },
]);

export const DataListContext = React.createContext([
    [],
    () => {
        /* noop */
    },
]);

export const SpecialtyContext = React.createContext([
    [],
    () => {
        /* noop */
    },
]);

export const DragContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);
export const DropContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const AddETLFieldsContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const CancelClickContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

export const AddAccessContext = React.createContext([
    null,
    () => {
        /* noop */
    },
]);

const AddProviderPosition = (restProps) => {


    const [, Action] = userPositionApi();
    const [categoryList, setCategoryList] = React.useState([]);
    const [categoryFieldList, setCategoryFieldList] = React.useState([]);
    const [originalCategoryFieldList, setOriginalCategoryFieldList] =
        React.useState([]);
    const cookiesData = getUserCredential();
    const dispatch = useDispatch();
    const history = useHistory();
    const [state, setState] = React.useState({
        draggedItem: {},
    });
    const [dataList, setDataList] = React.useState({
        unitTypeDataList: [],
        positionTypeList: [],
        specialtyList: [],
    });
    const [loadingState, setLoadingState] = React.useState({
        initialLoad: false,
    });
    const [formFields, setFormFields] = React.useState({});
    const [errorObj, setErrorObj] = React.useState({});
    const [drag, setDrag] = React.useState(null);
    const [drop, setDrop] = React.useState(null);
    const [originalCategoryList, setOriginalCategoryList] = React.useState([]);
    const [isCancelClick, setCancelClick] = React.useState(false);
    const dropTimeout = React.useRef();

    const handleDropChange = React.useCallback((newDrop) => {
        if (!newDrop) {
            dropTimeout.current = window.setTimeout(() => {
                setDrop(newDrop);
            }, 50);
        } else {
            clearTimeout(dropTimeout.current);
            setDrop(newDrop);
        }
    }, []);

    React.useEffect(() => {
        setLoadingState((prev) => ({ ...prev, initialLoad: true }));
        getAPIList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAPIList = useCallback(() => {
        getCategoryList();
        getUnitTypeData();
        getPositionTypeList();
        getSpecialtyList();
    }, []);

    const getUnitTypeData = useCallback(async () => {
        let unitTypeDataReq = {
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: " AND (a.system_unit_name NOT LIKE 'fdm_%')",
            in_sort_number: null,
            in_sort_order: null,
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };

        let resUnitType = await GetUnitType(unitTypeDataReq, dispatch, history);
        if (resUnitType.length > 0) {
            let tempArray = [];

            resUnitType.map((item) => {
                let obj = {};

                obj.text = item.system_unit_name;
                obj.id = item.system_unit_mstr_key;

                tempArray.push(obj);

                return 0;
            });

            setDataList((preVState) => ({
                ...preVState,
                unitTypeDataList: tempArray,
            }));

            setFormFields((preVState) => ({
                ...preVState,
                unitTypeDataList: tempArray,
            }));
        }
    }, []);

    const getPositionTypeList = useCallback(async () => {
        let resPositionType = await GetPositionType({}, dispatch, history);
        if (resPositionType.length > 0) {
            let tempArray = [];
            resPositionType.map((item) => {
                let obj = {};

                obj.text = item.position_type_name;
                obj.id = item.org_position_type_key;

                tempArray.push(obj);

                return 0;
            });

            setDataList((preVState) => ({
                ...preVState,
                positionTypeList: tempArray,
            }));

            setFormFields((preVState) => ({
                ...preVState,
                positionTypeList: tempArray,
                vw_org_position_position_type_name: tempArray[0],
            }));
        }
    }, []);

    const getSpecialtyList = useCallback(async () => {
        let dataObjValue = {
            in_user_key: cookiesData.out_user_id,
            in_tenant_id: cookiesData.out_tenant_id,
            in_filter: null,
            in_sort_number: 3,
            in_sort_order: "ASC",
            in_page_row_offset: 0,
            in_page_rows: 9999,
        };
        let resSpecialty = await GetSPECIALTYAPI(
            dataObjValue,
            history,
            dispatch
        );
        if (resSpecialty) {
            let tempArray = [];
            resSpecialty.map((item) => {
                let obj = {};

                obj.text = item.specialty_name;
                obj.id = item.specialty_mstr_key;

                tempArray.push(obj);

                return 0;
            });
            setDataList((preVState) => ({
                ...preVState,
                specialtyList: tempArray,
            }));
            setFormFields((preVState) => ({
                ...preVState,
                specialtyList: tempArray,
                vw_org_position_specialty_name: tempArray[0],
            }));
        }
    }, []);

    const getCategoryList = useCallback(async () => {
        let reqData = {
            in_filter: null,
            in_module_name: "provider_position",
            in_sort_number: 1,
            in_sort_order: "ASC",
        };
        let res = await Action.getCategory(reqData);
        if (res) {
            getCategoryFieldList(
                res.sort((a, b) => a.category_order - b.category_order)
            );
        }
    }, []);

    const getCategoryFieldList = useCallback(async (mainCategory) => {
        let reqData = {
            in_filter: null,
            in_module_name: "provider_position",
            in_sort_number: 1,
            in_sort_order: "ASC",
            in_tenant_id: cookiesData.out_tenant_id,
        };
        let res = await Action.getCategoryField(reqData);
        if (res) {
            setLoadingState({
                ...loadingState,
                initialLoad: false,
            });
            const updatedRes = res
                .filter((x) => x.filter_label !== "provider effective date")
                .map((x) => {
                    if (
                        x.filter_column_name &&
                        x.filter_column_name.includes(".")
                    ) {
                        let col_name = x.filter_column_name.split(".")[1];
                        return { ...x, filter_column_name: col_name };
                    }
                    return x;
                });

            setCategoryFieldList([...updatedRes]);
            setOriginalCategoryFieldList([...updatedRes]);
            let mergedArray = [];
            mainCategory
                .filter((x) => x.category_code !== "doc_attr")
                .map((item) => {
                    let filteredData = res.filter(
                        (x) =>
                            x.category_code === item.category_code &&
                            x.filter_label.toLowerCase() !==
                                "provider effective date"
                    );
                    item["fields"] = filteredData
                        .sort((a, b) => a.cat_fdm_order - b.cat_fdm_order)
                        .filter(
                            (x) => x.filter_label !== "provider effective date"
                        )
                        .map((x) => {
                            if (
                                x.filter_column_name &&
                                x.filter_column_name.includes(".")
                            ) {
                                let col_name =
                                    x.filter_column_name.split(".")[1];
                                return {
                                    ...x,
                                    filter_column_name: col_name,
                                    selected: false,
                                    fields: filteredData,
                                };
                            }
                            return { ...x, fields: filteredData };
                        });
                    mergedArray.push(item);
                    return 0;
                });
            setCategoryList(mergedArray);
            setOriginalCategoryList(mergedArray);
            changeUpdateBtnState();
            changeCancelBtnState();
        }
    }, []);

    if (document.getElementById("grid-incell-cancel")) {
        document.getElementById("grid-incell-cancel").onclick = function (e) {
            setFormFields({
                unitTypeDataList: formFields.unitTypeDataList,
                positionTypeList: formFields.positionTypeList,
                specialtyList: formFields.specialtyList,
                vw_org_position_position_type_name:
                    formFields.positionTypeList[0],
                vw_org_position_specialty_name: formFields.specialtyList[0],
                userData: {},
                groupData: {},
            });
            setOriginalCategoryFieldList([]);
            setErrorObj({});
            setCategoryList(originalCategoryList);
            setOriginalCategoryFieldList(originalCategoryFieldList);
            changeUpdateBtnState("disable");
            setCancelClick(true);
        };
    }

    if (document.getElementById("grid-back-btn")) {
        document.getElementById("grid-back-btn").onclick = function (e) {
            e.preventDefault();
            restProps.setMode("Default");
        };
    }

    if (document.getElementById("grid-incell-update")) {
        document.getElementById("grid-incell-update").onclick = function (e) {
            let isValid = handleValidation();
            if (isValid) {
                handleSubmit();
            }
        };
    }

    const handleValidation = () => {
        let isValid = false;
        let errObj = {};

        const updatedCategoryList = categoryList.map((category) => ({
            ...category,
            fields: category.fields.map((field) => {
                let jsonObject = field?.dropdown_control
                    ? JSON.parse(field.dropdown_control)
                    : {};
                const isRequired =
                    (field.is_nullable === 0 &&
                        field.filter_column_datatype !== "boolean") ||
                    jsonObject.required === "true";
                const fieldValue =
                    formFields[
                        field.filter_table_name + "_" + field.filter_column_name
                    ];
                const isEmpty =
                    fieldValue === null ||
                    fieldValue === "" ||
                    fieldValue === undefined ||
                    (typeof fieldValue === "string" &&
                        fieldValue.trim() === "");

                if (isRequired && isEmpty) {
                    isValid = false;
                    errObj[field.filter_label] = `${toCamelCase(
                        field.filter_label
                    )} is required`;
                }

                return { ...field };
            }),
        }));
        if (
            formFields.groupData &&
            Object.keys(formFields.groupData).length > 0
        ) {
            isValid = false;
            errObj["GROUP NAME"] = "";
        }

        isValid = Object.values(errObj).every((value) => value === "");

        setErrorObj({ ...errObj });
        setCategoryList(updatedCategoryList);
        return isValid;
    };

    const handleCategoryData = async (categoryName) => {
        const categoryData = categoryFieldList.filter(
            (x) => x.filter_table_name === categoryName
        );

        const reqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
        };

        categoryData
            .filter((x) => x.custom_fld_col_mstr_key === null)
            .forEach((x) => {
                const columnName = "in_" + x.filter_column_name;

                let fieldValue =
                    typeof formFields[
                        x.filter_table_name + "_" + x.filter_column_name
                    ] === "object" && x.dropdown_control !== "0"
                        ? formFields[
                              x.filter_table_name + "_" + x.filter_column_name
                          ].text
                        : formFields[
                              x.filter_table_name + "_" + x.filter_column_name
                          ] || "";

                if (x.filter_column_datatype === "date") {
                    fieldValue = fieldValue
                        ? formatDateOnlyMoment(fieldValue) + " 00:00:00"
                        : "";
                }

                if(x.filter_column_datatype === 'number'){
                    fieldValue = fieldValue === 0 ? '0.00' : fieldValue
                }

                reqData[columnName] = reqData[columnName] = typeof fieldValue === 'number' ? fieldValue : fieldValue || "";
            });
        return reqData;
    };

    const handleSubmit = async () => {
        setLoadingState({ ...loadingState, isSaveLoading: true });
        let providerData = await handleCategoryData("vw_provider_master");
        const customFieldDataKeys = await getCustomFields(
            "vw_provider_master",
            "provider_mstr_key"
        );
        let positionData = await handleCategoryData("vw_org_position");
        let providerReqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_acct_encrypt_key: cookiesData.out_acct_encrypt_key,
            in_employee_id: providerData.in_employee_id,
            in_alternate_id: providerData.in_alternate_id || null,
            in_provider_id: providerData.in_provider_id,
            in_date_version: 1,
            in_user_key: formFields?.userData?.user_key || "",
            in_first_name: providerData.in_firstname,
            in_last_name: providerData.in_lastname,
            in_middle_name: providerData.in_middlename,
            in_full_name: providerData.in_fullname,
            in_physician_flag: providerData.in_physician_flag || 0,
            in_department_name: providerData.in_department_name || null,
            in_department_id: providerData.in_department_id || null,
            in_credentials: providerData.in_credentials || null,
            in_npi: providerData.in_npi,
            in_salary: providerData.in_salary || null,
            in_salary_unittype:
                formFields.vw_provider_master_salary_unit?.id || null,
            in_target: providerData.in_total_target_incentive || null,
            in_target_unittype:
                formFields.vw_provider_master_target_unit?.id || null,
            in_hire_date: providerData.in_hire_date
                ? formatDateOnlyMoment(providerData.in_hire_date)
                : null,
            in_termination_date: providerData.in_termination_date
                ? formatDateOnlyMoment(providerData.in_termination_date)
                : null,
            in_eff_start_date: formatDateOnlyMoment(positionData.in_eff_start_date),
            in_primary_loc_id: providerData.in_primary_loc_id || null,
            in_primary_loc_name: providerData.in_primary_loc_name,
            in_secondary_loc_id: providerData.in_secondary_loc_id || null,
            in_secondary_loc_name: providerData.in_secondary_loc_name,
            in_team_name: providerData.in_team_name || null,
            in_certification: providerData.in_certification,
            in_medical_school_name: providerData.in_medical_school_name,
            in_usa_training_flag: providerData.in_usa_training_flag || 0,
            in_residency_name: providerData.in_residency_name,
            in_residency_completion_date:
                providerData.in_residency_completion_date
                    ? formatDateOnlyMoment(providerData.in_residency_completion_date)
                    : null,
            in_fellowship_name: providerData.in_fellowship_name,
            in_version_desc: providerData.in_version_desc,
            in_title_name: formFields?.groupData?.title_name,
        };
        providerData.in_custom_field_data_keys = customFieldDataKeys;
        if (providerData.in_alternate_id === undefined) {
            providerData.in_alternate_id = "";
        }
        if (providerData.in_target === undefined) {
            providerData.in_target = "";
        }

        providerReqData.in_custom_field_data_keys = customFieldDataKeys;
        let res = await Action.insertProvider(
            providerReqData,
            dispatch,
            history
        );
        if (res.out_last_insert_key && res.out_ret_code === 0) {
            await handlePositionSubmit(res.out_last_insert_key);
        } else {
            setLoadingState({ ...loadingState, isSaveLoading: false });
        }
    };

    const handlePositionSubmit = async (provider_key) => {
        let positionData = await handleCategoryData("vw_org_position");
        const customFieldDataKeys = await getCustomFields(
            "vw_org_position",
            "org_position_key"
        );
        let positionReqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_date_version: 1,
            in_position_name: positionData.in_position_name,
            in_position_desc: positionData.in_position_desc || "",
            in_org_title_key: positionData.in_org_title_key || "",
            in_specialty_mstr_key:
                formFields.vw_org_position_specialty_name?.id || "",
            in_specialty_name : formFields.vw_org_position_specialty_name?.text || "",
            in_org_position_type_key:
                positionData.in_org_position_type_key || "",
            in_eff_start_date: formatDateOnlyMoment(positionData.in_eff_start_date),
            in_contract_type: positionData.in_contract_type,
            in_contract_term_months: positionData.in_contract_term_months === '' ? null :  positionData.in_contract_term_months,
            in_utilization_unittype: positionData.in_utilization_unittype || "",
            in_utilization_clinical: positionData.in_utilization_clinical === '' ? null :  positionData.in_utilization_clinical,
            in_utilization_admin: positionData.in_utilization_admin === '' ? null :  positionData.in_utilization_admin,
            in_utilization_research: positionData.in_utilization_research  === '' ? null :  positionData.in_utilization_research,
            in_utilization_academic: positionData.in_utilization_academic === '' ? null :  positionData.in_utilization_academic,
            in_utilization_total: positionData.in_utilization_total === '' ? null :  positionData.in_utilization_total,
            in_pay_frequency: positionData.in_pay_frequency || null,
        };
        positionReqData.in_provider_mstr_key = provider_key;
        positionReqData.in_title_name = formFields?.groupData?.title_name;
        positionReqData.in_custom_field_data_keys = customFieldDataKeys;
        positionReqData.in_org_position_type_key =
            formFields.vw_org_position_position_type_name?.id || "";
        positionReqData.in_utilization_unittype =
            formFields.vw_org_position_utilization_unit?.id || "";

        positionReqData.in_provider_mstr_key = provider_key;
        positionReqData.in_org_title_key = formFields?.groupData?.org_title_key;
        positionReqData.in_custom_field_data_keys = customFieldDataKeys;

        let dataV = customFieldDataKeys?.find(
            (x) => x.in_custom_fld_col_mstr_key === 605
        )
        let dataValues = customFieldDataKeys?.find(
            (x) => x.in_custom_fld_col_mstr_key === 49
        )
        positionReqData.providerKeys = [
            {
                provider_id: formFields["vw_provider_master_provider_id"] || "",
            },
            {
                employee_id: formFields["vw_provider_master_employee_id"] || "",
            },
            {
                alternate_id:
                    formFields["vw_provider_master_alternate_id"] || "",
            },
            {
                title_name: formFields?.groupData?.title_name || "",
            },
            {
                specialty_name: formFields.vw_org_position_specialty_name?.text || "",
            },
            {
                cst_aces_name : dataV?.in_data_value || ""
            },
            {
                cst_assignment_number : dataValues?.in_data_value || ""
            },
            {
                npi : formFields["vw_provider_master_npi"] || "",
            }
        ];

        let res = await Action.insertPosition(
            positionReqData,
            dispatch,
            history
        );
        if (res.out_last_insert_key) {
            setLoadingState({ ...loadingState, isSaveLoading: false });
            // if(restProps.isSearchClick){
            restProps.setAdd(true);
            restProps.setMode("Default");
            // }else{
            //     restProps.setMode('Default')
            // }
        } else {
            setLoadingState({ ...loadingState, isSaveLoading: false });
        }
    };

    const getCustomFields = async (categoryName, mstrKeyName) => {
        let tempData = [];
        let obj = {};
        const categoryData = categoryFieldList.filter(
            (x) =>
                x.filter_table_name === categoryName &&
                x.custom_fld_mstr_key !== null
        );
        categoryData.forEach((x) => {
            const columnName = x.filter_column_name;
            let fieldValue = "";
            if (x.custom_fld_mstr_key !== null) {
                fieldValue =
                    formFields[
                        x.filter_table_name +
                            "customFields" +
                            x.custom_fld_mstr_key +
                            x.custom_fld_col_mstr_key +
                            "_" +
                            x.custom_fld_data_type.toLowerCase()
                    ];
                if (x.filter_column_datatype === "date") {
                    fieldValue = fieldValue ? formatDateOnlyMoment(fieldValue) : "";
                }
            } else if (x.filter_column_datatype === "date") {
                fieldValue = fieldValue ? formatDateOnlyMoment(fieldValue) : "";
            }
            else if (x.filter_column_datatype === "number") {
                fieldValue =  fieldValue === 0 ? '0.00' : fieldValue || null
            }
            obj = { ...x };
            obj[columnName] = fieldValue || "";
            tempData.push(obj);
        });

        let reqData = [];
        tempData.map((item) => {
            let customFields = {
                in_custom_fld_col_mstr_key: item.custom_fld_col_mstr_key,
                in_custom_fld_data_src_mstr_key: "",
                in_data_value: item[item.filter_column_name],
                in_mstr_key_name: mstrKeyName,
                in_date_version: 1,
                in_custom_fld_data_date:
                    formatDateOnlyMoment(
                        formFields[`vw_org_position_eff_start_date`]
                    ) || null,
            };
            reqData.push(customFields);
            return 0;
        });
        return reqData;
    };

    const handleDrop = (e) => {
        if (
            e.dataItem.category_code !== "doc_attr" &&
            state.draggedItem.cat_fdm_assign_key &&
            state.draggedItem.filter_column_name !== "eff_start_date"
        ) {
            let findIndex = categoryList.findIndex(
                (x) => x.category_code === e.dataItem.category_code
            );
            if (findIndex !== -1) {
                let newData = categoryList.map((category) => {
                    if (category.category_code === e.dataItem.category_code) {
                        let sortedFields = category.fields;
                        let findCurrentIndex = sortedFields.findIndex(
                            (x) =>
                                x.cat_fdm_assign_key ===
                                e.dataItem.cat_fdm_assign_key
                        );
                        if (
                            state.draggedItem.category_code !==
                            e.dataItem.category_code
                        ) {
                            sortedFields.push({
                                ...state.draggedItem,
                                category_code: e.dataItem.category_code,
                            });
                        }
                        let findDropIndex = sortedFields.findIndex(
                            (x) =>
                                x.cat_fdm_assign_key ===
                                state.draggedItem.cat_fdm_assign_key
                        );

                        if (findCurrentIndex !== -1 && findDropIndex !== -1) {
                            sortedFields[findCurrentIndex] = state.draggedItem;
                            sortedFields[findDropIndex] = e.dataItem;
                        }

                        return { ...category, fields: sortedFields };
                    }
                    return category;
                });
                if (
                    state.draggedItem.filter_column_name !== "eff_start_date" &&
                    state.draggedItem.cat_fdm_assign_key
                ) {
                    updateCategoryFdmOrder(
                        newData.filter(
                            (x) => x.category_code === e.dataItem.category_code
                        ),
                        state.draggedItem.cat_fdm_assign_key,
                        categoryList[findIndex].category_config_key
                    );
                }
            }
        }
    };

    const handleParentDragStart = (e, id) => {
        e.dataTransfer.setData("text/plain", id);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleParentDrop = (e, dropId) => {
        e.preventDefault();
        if (e.target.id === "header") {
            const dragId = e.dataTransfer.getData("text/plain");
            const updatedCategories = categoryList.slice();
            const dragIndex = categoryList.findIndex(
                (x) => x.category_config_key === Number(dragId)
            );
            const dropIndex = categoryList.findIndex(
                (x) => x.category_config_key === dropId
            );

            if (
                state.draggedItem.filter_column_name !== "eff_start_date" &&
                dragIndex !== dropIndex &&
                dragIndex !== -1 &&
                dropIndex !== -1
            ) {
                const [draggedCategory] = updatedCategories.splice(
                    dragIndex,
                    1
                );
                updatedCategories.splice(dropIndex, 0, draggedCategory);
                updateCategoryOrder(updatedCategories, Number(dragId), dropId);
            }
        }
    };

    const updateCategoryOrder = async (updatedCategories, dragId, dropId) => {
        setLoadingState({ ...loadingState, isUpdateCategory: true });
        let reqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_category_config_key_list: updatedCategories
                .map((item) => item.category_config_key)
                .toString(),
        };
        let res = await Action.updateCategoryOrder(reqData);
        if (res) {
            setLoadingState({ ...loadingState, isUpdateCategory: false });
            setCategoryList(updatedCategories);
        }
    };

    const updateCategoryFdmOrder = async (newData, keyList, configKey) => {
        setLoadingState({ ...loadingState, isUpdateCategoryFdm: true });
        let reqData = {
            in_user_id: cookiesData.out_user_id.toString(),
            in_tenant_id: cookiesData.out_tenant_id,
            in_cat_fdm_assign_key_list: newData
                .map((item) => {
                    let ids = [];
                    item.fields.forEach((fItem) => {
                        ids.push(fItem.cat_fdm_assign_key);
                    });
                    return ids;
                })
                .toString(),
            in_category_config_key: configKey.toString(),
        };
        let res = await Action.updateCategoryFdmOrderAsync(reqData);
        if (res) {
            setLoadingState({ ...loadingState, isUpdateCategoryFdm: false });
            getCategoryList();
        }
    };

    const handleDragStart = (e) => {
        setState({
            ...state,
            draggedItem: e.dataItem,
        });
    };

    const handleItemClick = (event) => {
        let findIndex = categoryList.findIndex(
            (x) => x.category_code === event.dataItem.category_code
        );

        setCategoryList((prevCategoryList) => {
            const updatedList = [...prevCategoryList];
            updatedList[findIndex].fields = updatedList[findIndex].fields.map(
                (item) => {
                    if (
                        item.cat_fdm_assign_key ===
                        event.dataItem.cat_fdm_assign_key
                    ) {
                        item[SELECTED_FIELD] = !item[SELECTED_FIELD];
                    } else if (!event.nativeEvent.ctrlKey) {
                        item[SELECTED_FIELD] = false;
                    }
                    return item;
                }
            );

            return updatedList;
        });
    };

    const headerConfig = {
        title: "Create New Provider-Position",
        id: "add-provider-position",
        buttons: { template_fx: GridButton },
    };

    return (
        <>
            {(loadingState.isSaveLoading ||
                loadingState.isUpdateCategory ||
                loadingState.isUpdateCategoryFdm) && <LoadingPanel />}
            {loadingState.initialLoad ? (
                <LoadingPanel />
            ) : (
                <>
                    <AddAccessContext.Provider value={[restProps.pageProps]}>
                        <FormFieldsContext.Provider
                            value={[formFields, setFormFields]}
                        >
                            <CancelClickContext.Provider
                                value={[isCancelClick, setCancelClick]}
                            >
                                <AddETLFieldsContext.Provider value={[]}>
                                    <ErrorContext.Provider
                                        value={[errorObj, setErrorObj]}
                                    >
                                        <DataListContext.Provider
                                            value={[dataList, setDataList]}
                                        >
                                            <DragContext.Provider
                                                value={[drag, setDrag]}
                                            >
                                                <DropContext.Provider
                                                    value={[
                                                        drop,
                                                        handleDropChange,
                                                    ]}
                                                >
                                                    <div>
                                                        <div>
                                                            <div className="content">
                                                                <div className="content-head">
                                                                    <HeaderPage
                                                                        pageProps={
                                                                            restProps.pageProps
                                                                        }
                                                                        headerConfig={
                                                                            headerConfig
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className="content-body">
                                                                    <div className="Propos-container">
                                                                        <div className="att-main">
                                                                            {categoryList.map(
                                                                                (
                                                                                    item
                                                                                ) => {
                                                                                    return (
                                                                                        <>
                                                                                            <div
                                                                                                className="attr-col"
                                                                                                key={
                                                                                                    item.category_config_key
                                                                                                }
                                                                                                draggable="true"
                                                                                                onDragStart={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleParentDragStart(
                                                                                                        e,
                                                                                                        item.category_config_key
                                                                                                    )
                                                                                                }
                                                                                                onDragOver={
                                                                                                    handleDragOver
                                                                                                }
                                                                                                onDrop={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    handleParentDrop(
                                                                                                        e,
                                                                                                        item.category_config_key
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <div
                                                                                                    id="header"
                                                                                                    className="attr-col-heading"
                                                                                                >
                                                                                                    <i className="fas fa-arrows-alt"></i>
                                                                                                    {
                                                                                                        item.category_label
                                                                                                    }
                                                                                                </div>
                                                                                                <div className="attr-col-content">
                                                                                                    <div className="form-row providerbox">
                                                                                                        <>
                                                                                                            <ListBox
                                                                                                                style={{
                                                                                                                    width: "100%",
                                                                                                                }}
                                                                                                                data={
                                                                                                                    item.fields
                                                                                                                }
                                                                                                                selectedField={
                                                                                                                    SELECTED_FIELD
                                                                                                                }
                                                                                                                onItemClick={(
                                                                                                                    e
                                                                                                                ) =>
                                                                                                                    handleItemClick(
                                                                                                                        e
                                                                                                                    )
                                                                                                                }
                                                                                                                onDragStart={
                                                                                                                    item.category_code ===
                                                                                                                    "doc_attr"
                                                                                                                        ? () => {}
                                                                                                                        : handleDragStart
                                                                                                                }
                                                                                                                onDrop={
                                                                                                                    handleDrop
                                                                                                                }
                                                                                                                item={
                                                                                                                    ProviderFields
                                                                                                                }
                                                                                                            ></ListBox>
                                                                                                        </>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    );
                                                                                }
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </DropContext.Provider>
                                            </DragContext.Provider>
                                        </DataListContext.Provider>
                                    </ErrorContext.Provider>
                                </AddETLFieldsContext.Provider>
                            </CancelClickContext.Provider>
                        </FormFieldsContext.Provider>
                    </AddAccessContext.Provider>
                </>
            )}
        </>
    );
};
export default React.memo(AddProviderPosition);

export const GridButton = () => {
    return (
        <>
            <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
                Cancel
            </Button>
            <Button
                type="submit"
                className="primarybtn"
                id="grid-incell-update"
            >
                Save
            </Button>
            <Button
                title="Back to Provider-Position Listing"
                type="submit"
                className="cancelbtn backbtn"
                id="grid-back-btn"
            >
                <span className="k-button-text">
                    <i className="fa fa-arrow-left"></i>
                </span>
            </Button>
        </>
    );
};
