import * as React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  BOOLEAN_OBJECT,
  SELECT_ALL_OBJECT,
  POSTION_PARMAS_FILTER,
  REPORT_DISPLAY_FILTER,
  UNIT_TYPE_FX,
  CUSTOM_TABLE_TYPE,
  NODE_NAME_LIST,
  BUSINESS_OBJECT_TYPES,
} from "../../constants/constants";
import {
  GetKPIType,
  GetPeriodTypeAPI,
  GetEventTypeAPI,
  GetEventTableAPI,
  GetUnitType,
  GeJobTypeAPI,
  GetRuleTypeAPI,
  getApprovalStatus,
  getRoleTypeData,
  GeDbRoleAPI,
  GeUserStatusTypeAPI,
} from "../../lib/common-service";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential } from "../../lib/cookies";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  SELECT_RESULT_GROUP,
  SELECT_RESULT_TYPE,
  GET_EVENT_GROUP,
  SELECT_RULE_RESULT_NAME,
  SELECT_COMP_ELEMENT_TYPE,
} from "../../api-setup/api-endpoints";
import { POSTAPI } from "../../api-setup/api-helper";
import { userGridSelector } from "../../redux/selectors/custom-selector";

let value = "";

let apiDataSource = {
  boolean: undefined,
  kpiType: undefined,
  periodType: undefined,
  unitType: undefined,
  resultName: undefined,
  compElement: undefined,
  eventType: undefined,
  eventTable: undefined,
  eventGroupType: undefined,
  resultType: undefined,
  resultGroup: undefined,
  displayGroup: undefined,
  jobType: undefined,
  displayGroupFx: undefined,
  userStatusType: undefined,
  dbRole: undefined,
  objType: undefined,
};

const GetBooleanDataSource = () => {
  apiDataSource.boolean = apiDataSource.boolean ?? BOOLEAN_OBJECT;

  return apiDataSource.boolean;
};

const GetKpiTypeDataSource = () => {
  const cookiesData = getUserCredential();

  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    // if (apiDataSource.kpiType === undefined) {
    const procsParam = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_sort_number: 1,
      in_sort_order: "ASC",
      in_page_rows: 9999,
      in_page_row_offset: 0,
    };

    let resAPI = await GetKPIType(procsParam, dispatch, history);
    let tempArray = [];

    resAPI.map((item) => {
      tempArray.push({
        text: item.kpi_type_name,
        value: item.kpi_type_mstr_key,
      });
      return 0;
    });

    apiDataSource.kpiType = tempArray;
    // }

    setData(apiDataSource.kpiType);
  };

  return data;
};

const GetPeriodTypeDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.periodType === undefined) {
      const procsParam = {
        in_filter: "AND a.period_type_hierarchy_level IS NOT NULL",
        in_sort_number: 1,
        in_sort_order: "DESC",
      };

      let resAPI = await GetPeriodTypeAPI(procsParam, dispatch, history);
      let tempArray = [];

      resAPI.map((item) => {
        tempArray.push({
          text: item.period_type_name,
          value: item.period_type_mstr_key,
        });
        return 0;
      });

      apiDataSource.periodType = tempArray;
    }

    setData(apiDataSource.periodType);
  };

  return data;
};

const GetCreditAmountUnitTypeDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.unitType === undefined) {
      const procsParam = {
        in_filter: "AND (a.system_unit_name NOT LIKE 'fdm_%')",
        in_sort_number: 3,
        in_sort_order: null,
        in_page_row_offset: 0,
        in_page_rows: 9999,
      };

      let resAPI = await GetUnitType(procsParam, dispatch, history);
      let tempArray = [];

      resAPI.map((item) => {
        tempArray.push({
          text: item.system_unit_name,
          value: item.system_unit_name,
        });
        return 0;
      });

      apiDataSource.unitType = tempArray;
    }

    setData(apiDataSource.unitType);
  };

  return data;
};

const GetUnitTypeDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.unitType === undefined) {
      const procsParam = {
        in_filter: "AND (a.system_unit_name NOT LIKE 'fdm_%')",
        in_sort_number: 3,
        in_sort_order: null,
        in_page_row_offset: 0,
        in_page_rows: 9999,
      };

      let resAPI = await GetUnitType(procsParam, dispatch, history);
      let tempArray = [];

      resAPI.map((item) => {
        tempArray.push({
          text: item.system_unit_name,
          value: item.system_unit_mstr_key,
        });
        return 0;
      });

      apiDataSource.unitType = tempArray;
    }

    setData(apiDataSource.unitType);
  };

  return data;
};
const GetRuleResultNameDataSource = () => {
  const cookiesData = getUserCredential();
  const dispatch = useDispatch();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.resultName === undefined) {
      const procsParam = {
        in_tenant_id: "deborahhlc01",
        in_filter: null,
        in_sort_number: 1,
        in_sort_order: "DESC",
        in_page_rows: 0,
        in_page_row_offset: 100,
      };
      let tempArray = [];

      await POSTAPI(
        false,
        SELECT_RULE_RESULT_NAME,
        null,
        header,
        procsParam,
        dispatch
      ).then((res) => {
        if (res.status) {
          res.data.row_result.map((item) => {
            let obj = {};
            obj.text = item.result_name;
            obj.value = item.result_name_mstr_key;
            tempArray.push(obj);
            return 0;
          });
        } else {
          // errStatusHandler(res, history, dispatch);
        }
      });

      apiDataSource.resultName = tempArray;
    }

    setData(apiDataSource.resultName);
  };

  return data;
};

// const GetJobTypeDataSource = () => {
//   const cookiesData = getUserCredential();
//   const dispatch = useDispatch();
//   const header = {
//     "x-access-token": cookiesData.secretToken,
//   };

//   const [data, setData] = React.useState(undefined);

//   React.useEffect(() => {
//     setDataSource();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []);

//   const setDataSource = async () => {
//     if (apiDataSource.compElement === undefined) {
//       const procsParam = {
//         in_filter: null,
//         in_sort_number: 1,
//         in_sort_order: "DESC",
//       };
//       let tempArray = [];

//       await POSTAPI(
//         false,
//         SELECT_JOB_TYPE,
//         null,
//         header,
//         procsParam,
//         dispatch
//       ).then((res) => {
//         if (res.status) {
//           res.data.row_result.map((item) => {
//             let obj = {};
//             obj.text = item.job_type_name;
//             obj.value = item.job_type_mstr_key;
//             tempArray.push(obj);
//             return 0;
//           });
//         } else {
//           // errStatusHandler(res, history, dispatch);
//         }
//       });

//       apiDataSource.compElement = tempArray;
//     }

//     setData(apiDataSource.compElement);
//   };

//   return data;
// };

const GetCompElementDataSource = () => {
  const cookiesData = getUserCredential();
  const dispatch = useDispatch();
  const header = {
    "x-access-token": cookiesData.secretToken,
  };

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.compElement === undefined) {
      const procsParam = {
        in_filter: null,
        in_sort_number: 1,
        in_sort_order: "DESC",
      };
      let tempArray = [];

      await POSTAPI(
        false,
        SELECT_COMP_ELEMENT_TYPE,
        null,
        header,
        procsParam,
        dispatch
      ).then((res) => {
        if (res.status) {
          res.data.row_result.map((item) => {
            let obj = {};
            obj.text = item.comp_el_type_name;
            obj.value = item.comp_el_type_mstr_key;
            tempArray.push(obj);
            return 0;
          });
        } else {
          // errStatusHandler(res, history, dispatch);
        }
      });

      apiDataSource.compElement = tempArray;
    }

    setData(apiDataSource.compElement);
  };

  return data;
};

const GetEventTypeDataSource = () => {
  const cookiesData = getUserCredential();
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.eventType === undefined) {
      const procsParam = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: null,
        in_sort_number: 1,
        in_sort_order: "ASC",
        in_page_row_offset: 0,
        in_page_rows: 9999,
      };
      let tempArray = [];

      let resEventType = await GetEventTypeAPI(procsParam, dispatch, history);

      resEventType.map((item) => {
        tempArray.push({
          text: item.event_type_name,
          value: item.event_type_mstr_key,
        });
        return 0;
      });

      apiDataSource.eventType = tempArray;
    }

    setData(apiDataSource.eventType);
  };

  return data;
};
const GetEventTableDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.eventTable === undefined) {
      const procsParam = {
        in_filter: "",
        in_sort_number: null,
        in_sort_order: null,
        in_page_row_offset: 0,
        in_page_rows: 100,
      };
      let tempArray = [];

      let resEventType = await GetEventTableAPI(procsParam, dispatch, history);

      resEventType.map((item) => {
        tempArray.push({
          text: item.etl_table_label,
          value: item.etl_table_mstr_key,
        });
        return 0;
      });

      apiDataSource.eventTable = tempArray;
    }

    setData(apiDataSource.eventTable);
  };

  return data;
};
const GetEventGroupDataSource = () => {
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();

  const header = {
    "x-access-token": cookiesData.secretToken,
  };

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.eventGroupType === undefined) {
      const procsParam = {
        in_filter: null,
        in_sort_number: 1,
        in_sort_order: "DESC",
        in_page_row_offset: 0,
        in_page_rows: 100,
      };
      let tempArray = [{ text: "", value: "blank" }];

      await POSTAPI(
        false,
        GET_EVENT_GROUP,
        null,
        header,
        procsParam,
        dispatch
      ).then((res) => {
        if (res.status) {
          if (res.data.out_parameters.out_ret_code > 0) {
          } else {
            res.data.row_result.map((item) => {
              let obj = {};
              obj.value = item.event_group_mstr_key;
              obj.text = item.event_group_name;
              tempArray.push(obj);
              return 0;
            });
          }
        }
      });

      apiDataSource.eventGroupType = tempArray;
    }

    setData(apiDataSource.eventGroupType);
  };

  return data;
};
const GetResultTypeDataSource = () => {
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();

  const header = {
    "x-access-token": cookiesData.secretToken,
  };

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    // if (apiDataSource.resultType === undefined) {
    const procsParam = {
      in_filter: null,
      in_sort_number: 1,
      "in_sort_order ": "ASC",
      in_page_row_offset: 0,
      in_page_rows: 9999,
    };
    let tempArray = [];

    await POSTAPI(
      false,
      SELECT_RESULT_TYPE,
      null,
      header,
      procsParam,
      dispatch
    ).then((res) => {
      if (res.status) {
        if (res.data.out_parameters.out_ret_code > 0) {
        } else {
          const defaultObj = { text: "", value: "blank" };
          tempArray.push(
            defaultObj,
            ...res.data.row_result.map((item) => ({
              text: item.result_type_name,
              value: item.result_type_mstr_key,
            }))
          );
        }
      }
    });

    apiDataSource.resultType = tempArray;
    // }

    setData(apiDataSource.resultType);
  };

  return data;
};
const GetResultGroupDataSource = () => {
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();

  const header = {
    "x-access-token": cookiesData.secretToken,
  };

  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.resultGroup === undefined) {
      const procsParam = {
        in_filter: null,
        in_sort_number: 1,
        in_sort_order: "DESC",
        in_page_row_offset: 0,
        in_page_rows: 100,
      };
      let tempArray = [];

      await POSTAPI(
        false,
        SELECT_RESULT_GROUP,
        null,
        header,
        procsParam,
        dispatch
      ).then((res) => {
        if (res.status) {
          if (res.data.out_parameters.out_ret_code > 0) {
          } else {
            res.data.row_result.map((item) => {
              let obj = {};
              obj.text = item.result_group_name;
              obj.value = item.result_group_mstr_key;
              tempArray.push(obj);
              return 0;
            });
          }
        }
      });

      apiDataSource.resultGroup = tempArray;
    }

    setData(apiDataSource.resultGroup);
  };

  return data;
};

const GetDisplayGroupDataSource = () => {
  apiDataSource.displayGroup =
    apiDataSource.displayGroup ?? REPORT_DISPLAY_FILTER;

  return apiDataSource.displayGroup;
};

const GetDisplayGroupFxDataSource = () => {
  apiDataSource.displayGroupFx = apiDataSource.displayGroupFx ?? UNIT_TYPE_FX;

  return apiDataSource.displayGroupFx;
};

const GetRuleTypeDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.jobType === undefined) {
      let procsParam = {
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: "ASC",
      };
      let tempArray = [];

      let resRuleType = await GetRuleTypeAPI(procsParam, dispatch, history);

      resRuleType
        .filter((x) => x.rule_config_table_name !== "")
        .map((item) => {
          tempArray.push({
            text: item.rule_type_name,
            value: item.rule_type_mstr_key,
          });
          return 0;
        });

      apiDataSource.resRuleType = tempArray;
    }

    setData(apiDataSource.resRuleType);
  };

  return data;
};

const GetCustomTableTypeDataSource = () => {
  apiDataSource.customTableType =
    apiDataSource.customTableType ?? CUSTOM_TABLE_TYPE;

  return apiDataSource.customTableType;
};

const GetNodeTypeDataSource = () => {
  apiDataSource.nodeType = apiDataSource.nodeType ?? NODE_NAME_LIST;

  return apiDataSource.nodeType;
};
const GetDisplayObjDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState(BUSINESS_OBJECT_TYPES);

  apiDataSource.objType = apiDataSource.objType ?? BUSINESS_OBJECT_TYPES;

  return apiDataSource.objType;

  // React.useEffect(() => {
  //   setDataSource();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // const setDataSource = async () => {
  //   if (apiDataSource.jobType === undefined) {
  //     let procsParam = {
  //       in_filter: null,
  //       in_sort_number: DEFAULT_SORT.number,
  //       in_sort_order: "ASC",
  //     };
  //     let tempArray = [];

  //     let resJobType = await GeJobTypeAPI(procsParam, dispatch, history);

  //     resJobType.map((item) => {
  //       tempArray.push({
  //         text: item.job_type_name,
  //         value: item.job_type_name,
  //       });
  //       return 0;
  //     });

  //     apiDataSource.jobType = tempArray;
  //   }

  //   setData(apiDataSource.jobType);
  // };

  return data;
};

const GetDisplayJobDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.jobType === undefined) {
      let procsParam = {
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: "ASC",
      };
      let tempArray = [];

      let resJobType = await GeJobTypeAPI(procsParam, dispatch, history);

      resJobType.map((item) => {
        tempArray.push({
          text: item.job_type_name,
          value: item.job_type_mstr_key,
        });
        return 0;
      });

      apiDataSource.jobType = tempArray;
    }

    setData(apiDataSource.jobType);
  };

  return data;
};

const GetRoleTypeDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.approvalStatus === undefined) {
      let procsParam = {
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: "ASC",
      };
      let tempArray = [];

      let resRoleTypeStatus = await getRoleTypeData(
        procsParam,
        dispatch,
        history
      );
      resRoleTypeStatus.map((item) => {
        tempArray.push({
          text: item.db_role_type_name,
          value: item.db_role_type_name,
        });
        return 0;
      });

      apiDataSource.resRoleTypeStatus = tempArray;
    }

    setData(apiDataSource.resRoleTypeStatus);
  };

  return data;
};

const GetApprovalStatusDataSource = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = React.useState(undefined);

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.approvalStatus === undefined) {
      let procsParam = {
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: "ASC",
      };
      let tempArray = [];

      let resApprovalStatus = await getApprovalStatus(
        procsParam,
        dispatch,
        history
      );

      resApprovalStatus.map((item) => {
        tempArray.push({
          text: item.approval_status_name,
          value: item.approval_status_mstr_key,
        });
        return 0;
      });

      apiDataSource.approvalStatus = tempArray;
    }

    setData(apiDataSource.approvalStatus);
  };

  return data;
};

const GetDisplayUserStatusDataSource = () => {
  const [data, setData] = React.useState(undefined);

  const { userStatusData } = useSelector(
    userGridSelector,
    shallowEqual
  ).userStatusData;

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.userStatusType === undefined) {
      if (userStatusData === undefined) {
        let returnArray = [];

        let procsParam = {
          in_filter: null,
          in_sort_number: DEFAULT_SORT.number,
          in_sort_order: "ASC",
        };

        let res = await GeUserStatusTypeAPI(procsParam);

        res.map((item) => {
          returnArray.push({
            text: item.status_type_name,
            value: item.status_type_key,
          });
          return 0;
        });
        apiDataSource.userStatusType = returnArray;
      } else {
        apiDataSource.userStatusType = userStatusData;
      }
    }

    setData(apiDataSource.userStatusType);
  };

  return data;
};

const GetDisplayDbRoleDataSource = () => {
  const [data, setData] = React.useState(undefined);

  const { roleData } = useSelector(userGridSelector, shallowEqual).roleData;

  React.useEffect(() => {
    setDataSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setDataSource = async () => {
    if (apiDataSource.dbRole === undefined) {
      if (roleData === undefined) {
        let returnArray = [];
        let procsParam = {
          in_filter: null,
          in_sort_number: 3,
          in_sort_order: "ASC",
          in_page_row_offset: 0,
          in_page_rows: 9999,
        };

        let res = await GeDbRoleAPI(procsParam);

        res.map((item) => {
          returnArray.push({
            text: item.db_role_name,
            value: item.db_role_key,
            role_desc: item.db_role_desc,
          });
          return 0;
        });
        apiDataSource.dbRole = returnArray;
      } else {
        apiDataSource.dbRole = roleData;
      }
    }

    setData(apiDataSource.dbRole);
  };

  return data;
};

const GetDisplayJobParamSource = () => {
  const [data] = React.useState(POSTION_PARMAS_FILTER);

  return data;
};

const AppendDefaultOpt = (dataSource) => {
  const defaultExists = !dataSource.length
    ? 1
    : dataSource.filter((dataItem) => {
        return dataItem.value === "";
      }).length;

  defaultExists <= 0 && dataSource.unshift(SELECT_ALL_OBJECT);
};

const onDropdownChange = (event, props) => {
  const { firstFilterProps } = props;
  const field = firstFilterProps.field;

  value = field === "event_group_name" ? event.value.text : event.value.value;

  firstFilterProps.onChange({ value, operator: "eq" });
};

const InitDropdown = (props, templateProps) => {
  const [state, setState] = React.useState({});
  const dataSource = templateProps.dataSource;

  //on dropdown load
  React.useEffect(() => {
    if (dataSource && dataSource.length > 0) {
      //append value
      if (templateProps.appendDefaultOpt !== false) {
        AppendDefaultOpt(dataSource);
      }
      const firstFltrPropVal = props.firstFilterProps.value;

      const firstFilterVal =
        firstFltrPropVal !== undefined ? firstFltrPropVal : "";

      //select default value when dropdown load
      setState({
        value: dataSource.filter(function (dataItem) {
          return dataItem.value == firstFilterVal;
        })[0],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);
  return (
    <div>
      <DropDownList
        data={dataSource}
        textField="text"
        dataItemKey="value"
        value={state.value}
        onChange={(e) => {
          setState({ value: e.target.value });
          onDropdownChange(e, props);
        }}
        style={{
          width: "150px",
        }}
      />
    </div>
  );
};

export const BooleanFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetBooleanDataSource() ?? [],
  });
};

export const KpiFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetKpiTypeDataSource() ?? [],
  });
};

export const PeriodTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetPeriodTypeDataSource() ?? [],
  });
};

export const UnitTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetUnitTypeDataSource() ?? [],
  });
};

export const creditUnitTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetCreditAmountUnitTypeDataSource() ?? [],
  });
};

export const RuleResultNameFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetRuleResultNameDataSource() ?? [],
  });
};

export const CompElementFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetCompElementDataSource() ?? [],
  });
};
export const EventTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetEventTypeDataSource() ?? [],
  });
};
export const EventTableFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetEventTableDataSource() ?? [],
  });
};
export const EventGroupTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetEventGroupDataSource() ?? [],
  });
};
export const ResultTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetResultTypeDataSource() ?? [],
  });
};
export const ResultGroupFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetResultGroupDataSource() ?? [],
  });
};
export const DisplayGroupFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetDisplayGroupDataSource() ?? [],
  });
};

export const JobTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetDisplayJobDataSource() ?? [],
  });
};
export const objTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetDisplayObjDataSource() ?? [],
  });
};
export const JobParamsFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetDisplayJobParamSource() ?? [],
  });
};

export const SystemGroupFxFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetDisplayGroupFxDataSource() ?? [],
    appendDefaultOpt: false,
  });
};

export const UserStatusTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetDisplayUserStatusDataSource() ?? [],
  });
};

export const DbRoleFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetDisplayDbRoleDataSource() ?? [],
  });
};

export const RuleTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetRuleTypeDataSource() ?? [],
  });
};

export const ApprovalStatusKeyTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetApprovalStatusDataSource() ?? [],
  });
};

export const CustomTableTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetCustomTableTypeDataSource() ?? [],
    appendDefaultOpt: false,
  });
};

export const NodeTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetNodeTypeDataSource() ?? [],
    appendDefaultOpt: false,
  });
};

export const RoleTypeFilterTemplate = (filterProps) => {
  return InitDropdown(filterProps, {
    dataSource: GetRoleTypeDataSource() ?? [],
  });
};

export const CustomLinkFilterUI = (props) => {
  const onChange = (event) => {
    const value = { text: event.value.text, value: event.value.value };
    const { firstFilterProps } = props;
    firstFilterProps.onChange({
      value,
      operator: "eq",
    });
  };
  const { firstFilterProps } = props;
  const value = firstFilterProps.value;
  return (
    <div>
      <DropDownList
        style={{
          width: "150px",
        }}
        onChange={onChange}
        value={value}
        data={BOOLEAN_OBJECT}
        dataItemKey="value"
        textField="text"
        placeholder={"Select"}
      />
    </div>
  );
};
