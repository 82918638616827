export const PASSWORD_MIN_Length = 8;
export const PASSWORD_MAX_Length = 13;
export const OTP_LENGTH = 6;
export const CUSTOMER_SUCCESS_AND_SUCCESS =
  "https://procareportal.atlassian.net/servicedesk/customer/portals";
export const RELEASE_NOTE =
  "https://procareportal.atlassian.net/servicedesk/customer/kb/view/1809350734";

export const UNSAVE_TEXT_WARNING =
  "There are unsaved changes to the current record. Do you want to navigate to clicked record?";
export const UNSAVEQUERY_TEXT_WARNING =
  "There are unsaved Query to the current filter Query . Do you want to save the Query??";

export const MULTIPLE_NUMBER_REGEX = new RegExp(/^\((\d+(\.\d+)?(,\s*\d+(\.\d+)?)*?)\)$/);
export const MULTIPLE_STRING_REGEX = new RegExp(/^(\((("([^"]*)")|('([^']*)'))\s*(,\s*(("([^"]*)")|('([^']*)'))\s*)*)?\)$/);
export const MULTIPLE_STRING_REGEX_FOR_RULES = new RegExp(/^(\((("([^"]*)"))\s*(,\s*(("([^"]*)"))\s*)*)?\)$/);
export const REGEX_FOR_LIKE_NUMBER = new RegExp(/^%?\d+(\.\d+)?%?$/);
export const REGEX_FOR_LIKE_STRING = new RegExp(/^%[a-zA-Z0-9\s'!@#$%^&*()_+\-=[\]{};:"\\|,.<>/?]*%$/);

export const REGEX_FOR_DATE_IN = new RegExp(
  /^\("\d{4}-\d{2}-\d{2}"(,\s*"\d{4}-\d{2}-\d{2}")*\)$/
);
export const REGEX_FOR_DATE_LIKE = new RegExp(
  /^%?\d{4}-\d{2}-\d{2}%?\s*%?\d{4}-\d{2}-\d{2}%?$/
);

export const DTU_UPLOAD_MAX_FILE_SIZE = 100;

export const PROVIDER_UPLOAD_MAX_FILE_SIZE = 10;

export const DTU_UPLOAD_ALLOWED_EXTENSIONS = ".csv";

export const BUSINESS_OBJECT_TYPES = [
  {
    text: "Position",
    value: "position",
  },
  {
    text: "Group",
    value: "group",
  },
  {
    text: "Specialty",
    value: "specialty",
  },
  {
    text: "Comp plan",
    value: "comp_plan",
  },
];
export const EFFCTIVE_TYPE = [
  {
    text: "All Assigned KPIs",
    value: "kpi",
  },
  {
    text: "All Assigned Providers",
    value: "provider",
  },
];

export const ACTION_ON_EXISTING = [
  {
    label: "Error",
    value: "error",
  },
  {
    label: "Delete",
    value: "delete",
  },
  {
    label: "Update",
    value: "update",
  },
];

export const DATA_LOAD_OPTIONS = [
  {
    label: "Load all Sucessfull",
    value: "error",
  },
  {
    label: "Load only if no error",
    value: "delete",
  },
];

export const MAX_ERROR_COUNT = [
  {
    label: "50",
    value: "error",
  },
  {
    label: "100",
    value: "delete",
  },
  { label: "150", value: "delete" },
  {
    label: "200",
    value: "delete",
  },
];

export const DATA_FILE = [
  {
    label: "0",
    value: "error",
  },
  {
    label: "1",
    value: "delete",
  },
];

export const CALENDAR_PERIOD_TYPES = [
  {
    text: "Regular",
    value: "Regular",
  },
  {
    text: "Fiscal",
    value: "Fiscal",
  },
];

export const EXPAND_ACTION_COLUM_OBJECT = {
  title: "ACTION",
  field: "expand",
  show: true,
  minWidth: 80,
};

export const EXPAND_ACTION_ROLE_COLUM_OBJECT = {
  title: "ACTION",
  field: "expand",
  show: true,
  minWidth: 80,
};

export const ACTION_COLUM_OBJECT_DATA_ROWS = {
  title: "ACTION",
  field: "expand",
  type: "expand",
  show: true,
  minWidth: 80,
};

export const RESULT_TYPES = {
  CREDITS: "/credits",
  MEASUREMENTS: "/measurements",
  INCENTIVES: "/incentives",
  PAYMENTS: "/payments",
};

export const RESULT_TYPES_STRING = {
  CREDITS: "credits",
  MEASUREMENTS: "measurements",
  INCENTIVES: "incentives",
  PAYMENTS: "payments",
};

export const RESULT_TYPES_STRING_UPPERCASE = {
  CREDITS: "Credits",
  MEASUREMENTS: "Measurements",
  INCENTIVES: "Incentives",
  PAYMENTS: "Payments",
};

export const RESULT_TYPES_MODULE = {
  CREDITS: "credit_rule_results",
  MEASUREMENTS: "measurements_rule_results",
  INCENTIVES: "incentive_rule_results",
  PAYMENTS: "payment_rule_results",
};

export const EXPORT_RESULT_MODULE = {
  CREDITS: "sp_select_results_credits_export",
  MEASUREMENTS: "sp_select_results_measurements_export",
  INCENTIVES: "sp_select_results_incentives_export",
  PAYMENTS: "sp_select_results_payments_export",
};

export const RESULT_SCREEN_STRING = {
  CREDITS: "creditResults",
  MEASUREMENTS: "measurementResults",
  INCENTIVES: "incentiveResults",
  PAYMENTS: "paymentResults",
};

export const ROLE_TYPE_KEY = [
  {
    text: "Admin",
    value: 1,
  },
  {
    text: "Provider",
    value: 2,
  },
  {
    text: "Super User Admin",
    value: 3,
  },
  {
    text: "Business Group Manager",
    value: 4,
  },
];

export const ROLE_CATEGORY = [
  {
    text: "Sort By: All Category",
    value: "sortByAllCategory",
  },
  {
    text: "Role",
    value: "role",
  },
  {
    text: "Description",
    value: "description",
  },
  {
    text: "Type of Role",
    value: "typeOfRole",
  },
  {
    text: "Landing Page",
    value: "landingPage",
  },
];

export const TABLE_TYPES = {
  EVENTS: "/events",
  CUSTOM_TABLES: "/custom-tables",
  RATE_TABLES: "/rate-tables",
};

export const TABLE_TYPES_STRING_UPPERCASE = {
  EVENTS: "Events",
  CUSTOM_TABLES: "Custom-Tables",
  RATE_TABLES: "Rate-Tables",
};

export const DELETE_BTN_ACTIONS = (
  onCancelConfirm,
  onDeleteConfirm,
  length
) => {
  return [
    {
      name: "Cancel",
      onClick: onCancelConfirm,
    },
    {
      name: "Yes",
      disabled: length === 0 ? true : false,
      onClick: () => {
        onDeleteConfirm();
      },
    },
  ];
};

export const DELETE_BATCH_BTN_ACTIONS = (
    onCancelConfirm,
    onDeleteConfirm,
    length
) => {
    return [
        {
            name: "Cancel",
            onClick: onCancelConfirm,
        },
        {
            name: "Next",
            disabled: length === 0 ? true : false,
            onClick: () => {
                onDeleteConfirm();
            },
        },
    ];
};

export const DELETE_BTN_ACTIONS_JOBS = (
  onCancelConfirm,
  onDeleteConfirm,
  onBatchDeleteConfirm,
  length
) => {
  return [
    {
      name: "Cancel",
      onClick: onCancelConfirm,
    },
    {
      name: "Yes",
      disabled: length === 0 ? true : false,
      onClick: () => {
        onDeleteConfirm();
      },
    },
    {
      name: "Delete All Scheduled Jobs",
      disabled: length === 0 ? true : false,
      onClick: () => {
        onBatchDeleteConfirm();
      },
    },
  ];
};

export const WARNING_BTN_ACTIONS = (
  onCancelConfirm,
  onDeleteConfirm,
  length
) => {
  return [
    {
      name: "Ok",
      onClick: onCancelConfirm,
    },
  ];
};

export const CANCEL_BTN_ACTIONS = (
  onCancelConfirm,
  onDeleteConfirm,
  length
) => {
  return [
    {
      name: "Cancel",
      onClick: onCancelConfirm,
    },
  ];
};

export const CONFIRMATION_BTN_ACTIONS = (
  onCancelConfirm,
  onConfirm,
  length
) => {
  return [
    {
      name: "No",
      onClick: onCancelConfirm,
    },
    {
      name: "Yes",
      disabled: length === 0 ? true : false,
      onClick: () => {
        onConfirm();
      },
    },
  ];
};

export const CANCEL_JOB_BTN_ACTIONS = (
  onJobCancelConfirm,
  onJobDeleteConfirm,
  length
) => {
  return [
    {
      name: "Cancel",
      onClick: onJobCancelConfirm,
    },
    {
      name: "Yes",
      disabled: length === 0 ? true : false,
      onClick: () => {
        onJobDeleteConfirm();
      },
    },
  ];
};

export const UNSAVE_BTN_ACTIONS = (onCancelConfirm, onYesClick) => {
  return [
    {
      name: "Discard Changes",
      onClick: onCancelConfirm,
    },
    {
      name: "Save Changes",
      onClick: onYesClick,
    },
  ];
};

export const REPORT_DISPLAY = [
  {
    text: "None",
    value: 0,
  },
  {
    text: "Plan",
    value: 1,
  },
  {
    text: "Provider",
    value: 2,
  },
  {
    text: "All",
    value: 3,
  },
];
export const REPORT_DISPLAY_FILTER = [
  {
    text: "None",
    value: "0",
  },
  {
    text: "Plan",
    value: "1",
  },
  {
    text: "Provider",
    value: "2",
  },
  {
    text: "All",
    value: "3",
  },
];

export const LANDING_PAGE = [
  {
    text: "Dashboard",
    value: 45,
  },
];

/*
 * Start to changed variables after restructure
 */
export const MULTI_SELECT_ALL_ID = "Select All";
export const MULTI_SELECT_ALL_TEXT = "Select All";
export const MULTI_SELECT_ALL_ZERO_ID = "0";

export const TIMEOUT_DIALOG_TIMER_COUNTDOWN = 60;

export const SELECT_ALL_OBJECT = {
  text: "Select All",
  value: "",
};

export const BOOLEAN_OBJECT = [
  {
    text: "True",
    value: "1",
  },
  {
    text: "False",
    value: "0",
  },
];
export const POSTION_PARMAS = {
  plan_param: "Comp Plan",
  position_param: "Position",
  stagehook_param: "Stagehook",
  published_position: "Published Position Only",
};
export const POSTION_PARMAS_FILTER = [
  {
    text: "Comp Plan",
    value: "plan_param",
  },
  {
    text: "Position",
    value: "position_param",
  },
  {
    text: "Stagehook",
    value: "stagehook_param",
  },
  {
    text: "Published Position Only",
    value: "published_position",
  },
];

export const RATE_TYPE = [
  {
    text: "Clif",
    value: "Clif",
  },
  {
    text: "Flat",
    value: "Flat",
  },
  {
    text: "Step",
    value: "Step",
  },
];

export const USE_TARGET = [
  {
    text: "Quality Payment",
    value: "qualitypayment",
  },
  {
    text: "Guarantee Payment",
    value: "guaranteepayment",
  },
];

export const UNIT_TYPE_FX_DROPDOWN_CELL = [
  {
    text: "",
    value: "",
  },
  {
    text: "sum",
    value: "sum",
  },
  {
    text: "avg",
    value: "avg",
  },
  {
    text: "max",
    value: "max",
  },
  {
    text: "min",
    value: "min",
  },
];
export const UNIT_TYPE_FX = [
  {
    text: "Select Group Fx",
    value: "",
  },
  {
    text: "",
    value: "blank",
  },
  {
    text: "sum",
    value: "sum",
  },
  {
    text: "avg",
    value: "avg",
  },
  {
    text: "max",
    value: "max",
  },
  {
    text: "min",
    value: "min",
  },
];

export const REPORT_TYPES = {
  COMPENSATION_DETAIL: "/compensation_detail",
};

export const REPORT_TYPES_STRING_UPPERCASE = {
  COMPENSATION_DETAIL: "Compensation Detail",
};

export const LOGICAL_OPERATORS = [
  {
    operator_value: "OR",
    operator_label: "OR",
  },
  {
    operator_value: "AND",
    operator_label: "AND",
  },
];

export const SELECT_DUMMY_DATA = [
  {
    text: "option1",
    value: "option1",
  },
  {
    text: "option2",
    value: "option2",
  },
];

export const RULE_COND_FLAG = [
  {
    text: "Rule Value",
    value: 0,
  },
  {
    text: "Rule Filter",
    value: 1,
  },
  {
    text: "Rate Formula",
    value: 2,
  },
];

export const FORMULA_TYPE = [
  { text: "boolean", value: "boolean" },
  { text: "number", value: "number" },
  { text: "rate table", value: "rate table" },
  { text: "string", value: "string" },
];

export const ENTITY_REF_LEVEL = [
  {
    text: "Position",
    value: "position",
  },
  {
    text: "Provider",
    value: "provider",
  },
  {
    text: "Team",
    value: "team",
  },
  {
    text: "Group",
    value: "group",
  },
  {
    text: "Specialty",
    value: "specialty",
  },
  {
    text: "Plan",
    value: "plan",
  },
];

export const MATH_SQL_OPERATORS = [
  {
    operator_value: "=",
    operator_label: "Is Equal To",
  },
  {
    operator_value: "!=",
    operator_label: "Is Not Equal To",
  },
  {
    operator_value: "&gt;",
    operator_label: "Is Greater Than",
  },
  {
    operator_value: "&lt;",
    operator_label: "Is Less Than",
  },
  {
    operator_value: "&gt;=",
    operator_label: "Is Greater Or Equal To",
  },
  {
    operator_value: "&lt;=",
    operator_label: "Is Less Or Equal To",
  },
  {
    operator_value: "BETWEEN",
    operator_label: "Between",
  },
  {
    operator_value: "NOT BETWEEN",
    operator_label: "Not Between",
  },
  {
    operator_value: "IN",
    operator_label: "In",
  },
  {
    operator_value: "NOT IN",
    operator_label: "Not In",
  },
  {
    operator_value: "= ' '",
    operator_label: "Is Empty",
  },
  {
    operator_value: "!= ' '",
    operator_label: "Is Not Empty",
  },
  {
    operator_value: "LIKE",
    operator_label: "Contains",
  },
  {
    operator_value: "NOT LIKE",
    operator_label: "Does Not Contain",
  },
  {
    operator_value: "+",
    operator_label: "+",
  },
  {
    operator_value: "-",
    operator_label: "-",
  },
  {
    operator_value: "*",
    operator_label: "*",
  },
  {
    operator_value: "/",
    operator_label: "/",
  },
  {
    operator_value: "%",
    operator_label: "%",
  },
];
export const dashboardRuleResultTableConfig = {
  credit: {
    key: "credit",
    value: "credit_rule_results",
  },
  measurement: {
    key: "measurement",
    value: "measurements_rule_results",
  },
  incentive: {
    key: "incentive",
    value: "incentive_rule_results",
  },
  payment: {
    key: "payment",
    value: "payment_rule_results",
  },
};
export const NODE_NAME_LIST_FILTER = [
  {
    text: "Select All",
    value: "",
  },
  {
    text: "Position",
    value: "position",
  },
  {
    text: "Team",
    value: "team",
  },
  {
    text: "Group",
    value: "group",
  },
  {
    text: "Specialty",
    value: "specialty",
  },
  {
    text: "Plan",
    value: "plan",
  },
];

export const NODE_NAME_LIST = [
  {
    text: "Position",
    value: "position",
  },
  {
    text: "Team",
    value: "team",
  },
  {
    text: "Group",
    value: "group",
  },
  {
    text: "Specialty",
    value: "specialty",
  },
  {
    text: "Plan",
    value: "plan",
  },
];

export const CUSTOM_TABLE_TYPE = [
  {
    text: "Standard",
    value: "S",
  },
  {
    text: "Hierarchical",
    value: "H",
  },
];

export const DAYS_OF_WEEK = [
  { text: "Sun", value: 0 },
  { text: "Mon", value: 1 },
  { text: "Tue", value: 2 },
  { text: "Wed", value: 3 },
  { text: "Thu", value: 4 },
  { text: "Fri", value: 5 },
  { text: "Sat", value: 6 },
];

export const MONTH_OF_YEAR = [
  { text: "January", value: 1, maxDays: 31 },
  { text: "February", value: 2, maxDays: 28 },
  { text: "March", value: 3, maxDays: 31 },
  { text: "April", value: 4, maxDays: 30 },
  { text: "May", value: 5, maxDays: 31 },
  { text: "June", value: 6, maxDays: 30 },
  { text: "July", value: 7, maxDays: 31 },
  { text: "August", value: 8, maxDays: 31 },
  { text: "September", value: 9, maxDays: 30 },
  { text: "October", value: 10, maxDays: 31 },
  { text: "November", value: 11, maxDays: 30 },
  { text: "December", value: 12, maxDays: 31 },
];

export const PROVIDER_POSITION_DATA = [
  {
    text: "POSITION",
    id: "position",
    table: "org_position",
  },
  {
    text: "PROVIDER",
    id: "provider",
    table: "provider_master",
  },
];

export const OPEN_PAREN_DATA = [
  { text: "", value: "" },
  { text: "(", value: "(" },
];
export const CLOSE_PAREN_DATA = [
  { text: "", value: "" },
  { text: ")", value: ")" },
];

export const pattern = /^[a-zA-Z0-9 _]*$/;
export const EVENT_TYPE_ID = 225;
/*
 * End to changed variables after restructure
 */

export const ALLOW_PROVIDER_DOC_EXTENSION = [
  ".pdf",
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
];
