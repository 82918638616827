// eslint-disable-next-line no-useless-concat
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
  getSelectedState,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import * as React from "react";
import { useDispatch } from "react-redux";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { DialogComponent } from "../../components/dialog";
import { CellRender, RowRender } from "../../components/renderers";
import { DELETE_BTN_ACTIONS, POSTION_PARMAS } from "../../constants/constants";
import { DEFAULT_SORT } from "../../constants/grid";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { removeMessage } from "../../redux/actions/setting-actions";
import MessageSchema from "../../utils/messageSchema";
import {
  getfilterDataString,
  changeCancelBtnState,
  changeUpdateBtnState,
  // isSameRow,
  debounceForFunc,
  executeGetColumnOrderInfo,
} from "../../utils/utils";
import useApi from "./service";
import JobDetails from "../common/jobDetails";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { useHistory } from "react-router";
import useColumnReorder from "src/custom-hooks/useColumnReorder";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "job_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const JobData = (restProps) => {
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const defaultPageSize = getTenantCookieData("record_per_page");
  const history = useHistory();

  const [list, Action] = useApi();
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const {
    data,
    columns,
    dataCount,
    setData,
    originalData,
    isAPICalling,
    setConfirmationPopup,
    confirmationPopup,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
    setLoadingStateSearch,
    loading,
    setColumns,
  } = list;
  const { columnReorderData } = useColumnReorder(
    cookiesData,
    restProps.setColumnsData,
    "",
    "procare_system",
    setLoadingStateSearch
);
  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });
  const [pageSize, setPageSize] = React.useState(defaultPageSize);
  const [exportData, setExportData] = React.useState([]);
  const [gridColumns, setGridColumns] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [errorObj, setErrorObj] = React.useState({});
  const [isAddJobtoQueue, setIsAddJobtoQueue] = React.useState(false);
  const [jobMstrKey, setJobMstrKey] = React.useState();
  const [compPlanKeys, setcompPlanKeys] = React.useState();
  const [dataItemList, setDataItemList] = React.useState({});
  const [resetSort, setResetSort] = React.useState(false);
  const [filterField, setFilterField] = React.useState({
    logic: "and",
    filters: [],
  });
  const [sortField, setSortField] = React.useState([]);

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_db_role_key: cookiesData?.out_db_role_key,
    in_filter: null,
    in_sort_number: DEFAULT_SORT.number,
    in_sort_order: DEFAULT_SORT.order,
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_load_type: "data-reload",
  };

  if (document.getElementById("grid-incell-cancel")) {
    document.getElementById("grid-incell-cancel").onclick = function (e) {
      const inputCell = document.activeElement.closest("td");
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
      if (inputCell) {
        const cellClass = inputCell.classList[0];
        cellClass === "plan-field" && cancelJobGridChanges();
      } else {
        restProps.isJobEdit === true && cancelJobGridChanges();
        restProps.isEngineEdit === true && cancelEnginePhaseChanges();
      }
    };
  }

  if (document.getElementById("grid-incell-update")) {
    document.getElementById("grid-incell-update").onclick = function (e) {
      restProps.isJobEdit === true && updateJobGridChanges();
      restProps.isEngineEdit === true && updateEnginePhaseChanges();
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ isUnSavedChange: false }));
      // restProps.isProviderEdit === true && updateproviderGridChanges();
    };
  }

  // React.useEffect(() => {
  //   if (restProps.isAPICall) {
  //     restProps.setSelectedRowId("");
  //     setSelectedState({});
  //     onRefreshClick();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [restProps.isAPICall]);

  const handleAddData = async () => {
    if (restProps.isAPICall) {
      restProps.setSelectedRowId("");
      setSelectedState({});
      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
      };
      let res = await Action.getListDataLengthAsync(dataVal);

      if (res.length === 0) {
        setResetSort(true);

        // Set filter to false
        setFilterData({
          ...filterData,
          data: "",
          isFilter: false,
          isSort: false,
        });
        onAddRefreshClick();
      } else {
        onRefreshClick();
      }
    }
  };

  React.useEffect(() => {
    handleAddData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAPICall]);

  React.useEffect(() => {
    if (restProps.isAddNewConfig) {
      restProps.setNewConfig(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isAddNewConfig]);

  const handleDeleteData = async () => {
    if (list.loadingState.isDeleteData) {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
      };
      let res = await Action.getListDataLengthAsync(dataVal);

      if (res.length === 0) {
        setResetSort(true);

        // Set filter to false
        setFilterData({
          ...filterData,
          data: "",
          isFilter: false,
        });
        onAddRefreshClick();
      } else {
        // setResetSort(true);
        onRefreshClick();
      }
    }
  };

  React.useEffect(() => {
    handleDeleteData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list.loadingState.isDeleteData]);

  React.useEffect(() => {
    if (restProps.isShow.deleteRows) {
      remove();
      restProps.setShow({ ...restProps.isShow, deleteRows: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isShow.deleteRows]);

  React.useEffect(() => {
    restProps.setDataList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setDataList(data);
      restProps.setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    if (originalData && originalData.length > 0) {
      let prevSelected = originalData.find(
        (obj) => obj.job_mstr_key == restProps.selectedRowId
      );

      setNewSelectedRow(
        prevSelected !== undefined
          ? { [prevSelected.job_mstr_key]: true }
          : { [originalData[0].job_mstr_key]: true }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalData]);

  React.useEffect(() => {
    const dataaVal = {
      ...apiCallData,
      in_load_type: "grid-reload",
    };
    getGridData(dataaVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (restProps.isCheckColumn) {
      setGridColumns(restProps.columnsData);
      restProps.setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isCheckColumn]);

  const getGridData = async (inParams) => {
    await Action.getListDataAsync(inParams);
    setErrorObj({});

    restProps.setisJobEdit(false);
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const cancelJobGridChanges = () => {
    dispatch(
      removeMessage({
        message: "",
        type: "Error",
        show: false,
      })
    );
    originalData.map((item) => {
      item.inEdit = false;
      item.selected = false;
      return 0;
    });
    restProps.setisJobEdit(false);
    setData(originalData);
    setErrorObj({});
    restProps.setDataList(originalData);
  };

  const cancelEnginePhaseChanges = () => {
    restProps.setisCancelClick(true);
  };

  const updateEnginePhaseChanges = () => {
    let valid = handleValidation();

    if (valid) {
      restProps.setisUpdateClick(true);
    } else {
      restProps.setisUpdateClick(false);
    }
  };
  const handleValidation = () => {
    let isValid = true;

    data
      .filter((x) => x.inEdit !== undefined)
      .map((item) => {
        const { job_name, rowId, email_subject, email_body, job_type_name } =
          item;

        let errorObj = {
          job_name: "",
          email_subject: "",
          email_body: "",
        };

        if (job_name.trim() === "") {
          errorObj.job_name = MessageSchema.jobNameRequired;
        } else {
          errorObj.job_name = "";
        }
        if (job_type_name === "Email Process") {
          if (email_subject.trim() === "") {
            errorObj.email_subject = MessageSchema.jobSubjectRequired;
          } else {
            errorObj.email_subject = "";
          }
          if (email_body.trim() === "") {
            errorObj.email_body = MessageSchema.jobBodyRequired;
          } else {
            errorObj.email_body = "";
          }
        }

        let obj = {};

        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);

        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }

        return 0;
      });

    return isValid;
  };

  const updateJobData = async () => {
    let updatedJOBDataList = [];

    //update plan data
    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        let obj = {};
        obj.in_user_id = cookiesData.out_user_id.toString();
        obj.in_tenant_id = cookiesData.out_tenant_id;
        obj.in_job_mstr_key = item.job_mstr_key;
        obj.in_job_type_mstr_key = item.job_type_mstr_key;
        obj.in_job_name = item.job_name;
        obj.in_job_desc = item.job_desc;
        obj.in_job_email_notif = item.job_email_notif;
        obj.in_email_subject = item.email_subject;
        obj.in_email_body = item.email_body;
        obj.in_published_position = item.published_position;

        updatedJOBDataList.push(obj);

        return 0;
      });

    const updateJobBodyData = {
      updateJOB: [...updatedJOBDataList],
    };
    if (updatedJOBDataList.length > 0) {
      let response = await Action.updateDataAsync(updateJobBodyData);

      if (response) {
        // TODO : UNSAVED CHANGES

        // dispatch(
        //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
        // );
        data.map((item) => {
          item.inEdit = false;
          return 0;
        });

        restProps.setisJobEdit(false);
        onRefreshClick();
      }
    }
  };

  const updateJobGridChanges = () => {
    let valid = handleValidation();

    if (valid) {
      updateJobData();
    } else {
      let elements = document.getElementsByClassName("k-textbox");
      if (elements.length === 0) {
        elements = document.getElementsByClassName("k-textarea");
      }
      elements[0].focus();

      changeCancelBtnState("enable");
      changeUpdateBtnState("enable");
      // TODO : UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ isUnSavedChange: true }));
    }
  };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  function checkCompPlanJobs(jobArray) {
    const compPlanJobs = jobArray.filter(
      (job) => job.job_type_code === "comp_plan"
    );
    const compPlanNames = compPlanJobs.map((job) => job.comp_plan_name);

    const uniqueNames = new Set(compPlanNames);

    return uniqueNames.size === compPlanNames.length;
  }
  const onDeleteConfirm = async (event) => {
    const apiCallData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_db_role_key: cookiesData?.out_db_role_key,
      in_filter: null,
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: DEFAULT_SORT.order,
      in_page_row_offset: 0,
      in_page_rows: defaultPageSize.recordTake,
      in_load_type: "data-reload",
    };
    let allData = await Action.getAllListData(apiCallData);
    deleteSelectedIds.forEach((deleteItem) => {
      // Find the matching item in data
      const match = allData.find(
        (item) => item.job_mstr_key === deleteItem.mstr_key
      );

      if (match) {
        // Initialize the delete flag to 0
        deleteItem.job_delete_flag = 0;

        // Check if the job_type_mstr_key is 2
        if (match.job_type_mstr_key === 2) {
          // Find all items in data with the same comp_plan_name
          const sameCompPlanItems = allData.filter(
            (item) => item.comp_plan_name === match.comp_plan_name
          );

          // Check if all items with the same comp_plan_name exist in deleteSelectedIds
          const allInDeleteSelectedIds = sameCompPlanItems.every((item) =>
            deleteSelectedIds.some(
              (deleteItem) => deleteItem.mstr_key === item.job_mstr_key
            )
          );

          if (!allInDeleteSelectedIds) {
            deleteItem.job_delete_flag = 1;
          }
        }

        // Add jobTypeName to deleteItem
        deleteItem.jobTypeName = match.job_name;
      }
    });

    const createFinalArray = (allData, deleteSelectedIds) => {
      const finalArray = [...deleteSelectedIds];

      deleteSelectedIds.forEach((deleteItem) => {
        const deleteJob = allData.find(
          (job) => job.job_mstr_key === deleteItem.mstr_key
        );

        if (deleteJob) {
          const matchingJobs = allData.filter(
            (job) => job.comp_plan_name === deleteJob.comp_plan_name
          );
          const chekCompPlanJob = checkCompPlanJobs(matchingJobs);
          if (chekCompPlanJob) {
            const matchingJobsforCompPlan = data.filter(
              (job) =>
                job.comp_plan_name === deleteJob.comp_plan_name &&
                job.job_type_mstr_key === 2
            );
            matchingJobsforCompPlan.forEach((job) => {
              if (
                !finalArray.some((item) => item.mstr_key === job.job_mstr_key)
              ) {
                finalArray.push({
                  mstr_key: job.job_mstr_key,

                  job_delete_flag: 0,
                  jobTypeName: job.job_name,
                });
              }
            });
          }
        }
      });

      return finalArray;
    };

    const finalArray = createFinalArray(allData, deleteSelectedIds);

    let deleteData = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_mstr_key: finalArray,
    };

    // TODO : UNSAVED CHANGES

    // dispatch(
    //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
    // );
    await Action.deleteDataAsync(deleteData);
    //set filter to false

    setDeleteSelectedIds([]);
    setErrorObj({});
    restProps.setSelectedRowId("");
    setSelectedState({});

    restProps.setisJobEdit(false);
  };
  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
    data.map((item) => {
      item.selected = false;
      return 0;
    });
    setData([...data]);
    if (action === "outSideClick") {
      // TODO : UNSAVED CHANGES
      // dispatch(
      //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
      // );
    }
  };

  // const isRowSelected = () => {
  //   const selectedStateVal = Object.values(selectedState);

  //   /**
  //    * consider no row selected if no selected state is true
  //    * the flag is set to false when user deselect any row
  //    */
  //   if (selectedStateVal.length && !selectedStateVal.includes(true)) {
  //     return false;
  //   }

  //   const selectedKeys = Object.keys(selectedState).map((key) => parseInt(key));

  //   let ids = data.map((el) => el.job_mstr_key);

  //   return !selectedKeys.length
  //     ? false
  //     : selectedKeys.every((value) => {
  //       return ids.includes(value);
  //     });
  // };

  const onRowClick = (dataItem) => {
    //restProps.setDataList(data);
    if (restProps.isAPICall === true) {
      restProps.setAPICall(false);
      //return false;
    }
    if (originalData.length <= 0 || data.length <= 0) {
      restProps.setSelectedRowId("");
      return false;
    }
    //default select clicked row
    restProps.setSelectedRowId(dataItem.job_mstr_key);
    // if (!isRowSelected()) {
    //   let newSelectedState = {};
    //   let newData = data.map((item) => ({
    //     ...item,
    //     selected: item.job_mstr_key === dataItem.job_mstr_key ? true : false,
    //   }));
    //   newSelectedState[dataItem.job_mstr_key] = true;
    //   setData(newData);
    //   setSelectedState(newSelectedState);
    //   setDeleteSelectedIds([{ mstr_key: dataItem.job_mstr_key }]);
    // } else {
    //   const newSelectedStateKeys = Object.keys(selectedState);
    //   const selectedKeyArr = newSelectedStateKeys.filter(function (x) {
    //     return selectedState[x] === true;
    //   });
    //   //default selected latest row from all selection
    //   !selectedKeyArr.includes(dataItem.job_mstr_key.toString()) &&
    //     restProps.setSelectedRowId(selectedKeyArr[selectedKeyArr.length - 1]);
    // }
  };

  const setNewSelectedRow = (newState, isReset) => {
    const keys = Object.keys(newState);
    setSelectedState(newState);
    let tempArray = [];
    keys.map((item) => {
      if (newState[item] === false) {
        return 0;
      }
      let obj = {};
      obj.mstr_key = Number(item);
      tempArray.push(obj);
      return 0;
    });
    setDeleteSelectedIds(tempArray);

    const selectedKeyArr = keys.filter(function (x) {
      return newState[x] !== false;
    });

    if (isReset) {
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = selectedKeyArr.includes(item.job_mstr_key.toString());
        return 0;
      });
      const item = originalData.find((it) => it.selected === true);
      setData(originalData);
      restProps.setDataList(originalData);
      debounceForFunc(() => onRowClick(item), 100);
    } else {
      let newData = data.map((item) => ({
        ...item,
        selected: selectedKeyArr.includes(item.job_mstr_key.toString()),
      }));
      const item = newData.find((it) => it.selected === true);
      setData(newData);
      restProps.setDataList(newData);
      debounceForFunc(() => onRowClick(item), 100);
    }
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    // let newKeys = Object.keys(newSelectedState);
    // let oldKeys = Object.keys(selectedState);
    // const isSame = isSameRow(oldKeys, newKeys);
    // TODO : UNSAVED CHANGES

    // if (unsavedPopup.isUnSavedChange && !isSame) {
    //   dispatch(
    //     setUnSavedChangesPopup({
    //       ...unsavedPopup,
    //       isVisible: true,
    //       callbackFn: () => {
    //         setNewSelectedRow(newSelectedState, true);
    //         setErrorObj({});
    //         changeUpdateBtnState("disable");
    //         changeCancelBtnState("disable");
    //       },
    //     })
    //   );
    //   return;
    // }

    setNewSelectedRow(newSelectedState, false);
  };
  const enterEdit = (dataItem, field) => {
    if (restProps.pageProps.writeAccess === 0) {
      return false;
    }

    let newData = data.map((item) => ({
      ...item,
      inEdit: item.job_mstr_key === dataItem.job_mstr_key ? field : undefined,
    }));

    setData(newData);
    restProps.setisJobEdit(true);
  };

  const exitEdit = (dataItem, index, dataIndex, e) => {
    if (dataItem.comp_plan_name === "" || dataItem.job_name === "") {
      handleValidation();
    }
    // let newData = data.map((item) => ({
    //   ...item,
    //   inEdit: undefined
    // }));
    // setData(newData);
  };

  const itemChange = React.useCallback(
    (event) => {
      const field = event.field || "";
      const newData = data.map((item) =>
        item.job_mstr_key === event.dataItem.job_mstr_key
          ? { ...item, [field]: event.value, inIteamEdit: true }
          : item
      );
      let editItem = event.dataItem;
      editItem[field] = event.value;
      setData(newData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data]
  );

  const handlePageChange = async (event) => {
    const pageData = event.page;
    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridData(dataVal);
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort, filterObj) => {
    if (isSort) {
      setSortField(columnMenuData);
    } else {
      setFilterField(filterObj);
    }
    let index = 1;

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let val = columnMenuData[0].field;
      if (columnMenuData[0].field === "job_type_mstr_key") {
        val = columnMenuData[0].field.replace("_mstr_key", "_name");
      }
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === val) + 1;
    }

    let filterString = isFilter
      ? getfilterDataString(columnMenuData, columns)
      : filterData.data;

    // Use a regular expression to find and capture the value inside the single quotes
    const regex = /.job_param = '([^']+)'/;
    const match = filterString.match(regex);
    if (match) {
      // Extract the captured value
      const capturedValue = match[1];
      filterString = filterString.replace(
        `job_param = '${capturedValue}'`,
        `${capturedValue} = 1`
      );
    }
    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);
    getGridData(dataVal);
  };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          sortable={
            column.field === "job_param" || column.field === "job_mstr_key"
              ? false
              : true
          }
          reorderable={column.field === "job_mstr_key" ? false : true}
          filterable={column.field === "job_mstr_key" ? false : true}
          width={column.field === "job_mstr_key" ? "80px" : "180px"}
          resizable={column.field === "job_mstr_key" ? false : true}
          cell={
            column.field === "published_position"
              ? publicAssinCell
              : column.field === "job_name" ||
                column.field === "job_desc" ||
                column.field === "email_body" ||
                column.field === "job_email_notif" ||
                column.field === "email_subject" ||
                column.field === "job_typ_name"
              ? NameCell
              : column.field === "job_mstr_key"
              ? customCell
              : StringReandOnlyCell
          }
          editable={false}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const customCell = React.useCallback((props) => {
    const { dataItem } = props;
    // const jobTypeCode = dataItem.job_type_code;
    // const jobPostion = dataItem.position_param;
    const jobMstrKey = dataItem.job_mstr_key;

    const handleIconClick = async () => {
      if (restProps.pageProps.writeAccess === 0) {
        return false;
      }
      setIsAddJobtoQueue(true);
      setJobMstrKey(jobMstrKey);
      setcompPlanKeys(dataItem.comp_plan_keys);
      setDataItemList(dataItem);
      let newSelectedState = {};

      newSelectedState[jobMstrKey] = true;
      setSelectedState(newSelectedState);
      restProps.setSelectedRowId(jobMstrKey);
      setDeleteSelectedIds([{ mstr_key: jobMstrKey }]);
    };

    return (
      <td className="k-command-cell text-center">
        {dataItem.data_write_access === 1 ? (
          <a onClick={handleIconClick}>
            <img
              src={require("../../images/add_to_queue.png").default}
              title="Add Job to Queue"
              className="pointer-class"
            />{" "}
          </a>
        ) : (
          false
        )}
      </td>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const publicAssinCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;
      if (
        dataItem["job_type_code"] === "email_process" ||
        dataItem["job_type_code"] === "stage_hook"
      ) {
        isInEdit = field === dataItem.inEdit;
      } else {
        isInEdit = false;
      }
      let valueData =
        props.dataItem[props.field] !== ""
          ? props.dataItem[props.field] === 1
            ? "true"
            : "false"
          : "";

      let value = props.field + dataItem.rowId;

      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO : UNSAVED CHANGES
          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
          // );
          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value === true ? 1 : 0,
          });
        }
      };
      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          className="kpi-field"
        >
          {isInEdit ? (
            <Checkbox
              value={dataItem[props.field]}
              checked={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={valueData}>{valueData}</span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

    let val = dataItem[field || ""];

    if (field.indexOf("_mstr_key") !== -1) {
      val = dataItem[field.replace("_mstr_key", "_name") || ""];
    }

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;
      if (dataItem["job_type_name"] === "Email Process") {
        if (
          field === "email_body" ||
          field === "job_email_notif" ||
          field === "email_subject"
        ) {
          isInEdit = field === dataItem.inEdit;
        }
      } else {
        if (
          field === "email_body" ||
          field === "job_email_notif" ||
          field === "email_subject"
        ) {
          isInEdit = false;
        }
      }
      let valueData = dataItem[field || ""];
      if (field.indexOf("_mstr_key") !== -1) {
        valueData = dataItem[field.replace("_mstr_key", "_name") || ""];
      }
      let value = props.field + dataItem.rowId;
      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO : UNSAVED CHANGES
          // dispatch(
          //   setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true })
          // );

          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td
          aria-colindex={ariaColumnIndex}
          data-grid-col-index={columnIndex}
          className="kpi-field"
        >
          {isInEdit ? (
            field === "email_body" ? (
              <textarea
                // rows={3}
                value={dataItem[props.field]}
                onChange={(e) => {
                  handleChange(e, props, value);
                }}
                name={`${props.field}`}
                autoComplete="off"
                className="k-textarea"
              />
            ) : (
              <Input
                value={dataItem[props.field]}
                onChange={(e) => {
                  handleChange(e, props, value);
                }}
                name={`${props.field}`}
                autoComplete="off"
              />
            )
          ) : (
            <span title={valueData}>{valueData}</span>
          )}
          {Object.keys(errorObj).length > 0 && (
            <div role="alert" className="k-form-error k-text-start">
              {errorObj[value]}
            </div>
          )}
        </td>
      );

      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj]
  );

  let GroupColumn = useCol(gridColumns);

  const onClearFilter = () => {
    setAPICallingState(true);
    setFilterField({ logic: "and", filters: [] });

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };
    setResetSort(false);
    getGridData(dataVal);
    restProps.isEngineEdit === true && cancelEnginePhaseChanges();
  };
  const onAddRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: DEFAULT_SORT.number,
      in_sort_order: DEFAULT_SORT.order,
    };
    setResetSort(false);

    getGridData(dataVal);
  };

  const customRowRender = (tr, props) => (
    <RowRender
      originalProps={props}
      tr={tr}
      editField={editField}
      exitEdit={exitEdit}
    />
  );

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);
    if (res) {
      const exportRes = res.map((item) => {
        for (const key in POSTION_PARMAS) {
          if (POSTION_PARMAS.hasOwnProperty(key)) {
            // Check if the key exists in the userData item and its value is 1
            if (item[key] === 1) {
              if (!item.job_param) {
                item.job_param = POSTION_PARMAS[key]; // Set the corresponding key from POSTION_PARMAS
              } else {
                item.job_param += `,${POSTION_PARMAS[key]}`; // Append with a comma separator
              }
            }
          }
        }

        return {
          ...item,
          published_position: item.published_position === 1,
          job_type_mstr_key: item.job_type_name,
        };
      });
      setExportData(exportRes);
    }
  };

  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };


  return (
    <>
      {isRefreshLoading && loadingPanel}
      {gridColumns.length > 0 ? (
        <DataTable
          isAPICalling={isAPICalling}
          initialLoading={initialLoading}
          resetSort={resetSort}
          data={data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          id="job-grid"
          editField={editField}
          pageData={pageData}
          dataCount={dataCount}
          customColumn={GroupColumn}
          columns={gridColumns}
          dataItemKey={DATA_ITEM_KEY}
          width={"auto"}
          module={"job_configuration"}
          getExportData={getExportData}
          setExportData={restProps.setExportDataClick}
          isExportDataClick={restProps.exportDataClick}
          fileName={"Export_job_configuration.xlsx"}
          defaultPageSize={defaultPageSize}
          pageSize={pageSize}
          setPageSize={setPageSize}
          exportData={exportData}
          rowRender={customRowRender}
          cellRender={customCellRender}
          itemChange={itemChange}
          handlePageChange={handlePageChange}
          handleColumnMenu={handleColumnMenu}
          onClearFilter={onClearFilter}
          onSelectionChange={onSelectionChange}
          refreshClick={onRefreshClick}
          reorderable={restProps.pageProps.writeAccess === 0 ? false : true}
          columnReorderData={columnReorderData}
          initialFilter={filterField}
          initialSort={sortField}
        />
      ) : (
        <>{loadingPanel}</>
      )}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        className={"Dialog-Delete"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={
          confirmationPopup.action === "Delete"
            ? "delete-confirm"
            : "unSave-confirm"
        }
      />
      {isAddJobtoQueue ? (
        <>
          <JobDetails
            visible={isAddJobtoQueue}
            setIsAddJobtoQueue={setIsAddJobtoQueue}
            pageProps={restProps}
            dataItemList={dataItemList}
            jobMstrKey={jobMstrKey}
            compPlanKeys={compPlanKeys}
            module={"job_configuration"}
          />
        </>
      ) : (
        false
      )}
    </>
  );
};

export const GridButton = (restProps) => {
  return (
    <>
      <Button type="submit" className="resetcancelbtn" id="grid-incell-cancel">
        Reset & Cancel
      </Button>
      <Button type="submit" className="primarybtn" id="grid-incell-update">
        Save
      </Button>
    </>
  );
};
