// eslint-disable-next-line no-useless-concat
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import {
  GridColumn as Column,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import DataTable from "../../../components/dataTable";
import { Tooltip } from "@progress/kendo-react-tooltip";

import {
  getfilterDataString,
  dateFormateCellForJob,
  formatDate,
} from "../../../utils/utils";
import { getUserCredential } from "../../../lib/cookies";
import { DEFAULT_SORT } from "../../../constants/grid";
import { columnMenuProps } from "../../../components/customColumnMenu";
import { CellRender, RowRender } from "../../../components/renderers";
import { GetSystemConfigData } from "../../../lib/common-service";
import PhaseDataGrid from "./phaseDataGrid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import "./list.css";
import useApi from "./service";
import usePhaseApi from "./processService";
import { socket } from "../../../App";
import { jobexecutionSelector } from "../../../redux/selectors/custom-selector";
import { shallowEqual, useSelector } from "react-redux";
import ErrorDetailLogs from "src/pages/common/errorDetailLogs";
import { useDispatch } from "react-redux";
import AssignPosition from "src/pages/common/assignPosition";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "proc_ctrl_mstr_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
);

export const JobRunLog = (restProps) => {
  const dispatch = useDispatch();
  const cookiesData = getUserCredential();
  const [list, Action] = useApi();
  const [, phaseAction] = usePhaseApi();

  const {
    data,
    columns,
    dataCount,
    setData,
    isAPICalling,
    setAPICallingState,
    initialLoading,
    isRefreshLoading,
    setRefreshLoading,
    isSaveData,
    setSaveData,
  } = list;

  //state to set and get filter, sort number, sort order
  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });

  const [exportData, setExportData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [procCtrlMstrKey, setprocCtrlMstrKey] = React.useState("");
  const [isErrorDetail, setIsErrorDetail] = React.useState(false);
  const [errorObj, setIsErrorObj] = React.useState("");

  const [isJobPublish, setisJobPublish] = React.useState(false);
  const [dataItemList, setDataItemList] = React.useState([]);
  const [isJobPublishStart, setisJobPublishStart] = React.useState(false);
  const [hasRendered, setHasRendered] = React.useState(false);
  const [showReportPublishDialouge, setShowReportPublishDialouge] =
    React.useState(false);

  const [refreshTableKey, setRefreshTableKey] = React.useState({
    refreshData: 0,
  });
  const [isClickAssignPosition, setIsClickAssignPosition] =
    React.useState(false);

  const JobExeucutionreduxData = useSelector(
    jobexecutionSelector,
    shallowEqual
  );
  const { dataProgressPercentage } = JobExeucutionreduxData.DATAPER;

  const apiCallData = {
    in_tenant_id: cookiesData.out_tenant_id,
    in_filter: null,
    in_proc_ctrl_mstr_key: 0,
    in_proc_ctrl_phase_key: 0,
    in_sort_number: 4,
    in_db_role_key: cookiesData?.out_db_role_key,
    in_sort_order: DEFAULT_SORT.order,
  };

  React.useEffect(() => {
    if (isSaveData) {
      setSaveData(false);
      restProps.setisAPICallForReport(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSaveData]);

  React.useEffect(() => {
    restProps.setLogDataList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    // if (restProps.isJobAPI) {
    getGridData(apiCallData);
    // restProps.setIsJobAPI(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const handleJobStatusProgress = async (dataStatus) => {
      if (
        dataStatus.jobStatus === true &&
        cookiesData.out_tenant_id == dataStatus.tenantId
      ) {
        setAPICallingState(true);
        await getGridData(apiCallData);
      }
    };
    socket.on("JOB_KILL_STATUS", handleJobStatusProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Include dependencies in the dependency array

  React.useEffect(() => {
    let previousJobQueueKey = null;
    let FailJobQueueKey = null;
    let hasJobCommentRun = false;
    const handleJobStatusProgress = async (dataStatus) => {
      if (dataStatus.jobStatus !== null) {
        if (
          dataStatus.jobStatus === "Running" &&
          cookiesData.out_tenant_id == dataStatus.tenantId
        ) {
          const newJobQueueKey = dataStatus.jobQueueKey;
          if (newJobQueueKey !== previousJobQueueKey) {
            previousJobQueueKey = newJobQueueKey;
            setAPICallingState(true);
            await getGridData(apiCallData);
          }
        } else {
          if (dataStatus.jobQueueKey !== FailJobQueueKey) {
            hasJobCommentRun = false; // Reset flag when jobQueueKey changes
          }
          if (
            dataStatus.jobComment !== null &&
            dataStatus.jobQueueKey !== FailJobQueueKey &&
            !hasJobCommentRun &&
            cookiesData.out_tenant_id == dataStatus.tenantId
          ) {
            FailJobQueueKey = dataStatus.jobQueueKey;
            hasJobCommentRun = true; // Mark as run
            setAPICallingState(true);
            await getGridData(apiCallData);
          }
        }
      }
    };
    socket.on("JOB_STATUS_PROGRESS", handleJobStatusProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Include dependencies in the dependency array

  React.useEffect(() => {
    let previousprocessCtrlMstrKey = null;

    const handleJobStatusPerProgress = async (dataPer) => {
      const roundedPercentage = Math.floor(dataPer.percentage);
      if (
        roundedPercentage === 100 &&
        cookiesData.out_tenant_id == dataPer.tenantId
      ) {
        var imageDiv = document.querySelector(".k-loading-imagegear");
        if (imageDiv) {
          imageDiv.style.display = "none";
        }
        const newprocessCtrlMstrKey = dataPer.processCtrlMstrKey;
        if (newprocessCtrlMstrKey !== previousprocessCtrlMstrKey) {
          previousprocessCtrlMstrKey = newprocessCtrlMstrKey;
          setAPICallingState(true);
          await getGridData(apiCallData);
        }
      }
    };

    socket.on("SUCCESS_PROGRESS_JOB_PER", handleJobStatusPerProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    let prvPubLoadMstrKey = null;

    const handleJobStatusPerProgress = async (dataPer) => {
      const roundedPercentage = Math.floor(dataPer.processCtrlPer);
      if (roundedPercentage === 100) {
        var imageDiv = document.querySelector(".k-loading-imagegear");
        if (imageDiv) {
          imageDiv.style.display = "none";
        } else {
          imageDiv.style.display = "block";
        }
        const newpubLoadMstrKey = dataPer.pubLoadMstrKey;
        if (
          newpubLoadMstrKey !== prvPubLoadMstrKey &&
          cookiesData.out_tenant_id == dataPer.tenantId
        ) {
          prvPubLoadMstrKey = newpubLoadMstrKey;
          setAPICallingState(true);
          await getGridData(apiCallData);
        }
      }
    };

    socket.on("SUCCESS_PROGRESS_REPORT", handleJobStatusPerProgress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getGridData = async (inParams) => {
    await Action.getSysteamPrefConfig(inParams);
  };

  // React.useEffect(async () => {
  //   if (conditionSatisfiedPer) {
  //     onRefreshClickJobPublish();
  //   }
  // }, [conditionSatisfiedPer]);

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : 4;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });

    const filteredArray = data
      .filter((item) => item.report_status === "Unpublished")
      .map((item) => item.proc_ctrl_mstr_key);
    const isMatched = filteredArray.some((value) => newSelectedState[value]);
    if (!newSelectedState.hasOwnProperty("null") && isMatched) {
      restProps.setIsPublishDisable(false);
      if (document.getElementById("single-button-id")) {
        document.getElementById("single-button-id").onclick = function (e) {
          restProps.setIsPublishDisable(true);
        };
      }

      setSelectedState(newSelectedState);
      let tempArray = [];
      Object.keys(newSelectedState).map((item) => {
        if (newSelectedState[item] === false) {
          return 0;
        }
        let obj = {};
        obj.id = Number(item);
        tempArray.push(obj);
        return 0;
      });
      restProps.setSelectedRowId(tempArray);
    } else {
      setSelectedState({});
      restProps.setSelectedRowId("");
    }
  };

  const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
    let index = 1;
    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }

    const filterString = isFilter
      ? getfilterDataString(columnMenuData, columns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length
        ? filterData.sort_order
        : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length
        ? filterData.in_sort_number
        : 4;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (
      columnMenuData[0] !== undefined &&
      columnMenuData[0].dir !== undefined
    ) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }

    const dataVal = {
      ...apiCallData,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });

    setAPICallingState(true);
    // getGridData(dataVal);
    let logInterval = localStorage.getItem("intervalDays");
    Action.getListDataAsync(dataVal, logInterval);
  };

  const useCol = (columns) => {
    return columns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          sortable={column.field === "publish_button" ? false : true}
          width={140}
          cell={
            column.field === "error_code"
              ? customLinkCell
              : column.field === "publish_button"
              ? PublishButtonCell
              : column.field === "start_time" || column.field === "end_time"
              ? dateFormateCellForJob
              : column.field === "linked_positions"
              ? PostionCell
              : StringReandOnlyCell
          }
          editable={false}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const PostionCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    const getAssignedPosition = async () => {
      setIsClickAssignPosition(true);
      setDataItemList(dataItem);
    };
    let val = dataItem[field || ""];
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        {val === null ? (
          <span title={val}>0</span>
        ) : (
          <span title={val}>
            <a
              title={"View Linked Positions"}
              className="tablelink"
              onClick={(e) => {
                getAssignedPosition(dataItem);
              }}
            >
              {val}
            </a>
          </span>
        )}
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);
  const StringReandOnlyCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field } = props;

    let val = dataItem[field || ""];

    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        <span title={val}>{val}</span>
      </td>
    );

    return defaultRendering;
  }, []);
  const openErrorDetail = (val) => {
    setIsErrorDetail(true);
    setIsErrorObj(val);
    if (val.error_code === 0) {
      setIsErrorDetail(false);
    }
  };

  const publishReport = React.useCallback(async (dataItem) => {
    const bodyData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_comp_plan_mstr_key: dataItem.comp_plan_mstr_key,
    };

    try {
      const resReportDataObject = await Action.getReportLoadDataAsync(bodyData);
      if (
        resReportDataObject.data.out_parameters.out_job_mstr_key === null ||
        resReportDataObject.data.out_parameters.out_job_mstr_key === 0
      ) {
        setRefreshLoading(true);
        setTimeout(() => {
          setShowReportPublishDialouge(true);
          setDataItemList(dataItem);
          setRefreshLoading(false);
        }, 0);
      } else {
        setTimeout(() => {
          setisJobPublish(true);
          setDataItemList(dataItem);
        }, 0);
      }
    } catch (error) {}
  }, []);

  const PublishButtonCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      const val = dataItem[field || ""];

      const canPublish =
        ((dataItem.report_status === "Unpublished" &&
          dataItem.in_process !== 1 &&
          dataItem.error_code === 0) ||
          (!isJobPublishStart &&
            dataItem.proc_ctrl_mstr_key === dataItemList.proc_ctrl_mstr_key)) &&
        dataItem.proc_ctrl_mstr_key !== dataItemList.proc_ctrl_mstr_key;

      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {dataItem.object_type === "Comp Plan" &&
          dataItem.report_status !== "Expired" &&
          dataItem.error_code === 0 ? (
            <span>
              <Button
                id="single-button-id"
                title={"Click here to add this job into queue for publish"}
                className={
                  canPublish
                    ? "primarybtn publishbtn btn-small"
                    : "publishbtn btn-small"
                }
                type="submit"
                disabled={!canPublish}
                onClick={() => publishReport(dataItem)} // Using memoized function
              >
                Publish
              </Button>
            </span>
          ) : null}
        </td>
      );

      return render?.call(undefined, defaultRendering, props);
    },
    [isJobPublishStart, publishReport] // Ensure dependencies are correct
  );

  const onJobPublishConfirm = async () => {
    setisJobPublish(false);
    setisJobPublishStart(true);
    let dataObject = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: "AND system_pref_code = 'sys_time_zone'",
      in_sort_number: null,
      in_sort_order: null,
      in_page_row_offset: null,
      in_page_rows: null,
    };
    const resDataObject = await GetSystemConfigData(dataObject, dispatch);
    const bodyData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_comp_plan_mstr_key: dataItemList.comp_plan_mstr_key,
    };
    const resReportDataObject = await Action.getReportLoadDataAsync(bodyData);

    const addbodyData = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_mstr_key: resReportDataObject.data.out_parameters.out_job_mstr_key,
      in_period_mstr_key: dataItemList.period_mstr_key,
      in_time_interval: "",
      in_comp_plan_keys: null,
      in_position_keys: null,
      in_user_tz: resDataObject[0].pref_value,
      in_batch_name: null,
    };
    Action.addJobQueueDataAsync(addbodyData);
  };

  const customLinkCell = React.useCallback((props) => {
    const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
    let val = dataItem[field || ""];
    const defaultRendering = (
      <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
        {val === 0 ? (
          <span title={val}>{val}</span>
        ) : (
          <Tooltip title="View Error Detail" arrow>
            <span>
              <a
                className="tablelink"
                onClick={(e) => {
                  openErrorDetail(dataItem);
                }}
              >
                {val}
              </a>
            </span>
          </Tooltip>
        )}
      </td>
    );
    return render?.call(undefined, defaultRendering, props);
  }, []);

  let GroupColumn = useCol(columns);

  const onClearFilter = () => {
    setAPICallingState(true);

    //set in_params
    const dataVal = {
      ...apiCallData,
      in_filter: null,
      in_sort_number: 4,
      in_sort_order: getInSortOrder(),
    };

    //set filter to false
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });

    getGridData(dataVal);
  };

  const onRefreshClick = () => {
    setAPICallingState(true);

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: 4,
      in_sort_order: getInSortOrder(),
    };

    getGridData(dataVal);
  };
  // const onRefreshClickJobPublish = () => {
  //   setAPICallingState(true);

  //   const dataVal = {
  //     ...apiCallData,
  //     in_filter: getInFilter(),
  //     in_sort_number: 4,
  //     in_sort_order: getInSortOrder(),
  //   };

  //   getGridData(dataVal);
  // };

  const customRowRender = (tr, props) => (
    <RowRender originalProps={props} tr={tr} />
  );

  const getExportData = async () => {
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
    };

    let res = await Action.exportDataAsync(dataVal);
    if (res) {
      const exportRes = res.map((item) => ({
        ...item,
        start_time: formatDate(item.start_time),
        end_time: item.end_time !== "" ? formatDate(item.end_time) : "",
      }));
      setExportData(exportRes);
    }
  };

  const customCellRender = (td, props) => {
    if (
      props.field == "expanded" &&
      (props.dataItem.job_type_code === "report_publish" ||
        props.dataItem.job_type_code === "email_process")
    ) {
      return <td></td>;
    }
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        // onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };

  const DetailComponent = (props) => {
    const parentDataItem = props.dataItem.phaseDataItem;

    return <>{parentDataItem ? getPhaseDataGrid(parentDataItem) : false}</>;
  };

  const getPhaseDataGrid = (parentDataItem) => {
    return (
      <>
        {parentDataItem ? (
          <>
            <PhaseDataGrid
              pageProps={restProps.pageProps}
              proc_ctrl_mstr_key={procCtrlMstrKey}
              parentDataItem={parentDataItem}
              setRefreshTableKey={setRefreshTableKey}
            />
          </>
        ) : (
          false
        )}
      </>
    );
  };

  const collapseRows = () => {
    const newData = data.filter((d) => d.expanded === true);

    if (newData.length) {
      newData.map((item) => {
        item.expanded = false;
        return 0;
      });
      setData([...data]);
    }
  };

  const getPhaseGridData = async (mstrKey) => {
    setRefreshLoading(true);
    const processApiCallData = {
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: null,
      in_proc_ctrl_mstr_key: mstrKey,
      in_proc_ctrl_phase_key: 0,
      in_sort_number: 7,
      in_db_role_key: cookiesData?.out_db_role_key,
      in_sort_order: DEFAULT_SORT.order,
      in_init: 1,
    };
    let phaseDataResponse = await phaseAction.getProcessListDataAsync(
      processApiCallData
    );
    setRefreshLoading(false);
    return phaseDataResponse;
  };

  const expandChange = async (event) => {
    let mstrKey = event.dataItem.proc_ctrl_mstr_key;

    let masterTableData = data.slice();

    let index = masterTableData.findIndex(
      (d) => d.proc_ctrl_mstr_key === mstrKey
    );

    masterTableData
      .filter((x) => x.expanded === true)
      .map((item) => {
        item.expanded = false;
        return 0;
      });
    setprocCtrlMstrKey(mstrKey);

    if (!event.value || event.dataItem.phaseDataItem) {
      if (event.value === true && refreshTableKey === mstrKey) {
        setRefreshTableKey(0);
      } else {
        masterTableData[index].expanded = event.value;
        masterTableData[index].selected = true;
        setData(masterTableData);
        return;
      }
    }

    let phaseDataItem = await getPhaseGridData(mstrKey);
    masterTableData[index].phaseDataItem = phaseDataItem;
    masterTableData[index].expanded = event.value;
    masterTableData[index].selected = true;
    setData(masterTableData);
  };
  const onJobCancelConfirm = () => {
    setisJobPublish(false);
  };
  const cancelReportPublish = () => {
    setShowReportPublishDialouge(false);
  };

  // Utility function to add delay
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const addReportPublishData = async () => {
    const timeout = (ms) =>
      new Promise((_, reject) =>
        setTimeout(() => reject(new Error("Operation timed out")), ms)
      );

    const bodyData = {
      in_user_id: cookiesData.out_user_id,
      in_tenant_id: cookiesData.out_tenant_id,
      in_job_name: `PUBLISH ${dataItemList.job_name}`,
      in_job_desc: `This is publish job triggered manually for ${dataItemList.job_name}`,
      in_job_type_mstr_key: 2,
      in_comp_plan_mstr_key: dataItemList.comp_plan_mstr_key,
      in_stagehook_job_mstr_key: null,
      in_is_attach_provider_doc: 0,
      in_notification_email: "",
      in_email_subject: "",
      in_email_body: "",
      in_plan_param: 0,
      in_position_param: 1,
      in_stagehook_param: 0,
      in_published_position: 0,
      report_publish_extis: false,
    };

    try {
      // First async action
      await Promise.race([Action.addDataAsync(bodyData), timeout(5000)]);

      await delay(500);

      const dataObject = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_filter: "AND system_pref_code = 'sys_time_zone'",
        in_sort_number: null,
        in_sort_order: null,
        in_page_row_offset: null,
        in_page_rows: null,
      };

      const resDataObject = await GetSystemConfigData(dataObject, dispatch);
      // Delay before next async action
      await delay(500);

      const reportBodyData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_comp_plan_mstr_key: dataItemList.comp_plan_mstr_key,
      };

      // Get report load data
      const resReportDataObject = await Action.getReportLoadDataAsync(
        reportBodyData
      );

      const addBodyData = {
        in_user_id: cookiesData.out_user_id,
        in_tenant_id: cookiesData.out_tenant_id,
        in_job_mstr_key:
          resReportDataObject.data.out_parameters.out_job_mstr_key,
        in_period_mstr_key: dataItemList.period_mstr_key,
        in_time_interval: "",
        in_comp_plan_keys: null,
        in_position_keys: null,
        in_user_tz: resDataObject[0].pref_value,
        in_batch_name: null,
      };
      await delay(500);
      await Action.addJobQueueDataAsync(addBodyData);

      // Close dialog
      setShowReportPublishDialouge(false);
    } catch (error) {}
  };

  return (
    <>
      {isRefreshLoading && <>{loadingPanel}</>}
      {columns.length > 0 ? (
        <DataTable
          isAPICalling={isAPICalling}
          initialLoading={initialLoading}
          data={data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          id="pipeline-grid"
          dataCount={dataCount}
          customColumn={GroupColumn}
          columns={columns}
          dataItemKey={DATA_ITEM_KEY}
          module={"job_configuration"}
          getExportData={getExportData}
          setExportData={restProps.setExportDataClick}
          isExportDataClick={restProps.exportDataClick}
          fileName={"Export_job_log.xlsx"}
          exportData={exportData}
          rowRender={customRowRender}
          cellRender={customCellRender}
          handleColumnMenu={handleColumnMenu}
          onClearFilter={onClearFilter}
          onSelectionChange={onSelectionChange}
          refreshClick={onRefreshClick}
          DetailComponent={DetailComponent}
          expandChange={expandChange}
          collapseRows={collapseRows}
          isPagination={false}
          reorderable={false}
        />
      ) : (
        <>{loadingPanel}</>
      )}
      {isErrorDetail && (
        <ErrorDetailLogs
          visible={isErrorDetail}
          setIsErrorDetail={setIsErrorDetail}
          errorObj={errorObj}
          // gridHeight={100}
          modalHeight={306}
          module={"job"}
        ></ErrorDetailLogs>
      )}
      {isJobPublish ? (
        <Dialog title={"Publish Report"} id="dialog-timeout" width={550}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            <img
              src={require("../../../images/alert.png").default}
              alt="ProCARE"
              style={{ height: "25px" }} // Fixed the height value
            />{" "}
            Are you sure you want to publish the selected unpublished comp plan?
          </p>
          <DialogActionsBar layout="center">
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onJobCancelConfirm}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={onJobPublishConfirm}
            >
              Yes
            </button>
          </DialogActionsBar>{" "}
        </Dialog>
      ) : (
        false
      )}
      {showReportPublishDialouge ? (
        <Dialog title={"Publish Report"} id="dialog-timeout" width={550}>
          <p
            style={{
              margin: "25px",
              textAlign: "center",
            }}
          >
            <img
              src={require("../../../images/alert.png").default}
              alt="ProCARE"
              style={{ height: "25px" }} // Fixed the height value
            />{" "}
            Are you sure you want to publish the selected unpublished comp plan?
            <br />
            <br />
            Apparently report publish job is not exist in the system selected
            comp plan. Do you want to let system create report publish job
          </p>
          <DialogActionsBar layout="center">
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={cancelReportPublish}
            >
              No
            </button>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={addReportPublishData}
            >
              Yes
            </button>
          </DialogActionsBar>{" "}
        </Dialog>
      ) : (
        false
      )}

      {isClickAssignPosition ? (
        <>
          <AssignPosition
            visible={isClickAssignPosition}
            setIsClickAssignPosition={setIsClickAssignPosition}
            pageProps={restProps}
            dataItemList={dataItemList}
            // jobMstrKey={jobMstrKey}
            // compPlanKeys={compPlanKeys}
            // module={"job_configuration"}
          />
        </>
      ) : (
        false
      )}
    </>
  );
};
