// eslint-disable-next-line no-useless-concat
import { Button } from "@progress/kendo-react-buttons";
import { getter } from "@progress/kendo-react-common";
import {
    getSelectedState,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { Input } from "@progress/kendo-react-inputs";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as React from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { columnMenuProps } from "../../components/customColumnMenu";
import DataTable from "../../components/dataTable";
import { DialogComponent } from "../../components/dialog";
import { CellRender, RowRender } from "../../components/renderers";
import {
    DELETE_BTN_ACTIONS,
    CONFIRMATION_BTN_ACTIONS,
} from "../../constants/constants";
import { DEFAULT_SORT } from "../../constants/grid";
import {
    getUserCredential,
    getTenantCookieData,
    removeUserCredential,
    getLocalStorage,
} from "../../lib/cookies";
import {
    removeMessage,
    setUnSavedChangesPopup,
} from "../../redux/actions/setting-actions";
import {
    unSaveChangesPopupSelector,
    userGridSelector,
} from "../../redux/selectors/custom-selector";
import MessageSchema from "../../utils/messageSchema";
import {
    getfilterDataString,
    changeCancelBtnState,
    changeUpdateBtnState,
    executeGetColumnOrderInfo,
    // isSameRow,
} from "../../utils/utils";
import useApi from "./service";
import { CreateAuthCookie, CreateUserLocalStorage } from "src/utils/loginUtils";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { useHistory } from "react-router";
import useColumnReorder from "src/custom-hooks/useColumnReorder";

const editField = "inEdit";
const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "user_key";
const idGetter = getter(DATA_ITEM_KEY);

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

export const UserData = (restProps) => {
//   const history = useHistory();
const processEnv = process.env;
const localStorageData = getLocalStorage(processEnv.REACT_APP_CREDENTIAL_KEY);

    const dispatch = useDispatch();
    const cookiesData = getUserCredential();
    const defaultPageSize = getTenantCookieData("record_per_page");
    const [list, Action] = useApi();
    const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
    const { unsavedPopup } = useSelector(
        unSaveChangesPopupSelector,
        shallowEqual
    );
    const userGridReduxData = useSelector(userGridSelector, shallowEqual);

    const [emaiSendDataItem, setEmaiSendDataItem] = React.useState({});
    const [impersonateDataItem, setImpersonateDataItem] = React.useState({});
    const history = useHistory();
    const {
        data,
        columns,
        dataCount,
        setData,
        setColumns,
        originalData,
        isAPICalling,
        setConfirmationPopup,
        confirmationPopup,
        setAPICallingState,
        initialLoading,
        isRefreshLoading,
        // setRefreshLoading,
    } = list;

    //state to set and get limit, offset
    const [pageData, setPage] = React.useState({
        skip: 0,
        take: defaultPageSize.recordTake,
    });

    const [pageSize, setPageSize] = React.useState(defaultPageSize);


    React.useEffect(() => {
        if (restProps.isAddNewConfig) {
            originalData.map((it) => {
                it.isInEdit = false;
                it.selected = false;
                return 0;
            });
            setData(originalData);
            setErrorObj({});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAddNewConfig, originalData]);

    //state to set and get filter, sort number, sort order
    const [filterData, setFilterData] = React.useState({
        isSort: false,
        isFilter: false,
        data: "",
        in_sort_number: DEFAULT_SORT.number,
        sort_order: DEFAULT_SORT.order,
    });

    const [exportData, setExportData] = React.useState([]);
    const [selectedState, setSelectedState] = React.useState({});
    const [errorObj, setErrorObj] = React.useState({});
    const [gridColumns, setGridColumns] = React.useState([]);

    const apiCallData = {
        in_tenant_id: cookiesData.out_tenant_id,
        in_encrypt_key: cookiesData.out_acct_encrypt_key,
        in_filter: null,
        in_sort_number: DEFAULT_SORT.number,
        in_sort_order: DEFAULT_SORT.order,
        in_page_row_offset: 0,
        in_page_rows: defaultPageSize.recordTake,
        in_load_type: "data-reload",
        in_export_flag: 0,
    };

    if (document.getElementById("grid-incell-cancel")) {
        document.getElementById("grid-incell-cancel").onclick = function (e) {
            dispatch(
                removeMessage({
                    message: "",
                    type: "Error",
                    show: false,
                })
            );
            originalData.map((item) => {
                item.inEdit = false;
                item.selected = false;
                return 0;
            });
            setData(originalData);
            setErrorObj({});
            restProps.setDataList(originalData);

            dispatch(
                setUnSavedChangesPopup({
                    ...unsavedPopup,
                    isUnSavedChange: false,
                })
            );

            //disable in-cell update and cancel button
            e.target.disableKendoBtn();
            changeUpdateBtnState();
        };
    }

    if (document.getElementById("grid-incell-update")) {
        document.getElementById("grid-incell-update").onclick = function (e) {
            handleUpdate();
        };
    }

    React.useEffect(() => {
        restProps.setDataList(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    React.useEffect(() => {
        if (restProps.isAPICall) {
            restProps.setAPICall(false);
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isAPICall]);

    React.useEffect(() => {
        if (list.loadingState.isDeleteData) {
            list.setLoadState({ ...list.loadingState, isDeleteData: false });
            onRefreshClick();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [list.loadingState.isDeleteData]);

    React.useEffect(() => {
        if (restProps.isShow.deleteRows) {
            remove();
            restProps.setShow({ ...restProps.isShow, deleteRows: false });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isShow.deleteRows]);

    React.useEffect(() => {
        if (Object.keys(emaiSendDataItem).length) {
            resendEmail();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [emaiSendDataItem]);

    React.useEffect(() => {
        if (Object.keys(impersonateDataItem).length) {
            impersonateUser();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [impersonateDataItem]);

    React.useEffect(() => {
        if (columns.length > 0) {
            restProps.setDataList(data);
            restProps.setColumnsData(columns);
            setGridColumns(columns);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns]);

    React.useEffect(() => {
        const dataVal = {
            ...apiCallData,
            in_load_type: "grid-reload",
        };
        Action.getListDataAsync(dataVal);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (restProps.isCheckColumn) {
            setGridColumns(restProps.columnsData);
            restProps.setColumnCheck(false);
            columnReorderData(restProps.columnsData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [restProps.isCheckColumn]);

    const getInFilter = () => {
        return filterData.isFilter ? filterData.data : null;
    };

    const getInSortNumber = () => {
        return filterData.isSort
            ? filterData.in_sort_number
            : DEFAULT_SORT.number;
    };

    const getInSortOrder = () => {
        return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
    };

    const getPageTake = () => {
        return pageData.take;
    };

    const getPageSkip = () => {
        return pageData.skip;
    };

    const handleValidation = () => {
        let isValid = true;
        data.filter((x) => x.inIteamEdit === true).map((item) => {
            const {
                user_username,
                user_first_name,
                user_last_name,
                user_email,
                rowId,
            } = item;
            let errorObj = {
                user_username: "",
                user_first_name: "",
                user_last_name: "",
                user_email: "",
            };

            errorObj.user_username =
                user_username.trim() === ""
                    ? MessageSchema.usernameRequired
                    : "";
            errorObj.user_first_name =
                user_first_name.trim() === ""
                    ? MessageSchema.firstnameRequired
                    : "";
            errorObj.user_last_name =
                user_last_name.trim() === ""
                    ? MessageSchema.lastnameRequired
                    : "";
            errorObj.user_email =
                user_email.trim() === "" ? MessageSchema.emailRequired : "";

            if (errorObj.user_email == "") {
                errorObj.user_email =
                    user_email.validateEmail() === null
                        ? MessageSchema.emailValid
                        : "";
            }

            let obj = {};
            for (const [key, value] of Object.entries(errorObj)) {
                if (`${value}` !== "") {
                    obj[`${key + rowId}`] = `${value}`;
                }
            }

            if (Object.keys(obj).length) {
                setErrorObj(obj);

                for (const [, value] of Object.entries(obj)) {
                    if (value !== "") {
                        isValid = false;
                    }
                }
            }
            return 0;
        });
        return isValid;
    };

    const handleUpdate = async () => {
        let valid = handleValidation();
        if (valid) {
            let updatedDataList = [];
            data.filter((x) => x.inIteamEdit === true).map((item) => {
                let obj = {};
                obj.in_user_key = item.user_key;
                obj.in_version = item.version;
                obj.in_user_username = item.user_username;
                obj.in_user_first_name = item.user_first_name;
                obj.in_user_last_name = item.user_last_name;
                obj.in_user_email = item.user_email;
                obj.in_user_comments = item.user_comments;
                obj.in_db_role_key = item.db_role_mstr_key;
                obj.in_status_type_key = item.status_type_mstr_key;
                obj.in_employee_id = item?.employee_id;

                updatedDataList.push(obj);
                return 0;
            });

            const bodyData = {
                updateUser: [...updatedDataList],
            };

            if (updatedDataList.length > 0) {
                let response = await Action.updateUserDataAsync(bodyData);

                if (response) {
                    dispatch(
                        setUnSavedChangesPopup({
                            ...unsavedPopup,
                            isUnSavedChange: false,
                            isVisible: false,
                        })
                    );
                    data.map((item) => {
                        item.inEdit = false;
                        return 0;
                    });

                    setData(data);
                    onRefreshClick();
                    restProps.setUserEdit(false);
                }
            }
        } else {
            const elements = document.getElementsByClassName("k-textbox");
            elements[0] && elements[0].focus();

            changeCancelBtnState("enable");
            changeUpdateBtnState("enable");
        }
    };

    const remove = (ids) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            title: "Delete",
            desc: "Are you sure you want to delete this record?",
            actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
            action: "Delete",
        });
    };

    const resendEmail = () => {
        if (!emaiSendDataItem.user_email) {
            return false;
        }

        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            title: "Resend login credential email",
            desc:
                "Are you sure you want to resend login credential email to " +
                emaiSendDataItem.user_email +
                "?",
            actionBtn: CONFIRMATION_BTN_ACTIONS(
                onCancelConfirm,
                resendLoginCredential
            ),
            width: "550",
            action: "Delete",
        });
    };

    const impersonateUser = () => {
        if (!impersonateDataItem.user_email) {
            return false;
        }
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: true,
            title: "Impersonate User",
            desc:
                "Are you sure you want to impersonate " +
                impersonateDataItem.user_username +
                "?",
            actionBtn: CONFIRMATION_BTN_ACTIONS(
                onCancelConfirm,
                onImpersonateClick
            ),
            width: "550",
            action: "Delete",
        });
    };

    const onDeleteConfirm = (event) => {
        const deleteArray = deleteSelectedIds.map((obj2) => {
            const obj1 = data.find((elem) => elem.user_key === obj2.id);
            return {
                id: obj2.id,
                in_version: obj1?.version,
                userName: obj1?.user_username,
            };
        });
        let deleteData = {
            in_user_id: deleteArray,
        };

        Action.deleteDataAsync(deleteData).then(function (result) {
            if (result === "success") {
                setDeleteSelectedIds([]);
                setSelectedState({})
                restProps.setSelectedRowId("");
                restProps.setColumnsData([])
                // window.location.reload(false);
            }
        });

        dispatch(
            setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false })
        );
        setErrorObj({});
        restProps.setUserEdit(false);
    };

    const setNewSelectedRow = (newState, isReset) => {
        setSelectedState(newState);
        let tempArray = [];

        const keys = Object.keys(newState).filter(
            (key) => newState[key] === true
        );

        keys.map((item) => {
            let obj = {};
            obj.id = Number(item);
            tempArray.push(obj);
            return 0;
        });
        setDeleteSelectedIds(tempArray);
        restProps.setSelectedRowId(tempArray[0].id);
        if (isReset) {
            originalData.map((item) => {
                item.inEdit = false;
                item.selected = Number(item.user_key) === Number(keys[0]);
                return 0;
            });
            setData(originalData);
            restProps.setDataList(originalData);
        } else {
            restProps.setDataList(data);
        }
    };

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });

        setNewSelectedRow(newSelectedState, false);
    };

    const onCancelConfirm = (action) => {
        setConfirmationPopup({
            ...confirmationPopup,
            isVisible: false,
            type: "",
            actionBtn: [],
            data: {},
            action: "",
        });

        if (Object.keys(emaiSendDataItem).length) {
            setEmaiSendDataItem({});
        } else if (Object.keys(impersonateDataItem).length) {
            setImpersonateDataItem({});
        } else {
            data.map((item) => {
                item.selected = false;
                return 0;
            });
            setData([...data]);
        }

        if (action === "outSideClick") {
            dispatch(
                setUnSavedChangesPopup({
                    ...unsavedPopup,
                    isUnSavedChange: true,
                })
            );
        }
    };

    const onRowClick = (dataItem) => {
        let newData = data.map((item) => ({
            ...item,
            selected: item.user_key === dataItem.user_key ? true : false,
        }));
        setData(newData);
        restProps.setSelectedRowId(dataItem.user_key);
        restProps.setDataList(newData);
    };

    const enterEdit = (dataItem, field) => {
        if (restProps.pageProps.writeAccess === 0) {
            return false;
        }

        let newData = data.map((item) => ({
            ...item,
            inEdit: item.user_key === dataItem.user_key ? field : undefined,
        }));

        setData(newData);
    };

    const itemChange = React.useCallback(
        (event) => {
            restProps.setUserEdit(true);
            const field = event.field || "";
            const newData = data.map((item) =>
                item.user_key === event.dataItem.user_key
                    ? { ...item, [field]: event.value, inIteamEdit: true }
                    : item
            );
            let editItem = event.dataItem;
            editItem[field] = event.value;
            setData(newData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [data]
    );

    React.useEffect(()=>{
        if(!deleteSelectedIds.length){
            restProps.setSelectedRowId("")
        }
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteSelectedIds, restProps.selectedRowId])

    const setPageMenuState = (response) => {
        if (!deleteSelectedIds.length) {
            return false;
        }

        let ids = response.map((el) => el.user_key);

        let selectedKeys = [];
        let tempArray = [];
        let tempArrayData = [];

        Object.keys(selectedState).map((item) => {
            let obj = {};

            obj.id = Number(item);
            selectedKeys.push(item);
            tempArray.push(obj);

            return 0;
        });

        ids.map((item) => {
            if (selectedKeys.includes(item.toString())) {
                tempArrayData.push(item);
            }
            return 0;
        });

        let strSelectedKeys = "";

        if (tempArrayData.length > 0) {
            strSelectedKeys = parseInt(selectedKeys[0]);
        }
        restProps.setSelectedRowId(strSelectedKeys);
    };

    const handlePageChange = async (event) => {
        const pageData = event.page;

        setAPICallingState(true);

        setPage({ skip: pageData.skip, take: pageData.take });

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: pageData.skip,
            in_page_rows: pageData.take,
        };

        getGridData(dataVal);
    };

    const handleColumnMenu = (columnMenuData, isFilter, isSort) => {
        let index = 1;
        let filterSkip = getPageSkip();
        let filterTake = getPageTake();

        if (isFilter) {
            filterSkip = 0;
            filterTake = pageSize.recordTake;

            //reset pager when filter applies
            setPage({ skip: filterSkip, take: filterTake });
        }

        if (data.length > 0 && columnMenuData[0] !== undefined) {
            let obj = Object.keys(data[0]);
            let sortNameField = "";

            //replace mstr key with name field only when sorting is applied
            if (
                columnMenuData[0].field &&
                columnMenuData[0].field.indexOf("_mstr_key")
            ) {
                let sortKeyField = columnMenuData[0].field;
                sortNameField = sortKeyField.replace("_mstr_key", "_name");
            }

            const sortOriginField =
                sortNameField !== "" ? sortNameField : columnMenuData[0].field;

            index = obj.findIndex((x) => x === sortOriginField) + 1;
        }

        const filterString = isFilter
            ? getfilterDataString(columnMenuData, gridColumns)
            : filterData.data;

        isFilter = false;
        if (filterString !== "") {
            isFilter = true;
        }

        let sortDir =
            filterData.isSort && columnMenuData.length
                ? filterData.sort_order
                : DEFAULT_SORT.order;
        let sortNumber =
            filterData.isSort && columnMenuData.length
                ? filterData.in_sort_number
                : DEFAULT_SORT.number;

        isSort = !columnMenuData.length ? false : filterData.isSort;

        if (
            columnMenuData[0] !== undefined &&
            columnMenuData[0].dir !== undefined
        ) {
            isSort = true;
            sortDir = columnMenuData[0].dir.toUpperCase();
            sortNumber = index;
        }

        const dataVal = {
            ...apiCallData,
            in_filter: isFilter ? filterString : null,
            in_sort_number: sortNumber,
            in_sort_order: sortDir,
            in_page_row_offset: filterSkip,
            in_page_rows: filterTake,
        };

        setFilterData({
            ...filterData,
            isFilter: isFilter,
            isSort: isSort,
            data: filterString,
            in_sort_number: sortNumber,
            sort_order: sortDir,
        });

        setAPICallingState(true);

        getGridData(dataVal);
    };

    const resendLoginCredential = () => {
        const bodyData = {
            in_user_key: emaiSendDataItem.user_key,
            in_status_type_key: emaiSendDataItem.status_type_mstr_key,
        };

        onCancelConfirm();
        Action.resendEmailAsync(bodyData);
    };

    const onImpersonateClick = async () => {
        const bodyData = {
            in_username: impersonateDataItem.user_username,
        };

        //close popup
        onCancelConfirm();

        const response = await Action.impersonateAsync(bodyData);
        response.out_origin_user_first_name = cookiesData?.out_user_first_name;
        response.out_origin_user_last_name = cookiesData?.out_user_last_name;

        if (response?.out_secret_token) {
            //set impersonate flag
            response.is_impersonate = true;

            //clear cookie
            removeUserCredential();
            //set new cookie
            CreateAuthCookie(response);

            //redirection
            switch (response.out_credential) {
                case "MFA_ACCESS":
                    window.location.href = "/otp";
                    break;
                case "CHANGE_PWD_ACCESS":
                    window.location.href = "/change-password";
                    break;
                case "AUTH_ACCESS":
                    window.location.href = "/" + response.out_db_obj_code;
                    break;
                default:
                    window.location.href = "/authentication";
                    break;
            }
        }
    };

    const StatusTypeCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;

            let val = dataItem[field || ""];
            let text = "";

            const nameField = field.replace("_mstr_key", "_name");
            if (field.indexOf("_mstr_key") !== -1) {
                text = dataItem[nameField || ""].toLowerCase();
            }

            let isInEdit = field === dataItem.inEdit;

            const { userStatusData } = userGridReduxData.userStatusData;
            let filteredStatus = userStatusData;

            const elem1 = ["active", "inactive", "new"];
            const elem2 = ["email sent", "suspended"];

            if (elem1.includes(text)) {
                filteredStatus = userStatusData.filter((e) =>
                    elem1.includes(e.text.toLowerCase())
                );
            }

            if (elem2.includes(text)) {
                filteredStatus = userStatusData.filter((e) =>
                    [text, "new"].includes(e.text.toLowerCase())
                );
            }

            if (text.toLowerCase() === "locked") {
                filteredStatus = userStatusData.filter((e) =>
                    [text, "active", "new"].includes(e.text.toLowerCase())
                );
            }

            const handleChange = (e) => {
                if (props.onChange) {
                    dispatch(
                        setUnSavedChangesPopup({
                            ...unsavedPopup,
                            isUnSavedChange: true,
                        })
                    );
                    const valueObj = e.target.value;

                    props.onChange({
                        dataIndex: 0,
                        dataItem: dataItem,
                        field: field,
                        syntheticEvent: e.syntheticEvent,
                        value: valueObj.value,
                    });

                    changeUpdateBtnState("enable");

                    setTimeout(function () {
                        props.onChange({
                            dataItem: dataItem,
                            field: nameField,
                            syntheticEvent: e.syntheticEvent,
                            value: valueObj.text,
                        });
                    }, 500);
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    <div className={"status " + text}>
                        {isInEdit ? (
                            <DropDownList
                                name={`${props.field}`}
                                onChange={handleChange}
                                value={filteredStatus.find(
                                    (c) => c.value === val
                                )}
                                data={filteredStatus}
                                textField="text"
                                autoClose={false}
                            />
                        ) : (
                            <span title={text}>
                                {text}
                                {text === "email sent" ? (
                                    <span
                                        className="k-icon k-i-reset"
                                        title="Resend Login Credential"
                                        onClick={(e) => {
                                            setEmaiSendDataItem(dataItem);
                                        }}
                                    ></span>
                                ) : (
                                    ""
                                )}
                            </span>
                        )}
                        {Object.keys(errorObj).length > 0 ? (
                            <div
                                role="alert"
                                className="k-form-error k-text-start"
                            >
                                {Object.keys(errorObj).length > 0
                                    ? errorObj[val]
                                    : ""}
                            </div>
                        ) : (
                            false
                        )}
                    </div>
                </td>
            );

            return render?.call(undefined, defaultRendering, props);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj, userGridReduxData.userStatusData]
    );

    const DbRoleCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;

            let val = dataItem[field || ""];
            let text = "";

            const nameField = field.replace("_mstr_key", "_name");
            if (field.indexOf("_mstr_key") !== -1) {
                text = dataItem[nameField || ""];
            }

            let isInEdit = field === dataItem.inEdit;

            const { roleData } = userGridReduxData.roleData;

            let filteredRole = roleData.filter(
                (e) => e.text.toLowerCase() !== "select all"
            );

            const handleChange = (e) => {
                if (props.onChange) {
                    const valueObj = e.target.value;
                    dispatch(
                        setUnSavedChangesPopup({
                            ...unsavedPopup,
                            isUnSavedChange: true,
                        })
                    );

                    props.onChange({
                        dataIndex: 0,
                        dataItem: dataItem,
                        field: field,
                        syntheticEvent: e.syntheticEvent,
                        value: valueObj.value,
                    });

                    changeUpdateBtnState("enable");

                    setTimeout(function () {
                        props.onChange({
                            dataItem: dataItem,
                            field: "db_role_desc",
                            syntheticEvent: e.syntheticEvent,
                            value: valueObj.role_desc,
                        });
                    }, 200);

                    setTimeout(function () {
                        props.onChange({
                            dataItem: dataItem,
                            field: nameField,
                            syntheticEvent: e.syntheticEvent,
                            value: valueObj.text,
                        });
                    }, 500);
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <DropDownList
                            name={`${props.field}`}
                            onChange={handleChange}
                            value={filteredRole.find((c) => c.value === val)}
                            data={filteredRole}
                            textField="text"
                            autoClose={false}
                        />
                    ) : (
                        <span title={text}>{text}</span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[val]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );

            return render?.call(undefined, defaultRendering, props);
        },
           // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj, userGridReduxData.roleData]
    );

    const BooleanReandOnlyCell = React.useCallback((props) => {
        const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

        let val =
            dataItem[field] !== ""
                ? dataItem[field] === 1
                    ? "True"
                    : "False"
                : "";

        const defaultRendering = (
            <td
                aria-colindex={ariaColumnIndex}
                data-grid-col-index={columnIndex}
            >
                <span title={val}>{val}</span>
            </td>
        );

        return render?.call(undefined, defaultRendering, props);
    }, []);

    const NameCell = React.useCallback(
        (props) => {
            const { ariaColumnIndex, columnIndex, dataItem, field, render } =
                props;
            const nonEditFld = ["db_role_desc", "comp_plan_name", "group_name"];

            let isInEdit =
                field === dataItem.inEdit && !nonEditFld.includes(field);

            let value = field + dataItem.rowId;
            const handleChange = (e, props, value) => {
                if (props.onChange) {
                    dispatch(
                        setUnSavedChangesPopup({
                            ...unsavedPopup,
                            isUnSavedChange: true,
                        })
                    );
                    props.onChange({
                        dataItem: props.dataItem,
                        field: props.field,
                        syntheticEvent: e.syntheticEvent,
                        value: e.target.value,
                    });
                }
            };

            const defaultRendering = (
                <td
                    aria-colindex={ariaColumnIndex}
                    data-grid-col-index={columnIndex}
                >
                    {isInEdit ? (
                        <Input
                            // required
                            value={dataItem[props.field]}
                            onChange={(e) => {
                                handleChange(e, props, value);
                            }}
                            name={`${props.field}`}
                            autoComplete="off"
                        />
                    ) : (
                        <span title={props.dataItem[props.field || ""]}>
                            {field === "user_username" &&
                                dataItem.status_type_name === "active" &&
                                ["prv1", "bgm1"].includes(
                                    dataItem.db_role_type_abbr
                                ) ? (
                                <i
                                    className="fas cursor-pointer fa-universal-access fa-xl"
                                    title="Impersonate"
                                    onClick={(e) => {
                                        setImpersonateDataItem(dataItem);
                                    }}
                                >
                                    &nbsp;
                                </i>
                            ) : (
                                ""
                            )}
                            {props.dataItem[props.field || ""]}
                        </span>
                    )}
                    {Object.keys(errorObj).length > 0 ? (
                        <div role="alert" className="k-form-error k-text-start">
                            {Object.keys(errorObj).length > 0
                                ? errorObj[value]
                                : ""}
                        </div>
                    ) : (
                        false
                    )}
                </td>
            );
            return render?.call(undefined, defaultRendering, props);
        },
     // eslint-disable-next-line react-hooks/exhaustive-deps
        [errorObj]
    );

    const cellType = (fieldName) => {
        switch (fieldName) {
            case "status_type_mstr_key":
                return StatusTypeCell;
            case "db_role_mstr_key":
                return DbRoleCell;
            case "bg_assigned":
                return BooleanReandOnlyCell;
            default:
                return NameCell;
        }
    };

    const useCol = (columns) => {
        return columns.map((column, idx) => {
            return column.show && column.title !== "" ? (
                <Column
                    {...columnMenuProps(column)}
                    key={idx}
                    cell={cellType(column.field)}
                    sortable={true}
                    width={180}
                />
            ) : (
                false
            );
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    let GroupColumn = useCol(gridColumns);

    const onClearFilter = async () => {
        setAPICallingState(true);

        //set in_params
        const dataVal = {
            ...apiCallData,
            in_filter: null,
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };

        //set filter to false
        setFilterData({
            ...filterData,
            data: "",
            isFilter: false,
        });

        getGridData(dataVal);
    };

    const getGridData = async (dataVal) => {
        let response = await Action.getListDataAsync(dataVal);

        restProps.setDataList(response);
        setPageMenuState(response);
        changeCancelBtnState();
        changeUpdateBtnState();
        setErrorObj({});
    };

    const onRefreshClick = () => {
        setAPICallingState(true);

        const dataVal = {
            ...apiCallData,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_page_row_offset: getPageSkip(),
            in_page_rows: getPageTake(),
        };
        getGridData(dataVal);
    };

    const exitEdit = (dataItem, index, dataIndex, e) => {
        handleValidation();
        // let newData = data.map((item) => ({
        //     ...item,
        //     inEdit: undefined
        //   }));
        //   setData(newData);
    };

    const customRowRender = (tr, props) => (
        <RowRender
            originalProps={props}
            tr={tr}
            editField={editField}
            exitEdit={exitEdit}
        />
    );

    const getExportData = async () => {
        const dataVal = {
            ...apiCallData,
            in_page_row_offset: 0,
            in_page_rows: dataCount,
            in_filter: getInFilter(),
            in_sort_number: getInSortNumber(),
            in_sort_order: getInSortOrder(),
            in_export_flag: 1,
        };

        let res = await Action.exportDataAsync(dataVal);

        if (res) {
            const exportRes = res.map((item) => ({
                ...item,
                bg_assigned: item.bg_assigned === 1,
            }));
            setExportData(exportRes);
        }
    };

    const customCellRender = (td, props) => {
        return (
            <CellRender
                navigatable={true}
                originalProps={props}
                td={td}
                enterEdit={enterEdit}
                editField={editField}
                onRowClick={onRowClick}
                updateActBtn="grid-incell-update"
                cancelActBtn="grid-incell-cancel"
            />
        );
    };


    const { columnReorderData } = useColumnReorder(
        cookiesData,
        setColumns,
        "",
        "procare_admin"
    );
    
    return (
        <>
            {isRefreshLoading && loadingPanel}
            {columns.length > 0 ? (
                <DataTable
                    isAPICalling={isAPICalling}
                    initialLoading={initialLoading}
                    data={data.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    id="user-grid"
                    editField={editField}
                    pageData={pageData}
                    dataCount={dataCount}
                    customColumn={GroupColumn}
                    columns={gridColumns}
                    dataItemKey={DATA_ITEM_KEY}
                    width={"auto"}
                    height={"400px"}
                    module={"user"}
                    getExportData={getExportData}
                    setExportData={restProps.setExportDataClick}
                    isExportDataClick={restProps.exportDataClick}
                    fileName={"Export_user.xlsx"}
                    exportData={exportData}
                    rowRender={customRowRender}
                    cellRender={customCellRender}
                    itemChange={itemChange}
                    reorderable={true}
                    defaultPageSize={defaultPageSize}
                    pageSize={pageSize}
                    setPageSize={setPageSize}
                    handleColumnMenu={handleColumnMenu}
                    handlePageChange={handlePageChange}
                    refreshClick={onRefreshClick}
                    onClearFilter={onClearFilter}
                    onSelectionChange={onSelectionChange}
                    columnReorderData={columnReorderData}
                />
            ) : (
                <>{loadingPanel}</>
            )}
            <DialogComponent
                title={confirmationPopup.title}
                onCancelConfirm={onCancelConfirm}
                width={confirmationPopup?.width || "450"}
                height={"auto"}
                className={confirmationPopup.width ? "" : "Dialog-Delete"}
                desc={confirmationPopup.desc}
                visible={confirmationPopup.isVisible}
                actionBtn={confirmationPopup.actionBtn}
                titleClassName={
                    confirmationPopup.action === "Delete"
                        ? "delete-confirm"
                        : "unSave-confirm"
                }
            />
        </>
    );
};

export const GridButton = (restProps) => {
    return (
        <>
            <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
                Cancel
            </Button>
            <Button
                type="submit"
                className="primarybtn"
                id="grid-incell-update"
            >
                Save
            </Button>
        </>
    );
};
