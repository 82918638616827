// eslint-disable-next-line no-useless-concat
import * as React from "react";
import { GridColumn as Column, getSelectedState } from "@progress/kendo-react-grid";
import {
  GET_ROLE_OBJ_TABLE_ASSIGNMENT_ROLE,
  UPDATE_ROLE_OBJ_TABLE_ASSIGNMENT,
  GET_DB_ROLE_TABLE_ASSIGNMENT,
  INSERT_DB_ROLE_TABLE_ASSIGNMENT,
  GET_DB_ROLE_TYPE_KEY_LIST,
} from "../../api-setup/api-endpoints";
import { generateHeader, POSTAPI, GETAPI } from "../../api-setup/api-helper";
import { DialogComponent } from "../../components/dialog";
import { Dialog, DialogActionsBar, Window, WindowActionsBar } from "@progress/kendo-react-dialogs";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { columnMenuProps } from "../../components/customColumnMenu";
import { DEFAULT_SORT } from "../../constants/grid";
import DataTable from "../../components/dataTable";
import { executeGetColumnOrderInfo, getfilterDataString } from "../../utils/utils";
import { changeCancelBtnState, changeUpdateBtnState } from "../../utils/utils";
import {
  removeMessage,
  setNewMessage,
  // setUnSavedChangesPopup,
} from "../../redux/actions/setting-actions";
import { useDispatch } from "react-redux";
import useCustomRoleAPI from "./service";
import { Checkbox, Input } from "@progress/kendo-react-inputs";
import { DELETE_BTN_ACTIONS } from "../../constants/constants";
import { getUserCredential, getTenantCookieData } from "../../lib/cookies";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";
import { HeaderPage } from "../base/headerPage";
import { LoadingPanel } from "src/components/loadingPanel";
import { getter } from "@progress/kendo-data-query";
import { CellRender, RowRender } from "src/components/renderers";
// import { unSaveChangesPopupSelector } from "src/redux/selectors/custom-selector";
import { updateColumnReorderAsync } from "src/lib/common-service";
import { useHistory } from "react-router";
import useColumnReorder from "src/custom-hooks/useColumnReorder";

const DATA_ITEM_KEY = "db_role_key";
const editField = "inEdit";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);


const ModalTitle = () => {
  return <span>Role Assignments</span>;
};

function checkParentAccess(array, acess) {
  for (let i = 0; i < array.length; i++) {
    if (array[i][acess] !== 1) {
      return false;
    }
  }
  return true;
}

function compareArrays(array1, array2) {
  // Check if arrays have the same length
  if (array1.length !== array2.length) {
    return false;
  }

  // Loop through each object in both arrays
  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];
    const obj2 = array2[i];

    // Compare properties that should be the same
    if (
      obj1.src_mstr_key !== obj2.src_mstr_key ||
      obj1.src_name !== obj2.src_name ||
      obj1.db_role_obj_table_assign_mstr_key !== obj2.db_role_obj_table_assign_mstr_key
    ) {
      return false;
    }

    // Compare access properties
    if (
      obj1.filter_access !== obj2.filter_access ||
      obj1.read_access !== obj2.read_access ||
      obj1.write_access !== obj2.write_access
    ) {
      return false;
    }
  }
  return true;
}

let objectsAreSame = [];
function checkSameObject(x, y) {
  if (x.length > 0 && y.length > 0) {
    for (let val in y) {
      if (
        x[val] &&
        y[val] &&
        (x[val].read_access !== y[val].read_access || x[val].write_access !== y[val].write_access)
      ) {
        if (objectsAreSame.length > 0) {
          let findIndex = objectsAreSame.findIndex((item) => item.db_obj_key === x[val].db_obj_key);
          if (findIndex === -1) {
            objectsAreSame.push(x[val]);
          } else {
            objectsAreSame[findIndex] = x[val];
          }
        } else {
          objectsAreSame.push(x[val]);
        }
      }
    }
  }
  return objectsAreSame;
}

// let datObjectsAreSame = [];
function checkSameDataObject(array1, array2) {
  const newArray = [];

  // Loop through array1
  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];

    // Loop through array2 to find a matching object based on src_mstr_key
    const obj2 = array2.find((obj) => obj.src_mstr_key === obj1.src_mstr_key);

    if (obj2) {
      // If object is found in array2, compare read_access, filter_access, and write_access
      if (
        obj1.read_access === obj2.read_access &&
        obj1.filter_access === obj2.filter_access &&
        obj1.write_access === obj2.write_access
      ) {
        continue; // Values are the same, move on to next object
      }
    }

    // If no matching object is found in array2 or values are different, add object to newArray
    newArray.push(obj1);
  }

  return newArray;
}
function checkSameDataObjectRole(array1, array2) {
  const newArray = [];

  for (let i = 0; i < array1.length; i++) {
    const obj1 = array1[i];

    const obj2 = array2.find((obj) => obj.db_obj_key === obj1.db_obj_key);

    if (obj2) {
      if (obj1.read_access === obj2.read_access && obj1.write_access === obj2.write_access) {
        continue; // Values are the same, move on to next object
      }
    }
    newArray.push(obj1);
  }

  return newArray;
}
export const RoleTableData = (restProps) => {
  const dispatch = useDispatch();
  // const { unsavedPopup } = useSelector(unSaveChangesPopupSelector, shallowEqual);
  const [errorObj, setErrorObj] = React.useState({});
  const [list, Action] = useCustomRoleAPI();
  const [roleAssignMentData, setRoleAssignMentData] = React.useState([]);
  const [roleAssignMentList, setRoleAssignMentList] = React.useState([]);
  const [deleteSelectedIds, setDeleteSelectedIds] = React.useState([]);
  const [rolemstrKey, setRolemstrKey] = React.useState();
  const [dbObjKey, setdbObjKey] = React.useState();
  const [isControlAcess, setIsControlAcess] = React.useState(false);
  const [originalroleData, setOriginalrolelData] = React.useState([]);
  const [controlData, setControlData] = React.useState([]);
  const [originalcontrolData, setOriginalcontrolData] = React.useState([]);
  const [isDataAcess, setIsDataAccess] = React.useState(false);
  const [dataAccessData, setDataAccessData] = React.useState([]);
  const [originalDataAccessData, setOriginalDataAccessData] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState({});
  const [objTypeList, setObjKeyList] = React.useState([]);
  const [roleTypeKeyList, setRoleTypeKeyList] = React.useState([]);
  const [controlAccessTitle, setcontrolAccessTitle] = React.useState("");
  const [dataAccessWriteAccess, setdataAccessWriteAccess] = React.useState(0);
  const [isChecked, setIsChecked] = React.useState(false);
  const [isWriteChecked, setWriteIsChecked] = React.useState(false);
  const [isFilterChecked, setFilterIsChecked] = React.useState(false);
  const [isUpdateClick, setIsUpdateClick] = React.useState(false);
  const [isLandingPage, setIsLandingPage] = React.useState(false);
  const [landingPageText, setLandingPageText] = React.useState("");
  const [isChange, setIsChange] = React.useState(false);
  const [gridColumns, setGridColumns] = React.useState([]);
  const [openPopUp, setOpenPopup] = React.useState(false);
  const defaultPageSize = getTenantCookieData("record_per_page");
  const [loadingState, setLoadingState] = React.useState({
    isEditRoleLoading: false,
  });
  const [filterField, setFilterField] = React.useState({ logic: "and", filters: [] })
  const [sortField, setSortField] = React.useState([])
  const [dataItem, setDataItem] = React.useState({});
  const [resetSort, setResetSort] = React.useState(false);
  const history = useHistory();
  const {
    data,
    columns,
    dataCount,
    setData,
    setColumns,
    originalData,
    isAPICalling,
    setConfirmationPopup,
    confirmationPopup,
    setEditData,
    setAPICallingState,
    initialLoading,
    editData,
    isError,
    isRefreshLoading,
    noDataFound
  } = list;
  //state to set and get limit, offset
  const [pageData, setPage] = React.useState({
    skip: 0,
    take: defaultPageSize.recordTake,
  });

  const [pageSize, setPageSize] = React.useState(defaultPageSize);

  const [filterData, setFilterData] = React.useState({
    isSort: false,
    isFilter: false,
    data: "",
    in_sort_number: DEFAULT_SORT.number,
    sort_order: DEFAULT_SORT.order,
  });
  const [isControlAcessButtonClick, setIsControlAcessButtonClick] = React.useState(true);
  const [isDataAcessButtonClick, setIsDataAcessButtonClick] = React.useState(true);
  const [modal, setModal] = React.useState(true)
  const [stageChange, setStateChange] = React.useState('DEFAULT')
  const cookiesData = getUserCredential();
  const loggedInUserRoleKey = cookiesData?.out_db_role_key
    ? cookiesData.out_db_role_key
    : "";
  let apiCallData = {
    in_sort_number: 1,
    in_sort_order: "DESC",
    in_page_row_offset: 0,
    in_page_rows: defaultPageSize.recordTake,
    in_user_role_filter: ">0",
    in_load_type: "data-reload",
  };

  if (document.getElementById("grid-incell-cancel")) {
    document.getElementById("grid-incell-cancel").onclick = function (e) {
      dispatch(
        removeMessage({
          message: "",
          type: "Error",
          show: false,
        })
      );
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = false;
        return 0;
      });
      setData(originalData);
      // restProps.setSelectedRowId("");
      setErrorObj({});
      restProps.setDataList(originalData);
      handleRoleAssignCancel();
      setIsUpdateClick(false);
      changeCancelBtnState();
      changeUpdateBtnState();
    };
  }

  // TODO: UNSAVED CHANGES
  // React.useEffect(() => {
  //   if (unsavedPopup.isUnSavedChange) {
  //     setDoubleClickId("")
  //   }
  // }, [unsavedPopup])


  if (document.getElementById("grid-incell-update")) {
    document.getElementById("grid-incell-update").onclick = function (e) {
      updateData();
    };
  }

  React.useEffect(() => {
    restProps.setDataList(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  React.useEffect(() => {
    if (restProps.saveClick) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.saveClick]);

  React.useEffect(() => {
    if (restProps.isCheckColumn) {
      setColumns(restProps.columnsData);
      restProps.setColumnCheck(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isCheckColumn]);

  React.useEffect(() => {
    if (restProps.isShow.deleteRows) {
      remove();
      restProps.setShow({ ...restProps.isShow, deleteRows: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.isShow.deleteRows]);

  React.useEffect(() => {
    if (restProps.loadingState.isDeleteRow) {
      remove();
      restProps.setLoadingState({
        ...restProps.loadingState,
        isDeleteRow: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.loadingState.isDeleteRow]);

  React.useEffect(() => {
    if (restProps.cancelClick) {
      cancelData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restProps.cancelClick]);

  // React.useEffect(() => {
  //   if (restProps.isFilterClick) {
  //     restProps.setfilterArray(filterArray);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [restProps.isFilterClick]);

  // React.useEffect(() => {
  //   if (restProps.isShowQuery && restProps.filterApiData !== undefined) {
  //     let filterSkip = getPageSkip();
  //     let filterTake = getPageTake();
  //     filterSkip = 0;
  //     filterTake = pageSize.recordTake;

  //     //reset pager when filter applies
  //     setPage({ skip: filterSkip, take: filterTake });

  //     const dataVal = {
  //       ...restProps.filterApiData,
  //       in_filter:
  //         restProps.filterApiData !== "" && restProps.filterApiData !== undefined
  //           ? restProps.filterApiData.in_filter
  //           : null,
  //       in_sort_number: getInSortNumber(),
  //       in_sort_order: getInSortOrder(),
  //       in_page_row_offset: filterSkip,
  //       in_page_rows: filterTake,
  //     };

  //     getGridData(dataVal);
  //     restProps.setisShowQuery(false);
  //     restProps.setSelectedRowId("");
  //     setIsChange(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [restProps.isShowQuery, restProps.filterApiData]);

  React.useEffect(() => {
    if (columns.length > 0) {
      restProps.setColumnsData(columns);
      setGridColumns(columns);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns]);

  React.useEffect(() => {
    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
      in_load_type: "grid-reload",
    };
    Action.getRoleList(dataVal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRoleAssignCancel = () => {
    const updatedRoleAssignMentData = roleAssignMentData.map((roleAssignMentItem) => {
      const matchingOriginalRoleItem = originalroleData.find(
        (originalRoleItem) => originalRoleItem.db_obj_key === roleAssignMentItem.db_obj_key
      );

      if (matchingOriginalRoleItem) {
        return {
          ...roleAssignMentItem,
          write_access: matchingOriginalRoleItem.write_access,
          read_access: matchingOriginalRoleItem.read_access,
          childData: roleAssignMentItem.childData.map((childRoleAssignMentItem) => {
            const matchingChildOriginalRoleItem = matchingOriginalRoleItem.childData.find(
              (childOriginalRoleItem) =>
                childOriginalRoleItem.db_obj_key === childRoleAssignMentItem.db_obj_key
            );

            if (matchingChildOriginalRoleItem) {
              return {
                ...childRoleAssignMentItem,
                write_access: matchingChildOriginalRoleItem.write_access,
                read_access: matchingChildOriginalRoleItem.read_access,
              };
            } else {
              return childRoleAssignMentItem;
            }
          }),
        };
      } else {
        return roleAssignMentItem;
      }
    });

    setRoleAssignMentData(updatedRoleAssignMentData);
  };

  const getInFilter = () => {
    return filterData.isFilter ? filterData.data : null;
  };

  const getInSortNumber = () => {
    return filterData.isSort ? filterData.in_sort_number : DEFAULT_SORT.number;
  };

  const getInSortOrder = () => {
    return filterData.isSort ? filterData.sort_order : DEFAULT_SORT.order;
  };

  const getPageTake = () => {
    return pageData.take;
  };

  const getPageSkip = () => {
    return pageData.skip;
  };

  const handleSubmit = async () => {
    let dataVal = {
      ...apiCallData,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
    };
    // if (restProps.filterApiData !== "" && restProps.filterApiData !== undefined) {
    //   dataVal = {
    //     ...restProps.filterApiData,
    //     in_sort_number: getInSortNumber(),
    //     in_sort_order: getInSortOrder(),
    //     in_page_row_offset: getPageSkip(),
    //     in_page_rows: getPageTake(),
    //   };
    // }

    let res = await Action.addDataAsync(restProps.roleDataObj, dataVal);
    if (res) {
      restProps.setSelectedRowId("");
      restProps.setsaveClick(false);
      setDeleteSelectedIds([])
      setSelectedState({})
      const reqData = {
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: getPageSkip(),
        in_page_rows: getPageTake(),
        in_user_role_filter: ">0"
      };
      let response = await Action.getRoleList(reqData);
      if (response) {
        if (!filterData.isFilter) {
          let ids = response.map((el) => el.db_role_key);
          setSelectedState({ [ids[0]]: true })
          restProps.setSelectedRowId(ids[0]);
          setDeleteSelectedIds([{ id: ids[0] }])
        }
        if (filterData.isFilter) {
          let ids = response.map((el) => el.db_role_key);
          if (ids.length > 0) {
            setSelectedState({ [ids[0]]: true })
            restProps.setSelectedRowId(ids[0]);
            setDeleteSelectedIds([{ id: ids[0] }])
            setPageMenuState(response)
          }else{
            setPageMenuState(response)
          }
        }
        changeCancelBtnState();
        changeUpdateBtnState();
        restProps.setDataList(response);
        setErrorObj({});
        setIsUpdateClick(false);
      }
    } else {
      restProps.setsaveClick(false)
    }
  };



  const handleLandingPageDilouge = () => {
    setIsLandingPage(!isLandingPage);
  };

  // const onUnsavedChanges = () => {
  //   restProps.setbuttonClick(true);
  //   restProps.setcancelbuttonClick(true);

  //   if (data.filter((x) => x.db_role_key === undefined).length > 0) {
  //     discard();
  //   } else {
  //     let newData = originalData.map((item) =>
  //       item.db_role_key === editData.db_role_key
  //         ? { ...item, inEdit: false }
  //         : item.db_role_key === restProps.selectedRowId
  //           ? { ...item, selected: true }
  //           : { ...item, inEdit: false }
  //     );
  //     if (editData.db_role_key === undefined) {
  //       discard();
  //     } else {
  //       setData(newData);
  //     }
  //   }
  //   setEditData("");
  //   setErrorObj("");
  //   setConfirmationPopup({
  //     ...confirmationPopup,
  //     isVisible: false,
  //     type: "",
  //     actionBtn: [],
  //     data: {},
  //     action: "",
  //   });
  // };

  const remove = (ids) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: true,
      type: "DELETE_CONFIRM",
      actionBtn: DELETE_BTN_ACTIONS(onCancelConfirm, onDeleteConfirm),
      data: [],
      action: "Delete",
    });
  };

  function findChildData(a) {
    const newarray = [];
    roleAssignMentData.forEach((obj) => {
      const matchingChildData = obj.childData.filter((childObj) => childObj.db_obj_name === a);
      if (matchingChildData.length > 0) {
        newarray.push(...matchingChildData);
      }
    });
    return newarray;
  }

  function checkProperty(array, propertyName, propertyValue) {
    for (let i = 0; i < array.length; i++) {
      if (array[i][propertyName] === propertyValue) {
        return true;
      }
    }
    return false;
  }

  function compareEditObjects(a, b) {
    const keysToIgnore = ["selected", "isValidateRow", "expanded"];
    for (const [key, valueA] of Object.entries(a)) {
      if (!keysToIgnore.includes(key)) {
        const valueB = b[key];
        if (valueA !== valueB) {
          return true;
        }
      }
    }

    return false;
  }

  const updateData = async () => {
    if (isUpdateClick) {
      const editableData = originalData.filter(
        (item) => item.db_role_key === editData.db_role_key
      );
      const result = compareEditObjects(editableData[0], editData);
      let valid = handleValidation(editData);
      if (result && valid) {
        let tempArray = []
        setErrorObj("");
        data
          .filter((x) => x.inIteamEdit === true).map((item) => {
            let obj = {}
            obj.in_user_id = cookiesData.out_user_id.toString()
            obj.in_tenant_id = cookiesData.out_tenant_id
            obj.in_db_role_key = item.db_role_key
            obj.in_db_object_key = objTypeList.length > 0 ? objTypeList.find((x) => x.text === item.db_obj_name).value : item.db_obj_key
            obj.in_db_role_name = item.db_role_name
            obj.in_db_role_desc = item.db_role_desc
            obj.in_db_role_type_name = roleTypeKeyList.length > 0 ? roleTypeKeyList.find((x) => x.text === item.db_role_type_name).text : item.db_role_type_name
            tempArray.push(obj)
            return 0;
          })

        let requestData = {
          in_user_id: cookiesData.out_user_id.toString(),
          in_tenant_id: cookiesData.out_tenant_id,
          updateRole: tempArray
        }

        let response = await Action.updateDataAsync(requestData);
        if (response) {
          // TODO: UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
          data.map((item) => {
            item.inEdit = false;
            return 0;
          });

          setData(data);
          onRefreshClick();
          restProps.setRoleEdit(false);
          changeCancelBtnState();
          setIsUpdateClick(false);
        }
      } else {
        const elements = document.getElementsByClassName("k-textbox");
        elements[0].focus();
        // TODO: UNSAVED CHANGES
        // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));
        // changeCancelBtnState("enable");
        changeUpdateBtnState("enable");
      }
    }

  };
  const onDeleteConfirm = async (key) => {
    let deleteArray = []
    if (deleteSelectedIds.length > 0) {
      deleteArray = deleteSelectedIds.map((obj2) => {
        const obj1 = data.find((elem) => elem[DATA_ITEM_KEY] === obj2.id);
        return { id: obj2.id, roleName: obj1.db_role_name };
      });
    } else {
      let dataList = data.filter((elem) => elem[DATA_ITEM_KEY] === restProps.selectedRowId)
      deleteArray = dataList.map((obj2) => {
        let roleKey = obj2?.db_role_key
        const obj1 = data.find((elem) => elem[DATA_ITEM_KEY] === roleKey);
        return { id: roleKey, roleName: obj1.db_role_name };
      });
    }

    let reqData = {
      in_user_id: cookiesData.out_user_id.toString(),
      in_tenant_id: cookiesData.out_tenant_id,
      dbRoleKeyList: deleteArray

    };
    let res = await Action.deleteDataAsync(reqData);
    if (res === 'success') {
      list.setLoadState({ ...list.loadingState, isDeleteData: false });
      const dataVal = {
        ...apiCallData,
        in_filter: getInFilter(),
        in_sort_number: getInSortNumber(),
        in_sort_order: getInSortOrder(),
        in_page_row_offset: getPageSkip(),
        in_page_rows: getPageTake(),
        in_load_type: "grid-reload",
      };
      // if (data.length === 1) {
      //   setFilterData({
      //     ...filterData,
      //     data: "",
      //     isFilter: false,
      //     isSort: false
      //   });
      //   setResetSort(true);
      //   dataVal.in_filter = null
      // }
      restProps.setSelectedRowId("");
      setDeleteSelectedIds([]);
      setSelectedState({})
      setErrorObj({})
      await Action.getRoleList(dataVal);
      changeUpdateBtnState()
      changeCancelBtnState()
    }

  };

  const onCancelConfirm = (action) => {
    setConfirmationPopup({
      ...confirmationPopup,
      isVisible: false,
      type: "",
      actionBtn: [],
      data: {},
      action: "",
    });
  };

  const cancelData = () => {
    // TODO: UNSAVED CHANGES
    // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));

    originalData.map((item) => {
      item.inEdit = false;
      item.selected = false;
      item.expanded = false;
      return 0;
    });

    setEditData("");
    setData(originalData);
    setErrorObj("");
    restProps.setcancelClick(false);
    restProps.setbuttonClick(true);
    restProps.setcancelbuttonClick(true);
    setObjKeyList([]);
    setRoleTypeKeyList([])
    setDeleteSelectedIds([]);
  };

  const setPageMenuState = (response) => {
    if (!deleteSelectedIds.length) {
      return false;
    }

    let ids = response.map((el) => el.db_role_key);

    let selectedKeys = [];
    let tempArray = [];
    let tempArrayData = [];

    Object.keys(selectedState).map((item) => {
      let obj = {};

      obj.id = Number(item);
      selectedKeys.push(item);
      tempArray.push(obj);

      return 0;
    });

    ids.map((item) => {
      if (selectedKeys.includes(item.toString())) {
        tempArrayData.push(item);
      }
      return 0;
    });

    let strSelectedKeys = "";

    if (tempArrayData.length > 0) {
      strSelectedKeys = parseInt(selectedKeys[0]);
    }

    restProps.setSelectedRowId(strSelectedKeys);

  };

  const handlePageChange = async (event) => {
    const pageData = event.page;
    setAPICallingState(true);

    setPage({ skip: pageData.skip, take: pageData.take });

    const dataVal = {
      ...apiCallData,
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: pageData.skip,
      in_page_rows: pageData.take,
    };

    getGridDataList(dataVal)
  };



  const handleColumnMenu = async (columnMenuData, isFilter, isSort, filterObj) => {
    if (isSort) {
      setSortField(columnMenuData)
    } else {
      setFilterField(filterObj)
    }
    let index = 1;
    let filterSkip = getPageSkip();
    let filterTake = getPageTake();

    if (isFilter) {
      filterSkip = 0;
      filterTake = pageSize.recordTake;

      //reset pager when filter applies
      setPage({ skip: filterSkip, take: filterTake });
    }

    if (data.length > 0 && columnMenuData[0] !== undefined) {
      let obj = Object.keys(data[0]);
      index = obj.findIndex((x) => x === columnMenuData[0].field) + 1;
    }
    const filterString = isFilter
      ? getfilterDataString(columnMenuData, gridColumns)
      : filterData.data;

    isFilter = false;
    if (filterString !== "") {
      isFilter = true;
    }

    let sortDir =
      filterData.isSort && columnMenuData.length ? filterData.sort_order : DEFAULT_SORT.order;
    let sortNumber =
      filterData.isSort && columnMenuData.length ? filterData.in_sort_number : DEFAULT_SORT.number;

    isSort = !columnMenuData.length ? false : filterData.isSort;

    if (columnMenuData[0] !== undefined && columnMenuData[0].dir !== undefined) {
      isSort = true;
      sortDir = columnMenuData[0].dir.toUpperCase();
      sortNumber = index;
    }
    const dataVal = {
      ...apiCallData,
      in_tenant_id: cookiesData.out_tenant_id,
      in_filter: isFilter ? filterString : null,
      in_sort_number: sortNumber,
      in_sort_order: sortDir,
      in_page_row_offset: filterSkip,
      in_page_rows: filterTake,
      in_user_key: cookiesData.out_user_id.toString(),
      in_user_role_filter: "> 0",
    };

    setFilterData({
      ...filterData,
      isFilter: isFilter,
      isSort: isSort,
      data: filterString,
      in_sort_number: sortNumber,
      sort_order: sortDir,
    });
    setAPICallingState(true);
    getGridDataList(dataVal)

  };

  const DropDownCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;
      let isInEdit = field === dataItem.inEdit;

      const value = field && dataItem[field] ? dataItem[field] : "";
      const onChange = (e) => {
        if (props.onChange) {
          changeUpdateBtnState("enable");
          props.onChange({
            dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value.text,
          });
        }
      };


      let valueData = objTypeList;
      if (field === "db_role_type_key" || field === 'db_role_type_name') {
        valueData = roleTypeKeyList;
      }
      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <DropDownList
              style={{
                width: "150px",
              }}
              className={field === 'db_obj_name' ? 'capital-letter' : ''}
              onChange={onChange}
              value={valueData && valueData.find((c) => c.text === value)}
              data={valueData}
              textField="text"
              placeholder={"Select"}
              disabled={!valueData.length}
              loading={!valueData.length}
              popupSettings={{
                className: field === 'db_obj_name' ? 'capital-letter' : ''
              }}
            />
          ) : (
            <span title={props.dataItem[props.field || ""]}>
              {props.dataItem[props.field || ""]}
            </span>
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objTypeList, roleTypeKeyList, roleAssignMentList, isError, isChange])


  const onOpenPopup = async (props) => {
    setErrorObj({})
    setLoadingState({
      ...loadingState,
      isEditRoleLoading: true,
    });
    const { dataItem } = props;
    let dataValue = JSON.parse(JSON.stringify(data));
    let findIndex = dataValue.findIndex((x) => x.db_role_key === dataItem.db_role_key);
    dataValue[findIndex].expanded = dataItem.expanded ? false : true;

    const newArray = [];

    for (let i = 0; i < data.length; i++) {
      const object = data[i];

      if (object.inEdit && object.inEdit === true) {
        object.inEdit = false;
      }

      newArray.push(object);
    }

    if (dataItem.expanded) {
      setRoleAssignMentData([]);
      setRoleAssignMentList([]);
    }
    setDeleteSelectedIds([
      { id: dataValue[findIndex][DATA_ITEM_KEY] }
    ])
    setIsChange(false);
    dataValue[findIndex].selected = true;
    generateDataForEdit(dataValue[findIndex], findIndex, dataItem.db_role_key);
    restProps.setSelectedRowId(dataValue[findIndex][DATA_ITEM_KEY]);
    setSelectedState({ [dataValue[findIndex][DATA_ITEM_KEY]]: true });
    setRolemstrKey(dataItem.db_role_key);
    restProps.setcancelbuttonClick(false);
    setDataItem(dataItem);
    setOpenPopup(true);

  };

  const ActionCell = (props) => {
    return (
      <td title="View Role Assignments" className="text-center">
        <span
          className="k-icon k-font-icon k-i-usersettings"
          onClick={() => onOpenPopup(props)}
        ></span>
      </td>
    );
  };

  const NameCell = React.useCallback(
    (props) => {
      const { ariaColumnIndex, columnIndex, dataItem, field, render } = props;

      let value = props.field + dataItem.rowId;

      let isInEdit = field === dataItem.inEdit;

      const handleChange = (e, props, value) => {
        if (props.onChange) {
          // TODO: UNSAVED CHANGES
          // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: true }));
          changeUpdateBtnState("enable");
          props.onChange({
            // dataIndex: 0,
            dataItem: props.dataItem,
            field: props.field,
            syntheticEvent: e.syntheticEvent,
            value: e.target.value,
          });
        }
      };

      const defaultRendering = (
        <td aria-colindex={ariaColumnIndex} data-grid-col-index={columnIndex}>
          {isInEdit ? (
            <Input
              value={dataItem[props.field]}
              onChange={(e) => {
                handleChange(e, props, value);
              }}
              name={`${props.field}`}
              autoComplete="off"
            />
          ) : (
            <span title={props.dataItem[props.field || ""]}>
              {props.dataItem[props.field || ""]}
            </span>
          )}
          {Object.keys(errorObj).length > 0 ? (
            <div role="alert" className="k-form-error k-text-start">
              {Object.keys(errorObj).length > 0 ? errorObj[value] : ""}
            </div>
          ) : (
            false
          )}
        </td>
      );
      return render?.call(undefined, defaultRendering, props);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorObj, isError, roleAssignMentList]
  );

  const useCol = (useColumns) => {
    return useColumns.map((column, idx) => {
      return column.show && column.title !== "" ? (
        <Column
          {...columnMenuProps(column)}
          key={idx}
          cell={
            column.field === "db_obj_name" || column.field === "db_role_type_name"
              ? DropDownCell
              : column.field === "expand"
                ? ActionCell
                : NameCell
          }
          filterable={column.field === "expand" ? false : true}
          sortable={column.field === "expand" ? false : true}
          width={column.field === "expand" ? 80 : column.minWidth}
          reorderable={column.field === "expand" ? false : true}
          resizable={column.field === "expand" ? false : true}
        />
      ) : (
        false
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  let CustomTablesColumn = useCol(gridColumns);

  const onClearFilter = async () => {
    //set filter to false
    setFilterField({ logic: "and", filters: [] })
    setFilterData({
      ...filterData,
      data: "",
      isFilter: false,
    });
    let dataVal = {
      in_filter: null,
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
      in_user_role_filter: ">0"
    }
    let response = await Action.getRoleList(dataVal);
    setPageMenuState(response);

  };


  const getGridDataList = async (dataVal) => {
    let response = await Action.getRoleList(dataVal);
    if (response) {
      setPageMenuState(response);
      changeCancelBtnState();
      changeUpdateBtnState();
      restProps.setDataList(response);
      // TODO: UNSAVED CHANGES
      // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));

      setErrorObj({});
      setIsUpdateClick(false);
    }
  }

  const onRefreshClick = () => {
    setAPICallingState(true);
    // if (restProps.filterApiData !== "" && restProps.filterApiData !== undefined) {
    //   const dataVal = {
    //     ...restProps.filterApiData,
    //     in_filter: getInFilter(),
    //     in_sort_number: getInSortNumber(),
    //     in_sort_order: getInSortOrder(),
    //     in_page_row_offset: getPageSkip(),
    //     in_page_rows: getPageTake(),
    //   };
    //   Action.getRoleList(dataVal);
    //   changeCancelBtnState();
    //   // TODO: UNSAVED CHANGES
    //   // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));

    //   changeUpdateBtnState();
    //   setErrorObj({});
    // } else {
    const dataVal = {
      in_filter: getInFilter(),
      in_sort_number: getInSortNumber(),
      in_sort_order: getInSortOrder(),
      in_page_row_offset: getPageSkip(),
      in_page_rows: getPageTake(),
      in_user_role_filter: ">0"
    };
    getGridDataList(dataVal);
    // }
  };

  function compareObjects(obj1, obj2) {
    for (let key in obj1) {
      if (key !== "selected" && obj1.hasOwnProperty(key)) {
        if (obj1[key] !== obj2[key]) {
          return true; // Return true if any value of a key (except "selected") is different
        }
      }
    }
    return false; // Return false if all values (except "selected") are the same
  }


  function isAllChecked(list, accessKey) {
    // Check if all items in the list have accessKey set to 1
    return list.every(item => item[accessKey] === 1);
  }

  function isMainChecked(list, accessKey) {
    // Check if all items in the list have accessKey set to 1
    return list.some(item => item[accessKey] === 1);
  }
  const handleWriteMainCheckBox = (item, accessKey) => {
    let checked = false;
    if (isAllChecked(item.childData, accessKey)) {
      checked = true;
      // Check for subChildData in each filtered item
      item.childData.forEach(fItem => {

        if (fItem.subChildData.length > 0) {
          if (fItem.subChildData && !isAllChecked(fItem.subChildData, accessKey)) {
            checked = null; // If any subChildData item is not checked, set checked to null
          }
        }
      });
    } else {
      if (!isMainChecked(item.childData, accessKey)) {
        checked = false
      } else {
        checked = null
      }
    }
    return checked
  }

  const handleReadMainCheckBox = (item, accessKey) => {
    let checked = false;
    // let clonedList = JSON.parse(JSON.stringify(roleAssignMentList)); // Deep copy
    // let filteredData = clonedList.filter(x => x.parent_key === item.db_obj_key);
    if (isAllChecked(item.childData, accessKey)) {
      checked = true;
      // Check for subChildData in each filtered item
      item.childData.forEach(fItem => {

        if (fItem.subChildData.length > 0) {
          if (fItem.subChildData && !isAllChecked(fItem.subChildData, accessKey)) {
            checked = null; // If any subChildData item is not checked, set checked to null
          }
        }
      });
    } else {
      if (!isMainChecked(item.childData, accessKey)) {
        checked = false
      } else {
        checked = null
      }
    }
    return checked
  };

  const handleRoleAssignMentCheckbox = (e, item, isParent, access, parentkey, fromWhich) => {
    let roleAssignMentDataList = JSON.parse(JSON.stringify(roleAssignMentData));
    let roleAssignMentListData = JSON.parse(JSON.stringify(roleAssignMentList));
    let parentAssignRoleData = [...roleAssignMentListData]
    let index = roleAssignMentDataList.findIndex((x) => x.db_obj_key === item.db_obj_key);
    if (isParent) {
      roleAssignMentDataList[index][access] = e.target.value ? 1 : 0;
      let filterRoleData = roleAssignMentDataList.find((x) => x.db_obj_key === item.db_obj_key);
      if (filterRoleData.childData) {
        filterRoleData.childData.map((cItem) => {
          if (loggedInUserRoleKey === rolemstrKey && cItem.db_obj_name === 'ROLES') {
            cItem[access] = 1;
          } else {
            cItem[access] = e.target.value ? 1 : 0;
          }

          if (cItem.subChildData.length > 0) {
            cItem.subChildData.map((sItem) => {
              sItem[access] = e.target.value ? 1 : 0;
              return 0;
            });
          }
          return 0;
        });
      }
      if (access === "write_access") {
        roleAssignMentDataList[index]["read_access"] = 1;
        filterRoleData.childData.map((cbItem) => {
          cbItem["read_access"] = 1;
          if (cbItem.subChildData.length > 0) {
            cbItem.subChildData.map((sbItem) => {
              sbItem["read_access"] = 1;
              return 0;
            });
          }
          return 0;
        });
      } else {
        if (access === 'read_access' && e.target.value === false) {
          roleAssignMentDataList[index]["write_access"] = 0;
          filterRoleData.childData.map((cItem) => {
            if (loggedInUserRoleKey === rolemstrKey && cItem.db_obj_name === 'ROLES') {
              cItem["write_access"] = 1;
            } else {
              cItem["write_access"] = 0;
            }

            if (cItem.subChildData.length > 0) {
              cItem.subChildData.map((sItem) => {
                sItem["write_access"] = 0;
                return 0;
              });
            }
            return 0;
          });
        }
      }
      let filterIndex = parentAssignRoleData.findIndex((x) => x.db_obj_key === item.db_obj_key);
      parentAssignRoleData[filterIndex][access] = e.target.value ? 1 : 0;
      let filterRoleListData = parentAssignRoleData.find((x) => x.db_obj_key === item.db_obj_key);
      if (filterRoleListData.childData) {
        filterRoleListData.childData.forEach((cItem) => {
          let childIndex = parentAssignRoleData.findIndex(x => x.db_obj_key === cItem.db_obj_key);

          if (childIndex !== -1) {

            parentAssignRoleData[childIndex][access] = e.target.value ? 1 : 0;

            let childData = parentAssignRoleData[childIndex];
            if (childData.subChildData) {
              childData.subChildData.forEach((sItem) => {
                sItem[access] = e.target.value ? 1 : 0;
                let subChildIndex = parentAssignRoleData.findIndex(x => x.db_obj_key === sItem.db_obj_key);
                if (subChildIndex !== -1) {
                  parentAssignRoleData[subChildIndex][access] = e.target.value ? 1 : 0;
                }
              });
            }
          }
        });
      }
      if (access === "write_access") {
        parentAssignRoleData[filterIndex]["read_access"] = 1;

        filterRoleListData.childData.forEach((cbItem) => {
          let childIndex = parentAssignRoleData.findIndex(x => x.db_obj_key === cbItem.db_obj_key);

          if (childIndex !== -1) {
            parentAssignRoleData[childIndex]["read_access"] = 1

            let childData = parentAssignRoleData[childIndex];

            if (childData.subChildData) {
              childData.subChildData.forEach((sbItem) => {
                sbItem['read_access'] = 1;
                let subChildIndex = parentAssignRoleData.findIndex(x => x.db_obj_key === sbItem.db_obj_key);

                if (subChildIndex !== -1) {
                  parentAssignRoleData[subChildIndex]['read_access'] = 1
                }
                return 0;
              });
            }
          }
        });

      } else {
        if (access === 'read_access' && e.target.value === false) {
          parentAssignRoleData[filterIndex]["write_access"] = 0;

          filterRoleListData.childData.forEach((cbItem) => {
            let childIndex = parentAssignRoleData.findIndex(x => x.db_obj_key === cbItem.db_obj_key);

            if (childIndex !== -1) {
              parentAssignRoleData[childIndex]["write_access"] = 0

              let childData = parentAssignRoleData[childIndex];

              if (childData.subChildData) {
                childData.subChildData.forEach((sbItem) => {
                  sbItem['write_access'] = e.target.value ? 1 : 0;
                  let subChildIndex = parentAssignRoleData.findIndex(x => x.db_obj_key === sbItem.db_obj_key);

                  if (subChildIndex !== -1) {
                    parentAssignRoleData[subChildIndex]["write_access"] = 0
                  }
                });
              }
            }
          });
        }
      }
      let updatedArray = checkSameDataObjectRole(parentAssignRoleData, originalroleData);
      if (updatedArray.length === 0) {
        let orignalEditData = originalData.find((x) => x.db_obj_key === editData.db_obj_key);
        let updatedArrayData = compareObjects(editData, orignalEditData);
        if (!updatedArrayData) {
          setIsChange(false);
        }
      } else {
        setIsChange(true);
      }
    } else {
      if (fromWhich === "subChild") {
        let mainParentKey = roleAssignMentListData.find(
          (x) => x.db_obj_key === parentkey
        ).parent_key;
        let filterSubData = roleAssignMentDataList.find((x) => x.db_obj_key === mainParentKey);

        if (filterSubData) {
          let findSubIndex = filterSubData.childData.findIndex((x) => x.db_obj_key === parentkey);

          let childData = filterSubData.childData;

          let subChildParentIndex = childData[findSubIndex]["subChildData"].findIndex(
            (x) => x.db_obj_key === item.db_obj_key
          );

          filterSubData["childData"][findSubIndex]["subChildData"][subChildParentIndex][access] = e
            .target.checked
            ? 1
            : 0;

          if (access === "write_access") {
            filterSubData["childData"][findSubIndex]["subChildData"][subChildParentIndex][
              "read_access"
            ] = 1;
          } else {
            filterSubData["childData"][findSubIndex]["subChildData"][subChildParentIndex][
              "write_access"
            ] = 0;
          }
          let tempSubData = [];
          filterSubData.childData.map((fItem) => {
            if (fItem.subChildData.length) {
              tempSubData.push(fItem.subChildData);
            }
            return 0;
          });
        }
      } else {
        let filterData = roleAssignMentDataList.find((x) => x.db_obj_key === parentkey);

        if (filterData) {
          let findIndex = filterData.childData.findIndex((x) => x.db_obj_key === item.db_obj_key);

          filterData["childData"][findIndex][access] = e.target.checked ? 1 : 0;

          if (filterData["childData"][findIndex].subChildData.length > 0) {
            filterData["childData"][findIndex].subChildData[0][access] = filterData["childData"][findIndex][access]
          }
          if (access === "write_access") {
            filterData["childData"][findIndex]["read_access"] = 1;
            if (filterData["childData"][findIndex].subChildData.length > 0) {
              filterData["childData"][findIndex].subChildData[0]['read_access'] = 1
            }
          } else {
            filterData["childData"][findIndex]["write_access"] = 0;
            if (filterData["childData"][findIndex].subChildData.length > 0) {
              filterData["childData"][findIndex].subChildData[0]['write_access'] = 0
            }
          }
        }
      }
    }

    if (!isParent) {
      let findIndexValue = roleAssignMentListData.findIndex((x) => x.db_obj_key === item.db_obj_key);
      if (findIndexValue > 0 || findIndexValue === 0) {
        roleAssignMentListData[findIndexValue][access] = e.target.checked ? 1 : 0;

        if (fromWhich === "subChild") {
          let childData = roleAssignMentListData.filter(
            (x) => x.parent_key === item.db_obj_key && x.db_obj_type !== "control"
          );

          childData.map((CItem) => {
            CItem[access] = e.target.checked ? 1 : 0;
            return 0;
          });
          let mainParentKey = roleAssignMentListData.find(
            (x) => x.db_obj_key === parentkey
          ).parent_key;

          let filterDataArray = roleAssignMentDataList.find((x) => x.db_obj_key === mainParentKey);

          let tempSubChildData = [];
          filterDataArray.childData.map((fItem) => {
            if (fItem.subChildData.length) {
              tempSubChildData.push(fItem.subChildData);
            }
            return 0;
          });
          if (access === "write_access") {
            roleAssignMentListData[findIndexValue]["read_access"] = 1;

            childData.map((CItem) => {
              CItem["read_access"] = 1;
              CItem.subChildData.map((sItem) => {
                sItem["read_access"] = 1
                return 0;
              })
              return 0;
            });

          } else {
            roleAssignMentListData[findIndexValue]["write_access"] = 0;
            childData.map((CItem) => {
              CItem["write_access"] = 0;
              return 0;
            });
          }
        } else {
          let childData = roleAssignMentListData.filter(
            (x) => x.parent_key === item.db_obj_key && x.db_obj_type !== "control"
          );

          let roleParentKey = roleAssignMentListData.findIndex(
            (x) => x.db_obj_key === item.parent_key
          );
          childData.map((CItem) => {
            CItem[access] = e.target.checked ? 1 : 0;
            if (CItem?.subChildData && CItem.subChildData.length > 0) {
              CItem.subChildData.map((sItem) => {
                sItem[access] = e.target.checked ? 1 : 0;
                return 0;
              });
            }
            return 0;
          });

          let filterDataArray = roleAssignMentDataList.find((x) => x.db_obj_key === parentkey);
          if (filterDataArray) {
            if (
              filterDataArray["childData"].filter((x) => x[access] === 1).length ===
              filterDataArray["childData"].length
            ) {
              roleAssignMentListData[roleParentKey][access] = 1;
            } else {
              // roleAssignMentListData[roleParentKey][access] = 0;
            }
          }
          if (access === "write_access") {
            roleAssignMentListData[findIndexValue]["read_access"] = 1;

            childData.map((CItem) => {
              CItem["read_access"] = 1;
              if (CItem?.subChildData && CItem?.subChildData.length > 0) {
                CItem.subChildData.map((sItem) => {
                  sItem['read_access'] = 1
                  return 0;
                });
              }
              return 0;
            });

            if (roleAssignMentListData[findIndexValue]?.childData && roleAssignMentListData[findIndexValue]?.childData.length > 0) {
              roleAssignMentListData[findIndexValue]?.childData.map((item) => {
                item.subChildData.map((sItem) => {
                  sItem['read_access'] = 1
                  return 0;
                });
                return 0;
              })
            }

            if (filterDataArray && filterDataArray !== undefined) {
              if (
                filterDataArray["childData"].filter((x) => x[access] === 1).length ===
                filterDataArray["childData"].length
              ) {
                roleAssignMentListData[roleParentKey]["read_access"] = 1;
              }
            }
          } else {
            roleAssignMentListData[findIndexValue]["write_access"] = 0;
            childData.map((CItem) => {
              CItem["write_access"] = 0;
              if (CItem?.subChildData && CItem?.subChildData.length > 0) {
                CItem.subChildData.map((sItem) => {
                  sItem['write_access'] = 0
                  return 0;
                });
              }
              return 0;
            });
            if (roleAssignMentListData[findIndexValue]?.childData && roleAssignMentListData[findIndexValue]?.childData.length > 0) {
              roleAssignMentListData[findIndexValue]?.childData.map((item) => {
                item.subChildData.map((sItem) => {
                  sItem['write_access'] = 0
                  return 0;
                });
                return 0;
              })
            }

            if (filterDataArray && filterDataArray !== undefined) {
              if (
                filterDataArray["childData"].filter((x) => x[access] === 1).length !==
                filterDataArray["childData"].length
              ) {
                roleAssignMentListData[roleParentKey]["write_access"] = 0;

              }
            }
          }
        }
      }
      let updatedArray = checkSameDataObjectRole(roleAssignMentListData, originalroleData);
      if (updatedArray.length === 0) {
        let orignalEditData = originalData.find((x) => x.db_obj_key === editData.db_obj_key);
        let updatedArrayData = compareObjects(editData, orignalEditData);
        if (!updatedArrayData) {
          setIsChange(false);
        }
      } else {
        setIsChange(true);
      }
    }
    let filterCurrentData = JSON.parse(JSON.stringify(roleAssignMentListData))
    let filteredData = [...filterCurrentData]
    let filteredDataList = JSON.parse(JSON.stringify(filteredData))
    let roleAssignMentDataValue = [...roleAssignMentDataList]
    if (!isParent) {
      let currentElemIndex = filterCurrentData.findIndex((x) => x.db_obj_key === item.db_obj_key);
      if (currentElemIndex > -1) {
        if (filterCurrentData[currentElemIndex]?.childData && filterCurrentData[currentElemIndex]?.childData.length > 0) {
          filterCurrentData[currentElemIndex].childData.forEach((childItem, i) => {
            let subData = childItem.subChildData.find((x) => x.parent_key === childItem.db_obj_key)
            if (subData) {
              let findIndex = roleAssignMentListData.findIndex((x => x.db_obj_key === subData.db_obj_key))
              filterCurrentData[findIndex][access] = e.target.checked ? 1 : 0
              if (access === 'write_access' && e.target.checked) {
                filterCurrentData[findIndex]['read_access'] = 1
              }
              if (access === 'read_access' && e.target.checked === false) {
                filterCurrentData[findIndex]['write_access'] = 0
              }
            }

          });
        }
      }
      let parentData = ''
      if (fromWhich === 'subChild') {
        let findParentKey = filterCurrentData.find((x) => x.db_obj_key === parentkey).parent_key
        parentData = roleAssignMentDataList.find((x) => x.db_obj_key === findParentKey)
      } else {
        parentData = roleAssignMentDataList.find((x) => x.db_obj_key === parentkey)
      }
      let tempSubChildData = [];
      if (parentData) {
        let roleParentIndex = roleAssignMentDataValue.findIndex((x) => x.db_obj_key === parentData.db_obj_key)

        parentData.childData.map((fItem) => {
          tempSubChildData.push(fItem);
          if (fItem.subChildData.length) {
            tempSubChildData.push(fItem.subChildData[0]);
            return 0;
          }
          return 0;
        });
        let mainParentIndex = filterCurrentData.findIndex((x) => x.db_obj_key === parentData.db_obj_key)
        if (tempSubChildData.length > 0) {
          if (tempSubChildData.filter((x) => x[access] === 1).length === tempSubChildData.length) {
            roleAssignMentDataValue[roleParentIndex][access] = 1;
            if (fromWhich === 'subChild') {
              filterCurrentData[mainParentIndex][access] = 1;
            }
          } else {
            roleAssignMentDataValue[roleParentIndex][access] = 0;
            if (fromWhich === 'subChild') {
              filterCurrentData[mainParentIndex][access] = 0;
            }
          }

          if (access === 'read_access') {
            if (tempSubChildData.filter((x) => x['write_access'] === 1).length === tempSubChildData.length) {
              roleAssignMentDataValue[roleParentIndex]['write_access'] = e.target.checked ? 1 : 0;
              if (fromWhich === 'subChild') {
                filterCurrentData[mainParentIndex]['write_access'] = e.target.checked ? 1 : 0;
              }
            } else {
              roleAssignMentDataValue[roleParentIndex]['write_access'] = 0;
              if (fromWhich === 'subChild') {
                filterCurrentData[mainParentIndex]['write_access'] = 0
              }
            }
          }
          if (access === 'write_access') {
            if (tempSubChildData.filter((x) => x['write_access'] === 1).length === tempSubChildData.length) {
              roleAssignMentDataValue[roleParentIndex]['write_access'] = e.target.checked ? 1 : 0;
              if (e.target.checked) {
                roleAssignMentDataValue[roleParentIndex]['read_access'] = e.target.checked ? 1 : 0;
              }
              if (fromWhich === 'subChild') {
                filterCurrentData[mainParentIndex]['write_access'] = e.target.checked ? 1 : 0;
              }
            } else {
              roleAssignMentDataValue[roleParentIndex]['write_access'] = 0;
              if (fromWhich === 'subChild') {
                filterCurrentData[mainParentIndex]['write_access'] = 0
              }
            }
          }
        }
        if (access === 'write_access') {

          if (fromWhich === 'subChild') {
            if (tempSubChildData.filter((x) => x['write_access'] === 1).length !== tempSubChildData.length) {
              filterCurrentData[mainParentIndex]['write_access'] = 0
              roleAssignMentDataValue[roleParentIndex]['write_access'] = 0
            }
            if (tempSubChildData.filter((x) => x['read_access'] === 1).length !== tempSubChildData.length) {
              filterCurrentData[mainParentIndex]['read_access'] = 0
              roleAssignMentDataValue[roleParentIndex]['read_access'] = 0
            }
          }
          if (tempSubChildData.filter((x) => x['write_access'] === 1).length === tempSubChildData.length) {
            filterCurrentData[mainParentIndex]['read_access'] = 1
            roleAssignMentDataValue[roleParentIndex]['read_access'] = 1
          }

          if (tempSubChildData.filter((x) => x['read_access'] === 1).length === tempSubChildData.length) {
            filterCurrentData[mainParentIndex]['read_access'] = 1
            roleAssignMentDataValue[roleParentIndex]['read_access'] = 1
          }
        }

        if (access === 'read_access') {
          if (fromWhich === 'subChild') {
            if (tempSubChildData.filter((x) => x['read_access'] === 1).length !== tempSubChildData.length) {
              filterCurrentData[mainParentIndex]['read_access'] = 0
              filterCurrentData[mainParentIndex]['write_access'] = 0
            }
          }

          if (tempSubChildData.filter((x) => x['write_access'] === 1).length === tempSubChildData.length) {
            filterCurrentData[mainParentIndex]['read_access'] = 1
            roleAssignMentDataValue[roleParentIndex]['read_access'] = 1
          }

          if (tempSubChildData.filter((x) => x['read_access'] === 1).length === tempSubChildData.length) {
            filterCurrentData[mainParentIndex]['read_access'] = 1
            roleAssignMentDataValue[roleParentIndex]['read_access'] = 1
          }
        }


      }
      let findIndex = filteredData.findIndex((x) => x.db_obj_key === item.db_obj_key)
      if (findIndex > -1) {
        filteredData[findIndex][access] = e.target.checked ? 1 : 0;
      }

      // Update sub-child
      let updatedFilteredDataList = [...filteredDataList];
      let parentIndex = filteredDataList.findIndex((x) => x.db_obj_key === item.parent_key)
      if (parentIndex > 0) {
        if (filteredDataList[parentIndex].subChildData && filteredDataList[parentIndex].subChildData.length > 0) {
          let subItemData = filteredDataList[parentIndex].subChildData.find((x) => x.db_obj_key === item.db_obj_key)
          subItemData[access] = e.target.checked ? 1 : 0

          if (e.target.checked === false && access === 'read_access') {
            subItemData['write_access'] = 0
          }

          if (fromWhich === 'subChild') {
            if (access === 'write_access') {
              if (tempSubChildData.filter((x) => x['write_access'] === 1).length === tempSubChildData.length) {
                subItemData['read_access'] = 1
              }
            }
          }
        } else {
          let filterIndex = updatedFilteredDataList.findIndex((x) => x.db_obj_key === item.db_obj_key);
          updatedFilteredDataList[filterIndex][access] = e.target.checked ? 1 : 0;
          let filterRoleListData = updatedFilteredDataList.find((x) => x.db_obj_key === item.db_obj_key);
          if (filterRoleListData.childData) {
            filterRoleListData.childData.forEach((cItem) => {
              let childIndex = updatedFilteredDataList.findIndex(x => x.db_obj_key === cItem.db_obj_key);

              if (childIndex !== -1) {
                updatedFilteredDataList[childIndex][access] = e.target.checked ? 1 : 0;

                let childData = updatedFilteredDataList[childIndex];
                if (childData.subChildData) {
                  childData.subChildData.forEach((sItem) => {
                    sItem[access] = e.target.checked ? 1 : 0;
                    let subChildIndex = updatedFilteredDataList.findIndex(x => x.db_obj_key === sItem.db_obj_key);
                    if (subChildIndex !== -1) {
                      updatedFilteredDataList[subChildIndex][access] = e.target.checked ? 1 : 0;
                    }
                  });
                }
              }
            });
          }

          if (access === "write_access") {
            let pIndex = updatedFilteredDataList.findIndex((x) => x.db_obj_key === item.parent_key)
            if (updatedFilteredDataList[pIndex].childData.filter((x) => x['read_access'] === 1).length
              !== updatedFilteredDataList[pIndex].childData.filter((x) => x['read_access']).length
            ) {
              updatedFilteredDataList[filterIndex]["read_access"] = e.target.checked ? 1 : 0;
              if (filterRoleListData.childData) {
                filterRoleListData.childData.forEach((cbItem) => {
                  let childIndex = updatedFilteredDataList.findIndex(x => x.db_obj_key === cbItem.db_obj_key);

                  if (childIndex !== -1) {
                    updatedFilteredDataList[childIndex]["read_access"] = e.target.checked ? 1 : 0;

                    let childData = updatedFilteredDataList[childIndex];

                    if (childData.subChildData) {
                      childData.subChildData.forEach((sbItem) => {
                        sbItem['read_access'] = e.target.checked ? 1 : 0;
                        let subChildIndex = updatedFilteredDataList.findIndex(x => x.db_obj_key === sbItem.db_obj_key);

                        if (subChildIndex !== -1) {
                          updatedFilteredDataList[subChildIndex]['read_access'] = e.target.checked ? 1 : 0;
                        }
                      });
                    }
                  }
                });
              } else {
                updatedFilteredDataList[filterIndex].subChildData.forEach((sbItem) => {
                  sbItem['read_access'] = e.target.checked ? 1 : 0;;
                  sbItem['write_access'] = e.target.checked ? 1 : 0;
                  let subChildIndex = updatedFilteredDataList.findIndex(x => x.db_obj_key === sbItem.db_obj_key);

                  if (subChildIndex !== -1) {
                    updatedFilteredDataList[subChildIndex]['read_access'] = e.target.checked ? 1 : 0;
                    updatedFilteredDataList[subChildIndex]['write_access'] = e.target.checked ? 1 : 0;
                  }
                });
              }
            }
          }

          if (access === "read_access") {
            if (filterRoleListData.childData) {
              filterRoleListData.childData.forEach((cbItem) => {
                let childIndex = updatedFilteredDataList.findIndex(x => x.db_obj_key === cbItem.db_obj_key);

                if (childIndex !== -1) {
                  updatedFilteredDataList[childIndex]["read_access"] = e.target.value ? 1 : 0

                  let childData = updatedFilteredDataList[childIndex];

                  if (childData.subChildData) {
                    childData.subChildData.forEach((sbItem) => {
                      sbItem['read_access'] = e.target.value ? 1 : 0
                      let subChildIndex = updatedFilteredDataList.findIndex(x => x.db_obj_key === sbItem.db_obj_key);

                      if (subChildIndex !== -1) {
                        updatedFilteredDataList[subChildIndex]['read_access'] = e.target.checked ? 1 : 0
                      }
                    });
                  }
                }
              });
            } else {
              updatedFilteredDataList[filterIndex].subChildData.forEach((sbItem) => {
                sbItem['read_access'] = e.target.checked ? 1 : 0;;
                let subChildIndex = updatedFilteredDataList.findIndex(x => x.db_obj_key === sbItem.db_obj_key);

                if (subChildIndex !== -1) {
                  updatedFilteredDataList[subChildIndex]['read_access'] = e.target.checked ? 1 : 0;
                }
              });
            }
          }
        }
      }
      let updatedArray = JSON.parse(JSON.stringify(updatedFilteredDataList))
      setRoleAssignMentList([...updatedArray]);
    } else {
      setRoleAssignMentList([...parentAssignRoleData]);
    }
    setRoleAssignMentData([...roleAssignMentDataValue]);
  };

  function compareTwoArrayOfObjects(array1, array2) {
    if (array1.length !== array2.length) {
      return false;
    }

    // Loop through each object in both arrays
    for (let i = 0; i < array1.length; i++) {
      const obj1 = array1[i];
      const obj2 = array2[i];

      // Compare properties that should be the same
      if (
        obj1.src_mstr_key !== obj2.src_mstr_key ||
        obj1.src_name !== obj2.src_name ||
        obj1.db_role_obj_table_assign_mstr_key !== obj2.db_role_obj_table_assign_mstr_key
      ) {
        return false;
      }

      // Compare access properties
      if (obj1.read_access !== obj2.read_access || obj1.write_access !== obj2.write_access) {
        return false;
      }
    }
    return true;
  }

  const handleControlAssignMentCheckbox = (e, item, isParent, access, parentkey) => {
    setIsControlAcessButtonClick(false);
    let controlAssignMentDataList = JSON.parse(JSON.stringify(controlData));

    let index = controlAssignMentDataList.findIndex((x) => x.db_obj_key === item.db_obj_key);
    controlAssignMentDataList[index][access] = e.target.checked ? 1 : 0;
    let filterRoleData = controlAssignMentDataList.find((x) => x.db_obj_key === item.db_obj_key);
    filterRoleData.read_access = e.target.checked ? 1 : 0;

    setControlData([...controlAssignMentDataList]);
    let ArrayValue = compareTwoArrayOfObjects(controlAssignMentDataList, originalcontrolData);
    if (ArrayValue === true) {
      setIsControlAcessButtonClick(true);
    }
  };
  const handleDataControlAssignMentCheckbox = (e, item, access) => {
    setIsDataAcessButtonClick(false);
    let dataControlAssignMentDataList = JSON.parse(JSON.stringify(dataAccessData));
    let index = dataControlAssignMentDataList.findIndex(
      (x) => x.src_mstr_key === item.src_mstr_key
    );

    dataControlAssignMentDataList[index][access] = e.target.checked ? 1 : 0;
    let filterRoleData = dataControlAssignMentDataList.find(
      (x) => x.src_mstr_key === item.src_mstr_key
    );
    filterRoleData.access = e.target.checked ? 1 : 0;
    if (access === "read_access") {
      dataControlAssignMentDataList[index]["write_access"] = 0;
      let filterRoleDataRead = dataControlAssignMentDataList.find(
        (x) => x.src_mstr_key === item.src_mstr_key
      );
      filterRoleDataRead["write_access"] = 0;
      dataControlAssignMentDataList[index]["filter_access"] = 0;
      filterRoleDataRead = dataControlAssignMentDataList.find(
        (x) => x.src_mstr_key === item.src_mstr_key
      );
      filterRoleDataRead["filter_access"] = 0;
    } else if (access === "write_access" || access === "filter_access") {
      dataControlAssignMentDataList[index]["read_access"] = 1;
      let filterRoleDataWriteFilter = dataControlAssignMentDataList.find(
        (x) => x.src_mstr_key === item.src_mstr_key
      );
      filterRoleDataWriteFilter["read_access"] = 1;
    }

    const checkReadAcessParent = checkParentAccess(dataControlAssignMentDataList, "read_access");
    const checkWriteAcessParent = checkParentAccess(dataControlAssignMentDataList, "write_access");
    const checkFilterAcessParent = checkParentAccess(
      dataControlAssignMentDataList,
      "filter_access"
    );

    // Filter of any child data is checked 
    const checkReadAcessChild = dataControlAssignMentDataList.filter((x) => x['read_access'] > 0)
    const checkWriteAcessChild = dataControlAssignMentDataList.filter((x) => x['write_access'] > 0)
    const checkFilterAcessChild = dataControlAssignMentDataList.filter((x) => x['filter_access'] > 0)

    if (checkReadAcessParent) {
      setIsChecked(true);
    } else {
      setIsChecked(checkReadAcessChild.length === 0 ? false : null);
    }
    if (checkWriteAcessParent) {
      setWriteIsChecked(true);
    } else {
      setWriteIsChecked(checkWriteAcessChild.length === 0 ? false : null);
    }

    if (checkFilterAcessParent) {
      setFilterIsChecked(true);
    } else {
      setFilterIsChecked(checkFilterAcessChild.length === 0 ? false : null);
    }

    setDataAccessData([...dataControlAssignMentDataList]);
    let ArrayValue = compareArrays(dataControlAssignMentDataList, originalDataAccessData);

    if (ArrayValue === true) {
      setIsDataAcessButtonClick(true);
    } else {
      setIsDataAcessButtonClick(false);
    }
  };
  const handleParentDataControlAssignMentCheckbox = (access) => {
    if (access === "read_access") {
      setIsChecked(!isChecked);
      if (!isChecked === false) {
        setWriteIsChecked(false);
        setFilterIsChecked(false);
      }
    } else if (access === "write_access") {
      setWriteIsChecked(!isWriteChecked);
      setIsChecked(true);
    } else if (access === "filter_access") {
      setFilterIsChecked(!isFilterChecked);
      setIsChecked(true);
    }

    let dataControlAssignMentDataList = JSON.parse(JSON.stringify(dataAccessData));
    dataControlAssignMentDataList.map((CItem) => {
      let index = dataControlAssignMentDataList.findIndex(
        (x) => x.src_mstr_key === CItem.src_mstr_key
      );
      if (access === "read_access") {
        dataControlAssignMentDataList[index][access] = !isChecked ? 1 : 0;
        if (!isChecked === false) {
          dataControlAssignMentDataList[index]["write_access"] = 0;
          dataControlAssignMentDataList[index]["filter_access"] = 0;
        }
      } else if (access === "write_access") {
        dataControlAssignMentDataList[index][access] = !isWriteChecked ? 1 : 0;
        dataControlAssignMentDataList[index]["read_access"] = 1;
      } else if (access === "filter_access") {
        dataControlAssignMentDataList[index][access] = !isFilterChecked ? 1 : 0;
        dataControlAssignMentDataList[index]["read_access"] = 1;
      }

      return 0;
    });
    setDataAccessData([...dataControlAssignMentDataList]);
    let ArrayValue = compareArrays(dataControlAssignMentDataList, originalDataAccessData);

    if (ArrayValue === true) {
      setIsDataAcessButtonClick(true);
    } else {
      setIsDataAcessButtonClick(false);
    }
  };

  const handleRoleUpdate = () => {
    setOpenPopup(false);
    let updatedArray = checkSameDataObjectRole(roleAssignMentList, originalroleData);
    let tempData = [];
    updatedArray.forEach((item) => {
      // if (item.subChildData && item.subChildData.length > 0) {
      //   item.subChildData.forEach((sItem) => {
      //     tempData.push(sItem);
      //   });
      //   tempData.push(item);
      // } else {
      //   tempData.push(item);
      // }
      delete item.childData
      delete item.subChildData
      tempData.push(item);
    });
    tempData = tempData.filter((x) => {
      return !(loggedInUserRoleKey === rolemstrKey && (x.db_obj_name === 'ROLES' || x.db_obj_name === 'ADMINISTRATION'));
    });
    if (tempData.length > 0) {
      setAPICallingState(true);
      const dataobj = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_db_role_key: rolemstrKey,
        updateRole: tempData.map((x) => {
          return {
            db_obj_key: x.db_obj_key,
            write_access: x.write_access,
            read_access: x.read_access
          };
        })
      };
      POSTAPI(
        false,
        UPDATE_ROLE_OBJ_TABLE_ASSIGNMENT,
        null,
        generateHeader(),
        dataobj,
        dispatch
      ).then((res) => {
        if (res.data) {
          if (
            res.data.out_parameters.out_ret_code === 0 ||
            res.data.out_parameters.out_ret_code === null
          ) {
            restProps.setbuttonClick(true);
            restProps.setcancelbuttonClick(true);
            let dataValue = JSON.parse(JSON.stringify(data));
            let findIndex = dataValue.findIndex((x) => x.db_role_key === rolemstrKey);
            updatedArray = [];
            if (!isUpdateClick) {
              generateDataForEdit(dataValue[findIndex], findIndex, rolemstrKey);
            } else {
              getObjNameDataList(rolemstrKey);
              onRefreshClick();
            }
            setIsUpdateClick(false);
            changeCancelBtnState();
            // TODO: UNSAVED CHANGES
            // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));

            changeUpdateBtnState();

            // Action
            // let dataValue = JSON.parse(JSON.stringify(data));
            // let findIndex = dataValue.findIndex(
            //   (x) => x.db_role_key === rolemstrKey
            // );
            // dataValue[findIndex].expanded = false;
            // setData(dataValue);
          } else {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
          }
        }
      });
      return 0;
    }
  };

  const handleControlAssignMentUpdate = () => {
    let updatedArray = checkSameObject(controlData, originalcontrolData);
    if (updatedArray.length > 0) {
      const dataobj = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_db_role_key: rolemstrKey,
        updateRole: [...updatedArray],
      };
      POSTAPI(
        false,
        UPDATE_ROLE_OBJ_TABLE_ASSIGNMENT,
        null,
        generateHeader(),
        dataobj,
        dispatch
      ).then((res) => {
        if (res.data) {
          if (res.data.out_parameters.out_ret_code === 0) {
            setIsControlAcess(false);
            setIsControlAcessButtonClick(true);
            // let dataValue = JSON.parse(JSON.stringify(data));
            // let findIndex = dataValue.findIndex(
            //   (x) => x.db_role_key === rolemstrKey
            // );
            // dataValue[findIndex].expanded = false;
            // setData(dataValue);
          } else {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
          }
        }
      });
      return 0;
    }
  };

  const handleDataControlAssignMentUpdate = () => {
    let updatedArray = checkSameDataObject(dataAccessData, originalDataAccessData);
    if (updatedArray.length > 0) {
      setIsDataAcessButtonClick(true);
      const dataobj = {
        in_user_id: cookiesData.out_user_id.toString(),
        in_db_role_key: rolemstrKey,
        in_db_obj_key: dbObjKey,
        insertRoleTableObj: [...updatedArray],
      };
      POSTAPI(
        false,
        INSERT_DB_ROLE_TABLE_ASSIGNMENT,
        null,
        generateHeader(),
        dataobj,
        dispatch
      ).then((res) => {
        if (res.data) {
          if (res.data.out_parameters.out_ret_code === 0) {
            setIsDataAccess(false);
            setDataAccessData([]);
            let roleAssignObjKey = roleAssignMentList.find((x) => x.db_obj_key === dbObjKey)
            if (roleAssignObjKey) {
              let roleAssignData = roleAssignMentData.find((x) => x.db_obj_key === roleAssignObjKey.parent_key)
              let parentIndex = roleAssignMentData.findIndex((x) => x.db_obj_key === roleAssignObjKey.parent_key)
              let roleAssignDataIndex = roleAssignMentData.findIndex((x) => x.db_obj_key === roleAssignData.db_obj_key)
              let checkReadAccess = dataAccessData.filter((x) => x['read_access'] === 1)
              let checkWriteAccess = dataAccessData.filter((x) => x['write_access'] === 1)
              if (roleAssignDataIndex > -1) {
                let roleAssignDataList = [...roleAssignMentData]
                let childIndex = roleAssignDataList[parentIndex].childData.findIndex((x) => x.db_obj_key === dbObjKey)
                roleAssignDataList[parentIndex].childData[childIndex]['data_read_access_flag'] = checkReadAccess.length === originalDataAccessData.length ? 'check' :
                  checkReadAccess.length === 0 ? 'uncheck' : checkReadAccess.length !== originalDataAccessData.length ? 'indeterminate' : 'uncheck'
                roleAssignDataList[parentIndex].childData[childIndex]['data_write_access_flag'] = checkWriteAccess.length === originalDataAccessData.length ? 'check' :
                  checkWriteAccess.length === 0 ? 'uncheck' : checkWriteAccess.length !== originalDataAccessData.length ? 'indeterminate' : 'uncheck'
                setRoleAssignMentData(roleAssignDataList)
              }
            }
            setdbObjKey('')

            // let dataValue = JSON.parse(JSON.stringify(data));
            // let findIndex = dataValue.findIndex(
            //   (x) => x.db_role_key === rolemstrKey
            // );
            // dataValue[findIndex].expanded = false;
            // setData(dataValue);
          } else {
            dispatch(
              setNewMessage({
                message: res.data.out_parameters.out_error_msg,
                type: "Error",
                show: true,
              })
            );
          }
        }
      });
      return 0;
    }
  };

  const handleControlAssignMent = () => {
    setIsControlAcess(false);
    setIsDataAccess(false);
    setControlData([]);
    setDataAccessData([]);
    setIsControlAcessButtonClick(true);
    setIsDataAcessButtonClick(true);
    setErrorObj({});
  };

  const setControlAccess = (roleObjKey, roleObjName) => {
    setIsControlAcess(true);
    setcontrolAccessTitle(roleObjName);

    let objRoleData = {
      in_db_role_key: rolemstrKey,
      in_filter: `AND a.parent_key ='${roleObjKey}' AND a.db_obj_type = 'control'`,
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 100,
    };

    POSTAPI(
      false,
      GET_ROLE_OBJ_TABLE_ASSIGNMENT_ROLE,
      null,
      generateHeader(),
      objRoleData,
      dispatch
    ).then((res) => {
      if (res && res.data && res.data) {
        if (res.data.out_parameters.out_ret_code === 0) {
          let controlData = res.data.row_result;
          setControlData(controlData);
          setOriginalcontrolData(controlData);
        } else {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }
      }
    });
  };
  const setDataAccess = (roleObjKey, roleObjName, roleObjAcess) => {
    setIsDataAcessButtonClick(true);
    setcontrolAccessTitle(roleObjName);
    const WriteAcces = originalroleData.find(function (item) {
      return item.db_obj_key === roleObjKey;
    });
    setdataAccessWriteAccess(WriteAcces.write_access);
    setIsDataAccess(true);
    setdbObjKey(roleObjKey);
    let objRoleData = {
      in_db_role_key: rolemstrKey,
      in_db_obj_key: roleObjKey,
      // in_filter: `AND a.parent_key =\'${roleObjKey}\' AND a.db_obj_type = 'data'`,
      in_filter: null,
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 99999,
    };

    POSTAPI(
      false,
      GET_DB_ROLE_TABLE_ASSIGNMENT,
      null,
      generateHeader(),
      objRoleData,
      dispatch
    ).then((res) => {
      if (res && res.data && res.data) {
        if (res.data.out_parameters.out_ret_code === 0) {
          let dataAccessData = res.data.row_result;
          setDataAccessData(dataAccessData);
          setOriginalDataAccessData(dataAccessData);
          const parentValue = dataAccessData.every(function (item) {
            return item.read_access === 1;
          });
          // Filter of any child data is checked 
          const checkReadAcessChild = dataAccessData.filter((x) => x['read_access'] > 0)
          const checkWriteAcessChild = dataAccessData.filter((x) => x['write_access'] > 0)
          const checkFilterAcessChild = dataAccessData.filter((x) => x['filter_access'] > 0)
          if (parentValue) {
            setIsChecked(true);
          } else {
            setIsChecked(checkReadAcessChild.length === 0 ? false : null);
          }
          const writeParentValue = dataAccessData.every(function (item) {
            return item.write_access === 1;
          });
          if (writeParentValue) {
            setWriteIsChecked(true);
          } else {
            setWriteIsChecked(checkWriteAcessChild.length === 0 ? false : null);
          }
          const filterParentValue = dataAccessData.every(function (item) {
            return item.filter_access === 1;
          });
          if (filterParentValue) {
            setFilterIsChecked(true);
          } else {
            setFilterIsChecked(checkFilterAcessChild.length === 0 ? false : null);
          }
        } else {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }
      }
    });
  };

  const generateDataForEdit = (dataVal, findIndex, db_role_key) => {
    if (dataVal.expanded === true) {
      setAPICallingState(true);
    }

    setRoleAssignMentData([]);
    setRoleAssignMentList([]);
    data.map((item) => {
      item.expanded = false;
      item.selected = item.db_role_key === db_role_key;
      return 0;
    });

    // ele.style.display = "block";
    let objRoleData = {
      in_db_role_key: db_role_key,
      in_filter: null,
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 100,
    };
    POSTAPI(
      false,
      GET_ROLE_OBJ_TABLE_ASSIGNMENT_ROLE,
      null,
      generateHeader(),
      objRoleData,
      dispatch
    ).then((res) => {
      if (res && res.data && res.data) {
        if (res.data.out_parameters.out_ret_code === 0) {
          let parentData = res.data.row_result.filter((x) => x.parent_key === null);
          let tempData = [];
          parentData.map((item, i) => {
            let childData = res.data.row_result.filter((x) => x.parent_key === item.db_obj_key);
            item.paretn_db_role_key = db_role_key;
            item.childData = childData.filter((x) => x.db_obj_code !== 'custom_fields');;
            childData.map((cItem) => {
              if (
                cItem.db_obj_code === "result_name" ||
                cItem.db_obj_code === "kpi" ||
                cItem.db_obj_code === "events"
              ) {
                let subChildData = res.data.row_result.filter(
                  (x) => x.parent_key === cItem.db_obj_key
                );
                cItem.subChildData = subChildData;
              } else {
                cItem.subChildData = [];
              }
              return 0;
            });
            tempData.push(childData);
            return 0;
          });
          setRoleAssignMentData(parentData);
          setAPICallingState(false);
          // dataVal.expanded = true;
          // setData(dataVal);
          setOriginalrolelData(res.data.row_result.filter((x) => x.db_obj_code !== 'custom_fields'));
          setRoleAssignMentList(res.data.row_result.filter((x) => x.db_obj_code !== 'custom_fields'));
          setLoadingState({
            ...loadingState,
            isEditRoleLoading: false,
          });
        } else {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }
      }
    });

    data[findIndex] = dataVal;
    setData(data);
    restProps.setDataList([...data, dataVal]);
  };

  const getObjNameDataList = (db_role_key) => {
    let objRoleData = {
      in_db_role_key: db_role_key,

      in_filter: null,
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 100,
    };
    POSTAPI(
      false,
      GET_ROLE_OBJ_TABLE_ASSIGNMENT_ROLE,
      null,
      generateHeader(),
      objRoleData,
      dispatch
    ).then((res) => {
      if (res && res.data && res.data) {
        if (res.data.out_parameters.out_ret_code === 0) {
          let parentData = res.data.row_result.filter((x) => x.parent_key === null);
          let tempData = [];
          parentData.map((item) => {
            let childData = res.data.row_result.filter((x) => x.parent_key === item.db_obj_key);
            item.paretn_db_role_key = db_role_key;
            item.childData = childData;
            tempData.push(childData);
            return 0;
          });
          setRoleAssignMentData(parentData);
          setAPICallingState(false);
          // dataVal.expanded = true;
          // setData(dataVal);
          setOriginalrolelData(res.data.row_result);
          setRoleAssignMentList(res.data.row_result);
        } else {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }
      }
    });
  };

  const getObjNameData = (db_role_key, editData, dataItem) => {
    let objRoleData = {
      in_db_role_key: db_role_key,
      in_filter:
        "AND (a.read_access = 1 AND a.db_obj_type = 'menu') AND (a.parent_key IS NOT NULL OR a.db_obj_code = 'dashboardpage') ",
      // in_filter:null,
      in_sort_number: 2,
      in_sort_order: "ASC",
      in_page_row_offset: 0,
      in_page_rows: 100,
    };
    POSTAPI(
      false,
      GET_ROLE_OBJ_TABLE_ASSIGNMENT_ROLE,
      null,
      generateHeader(),
      objRoleData,
      dispatch
    ).then((res) => {
      if (res && res.data && res.data) {
        if (res.data.out_parameters.out_ret_code === 0) {
          let objData = res.data.row_result.filter((x) => x.parent_key !== null);
          let tempData = [];
          objData.filter((x) => x.is_menu === 1).map((item) => {
            let obj = {};
            obj.text = item.db_obj_name;
            obj.value = item.db_obj_key;
            tempData.push(obj);
            return 0;
          });
          setObjKeyList(tempData.sort((a, b) => a.db_obj_order - b.db_obj_order));
          if (editData) {
            editData.selected = editData.db_role_key ? false : editData.selected;
            let newData = data.map((item) =>
              item.db_role_key === dataItem.db_role_key
                ? { ...item, inEdit: true }
                : { ...item, inEdit: false }
            );
            setData(newData);
          }
          getRoleTypeKeyList();
        } else {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }
      }
    });
  };
  const getRoleTypeKeyList = () => {
    GETAPI(false, GET_DB_ROLE_TYPE_KEY_LIST, null, generateHeader(), null, dispatch).then((res) => {
      if (res && res.data) {
        if (res.data.out_parameters.out_ret_code === 0) {
          let tempData = [];
          res.data.row_result.map((item) => {
            let obj = {};
            obj.text = item.db_role_type_name;
            obj.value = item.db_role_type_key;
            tempData.push(obj);
            return 0;
          });
          setRoleTypeKeyList(tempData);
          // setFormFields({
          //   ...formFields,
          //   in_db_role_type_key: res.data.row_result[0].db_role_type_name,
          // });
        } else {
          dispatch(
            setNewMessage({
              message: res.data.out_parameters.out_error_msg,
              type: "Error",
              show: true,
            })
          );
        }
      }
    });
  };

  const handleValidation = (dataItem) => {
    let isValid = true;
    data
      .filter((x) => x.inIteamEdit === true)
      .map((item) => {
        const { db_role_name, db_role_desc, rowId } = item;
        let errorObj = {
          db_role_name: "",
          db_role_desc: ""
        };
        if (db_role_name.trim() === "") {
          errorObj.db_role_name = "Please enter role name";
        } else {
          errorObj.db_role_name = "";
        }
        if (db_role_desc.trim() === "") {
          errorObj.db_role_desc = "Please enter role description";
        } else {
          errorObj.db_role_desc = "";
        }

        let obj = {};
        for (const [key, value] of Object.entries(errorObj)) {
          obj[`${key + rowId}`] = `${value}`;
        }

        setErrorObj(obj);
        for (const [, value] of Object.entries(obj)) {
          if (value !== "") {
            isValid = false;
          }
        }
        return 0;
      });
    return isValid;
  };

  const enterEdit = (dataItem, field) => {
    setIsChange(false);
    changeCancelBtnState("enable");
    if (restProps.pageProps.writeAccess === 0) {
      return false;
    }

    let newData = data.map((item) => ({
      ...item,
      inEdit: item.db_role_key === dataItem.db_role_key ? field : undefined,
    }));
    setIsUpdateClick(true);
    setData(newData);
    if (field === "db_role_type_key" || field === 'db_role_type_name' || field === 'db_obj_name') {
      getObjNameData(dataItem.db_role_key);
    }
  };

  const itemChange = (event) => {
    restProps.setRoleEdit(true);
    const field = event.field || "";
    const newData = data.map((item) =>
      item.db_role_key === event.dataItem.db_role_key
        ? {
          ...item,
          [field]: event.value,
          inIteamEdit: true
        }
        : item
    );
    let editItem = event.dataItem;
    editItem[field] = event.value;
    delete editItem.inEdit;
    setEditData(editItem);
    setData(newData);
    restProps.setDataList(newData);
    restProps.setbuttonClick(false);
    restProps.setcancelbuttonClick(false);
    // restProps.seteditClick(true);
  };

  // function removeInEdit(array) {
  //   const newArray = array.map((obj) => {
  //     const { inEdit, ...rest } = obj;
  //     return rest;
  //   });
  //   return newArray;
  // }

  const onRowClick = (dataItem) => {
    let newData = data.map((item) => ({
      ...item,
      selected: item.db_role_key === dataItem.db_role_key ? true : false,
    }));
    setData(newData);
    restProps.setSelectedRowId(dataItem.db_role_key);
    restProps.setDataList(newData);
  };

  const CustomCellRead = (props) => {
    const value = props.dataItem.read_access;
    return (
      <td className="k-command-cell delRecord">
        <input
          // className="k-checkbox"
          name="read_access"
          type="checkbox"
          id="read_access"
          value={value}
          checked={value}
          onChange={(e) => {
            handleDataControlAssignMentCheckbox(e, props.dataItem, "read_access");
          }}
        />
      </td>
    );
  };

  const ActionHeaderRead = (props) => {
    let valueChecked = 0;
    return (
      <>
        <Checkbox
          className="dataaccess-checkbox"
          name="read_access"
          type="checkbox"
          id="read_access"
          value={valueChecked}
          checked={isChecked}
          onChange={(e) => {
            handleParentDataControlAssignMentCheckbox("read_access");
          }}
        />
        &nbsp;&nbsp;
        <span>Read Access</span>
      </>
    );
  };
  const ActionHeaderWrite = (props) => {
    let valueChecked = 0;
    return (
      <>
        <Checkbox
          className="dataaccess-checkbox"
          name="write_access"
          type="checkbox"
          id="write_access"
          value={valueChecked}
          checked={isWriteChecked}
          onChange={(e) => {
            handleParentDataControlAssignMentCheckbox("write_access");
          }}
          disabled={dataAccessWriteAccess === 0 ? true : false}
        />
        &nbsp;&nbsp;
        <span>Write Access</span>
      </>
    );
  };
  const ActionHeaderFilter = (props) => {
    let valueChecked = 0;
    return (
      <>
        <Checkbox
          className="dataaccess-checkbox"
          // className="k-checkbox"
          name="filter_access"
          type="checkbox"
          id="filter_access"
          value={valueChecked}
          checked={isFilterChecked}
          onChange={(e) => {
            handleParentDataControlAssignMentCheckbox("filter_access");
          }}
        />
        &nbsp;&nbsp;
        <span>Filter Access</span>
      </>
    );
  };
  const CustomCellWrite = (props) => {
    const value = props.dataItem.write_access;
    return (
      <td className="k-command-cell delRecord">
        <input
          // className="k-checkbox"
          name="write_access"
          id="write_access"
          type="checkbox"
          value={value}
          checked={value}
          onChange={(e) => {
            handleDataControlAssignMentCheckbox(e, props.dataItem, "write_access");
          }}
          disabled={dataAccessWriteAccess === 0 ? true : false}
        />
      </td>
    );
  };
  const CustomCellFilter = (props) => {
    const value = props.dataItem.filter_access;
    return (
      <td className="k-command-cell delRecord">
        <input
          // className="k-checkbox"
          name="filter_access"
          id="filter_access"
          type="checkbox"
          value={value}
          checked={value}
          onChange={(e) => {
            handleDataControlAssignMentCheckbox(e, props.dataItem, "filter_access");
          }}
        />
      </td>
    );
  };
  const MyCustomCellForRead = (props) => <CustomCellRead {...props} />;
  const MyCustomCellForWrite = (props) => <CustomCellWrite {...props} />;
  const MyCustomCellForFilter = (props) => <CustomCellFilter {...props} />;

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  const camelize = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  };

  const onClosePopup = () => {
    setOpenPopup(false);
    setRolemstrKey("");
  };

  const handleRoleAssignMentSave = async () => {
    let expandedData = data.find((x) => x.db_role_key === rolemstrKey);
    if (expandedData !== undefined) {
      const childData = findChildData(expandedData.db_obj_name);
      const hasReadAccess = checkProperty(childData, "read_access", 1);
      let response = "";
      if (!hasReadAccess) {
        handleLandingPageDilouge();
        setLandingPageText(expandedData.db_obj_name);
        return false;
      }
      setIsChange(false);
      await handleRoleUpdate();
      if (response) {
        // TODO: UNSAVED CHANGES
        // dispatch(setUnSavedChangesPopup({ ...unsavedPopup, isUnSavedChange: false }));
        data.map((item) => {
          item.inEdit = false;
          return 0;
        });
        setData(data);
        onRefreshClick();
        restProps.setRoleEdit(false);
        setObjKeyList([])
      }
    }
  };


  const onSaveBtnClick = () => {
    handleRoleAssignMentSave();
  };

  const onCancelBtnClick = () => {
    setOpenPopup(false);
    setRolemstrKey("");
  };

  const RoleAssignMentButtons = () => {
    return (
      <>
        <Button type="submit" className="cancelbtn" onClick={onCancelBtnClick}>
          Cancel
        </Button>
        <Button type="submit" className="primarybtn" disabled={!isChange} onClick={onSaveBtnClick}>
          Save
        </Button>
      </>
    );
  };

  const headerConfig = {
    title: ` ${dataItem?.db_role_name} | ${dataItem?.db_role_type_name}`,
    id: "role-assignment",
    buttons: { template_fx: RoleAssignMentButtons },
  };

  const setNewSelectedRow = (newState, isReset) => {
    setSelectedState(newState);
    let tempArray = [];

    const keys = Object.keys(newState).filter(key => newState[key] === true);

    keys.map((item) => {

      let obj = {};
      obj.id = Number(item);
      tempArray.push(obj);
      return 0;
    });

    restProps.setSelectedRowId(tempArray[0]);
    setDeleteSelectedIds(tempArray);
    if (isReset) {
      originalData.map((item) => {
        item.inEdit = false;
        item.selected = Number(item[DATA_ITEM_KEY]) === Number(keys[0]);
        return 0;
      });
      setData(originalData);
      restProps.setDataList(originalData);
    } else {
      restProps.setDataList(data);
    }

  };

  const onSelectionChange = (event) => {
    const newSelectedState = getSelectedState({
      event,
      selectedState: selectedState,
      dataItemKey: DATA_ITEM_KEY,
    });
    // let newKeys = Object.keys(newSelectedState);
    // let oldKeys = Object.keys(selectedState);
    // const isSame = isSameRow(oldKeys, newKeys);

    // if (unsavedPopup.isUnSavedChange && !isSame) {
    //   dispatch(setUnSavedChangesPopup({
    //     ...unsavedPopup, isVisible: true, callbackFn: () => {
    //       setNewSelectedRow(newSelectedState, true);
    //       setErrorObj({});
    //       changeUpdateBtnState("disable", "grid-incell-update");
    //       changeCancelBtnState("disable", "grid-incell-cancel");
    //     }
    //   }));
    //   return;
    // }
    setNewSelectedRow(newSelectedState, false)

  };

  const customRowRender = (tr, props) => (
    <RowRender originalProps={props} tr={tr} editField={editField} />
  );
  const customCellRender = (td, props) => {
    return (
      <CellRender
        navigatable={true}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
        onRowClick={onRowClick}
        updateActBtn="grid-incell-update"
        cancelActBtn="grid-incell-cancel"
      />
    );
  };

  const { columnReorderData } = useColumnReorder(
    cookiesData,
    setColumns,
    "role",
    "procare_admin"
);


  const handleStageChange = (e) => {
    setStateChange(e.state)
    if (e.state === 'MINIMIZED') {
      setModal(false)
    } else if (e.state === 'DEFAULT') {
      setModal(true)
    }
  };


  return (
    <>
      {isRefreshLoading && loadingPanel}
      {columns.length > 0 ? (
        <DataTable
          isAPICalling={isAPICalling}
          initialLoading={initialLoading}
          data={data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          noDataFound={noDataFound}
          id='role-table-grid'
          pageData={pageData}
          editField={editField}
          dataCount={dataCount}
          customColumn={CustomTablesColumn}
          columns={gridColumns}
          dataItemKey={DATA_ITEM_KEY}
          width={"auto"}
          module={"role-table"}
          defaultPageSize={defaultPageSize}
          pageSize={pageSize}
          setPageSize={setPageSize}
          handleColumnMenu={handleColumnMenu}
          handlePageChange={handlePageChange}
          refreshClick={onRefreshClick}
          onClearFilter={onClearFilter}
          onSelectionChange={onSelectionChange}
          rowRender={customRowRender}
          cellRender={customCellRender}
          itemChange={itemChange}
          reorderable={true}
          columnReorderData={columnReorderData}
          initialFilter={filterField}
          initialSort={sortField}
          resetSort={resetSort}
        />
      ) : (
        <>{loadingPanel}</>
      )}
      <DialogComponent
        title={confirmationPopup.title}
        onCancelConfirm={onCancelConfirm}
        width={"450"}
        height={"auto"}
        className={"Dialog-Delete"}
        desc={confirmationPopup.desc}
        visible={confirmationPopup.isVisible}
        actionBtn={confirmationPopup.actionBtn}
        titleClassName={confirmationPopup.action === "Delete" ? "delete-confirm" : "unSave-confirm"}
      />

      {isControlAcess ? (
        controlData.length > 0 ? (
          <>
            <Dialog
              title={`Manage ${camelize(controlAccessTitle)} Control Access`}
              onRemove={handleControlAssignMent}
              onClose={handleControlAssignMent}
              appendTo={null}
            >
              <p>
                {controlData.map((controlItem) => {
                  return (
                    <div className="dialogheadcheckbox checkboxhighlight">
                      <h4>
                        <span>{controlItem.db_obj_name}</span>
                        <div className="floatleft mr3">
                          <label>
                            <input
                              type="checkbox"
                              name="true"
                              checked={controlItem.read_access}
                              value=""
                              id="t"
                              onChange={(e) => {
                                handleControlAssignMentCheckbox(
                                  e,
                                  controlItem,
                                  false,
                                  "read_access",
                                  controlItem.parent_key
                                );
                              }}
                            />{" "}
                          </label>
                        </div>
                      </h4>
                    </div>
                  );
                })}
              </p>

              <div className="dropdownFooter">
                <div className="k-form-buttons">
                  <Button
                    className="primarybtn"
                    onClick={handleControlAssignMentUpdate}
                    disabled={isControlAcessButtonClick}
                  >
                    Save
                  </Button>
                  <Button className="cancelbtn" onClick={handleControlAssignMent}>
                    Cancel
                  </Button>
                </div>
              </div>
            </Dialog>
          </>
        ) : (
          <>{loadingPanel}</>
        )
      ) : (
        false
      )}
      {isDataAcess ? (
        <>
          <Window
            title={`Manage Data Access || ${camelize(controlAccessTitle)}`}
            onRemove={handleControlAssignMent}
            onClose={handleControlAssignMent}
            width={900}
            height={800}
          >
            {dataAccessData.length > 0 ? (
              <div
                className="dtu-file-container managedataaccesspopup"
              // ref={divRef}
              >
                <div className="dialogheadcheckbox">
                  <Grid data={dataAccessData} width={800}>
                    <GridColumn
                      field="src_name"
                      title={camelize(controlAccessTitle)}
                      width="100px"
                    />
                    <GridColumn
                      field="read_access"
                      title="Read Access"
                      cell={MyCustomCellForRead}
                      width="50px"
                      headerCell={ActionHeaderRead}
                    />
                    <GridColumn
                      field="write_access"
                      title="Write Access"
                      cell={MyCustomCellForWrite}
                      width="50px"
                      headerCell={ActionHeaderWrite}
                    />
                    {controlAccessTitle === "CUSTOM TABLES" ? (
                      <GridColumn
                        field="filter_access"
                        title="Filter Access"
                        cell={MyCustomCellForFilter}
                        width="50px"
                        headerCell={ActionHeaderFilter}
                      />
                    ) : (
                      false
                    )}
                  </Grid>
                </div>

              </div>
            ) : (
              <>{loadingPanel}</>
            )}
            <WindowActionsBar layout={'end'}>
              <Button
                className="primarybtn"
                onClick={handleDataControlAssignMentUpdate}
                disabled={isDataAcessButtonClick}
              >
                Save
              </Button>
              <Button className="cancelbtn" onClick={handleControlAssignMent}>
                Cancel
              </Button>
            </WindowActionsBar>
          </Window>
        </>
      ) : (
        false
      )}

      {isLandingPage ? (
        <Dialog title={"Error"} id="dialog-timeout" width={500}>
          <p style={{ margin: "25px" }}>
            Landing page {landingPageText}'s Read access must be checked for the selected role
          </p>

          <DialogActionsBar>
            <button
              className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
              onClick={handleLandingPageDilouge}
            >
              Ok
            </button>
          </DialogActionsBar>
        </Dialog>
      ) : (
        false
      )}
      {openPopUp && (
        <Window
          className={"Dialog-dtulogs Dialog-lModal customtabledatacont"}
          title={<ModalTitle />}
          onClose={() => {
            onClosePopup();
          }}
          width={1400}
          height={645}
          stage={stageChange}
          onStageChange={handleStageChange}
          modal={modal}
        >
          {!loadingState.isEditRoleLoading ? (
            <div className="roles-modal rolemodel-padding" id={"roles-modal_"}>
              <>
                <div className="content-head stickeybtns">
                  <HeaderPage pageProps={restProps.pageProps} headerConfig={headerConfig} />
                </div>

                <div className="rolesContent">
                  {roleAssignMentData.map((item, i) => {
                    return (
                      <div className="section" id={"section" + i} key={item.db_obj_key + i}>
                        <h2>
                          <i className="fas fa-compress"></i>
                          {item.db_obj_name}
                        </h2>
                        <div className="formFields" >
                          <div className="read-write">
                            <div className="flag-check ">
                              <label htmlFor={item.db_obj_key + "read"}> READ </label>
                              <Checkbox
                                value={handleReadMainCheckBox(item, 'read_access')}
                                name={item.db_obj_key + "read"}
                                id={item.db_obj_key + "read"}
                                checked={handleReadMainCheckBox(item, 'read_access')}
                                onChange={(e) => {
                                  handleRoleAssignMentCheckbox(e, item, true, "read_access");
                                }} />
                            </div>
                            <div className="flag-check ">
                              <label htmlFor={item.db_obj_key + "read"}> WRITE </label>
                              <Checkbox
                                value={handleWriteMainCheckBox(item, 'write_access')}
                                name={item.db_obj_key + "write"}
                                id={item.db_obj_key + "write"}
                                checked={handleWriteMainCheckBox(item, 'write_access')}
                                onChange={(e) => {
                                  handleRoleAssignMentCheckbox(e, item, true, "write_access");
                                }}
                              />
                            </div>
                          </div>
                          {item.childData.map((cItem, ci) => {
                            return (
                              <div className="rw" key={'section_' + ci}>
                                <span>
                                  <div className="itemwithicon">
                                    <div className="rolenamelabel">{cItem.db_obj_name}</div>

                                    {cItem.control_access === 1 ? (
                                      <div className="contain-acess">
                                        {" "}
                                        <i
                                          className="fas nav_icon_users tooltip"
                                          onClick={() =>
                                            setControlAccess(
                                              cItem.db_obj_key,
                                              cItem.db_obj_name
                                            )
                                          }
                                        >
                                          <span className="tooltiptext">
                                            <Tooltip> {"Manage Granular Level Access"}</Tooltip>
                                          </span>
                                        </i>
                                        {/* <Tooltip>  <div id="agglomerations">fffff</div></Tooltip> */}
                                      </div>
                                    ) : cItem.data_access === 1 ? (
                                      <div className="contain-acess">
                                        <i
                                          className="fas nav_icon_custom_tables tooltip"
                                          onClick={() =>
                                            setDataAccess(
                                              cItem.db_obj_key,
                                              cItem.db_obj_name,
                                              cItem.write_access
                                            )
                                          }
                                        >
                                          <span className="tooltiptext">
                                            {"Manage Granular Level Access"}
                                          </span>
                                        </i>
                                      </div>
                                    ) : (
                                      false
                                    )}
                                  </div>
                                  <label>
                                    <input
                                      type="checkbox"
                                      name="true"
                                      checked={cItem.read_access}
                                      value=""
                                      id="t"
                                      className={cItem.data_read_access_flag === 'uncheck' ? 'role-checkbox' : 'red'}
                                      onChange={(e) => {
                                        handleRoleAssignMentCheckbox(
                                          e,
                                          cItem,
                                          false,
                                          "read_access",
                                          item.db_obj_key
                                        );
                                      }}
                                      disabled={loggedInUserRoleKey === rolemstrKey && cItem.db_obj_name === 'ROLES' ? true : false}
                                    />{" "}
                                    <input
                                      type="checkbox"
                                      className={cItem.data_write_access_flag === 'uncheck' ? 'role-checkbox' : 'red'}
                                      onChange={(e) => {
                                        handleRoleAssignMentCheckbox(
                                          e,
                                          cItem,
                                          false,
                                          "write_access",
                                          item.db_obj_key
                                        );
                                      }}
                                      name="true"
                                      value=""
                                      id="f"
                                      checked={cItem.write_access}
                                      disabled={loggedInUserRoleKey === rolemstrKey && cItem.db_obj_name === 'ROLES' ? true : false}
                                    />
                                  </label>
                                </span>
                                {cItem.subChildData &&
                                  cItem.subChildData.map((sItem, si) => {
                                    return (
                                      <span>
                                        <div className="itemwithicon" key={'section12' + si}>
                                          <div className="rolenamelabel">
                                            {sItem?.db_obj_name}
                                          </div>
                                          {sItem.control_access === 1 ? (
                                            <div className="contain-acess">
                                              {" "}
                                              <i
                                                className="fas nav_icon_users tooltip"
                                                onClick={() =>
                                                  setControlAccess(
                                                    sItem.db_obj_key,
                                                    sItem.db_obj_name
                                                  )
                                                }
                                              >
                                                <span className="tooltiptext">
                                                  <Tooltip>
                                                    {" "}
                                                    {"Manage Granular Level Access"}
                                                  </Tooltip>
                                                </span>
                                              </i>
                                            </div>
                                          ) : sItem.data_access === 1 ? (
                                            <div className="contain-acess">
                                              <i
                                                className="fas nav_icon_custom_tables tooltip"
                                                onClick={() =>
                                                  setDataAccess(
                                                    sItem.db_obj_key,
                                                    sItem.db_obj_name,
                                                    sItem.write_access
                                                  )
                                                }
                                              >
                                                <span className="tooltiptext">
                                                  {"Manage Granular Level Access"}
                                                </span>
                                              </i>
                                            </div>
                                          ) : (
                                            false
                                          )}
                                        </div>
                                        <label>
                                          <input
                                            type="checkbox"
                                            name="true"
                                            disabled={cItem.db_obj_key === sItem.parent_key ? cItem.read_access === 1 ? false : true : true}
                                            checked={sItem.read_access}
                                            className={cItem.db_obj_key === sItem.parent_key ? cItem.read_access === 1 ? '' : 'disabled-pointer' : 'disabled-pointer'}
                                            value=""
                                            id="t"
                                            onChange={(e) => {
                                              handleRoleAssignMentCheckbox(
                                                e,
                                                sItem,
                                                false,
                                                "read_access",
                                                sItem.parent_key,
                                                "subChild"
                                              );
                                            }}
                                          />{" "}
                                          <input
                                            type="checkbox"
                                            disabled={cItem.db_obj_key === sItem.parent_key ? cItem.write_access === 1 ? false : true : true}
                                            className={cItem.db_obj_key === sItem.parent_key ? cItem.write_access === 1 ? '' : 'disabled-pointer' : 'disabled-pointer'}
                                            onChange={(e) => {
                                              handleRoleAssignMentCheckbox(
                                                e,
                                                sItem,
                                                false,
                                                "write_access",
                                                sItem.parent_key,
                                                "subChild"
                                              );
                                            }}
                                            name="true"
                                            value=""
                                            id="f"
                                            checked={sItem.write_access}
                                          />
                                        </label>
                                      </span>
                                    );
                                  })}
                              </div>

                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            </div>
          ) : (
            <LoadingPanel />
          )}
        </Window>
      )}
    </>
  );
};

export const GridButton = () => {
  return (
    <>
      <Button type="submit" className="cancelbtn" id="grid-incell-cancel">
        Cancel
      </Button>
      <Button type="submit" className="primarybtn" id="grid-incell-update">
        Save
      </Button>
    </>
  );
};
